import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Avatar, Typography } from "antd";
import Swal from "sweetalert2";
import screenfull from "screenfull";
import axios from "axios";

const { Text } = Typography;

//used (latest only)

//extension
function preventDefault(e) {
    e.preventDefault();
}
function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}
//end extension

// call this to Disable
export function disableScroll() {
    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
        window.addEventListener(
            "test",
            null,
            Object.defineProperty({}, "passive", {
                get: function () {
                    supportsPassive = true;
                },
            })
        );
    } catch (e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
    window.addEventListener("keydown", preventDefaultForScrollKeys, false);
}

// call this to Enable
export function enableScroll() {
    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
        window.addEventListener(
            "test",
            null,
            Object.defineProperty({}, "passive", {
                get: function () {
                    supportsPassive = true;
                },
            })
        );
    } catch (e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    window.removeEventListener("DOMMouseScroll", preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener("touchmove", preventDefault, wheelOpt);
    window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
}
//end used

export async function handleConnectSignalR(setConnection, apiUrl) {
    const connection = new HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl(apiUrl, HttpTransportType.WebSockets)
        .configureLogging(LogLevel.Information)
        .build();
    try {
        // console.clear();
        setConnection();
        // console.log(connection);
        connection.serverTimeoutInMilliseconds = 1800000; //30min
        await connection.start();
        setConnection(connection);
    } catch (error) {
        // connection.stop();
        Swal.fire({
            text: error,
            icon: "error",
        });
    }
}

export const langArr = [
    {
        key: "gb",
        label: "English",
    },
    {
        key: "cn",
        label: "中文",
    },
    {
        key: "my",
        label: "Malay",
    },
    {
        key: "pt",
        label: "português",
    },
    {
        key: "vn",
        label: "Tiếng Việt",
    },
    {
        key: "th",
        label: "ประเทศไทย",
    },
    {
        key: "kr",
        label: "한국",
    },
    {
        key: "mm",
        label: "မြန်မာ",
    },
];

export function getBetRate(dbArr, code) {
    let rate;

    dbArr?.reduce((obj, item) => {
        if (item.code === code) {
            rate = item.rate;
        }
    }, []);

    return rate;
}

export function verticalArr(array, chunkSize) {
    let chunks = [];

    array.forEach(function (element, index) {
        var group = index % chunkSize;
        var temp = chunks[group];

        if (!Array.isArray(temp)) {
            temp = [];
        }

        temp.push(element);
        chunks[group] = temp;
    });

    return chunks;
}

export function getChipImage(tableDetail, amount) {
    switch (true) {
        case amount >= tableDetail?.data?.chips?.split(",")[6]:
            return "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip7.png";
        case amount >= tableDetail?.data?.chips?.split(",")[5]:
            return "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip5.png";
        case amount >= tableDetail?.data?.chips?.split(",")[4]:
            return "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip4.png";
        case amount >= tableDetail?.data?.chips?.split(",")[3]:
            return "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip3.png";
        case amount >= tableDetail?.data?.chips?.split(",")[2]:
            return "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip2.png";
        case amount >= tableDetail?.data?.chips?.split(",")[1]:
            return "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip1.png";
        case amount >= tableDetail?.data?.chips?.split(",")[0]:
            return "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip0.png";
        default:
            break;
    }
}

export function appendChip(tableDetail, bet, code, className) {
    if (bet?.hasOwnProperty(code)) {
        return (
            <div className={className}>
                <Avatar
                    src={getChipImage(tableDetail, bet[code])}
                    size={{
                        xs: 30,
                        sm: 35,
                        md: 40,
                    }}
                />
                <div className="chip-text">
                    <Text>{bet[code] >= 10000 ? `${bet[code] / 1000}k` : bet[code]}</Text>
                </div>
            </div>
        );
    }
}

export function checkPlayable(isMuted, audio) {
    if (!isMuted) {
        audio.play();
    } else {
        audio.pause();
        audio.currentTime = 0;
    }
}

export function filterBetCode(betCode, betRateArr) {
    let rate;

    betRateArr?.filter((currentValue) => {
        if (currentValue?.betCode === betCode) {
            rate = currentValue?.betRate;
        }
    });
    return `1:${rate}`;
}

export function handleToggleFullScreen() {
    let isIOS = /iPhone|iPod/.test(navigator.userAgent);
    if (!isIOS) {
        if (screenfull.isFullscreen) {
            screenfull.exit();
        } else {
            screenfull.request();
        }
    }
}

export function handleStopAudio(audio) {
    if (audio) {
        audio.pause();
        audio.currentTime = 0;
    }
}

export function handlePlayAudio(audio) {
    if (audio) {
        audio.play();
    }
}

export function handlePlayBgm(audio) {
    if (audio) {
        audio.play();
        audio.onended = () => {
            audio.play();
        };
    }
}

export function handlePlayVideo(playerRef, result, windowWidth, windowHeight, videoQuality, setVideoLoading) {
    setVideoLoading(true);

    if (playerRef.current) {
        playerRef?.current?.stop();
        playerRef?.current?.clearView();
    }

    let play = new NodePlayer();
    playerRef.current = play;
    play.setView("video");
    play.setBufferTime(2000);

    if (windowWidth > windowHeight) {
        let height;
        if (windowWidth / 1.7 > windowHeight) {
            height = windowHeight;
        } else {
            height = windowWidth / 1.7;
        }
        play.resizeView(windowWidth, height);
    } else {
        let height;
        if (windowWidth / 1.7 > windowHeight) {
            height = windowHeight;
        } else {
            if (windowWidth >= 768) {
                height = windowWidth / 2.5;
            } else {
                height = windowWidth / 1.7;
            }
        }
        play.resizeView(windowWidth, height);
    }
    let videoUrl = result?.data?.liveURL;
    if (videoQuality !== "720") {
        videoUrl = videoUrl + `_${videoQuality}.flv`;
    } else {
        videoUrl = videoUrl + ".flv";
    }
    play.start(videoUrl);
    play.on("buffer", (s) => {
        if (s === "full") {
            setVideoLoading(false);
        } else {
            setVideoLoading(true);
        }
    });
}

export function checkUrl(tableInfo, navigate) {
    let redirectUrl = tableInfo?.split("&")[1].replaceAll("|", "/");

    if (redirectUrl) {
        window.location.replace(redirectUrl);
    } else {
        navigate(-1);
        // navigate("/lobby/games/all");
    }
}

export async function getIPAddress() {
    let ip;
    await axios
        .get("https://api.bigdatacloud.net/data/client-ip")
        .then((res) => {
            ip = res?.data?.ipString ?? "";
        })
        .catch((error) => {});
    // await fetch("https://geolocation-db.com/json/")
    //   .then((response) => response.json(), { signal: AbortSignal.timeout(100) })
    //   .then((data) => {
    //     ip = data.IPv4;
    //   })
    //   .catch((error) => console.log(error));
    return ip;
}

export function getBrowserAgent() {
    const test = (regexp) => {
        return regexp.test(window.navigator.userAgent);
    };
    const versionArr = window.navigator.userAgent?.split("/");

    if (test(/opr\//i) || !!window.opr) {
        return "Opera";
    } else if (test(/edg/i)) {
        return "Microsoft Edge";
    } else if (test(/chrome|chromium|crios/i)) {
        return "Google Chrome";
    } else if (test(/firefox|fxios/i)) {
        return "Firefox";
    } else if (test(/safari/i)) {
        if (
            (parseFloat(versionArr[versionArr?.length - 1]) >= 604.1 &&
                parseFloat(versionArr[versionArr?.length - 2].split(" ")[0]) > 16.3 &&
                versionArr[versionArr?.length - 3]?.split(" ")[1] !== "Mobile") ||
            (parseFloat(versionArr[versionArr?.length - 3].split(" ")[0]) > 16.3 && versionArr[versionArr?.length - 3].split(" ")[1] === "Mobile")
        ) {
            return "Google Chrome";
        } else {
            return "Safari";
        }
    } else if (test(/trident/i)) {
        return "IE";
    } else if (test(/ucbrowser/i)) {
        return "UC";
    } else if (test(/samsungbrowser/i)) {
        return "Samsung";
    } else {
        return "Unknown";
    }
}
