import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { formatNumber } from "../../../utils/Formatter";
import { useBaccarat } from "../hook/useBaccarat";

import "./VBaccarat.scss";
import { Spin, Switch } from "antd";

import CFBtnView from "../../../components-game/navigator/CFBtnView";
// import HChips from "../../../components-game/chips-set/horizontal/HChips";
import Timer from "../../../components-game/timer/Timer";
import BetPanel from "../component/bet-panel/BetPanel";
import RoadMap from "../component/road-map/RoadMap";
import WinList from "../../../components-game/win-list/WinList";
import ActionMessage from "../../../components-game/action-message/ActionMessage";

import Icon from "../../../components-game/navigator/icon/Icon";
import table from "../../../assets/game/common/table.png";
import TableList from "../../../components-game/table-list/TableList";

import LoadingPage from "../../../pages/loading-page/LoadingPage";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import Dot from "../component/road-map/dots/Dot";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";
import GameTypeSelect from "../component/game-type-select/GameTypeSelect";
import VChips from "../../../components-game/chips-set/new-vertical/VChips";

function VBaccarat() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        betContainerClass,
        gameType,
        setGameType,
        chatMsg,
        connectionState,
    } = useBaccarat();

    const [openTableList, setOpenTableList] = useState(false);

    if (isFirstLoad) {
        return <LoadingPage />;
    }

    function handleChangeComm(e) {
        if (e) {
            setGameType("TIGER");
        } else {
            setGameType("COMMISSION");
        }
    }

    return (
        <div
            style={{ background: `linear-gradient(to bottom, ${tableDetail?.color?.split(",")[0]}, ${tableDetail?.color?.split(",")[1]})` }}
            id="vertical-baccarat"
        >
            <div className="header-container">
                <div className="header-title">
                    <div className="header-btn" onClick={() => navigate(-1)}>
                        <Icon image={back} />
                    </div>
                    {tableDetail?.tableName}
                </div>
                <div className="header-menu">
                    {streamingObj && (
                        <CFBtnView
                            BtnTable={true}
                            BtnGraphic={true}
                            connection={connection}
                            GraphicObj={streamingObj}
                            setOpenTableList={setOpenTableList}
                            tableDetail={tableDetail}
                            chatMsg={chatMsg}
                        />
                    )}
                </div>
            </div>

            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <div className="id-wrapper">
                        <div className="uid">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</div>
                        <div className={`uid ${connectionState}`}>ID: {userData?.playerID ? userData.playerID : "-"}</div>
                    </div>
                    <div className="bet-panel-btn-wrapper">
                        <Switch checkedChildren="No Comm" unCheckedChildren="Comm" checked={gameType !== "COMMISSION"} onChange={handleChangeComm} />
                    </div>
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                    <div className="win-list-wrapper" style={{ display: winList ? "block" : "none" }}>
                        {winList && <WinList winList={winList} />}
                    </div>
                    {dealerSignal?.second ? (
                        <div className="timer-container">
                            <Timer dealerSignal={dealerSignal} />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </Spin>

            <div className="bet-panel-container">
                {betItems && (
                    <BetPanel
                        tableInfo={tableInfo}
                        connection={connection}
                        chipArr={chipsArr}
                        rate={betItems}
                        tableDetail={tableDetail}
                        dealerSignal={dealerSignal}
                        setTotalBet={setTotalBet}
                        betStatistic={betStatistic}
                        roadMaps={roadMaps}
                        setLabelBet={setLabelBet}
                        cardResult={cardResult}
                        betContainerClass={betContainerClass}
                        gameType={gameType}
                    />
                )}
            </div>

            <div className={`action-msg-container ${actionMsg}`}>
                <ActionMessage text={actionMsg} color="gold" totalWin={totalBet} />
            </div>

            <div className="chips-set-container">
                {chipsArr && (
                    <VChips
                        setLabelBet={setLabelBet}
                        connection={connection}
                        tableInfo={tableInfo}
                        ChipsSet={chipsArr}
                        tableDetail={tableDetail}
                        dealerSignal={dealerSignal}
                        totalBet={totalBet}
                        setTotalBet={setTotalBet}
                        roadMaps={roadMaps}
                    />
                )}
            </div>

            <div className="footer-container">
                <div className="container-01">
                    <span className="lbl lbl-total-bet">
                        {t(labelBet)}: {userData?.currency + " " + formatNumber(totalBet)}
                    </span>
                    <span className="lbl lbl-balance">Balance: {userData?.currency + " " + formatNumber(balance)}</span>
                </div>

                <div className="container-02">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</div>
            </div>

            <div className="road-maps-container">
                <div className="roads-croc-small">
                    <div className="roads-croc road-2">
                        <RoadMap RoadType="02" Data={roadMaps?.data?.route_2} />
                    </div>

                    <div className="roads-small">
                        <div className="roads-small-item road-3">
                            <RoadMap RoadType="03" Data={roadMaps?.data?.route_3} />
                        </div>

                        <div className="roads-small-item road-4">
                            <RoadMap RoadType="04" Data={roadMaps?.data?.route_4} />
                        </div>

                        <div className="roads-small-item road-5">
                            <RoadMap RoadType="05" Data={roadMaps?.data?.route_5} />
                        </div>
                    </div>
                </div>

                <div className="road-1">
                    <RoadMap RoadType="01" Data={roadMaps?.data?.route_1} />
                </div>
            </div>

            <div className="road-map-statistic-container">
                <div className="statistic-forcast">
                    <div className="statistic-count">
                        <div className="lbl"># {roadMaps?.data2?.total}</div>

                        <div className="lbl">
                            <div className="lbl-dot">
                                <Dot FillColor="#3788ff" text="P" LineBottom={false} LineRight={false} />
                            </div>
                            <div className="content">{roadMaps?.data2?.player}</div>
                        </div>

                        <div className="lbl">
                            <div className="lbl-dot">
                                <Dot FillColor="#ee4043" text="B" LineBottom={false} LineRight={false} />
                            </div>
                            <div className="content">{roadMaps?.data2?.banker}</div>
                        </div>

                        <div className="lbl">
                            <div className="lbl-dot">
                                <Dot FillColor="#159252" text="T" LineBottom={false} LineRight={false} />
                            </div>
                            <div className="content">{roadMaps?.data2?.tie}</div>
                        </div>
                    </div>

                    <div className="forcast-wrapper">
                        <div className="player-forcast">
                            <div className="lbl-text">P?</div>
                            {roadMaps?.data?.forcast
                                ?.split(",")[1]
                                ?.split("-")
                                .map((items, index) => (
                                    <div key={index} className="lbl-dot" hidden={items === ""}>
                                        {index === 0 ? (
                                            <Dot
                                                FillColor="none"
                                                StrokeColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                text=""
                                                LineBottom={false}
                                                LineRight={false}
                                            />
                                        ) : index === 1 ? (
                                            <Dot
                                                StrokeColor="none"
                                                FillColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                text=""
                                                LineBottom={false}
                                                LineRight={false}
                                            />
                                        ) : (
                                            <Dot
                                                FillColor="none"
                                                StrokeColor="none"
                                                Tie
                                                TieColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                text=""
                                                LineBottom={false}
                                                LineRight={false}
                                            />
                                        )}
                                    </div>
                                ))}
                        </div>

                        <div className="banker-forcast">
                            <div className="lbl-text">B?</div>
                            {roadMaps?.data?.forcast
                                ?.split(",")[0]
                                ?.split("-")
                                .map((items, index) => (
                                    <div key={index} className="lbl-dot" hidden={items === ""}>
                                        {index === 0 ? (
                                            <Dot
                                                FillColor="none"
                                                StrokeColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                text=""
                                                LineBottom={false}
                                                LineRight={false}
                                            />
                                        ) : index === 1 ? (
                                            <Dot
                                                StrokeColor="none"
                                                FillColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                text=""
                                                LineBottom={false}
                                                LineRight={false}
                                            />
                                        ) : (
                                            <Dot
                                                FillColor="none"
                                                StrokeColor="none"
                                                Tie
                                                TieColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                text=""
                                                LineBottom={false}
                                                LineRight={false}
                                            />
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="tbl-btn-container">
                <div className="tbl-btn-wrapper" onClick={() => setOpenTableList(!openTableList)}>
                    <Icon image={table} />
                </div> */}

            <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />
            {/* </div> */}

            {!gameType && <GameTypeSelect gameType={gameType} setGameType={setGameType} />}

            {gameType && /iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default VBaccarat;
