import { Typography, Col, Image, Row, Card, Space, Popover, Menu, Avatar, Modal } from "antd";
import React, { useEffect } from "react";
import logo from "../../../../assets/logo/logo-new.png";
import "./Style1.scss";
import { UserOutlined, WalletFilled, GlobalOutlined, LogoutOutlined, EditOutlined, SoundOutlined, AudioMutedOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { formatNumber } from "../../../../utils/Formatter";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { langArr } from "../../../../utils/Common";
import { CircleFlag } from "react-circle-flags";
import moment from "moment";
import close from "../../../../assets/game/common/close.png";
import SuggestionModal from "../../../../components-game/suggestion-modal/SuggestionModal";
import MusicOn from "../../../../assets/game/common/unmute.png";
import MusicOff from "../../../../assets/game/common/mute.png";
import sug from "../../../../assets/game/common/suggestion.png";
import lang from "../../../../assets/game/common/language.png";
import logout from "../../../../assets/game/common/logout.png";

const { Title } = Typography;

function LobbyHeader1({ bgMusic, setBgMusic, activeUser, lobbyUser, connection, setConnection }) {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const [langOpen, setLangOpen] = useState(false);
    const { balance, fullScreen, setFullScreen, userData, setUserData, setBalance, setIsLogin } = useContext(AuthContext);
    const [newUser, setNewUser] = useState();
    const [date, setDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    const [suggestion, setSuggestion] = useState(false);
    const [backgrondMusic, setBackgroundMusic] = useState(false);
    useEffect(() => {
        const format = "hh:mm:ss";
        const time = moment();
        const beforeTime = moment("08:00:00", format);
        const afterTime = moment("20:00:00", format);

        if (time.isBetween(beforeTime, afterTime)) {
            if (!lobbyUser) {
                setNewUser(400);
            } else {
                setNewUser(lobbyUser + 400);
            }
        } else {
            if (!lobbyUser) {
                setNewUser(500);
            } else {
                setNewUser(lobbyUser + 500);
            }
        }
    }, [lobbyUser]);

    useEffect(() => {
        setInterval(() => {
            setDate(moment().format("YYYY-MM-DD HH:mm:ss"));
        }, 1000);
    }, []);

    function handleChangeLanguage(lang) {
        i18n.changeLanguage(lang);
        connection.invoke("ChangeLanguage", {
            // playerID: Cookies.get("playerID"),
            // playerToken: Cookies.get("playerToken"),
            playerID: localStorage.getItem("playerID"),
            playerToken: localStorage.getItem("playerToken"),
            lang: lang,
        });
        setLangOpen(false);
    }

    const langMenu = (
        <div className="menu">
            <Menu>
                {langArr?.map((lang, index) => (
                    <Menu.Item
                        key={index}
                        onClick={() => handleChangeLanguage(lang?.key)}
                        className={userData?.lang === lang?.key ? "menu-selected" : ""}
                    >
                        <Space>
                            <CircleFlag countryCode={lang?.key} height="20" />
                            <Title level={5}>{lang?.label}</Title>
                        </Space>
                    </Menu.Item>
                ))}
            </Menu>
        </div>
    );

    async function handleLogout() {
        let redirectUrl = decodeURIComponent(localStorage.getItem("redirectUrl"))?.replaceAll("|", "/");

        setUserData();
        setBalance();
        localStorage.removeItem("playerID");
        localStorage.removeItem("playerToken");
        connection.stop();
        setConnection();
        setIsLogin(false);

        if (redirectUrl === "") {
            window.location.replace("/admin-login");
        } else {
            window.location.replace(redirectUrl);
        }
    }

    function toggleFullscreen() {
        if (fullScreen) {
            setFullScreen(false);
            document.exitFullscreen();
            window.screen.orientation.lock("landscape");
        } else {
            setFullScreen(true);
            document.body.requestFullscreen();
            window.screen.orientation.lock("landscape");
        }
        setLangOpen(false);
    }

    const handleLangChange = (newOpen) => {
        setLangOpen(newOpen);
    };

    function handleBackgroundMusic() {
        var music = document.getElementById("myAudio");
        if (!backgrondMusic) {
            setBackgroundMusic(true);

            music.play();
        } else {
            setBackgroundMusic(false);
            music.pause();
            music.currentTime = 0;
        }
    }

    return (
        <>
            {window.innerWidth > 576 ? (
                // <Row justify="space-between" align="middle" className="lobby-header" gutter={[0, 10]}>
                //     <Col xs={0} sm={10} xl={8}>
                //         <div className="lobby-date-user">
                //             <div>{date}</div>
                //             <div className="user">
                //                 <UserOutlined onClick={() => setBgMusic(fa)} />
                //                 {newUser}
                //             </div>
                //         </div>
                //     </Col>
                //     <Col xs={0} sm={4} xl={3}>
                //         <Image src={logo} preview={false} />
                //     </Col>
                //     <Col xs={0} sm={10}>
                //         <Row justify="end" align="middle" gutter={10}>
                //             <Col className="lobby-menu">
                //                 <Card align="center">
                //                     <Space align="center">
                //                         <WalletFilled />
                //                         <Title level={5} style={{ color: "#bfbfbf" }}>
                //                             {userData?.currency}
                //                         </Title>
                //                         <Title level={5} style={{ color: "gold" }}>
                //                             {formatNumber(balance)}
                //                         </Title>
                //                     </Space>
                //                 </Card>
                //             </Col>
                //             <Col className="lobby-menu smoke">
                //                 <Card align="center"></Card>
                //             </Col>
                //             <Col className="lobby-menu smoke">
                //                 <audio id="myAudio">
                //                     <source
                //                         src="https://galaxy-game.sgp1.digitaloceanspaces.com/sound/lobby-music/tomp3.cc%20-%EA%B3%B5%EB%B6%80%ED%95%A0%20%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EB%AA%A8%EC%9D%8C%20%EA%B9%8A%EC%9D%80%20%EC%A7%91%EC%A4%91%EB%A0%A5%EC%9D%84%20%EC%9C%84%ED%95%9C%20%EC%9D%8C%EC%95%85%20%EC%B1%85%EC%9D%BD%EC%9D%84%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EB%8F%85%EC%84%9C%ED%95%A0%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EC%9E%94%EC%9E%94%ED%95%9C%20%EB%89%B4%EC%97%90%EC%9D%B4%EC%A7%80%20%ED%94%BC%EC%95%84%EB%85%B8%20%EC%9D%8C%EC%95%85%20%EB%AA%A8%EC%9D%8C_320kbps%20(mp3cut.net).mp3"
                //                         type="audio/mpeg"
                //                     />
                //                 </audio>
                //                 {backgrondMusic ? (
                //                     <Avatar onClick={() => handleBackgroundMusic()} src={MusicOn} />
                //                 ) : (
                //                     <Avatar src={MusicOff} onClick={() => handleBackgroundMusic()} />
                //                 )}
                //             </Col>
                //             <Col className="lobby-menu smoke">
                //                 <Avatar onClick={() => setSuggestion(true)} icon={<EditOutlined />} />
                //             </Col>

                //             <Col className="lobby-menu smoke">
                //                 <Popover
                //                     open={langOpen}
                //                     placement="bottomRight"
                //                     content={langMenu}
                //                     trigger="click"
                //                     arrow={false}
                //                     onOpenChange={handleLangChange}
                //                     overlayClassName="popover"
                //                     autoAdjustOverflow={false}
                //                 >
                //                     <Avatar icon={<GlobalOutlined />} />
                //                 </Popover>
                //             </Col>
                //             <Col className="lobby-menu smoke">
                //                 <Avatar onClick={() => handleLogout()} icon={<LogoutOutlined />} />
                //             </Col>
                //         </Row>
                //     </Col>
                // </Row>

                <div className="h-lobby-header">
                    <div className="lobby-left-wrapper">
                        <div className="date-time">{date}</div>
                        <div className="active-player">
                            <UserOutlined onClick={() => setBgMusic(fa)} />
                            {newUser}
                        </div>
                    </div>
                    <div className="lobby-center-wrapper">
                        <div className="logo">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                    <div className="lobby-right-wrapper">
                        <div className="balance">
                            <WalletFilled />
                            {`${userData?.currency} ${formatNumber(balance)}`}
                        </div>
                        <div className="lobby-menu">
                            <div className="sound-btn">
                                <audio id="myAudio">
                                    <source
                                        src="https://galaxy-game.sgp1.digitaloceanspaces.com/sound/lobby-music/tomp3.cc%20-%EA%B3%B5%EB%B6%80%ED%95%A0%20%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EB%AA%A8%EC%9D%8C%20%EA%B9%8A%EC%9D%80%20%EC%A7%91%EC%A4%91%EB%A0%A5%EC%9D%84%20%EC%9C%84%ED%95%9C%20%EC%9D%8C%EC%95%85%20%EC%B1%85%EC%9D%BD%EC%9D%84%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EB%8F%85%EC%84%9C%ED%95%A0%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EC%9E%94%EC%9E%94%ED%95%9C%20%EB%89%B4%EC%97%90%EC%9D%B4%EC%A7%80%20%ED%94%BC%EC%95%84%EB%85%B8%20%EC%9D%8C%EC%95%85%20%EB%AA%A8%EC%9D%8C_320kbps%20(mp3cut.net).mp3"
                                        type="audio/mpeg"
                                    />
                                </audio>
                                <img src={backgrondMusic ? MusicOn : MusicOff} alt="" onClick={() => handleBackgroundMusic()} />
                            </div>
                            <div className="suggestion-btn">
                                <img src={sug} alt="" onClick={() => setSuggestion(true)} />
                            </div>
                            <div className="lang-btn">
                                <Popover
                                    open={langOpen}
                                    placement="bottomRight"
                                    content={langMenu}
                                    trigger="click"
                                    arrow={false}
                                    onOpenChange={handleLangChange}
                                    overlayClassName="popover"
                                    autoAdjustOverflow={false}
                                >
                                    <img src={lang} alt="" />
                                </Popover>
                            </div>
                            <div className="logout-btn">
                                <img src={logout} alt="" onClick={() => handleLogout(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="v-lobby-header">
                    <div className="lobby-left-wrapper">
                        <div className="lobby-left-upper-wrapper">
                            <div className="logo">
                                <img src={logo} alt="" />
                            </div>
                        </div>
                        <div className="lobby-left-lower-wrapper">
                            <div className="date-time">{date}</div>
                            <div className="active-player">
                                <UserOutlined onClick={() => setBgMusic(fa)} />
                                {newUser}
                            </div>
                        </div>
                    </div>
                    <div className="lobby-right-wrapper">
                        <div className="lobby-right-upper-wrapper">
                            <div className="balance">
                                <WalletFilled />
                                {`${userData?.currency} ${formatNumber(balance)}`}
                            </div>
                        </div>
                        <div className="lobby-right-lower-wrapper">
                            <div className="sound-btn">
                                <audio id="myAudio">
                                    <source
                                        src="https://galaxy-game.sgp1.digitaloceanspaces.com/sound/lobby-music/tomp3.cc%20-%EA%B3%B5%EB%B6%80%ED%95%A0%20%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EB%AA%A8%EC%9D%8C%20%EA%B9%8A%EC%9D%80%20%EC%A7%91%EC%A4%91%EB%A0%A5%EC%9D%84%20%EC%9C%84%ED%95%9C%20%EC%9D%8C%EC%95%85%20%EC%B1%85%EC%9D%BD%EC%9D%84%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EB%8F%85%EC%84%9C%ED%95%A0%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EC%9E%94%EC%9E%94%ED%95%9C%20%EB%89%B4%EC%97%90%EC%9D%B4%EC%A7%80%20%ED%94%BC%EC%95%84%EB%85%B8%20%EC%9D%8C%EC%95%85%20%EB%AA%A8%EC%9D%8C_320kbps%20(mp3cut.net).mp3"
                                        type="audio/mpeg"
                                    />
                                </audio>
                                <img src={backgrondMusic ? MusicOn : MusicOff} alt="" onClick={() => handleBackgroundMusic()} />
                            </div>
                            <div className="suggestion-btn">
                                <img src={sug} alt="" onClick={() => setSuggestion(true)} />
                            </div>
                            <div className="lang-btn">
                                <Popover
                                    open={langOpen}
                                    placement="bottomRight"
                                    content={langMenu}
                                    trigger="click"
                                    arrow={false}
                                    onOpenChange={handleLangChange}
                                    overlayClassName="popover"
                                    autoAdjustOverflow={false}
                                >
                                    <img src={lang} alt="" />
                                </Popover>
                            </div>
                            <div className="logout-btn">
                                <img src={logout} alt="" onClick={() => handleLogout(true)} />
                            </div>
                        </div>
                    </div>
                </div>
                // <div className="lobby-header" style={{ padding: "10px" }}>
                //     <Row justify="space-between">
                //         <Col xs={10}>
                //             <Image src={logo} preview={false} />
                //         </Col>

                //         <Col xs={14}>
                //             <div className="lobby-date-user" style={{ fontSize: "13px", justifyContent: "flex-end" }}>
                //                 <div>{date}</div>
                //                 <div className="user">
                //                     <UserOutlined />
                //                     {newUser}
                //                 </div>
                //             </div>

                //             <Row gutter={20} justify="end" align="middle">
                //                 <Col xs={24}>
                //                     <Row justify="end" align="middle" gutter={10}>
                //                         <Col xs={24} className="lobby-menu">
                //                             <Card align="center" style={{ textAlign: "end" }}>
                //                                 <Space align="center">
                //                                     <WalletFilled />
                //                                     <div style={{ color: "#bfbfbf", fontSize: "13px" }}>{userData?.currency}</div>
                //                                     <div style={{ color: "gold", fontSize: "13px" }}>{formatNumber(balance)}</div>
                //                                 </Space>
                //                             </Card>
                //                         </Col>
                //                         <Col className="lobby-menu smoke">
                //                             <Card align="center"></Card>
                //                         </Col>
                //                         <Col className="lobby-menu smoke">
                //                             <audio id="myAudio">
                //                                 <source
                //                                     src="https://galaxy-game.sgp1.digitaloceanspaces.com/sound/lobby-music/tomp3.cc%20-%EA%B3%B5%EB%B6%80%ED%95%A0%20%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EB%AA%A8%EC%9D%8C%20%EA%B9%8A%EC%9D%80%20%EC%A7%91%EC%A4%91%EB%A0%A5%EC%9D%84%20%EC%9C%84%ED%95%9C%20%EC%9D%8C%EC%95%85%20%EC%B1%85%EC%9D%BD%EC%9D%84%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EB%8F%85%EC%84%9C%ED%95%A0%EB%95%8C%20%EB%93%A3%EB%8A%94%20%EC%9D%8C%EC%95%85%20%EC%9E%94%EC%9E%94%ED%95%9C%20%EB%89%B4%EC%97%90%EC%9D%B4%EC%A7%80%20%ED%94%BC%EC%95%84%EB%85%B8%20%EC%9D%8C%EC%95%85%20%EB%AA%A8%EC%9D%8C_320kbps%20(mp3cut.net).mp3"
                //                                     type="audio/mpeg"
                //                                 />
                //                             </audio>
                //                             {backgrondMusic ? (
                //                                 <Avatar onClick={() => handleBackgroundMusic()} src={MusicOn} />
                //                             ) : (
                //                                 <Avatar src={MusicOff} onClick={() => handleBackgroundMusic()} />
                //                             )}
                //                         </Col>
                //                         <Col className="lobby-menu smoke">
                //                             <Avatar onClick={() => setSuggestion(true)} icon={<EditOutlined />} />
                //                         </Col>
                //                         <Col xs={4} className="lobby-menu smoke">
                //                             <Popover
                //                                 open={langOpen}
                //                                 placement="bottomRight"
                //                                 content={langMenu}
                //                                 trigger="click"
                //                                 arrow={false}
                //                                 onOpenChange={handleLangChange}
                //                                 overlayClassName="popover"
                //                                 autoAdjustOverflow={false}
                //                             >
                //                                 <Avatar icon={<GlobalOutlined />} />
                //                             </Popover>
                //                         </Col>
                //                         <Col className="lobby-menu smoke">
                //                             <Avatar onClick={() => handleLogout()} icon={<LogoutOutlined />} />
                //                         </Col>
                //                     </Row>
                //                 </Col>
                //             </Row>
                //         </Col>
                //     </Row>
                // </div>
            )}
            {suggestion && <SuggestionModal suggestion={suggestion} setSuggestion={setSuggestion} />}
        </>
    );
}

export default LobbyHeader1;
