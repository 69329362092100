import React from "react";
import "./tp-how-to-play.scss";

function TpHowToPlay() {
  const translation = {
    gb: (
      <div className="tp-how-to-play">
        <div className="section">
          <div className="title">Overview:</div>

          <div className="content">
            <ul>
              <li>
                Three Pictures is played with a single deck of cards. All
                picture cards (King, Queen and Jack) and Tens carry the value of
                0, while other cards carry their denoted numerical value (Ace
                carries the value of 1).
              </li>
              <li>
                The highest ranking hand is known as “Three Knights”, that is a
                hand with 3 picture cards. The point count for the other hands
                will be counted at the face value of the 3 cards with the
                highest score being 9. For total scores exceeding 10 or 20, 10
                and 20 points will be deducted respectively.
              </li>
              <li>
                The objective of the game is to have the highest hand over the
                lower hand.
              </li>
              <li>
                In the event that the point count is the same, it will be
                considered a tie.
              </li>
              <li>The House charges a 5% commission on all winning bets.</li>
              <li>
                Players also have the option of wagering on tie bets (Payment 8
                to 1) and “Three Knights” bet (Payment 16 to 1).
              </li>
              <li>The payment for all bets against the dealer is 1 to 1.</li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">How To Play:</div>

          <div className="content">
            <ul>
              <li>
                The game has a total of 1 banker and 3 player, and members can
                place bets at will.
              </li>
              <li>
                When placing a bet using a deck of playing cards, the cards will
                be dealt from player 1 to player 3. The dealer will deal one
                card to each door until three cards are dealt.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Betting options and odds:</div>

          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>Banker</th>
                <th>Player 1</th>
                <th>Player 2</th>
                <th>Player 3</th>
              </tr>

              <tr>
                <td>Three Picture / Three Knights</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
              </tr>

              <tr>
                <td>Win</td>
                <td>1:0.95</td>
                <td>1:1</td>
                <td>1:1</td>
                <td>1:1</td>
              </tr>

              <tr>
                <td>Tie</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>

              <tr>
                <td>Points ( 0 ~ 9 )</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">Card Value:</div>

          <div className="content">
            <ul>
              <li>
                Three Picture uses the total number of card points to compare
                the big and small cards, and adds the public cards (J, Q, K) to
                change the big and small card types.
              </li>
              <li>
                The public cards are represented by "P", 1 public card is
                displayed as "P", 2 public cards are displayed as "2P", and 3
                public cards (three public cards) are displayed as "3P".
              </li>
              <li>
                The card display method is "community card + points". For
                example, if you get 1 community card and the sum of the points
                is 8, it will display "P8". If you get 2 community cards and the
                sum of the points is 0, it will display "2P0".
              </li>
              <li>
                The largest card type is "Three Lords", followed by "Double Lord
                9"; if there is no public card in the card type, the maximum
                card type is 9 points, and the suit of the playing card has no
                effect.
              </li>
              <li>
                When the points of both parties are the same as the number of
                community cards, it is a tie. When a tie occurs, if the member
                bets on "banker" or "player", the bet amount will be refunded.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Basic Card Size:</div>

          <div className="content">
            <ul>
              <li>J, Q, K as (community / picture / knight) card.</li>
              <li>
                Two of the cards are picture, and the other card is 9 points.
              </li>
              <li>One of the cards is picture and the total points are 9.</li>
              <li>The total points are 9 points.</li>
              <li>
                wo of the cards are picture and the other card is 8 points
              </li>
              <li>The total points are 8 points.</li>
            </ul>
          </div>
        </div>
      </div>
    ),
    cn: (
      <div className="tp-how-to-play">
        <div className="section">
          <div className="title">概述：</div>

          <div className="content">
            <ul>
              <li>
                三公使用一副扑克牌进行游戏。所有图片牌（国王、皇后和杰克）和十都计为0分，而其他牌则计为其标明的数值（A计为1分）。
              </li>
              <li>
                最高级别的牌型为“三公”，即由3张图片牌组成的牌型。其他牌型的点数将以3张牌中点数最高的牌的面值计算，得分最高为9。超过10或20分的总分，将分别扣除10和20分。
              </li>
              <li>游戏的目标是拥有比对手更高的牌型。</li>
              <li>如果点数相同，则为平局。</li>
              <li>庄家对所有赢得的赌注收取5%的佣金。</li>
              <li>
                玩家还可以选择在平局赌注（支付8比1）和“三公”赌注（支付16比1）上下注。
              </li>
              <li>所有与庄家对战的赌注支付比例均为1比1。</li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">如何游戏：</div>

          <div className="content">
            <ul>
              <li>游戏共有1个庄家和3个玩家，会员可以随意下注。</li>
              <li>
                在使用一副扑克牌下注时，牌将从玩家1发到玩家3。庄家将为每个门发一张牌，直到发出三张牌。
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">下注选项和赔率：</div>

          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>庄家</th>
                <th>玩家1</th>
                <th>玩家2</th>
                <th>玩家3</th>
              </tr>

              <tr>
                <td>三公 / 三公爵</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
              </tr>

              <tr>
                <td>胜利</td>
                <td>1:0.95</td>
                <td>1:1</td>
                <td>1:1</td>
                <td>1:1</td>
              </tr>

              <tr>
                <td>平局</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>

              <tr>
                <td>点数（0 ~ 9）</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">牌值：</div>

          <div className="content">
            <ul>
              <li>
                三公使用卡片点数总和来比较大小，并将公共卡片（J、Q、K）添加到更改大小卡片类型。
              </li>
              <li>
                公共卡片以"P"表示，1张公共卡片显示为"P"，2张公共卡片显示为"2P"，3张公共卡片显示为"3P"。
              </li>
              <li>
                卡片显示方法为"公共卡片 +
                点数"。例如，如果你获得1张公共卡片，点数总和为8，它将显示"P8"。如果你获得2张公共卡片，点数总和为0，它将显示"2P0"。
              </li>
              <li>
                最大的卡片类型是"三公爵"，其次是"双公爵9"；如果卡片类型中没有公共卡片，最大卡片类型为9点，而牌的花色则不影响。
              </li>
              <li>
                当双方的点数与公共卡片数相同时，为平局。在发生平局时，如果会员在"庄家"或"玩家"上下注，下注金额将被退还。
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">基本卡片大小：</div>

          <div className="content">
            <ul>
              <li>J、Q、K为（公共/图片/骑士）卡片。</li>
              <li>两张卡片为图片，另一张卡片为9点。</li>
              <li>其中一张卡片为图片，总点数为9。</li>
              <li>总点数为9点。</li>
              <li>两张卡片为图片，另一张卡片为8点。</li>
              <li>总点数为8点。</li>
            </ul>
          </div>
        </div>
      </div>
    ),
    my: (
      <div className="tp-how-to-play">
        <div className="section">
          <div className="title">Gambaran Keseluruhan:</div>

          <div className="content">
            <ul>
              <li>
                Permainan Tiga Gambar dimainkan dengan satu dek kad. Semua kad
                gambar (Raja, Ratu, dan Jack) dan Sepuluh membawa nilai 0,
                sementara kad lain membawa nilai numerik yang ditunjukkan (Ace
                membawa nilai 1).
              </li>
              <li>
                Tangan dengan peringkat tertinggi dikenali sebagai "Tiga
                Kesatria", iaitu tangan dengan 3 kad gambar. Jumlah mata bagi
                tangan lain akan dihitung pada nilai muka ketiga kad dengan
                nilai tertinggi adalah 9. Untuk jumlah mata melebihi 10 atau 20,
                10 dan 20 mata akan dikurangkan masing-masing.
              </li>
              <li>
                Objektif permainan adalah untuk memiliki tangan tertinggi
                melebihi tangan yang lebih rendah.
              </li>
              <li>Jika jumlah mata sama, itu dianggap seri.</li>
              <li>
                Rumah mengenakan komisen 5% atas semua pertaruhan yang
                dimenangi.
              </li>
              <li>
                Pemain juga memiliki pilihan untuk bertaruh pada pertaruhan seri
                (Bayaran 8 hingga 1) dan pertaruhan "Tiga Kesatria" (Bayaran 16
                hingga 1).
              </li>
              <li>
                Pembayaran untuk semua pertaruhan melawan peniaga adalah 1
                hingga 1.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Cara Bermain:</div>

          <div className="content">
            <ul>
              <li>
                Permainan mempunyai jumlah 1 peniaga dan 3 pemain, dan ahli
                boleh meletakkan pertaruhan mengikut kehendak.
              </li>
              <li>
                Apa bila meletakkan pertaruhan menggunakan satu dek kad remi,
                kad-kad akan dibahagikan dari pemain 1 hingga pemain 3. Peniaga
                akan membagikan satu kad kepada setiap pintu sehingga tiga kad
                dibahagikan.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Pilihan Pertaruhan dan Peluang:</div>

          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>Peniaga</th>
                <th>Pemain 1</th>
                <th>Pemain 2</th>
                <th>Pemain 3</th>
              </tr>

              <tr>
                <td>Tiga Gambar / Tiga Kesatria</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
              </tr>

              <tr>
                <td>Menang</td>
                <td>1:0.95</td>
                <td>1:1</td>
                <td>1:1</td>
                <td>1:1</td>
              </tr>

              <tr>
                <td>Seri</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>

              <tr>
                <td>Mata (0 ~ 9)</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">Nilai Kad:</div>

          <div className="content">
            <ul>
              <li>
                Tiga Gambar menggunakan jumlah keseluruhan mata kad untuk
                membandingkan kad besar dan kecil, dan menambahkan kad awam (J,
                Q, K) untuk mengubah jenis kad besar dan kecil.
              </li>
              <li>
                Kad awam diwakili oleh "P", 1 kad awam dipaparkan sebagai "P", 2
                kad awam dipaparkan sebagai "2P", dan 3 kad awam (tiga kad awam)
                dipaparkan sebagai "3P".
              </li>
              <li>
                Cara paparan kad adalah "kad awam + mata". Sebagai contoh, jika
                anda mendapat 1 kad awam dan jumlah mata adalah 8, ia akan
                dipaparkan sebagai "P8". Jika anda mendapat 2 kad awam dan
                jumlah mata adalah 0, ia akan dipaparkan sebagai "2P0".
              </li>
              <li>
                Jenis kad terbesar adalah "Tiga Tuan", diikuti oleh "Dua Tuan
                9"; jika tiada kad awam dalam jenis kad, jenis kad maksimum
                adalah 9 mata, dan suit kad bermain tidak mempengaruhi.
              </li>
              <li>
                Apabila mata kedua pihak sama dengan jumlah kad awam, itu adalah
                seri. Apabila seri berlaku, jika ahli bertaruh pada "peniaga"
                atau "pemain", jumlah pertaruhan akan dikembalikan.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Saiz Kad Asas:</div>

          <div className="content">
            <ul>
              <li>J, Q, K sebagai kad (komuniti / gambar / kesatria).</li>
              <li>
                Dua daripada kad adalah gambar, dan kad lain adalah 9 mata.
              </li>
              <li>
                Salah satu dari kad adalah gambar dan jumlah mata adalah 9.
              </li>
              <li>Jumlah mata adalah 9 mata.</li>
              <li>
                Dua daripada kad adalah gambar dan kad lain adalah 8 mata.
              </li>
              <li>Jumlah mata adalah 8 mata.</li>
            </ul>
          </div>
        </div>
      </div>
    ),
    pt: (
      <div className="tp-how-to-play">
        <div className="section">
          <div className="title">Visão Geral:</div>

          <div className="content">
            <ul>
              <li>
                O jogo Três Imagens é jogado com um único baralho de cartas.
                Todas as cartas de imagem (Rei, Rainha e Valete) e os Dez têm o
                valor de 0, enquanto as outras cartas têm seu valor numérico
                indicado (o Ás tem o valor de 1).
              </li>
              <li>
                A mão de classificação mais alta é conhecida como "Três
                Cavaleiros", que é uma mão com 3 cartas de imagem. A contagem de
                pontos para as outras mãos será contada com o valor nominal das
                3 cartas, sendo a pontuação mais alta 9. Para pontuações totais
                que excedem 10 ou 20, serão deduzidos 10 e 20 pontos,
                respectivamente.
              </li>
              <li>
                O objetivo do jogo é ter a mão mais alta em relação à mão
                inferior.
              </li>
              <li>
                No caso de a contagem de pontos ser a mesma, será considerado um
                empate.
              </li>
              <li>
                A Casa cobra uma comissão de 5% em todas as apostas vencedoras.
              </li>
              <li>
                Os jogadores também têm a opção de apostar em empates (Pagamento
                de 8 para 1) e na aposta "Três Cavaleiros" (Pagamento de 16 para
                1).
              </li>
              <li>
                O pagamento para todas as apostas contra o dealer é de 1 para 1.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Como Jogar:</div>

          <div className="content">
            <ul>
              <li>
                O jogo tem um total de 1 banqueiro e 3 jogadores, e os membros
                podem fazer apostas à vontade.
              </li>
              <li>
                Ao fazer uma aposta usando um baralho de cartas, as cartas serão
                distribuídas do jogador 1 ao jogador 3. O dealer distribuirá uma
                carta para cada porta até que três cartas sejam distribuídas.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Opções de Aposta e Odds:</div>

          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>Banqueiro</th>
                <th>Jogador 1</th>
                <th>Jogador 2</th>
                <th>Jogador 3</th>
              </tr>

              <tr>
                <td>Três Imagens / Três Cavaleiros</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
              </tr>

              <tr>
                <td>Vitória</td>
                <td>1:0,95</td>
                <td>1:1</td>
                <td>1:1</td>
                <td>1:1</td>
              </tr>

              <tr>
                <td>Empate</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>

              <tr>
                <td>Pontos (0 ~ 9)</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">Valor da Carta:</div>

          <div className="content">
            <ul>
              <li>
                Três Imagens usa o número total de pontos das cartas para
                comparar as cartas grandes e pequenas, e adiciona as cartas
                públicas (J, Q, K) para mudar os tipos de cartas grandes e
                pequenas.
              </li>
              <li>
                As cartas públicas são representadas por "P", 1 carta pública é
                exibida como "P", 2 cartas públicas são exibidas como "2P" e 3
                cartas públicas (três cartas públicas) são exibidas como "3P".
              </li>
              <li>
                O método de exibição da carta é "carta comunitária + pontos".
                Por exemplo, se você pegar 1 carta comunitária e a soma dos
                pontos for 8, ela será exibida como "P8". Se você pegar 2 cartas
                comunitárias e a soma dos pontos for 0, ela será exibida como
                "2P0".
              </li>
              <li>
                O maior tipo de carta é "Três Senhores", seguido por "Duplo
                Senhor 9"; se não houver carta pública no tipo de carta, o tipo
                de carta máximo é 9 pontos, e o naipe da carta de jogo não tem
                efeito.
              </li>
              <li>
                Quando os pontos de ambas as partes são iguais ao número de
                cartas comunitárias, é um empate. Quando ocorre um empate, se o
                membro apostar no "banqueiro" ou "jogador", o valor da aposta
                será reembolsado.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Tamanho Básico da Carta:</div>

          <div className="content">
            <ul>
              <li>J, Q, K como cartas (comunidade / imagem / cavaleiro).</li>
              <li>
                Dois das cartas são de imagem, e a outra carta é 9 pontos.
              </li>
              <li>Uma das cartas é de imagem e a soma dos pontos é 9.</li>
              <li>A soma dos pontos é 9 pontos.</li>
              <li>Dois das cartas são de imagem e a outra carta é 8 pontos.</li>
              <li>A soma dos pontos é 8 pontos.</li>
            </ul>
          </div>
        </div>
      </div>
    ),
    vn: (
      <div className="tp-how-to-play">
        <div className="section">
          <div className="title">Tổng Quan:</div>

          <div className="content">
            <ul>
              <li>
                Trò chơi Three Pictures được chơi với một bộ bài duy nhất. Tất
                cả các lá bài hình (K, Q và J) và Tens mang giá trị là 0, trong
                khi các lá bài khác mang giá trị số (Át mang giá trị là 1).
              </li>
              <li>
                Bộ bài có hạng cao nhất được biết đến là "Ba Hiệp Sĩ", là một bộ
                bài với 3 lá bài hình. Điểm số cho các bộ bài khác sẽ được tính
                theo giá trị của 3 lá bài có điểm số cao nhất là 9. Đối với tổng
                điểm vượt quá 10 hoặc 20, sẽ được trừ đi lần lượt 10 và 20 điểm.
              </li>
              <li>
                Mục tiêu của trò chơi là có bộ bài cao hơn so với bộ bài thấp
                hơn.
              </li>
              <li>
                Trong trường hợp điểm số bằng nhau, sẽ được xem xét là hòa.
              </li>
              <li>
                Nhà cái tính phí 5% hoa hồng trên tất cả các cược chiến thắng.
              </li>
              <li>
                Người chơi cũng có khả năng đặt cược vào cược hòa (Thanh toán 8
                đến 1) và cược "Ba Hiệp Sĩ" (Thanh toán 16 đến 1).
              </li>
              <li>
                Thanh toán cho tất cả các cược chống lại người chia bài là 1 đến
                1.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Cách Chơi:</div>

          <div className="content">
            <ul>
              <li>
                Trò chơi có tổng cộng 1 người chia bài và 3 người chơi, và các
                thành viên có thể đặt cược theo ý muốn.
              </li>
              <li>
                Khi đặt cược bằng một bộ bài, các lá bài sẽ được chia từ người
                chơi 1 đến người chơi 3. Người chia bài sẽ chia một lá bài cho
                mỗi cửa cho đến khi có ba lá bài được chia.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Tùy Chọn Cược và Tỷ Lệ:</div>

          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>Người chia bài</th>
                <th>Người chơi 1</th>
                <th>Người chơi 2</th>
                <th>Người chơi 3</th>
              </tr>

              <tr>
                <td>Ba Hình / Ba Hiệp Sĩ</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
              </tr>

              <tr>
                <td>Thắng</td>
                <td>1:0,95</td>
                <td>1:1</td>
                <td>1:1</td>
                <td>1:1</td>
              </tr>

              <tr>
                <td>Hòa</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>

              <tr>
                <td>Điểm (0 ~ 9)</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">Giá Trị Của Bài:</div>

          <div className="content">
            <ul>
              <li>
                Ba Hình sử dụng tổng số điểm của các lá bài để so sánh giữa lá
                bài lớn và lá bài nhỏ, và thêm các lá bài công cộng (J, Q, K) để
                thay đổi loại lá bài lớn và lá bài nhỏ.
              </li>
              <li>
                Các lá bài công cộng được biểu diễn bằng "P", 1 lá bài công cộng
                được hiển thị là "P", 2 lá bài công cộng được hiển thị là "2P"
                và 3 lá bài công cộng (ba lá bài công cộng) được hiển thị là
                "3P".
              </li>
              <li>
                Phương pháp hiển thị lá bài là "lá bài cộng đồng + điểm". Ví dụ,
                nếu bạn nhận được 1 lá bài cộng đồng và tổng số điểm là 8, nó sẽ
                hiển thị là "P8". Nếu bạn nhận được 2 lá bài cộng đồng và tổng
                số điểm là 0, nó sẽ hiển thị là "2P0".
              </li>
              <li>
                Loại lá bài lớn nhất là "Ba Chúa", tiếp theo là "Đôi Chúa 9";
                nếu không có lá bài công cộng trong loại lá bài, loại lá bài tối
                đa là 9 điểm, và nút lá bài chơi không ảnh hưởng.
              </li>
              <li>
                Khi điểm của cả hai bên giống với số lá bài cộng đồng, đó là
                hòa. Khi có một trận hòa xảy ra, nếu thành viên cược vào "người
                chia bài" hoặc "người chơi", số tiền cược sẽ được hoàn trả.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Kích Thước Cơ Bản Của Bài:</div>

          <div className="content">
            <ul>
              <li>J, Q, K được xem là lá bài (cộng đồng / hình / hiệp sĩ).</li>
              <li>Hai trong số lá bài là hình, và lá bài còn lại là 9 điểm.</li>
              <li>Một trong số lá bài là hình và tổng số điểm là 9.</li>
              <li>Tổng số điểm là 9 điểm.</li>
              <li>Hai trong số lá bài là hình và lá bài còn lại là 8 điểm.</li>
              <li>Tổng số điểm là 8 điểm.</li>
            </ul>
          </div>
        </div>
      </div>
    ),
    th: (
      <div className="tp-how-to-play">
        <div className="section">
          <div className="title">ภาพรวม:</div>

          <div className="content">
            <ul>
              <li>
                Three Pictures เล่นด้วยสำรับไพ่เดียว ไพ่ทุกใบที่เป็นภาพ (คิง
                ควีน และ แจ็ค) และเลขสิบมีค่าเป็น 0 ในขณะที่ไพ่อื่น ๆ
                มีค่าตามค่าตัวเลขที่ระบุ (เอซมีค่าเป็น 1)
              </li>
              <li>
                มือที่มีอันดับสูงที่สุดคือ "Three Knights" คือมือที่มีไพ่ภาพ 3
                ใบ คะแนนสำหรับมืออื่น ๆ จะนับเป็นค่าของไพ่ 3
                ใบที่มีคะแนนสูงที่สุดคือ 9 สำหรับคะแนนรวมที่เกิน 10 หรือ 20
                จะถูกหัก 10 และ 20 คะแนนตามลำดับ
              </li>
              <li>วัตถุประสงค์ของเกมคือการมีมือที่สูงกว่ามือต่ำ</li>
              <li>ในกรณีที่คะแนนเท่ากันจะถือเป็นเสมอ</li>
              <li>บ้านเก็บค่าคอมมิชชั่น 5% จากเงินเดิมพันที่ชนะ</li>
              <li>
                ผู้เล่นยังมีตัวเลือกในการเดิมพันเทียบกับการเสมอ (การชำระเงิน 8
                เท่า 1) และการเดิมพัน "Three Knights" (การชำระเงิน 16 เท่า 1)
              </li>
              <li>
                การชำระเงินสำหรับทุกการเดิมพันต่อต้านต่อผู้แจกไพ่คือ 1 เท่า 1
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">วิธีการเล่น:</div>

          <div className="content">
            <ul>
              <li>
                เกมมีทั้งหมด 1 บัญชีและ 3 ผู้เล่น
                สมาชิกสามารถวางเดิมพันตามต้องการ
              </li>
              <li>
                เมื่อวางเดิมพันโดยใช้สำรับไพ่ ไพ่จะถูกแจกจากผู้เล่น 1 ถึงผู้เล่น
                3 ผู้แจกไพ่จะแจกไพ่หนึ่งใบให้แต่ละประตูจนกระทั่งมีไพ่ 3 ใบ
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">ตัวเลือกการเดิมพันและอัตราการจ่าย:</div>

          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>ผู้แจกไพ่</th>
                <th>ผู้เล่น 1</th>
                <th>ผู้เล่น 2</th>
                <th>ผู้เล่น 3</th>
              </tr>

              <tr>
                <td>Three Pictures / Three Knights</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
              </tr>

              <tr>
                <td>ชนะ</td>
                <td>1:0.95</td>
                <td>1:1</td>
                <td>1:1</td>
                <td>1:1</td>
              </tr>

              <tr>
                <td>เสมอ</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>

              <tr>
                <td>คะแนน (0 ~ 9)</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">ค่าตัวของไพ่:</div>

          <div className="content">
            <ul>
              <li>
                Three Pictures
                ใช้จำนวนรวมของคะแนนไพ่เพื่อเปรียบเทียบไพ่ขนาดใหญ่และเล็ก
                และเพิ่มไพ่สาธารณะ (J, Q, K)
                เพื่อเปลี่ยนแปลงประเภทไพ่ขนาดใหญ่และเล็ก
              </li>
              <li>
                ไพ่สาธารณะถูกแทนด้วย "P" 1 ไพ่สาธารณะแสดงเป็น "P" 2
                ไพ่สาธารณะแสดงเป็น "2P" และ 3 ไพ่สาธารณะ (ไพ่สาธารณะสามใบ)
                แสดงเป็น "3P"
              </li>
              <li>
                วิธีการแสดงไพ่คือ "ไพ่สาธารณะ + คะแนน" เช่น หากคุณได้ 1
                ไพ่สาธารณะและผลรวมคะแนนเป็น 8 มันจะแสดงเป็น "P8" หากคุณได้ 2
                ไพ่สาธารณะและผลรวมคะแนนเป็น 0 มันจะแสดงเป็น "2P0"
              </li>
              <li>
                ประเภทไพ่ที่ใหญ่ที่สุดคือ "Three Lords" ตามด้วย "Double Lord 9"
                ถ้าไม่มีไพ่สาธารณะในประเภทไพ่ ประเภทไพ่สูงสุดคือ 9 คะแนน
                และสัญลักษณ์ของไพ่เล่นไม่มีผล
              </li>
              <li>
                เมื่อคะแนนของทั้งสองฝ่ายเท่ากับจำนวนไพ่สาธารณะเมื่อเสมอ
                เมื่อเกิดเสมอ หากสมาชิกเดิมพันที่ "ผู้แจกไพ่" หรือ "ผู้เล่น"
                เงินเดิมพันจะถูกคืน
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">ขนาดของไพ่พื้นฐาน:</div>

          <div className="content">
            <ul>
              <li>J, Q, K เป็นไพ่ (สาธารณะ / ภาพ / อัศวิน)</li>
              <li>สองในไพ่เป็นภาพ และไพ่ที่เหลือคือ 9 คะแนน</li>
              <li>หนึ่งในไพ่เป็นภาพและผลรวมคะแนนคือ 9</li>
              <li>ผลรวมคะแนนคือ 9 คะแนน</li>
              <li>สองในไพ่เป็นภาพและไพ่ที่เหลือคือ 8 คะแนน</li>
              <li>ผลรวมคะแนนคือ 8 คะแนน</li>
            </ul>
          </div>
        </div>
      </div>
    ),
    kr: (
      <div className="tp-how-to-play">
        <div className="section">
          <div className="title">개요:</div>

          <div className="content">
            <ul>
              <li>
                Three Pictures는 한 세트의 카드로 플레이됩니다. 모든 그림
                카드(킹, 퀸, 잭) 및 십자 카드는 0의 값을 가지며, 다른 카드는
                표시된 숫자 값을 가집니다(Ace는 1의 값을 가집니다).
              </li>
              <li>
                가장 높은 순위의 패는 "Three Knights"로, 3장의 그림 카드로
                이루어진 패입니다. 다른 패들의 포인트는 가장 높은 점수를 가진
                3장의 카드의 값으로 계산되며, 최고 점수는 9입니다. 10 또는 20을
                초과하는 총 점수의 경우 각각 10점 및 20점이 차감됩니다.
              </li>
              <li>게임의 목표는 낮은 패보다 높은 패를 가지는 것입니다.</li>
              <li>포인트가 동일한 경우, 무승부로 간주됩니다.</li>
              <li>하우스는 모든 이긴 베팅에 대해 5%의 수수료를 부과합니다.</li>
              <li>
                플레이어는 또한 무승부(8 대 1의 지불) 및 "Three Knights" 베팅(16
                대 1의 지불)에 베팅할 수 있습니다.
              </li>
              <li>딜러에 대한 모든 베팅의 지불은 1 대 1입니다.</li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">플레이 방법:</div>

          <div className="content">
            <ul>
              <li>
                게임에는 총 1명의 뱅커와 3명의 플레이어가 있으며, 회원은
                자유롭게 베팅할 수 있습니다.
              </li>
              <li>
                플레이 카드 덱을 사용하여 베팅할 때 카드는 플레이어 1부터
                플레이어 3까지 나눠지게 됩니다. 딜러는 각 문에 한 장의 카드를
                나누어 세 장이 나눠질 때까지 지속됩니다.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">베팅 옵션 및 배율:</div>

          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>뱅커</th>
                <th>플레이어 1</th>
                <th>플레이어 2</th>
                <th>플레이어 3</th>
              </tr>

              <tr>
                <td>세 그림 / 쓰리 나이츠</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
                <td>1:16</td>
              </tr>

              <tr>
                <td>승리</td>
                <td>1:0.95</td>
                <td>1:1</td>
                <td>1:1</td>
                <td>1:1</td>
              </tr>

              <tr>
                <td>무승부</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>

              <tr>
                <td>포인트 (0 ~ 9)</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
                <td>1:8</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">카드 값:</div>

          <div className="content">
            <ul>
              <li>
                쓰리 그림은 카드의 총 점수를 사용하여 큰 카드와 작은 카드를
                비교하고 공용 카드(J, Q, K)를 추가하여 큰 카드의 유형을
                변경합니다.
              </li>
              <li>
                공용 카드는 "P"로 표시되며, 1개의 공용 카드는 "P"로 표시되고,
                2개의 공용 카드는 "2P"로 표시되며, 3개의 공용 카드(세 공용
                카드)는 "3P"로 표시됩니다.
              </li>
              <li>
                카드 표시 방법은 "커뮤니티 카드 + 점수"입니다. 예를 들어, 1개의
                커뮤니티 카드를 얻고 점수의 합계가 8이면 "P8"로 표시됩니다.
                2개의 커뮤니티 카드를 얻고 점수의 합계가 0이면 "2P0"로
                표시됩니다.
              </li>
              <li>
                가장 큰 카드 유형은 "쓰리 로드"이고, 그 뒤를 이어 "더블 로드
                9"입니다. 카드 유형에 공용 카드가 없으면 최대 카드 유형은 9이며,
                플레이 카드의 무늬는 영향을 미치지 않습니다.
              </li>
              <li>
                양측의 점수가 공용 카드 수와 동일한 경우 무승부입니다. 무승부가
                발생할 때, 회원이 "뱅커" 또는 "플레이어"에 베팅하면 베팅 금액이
                환불됩니다.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">기본 카드 크기:</div>

          <div className="content">
            <ul>
              <li>J, Q, K는 (커뮤니티 / 그림 / 나이트) 카드로 간주됩니다.</li>
              <li>카드 중 2장은 그림이고, 다른 1장은 9점입니다.</li>
              <li>카드 중 1장은 그림이고, 총 점수는 9점입니다.</li>
              <li>총 점수는 9점입니다.</li>
              <li>카드 중 2장은 그림이고, 다른 1장은 8점입니다.</li>
              <li>총 점수는 8점입니다.</li>
            </ul>
          </div>
        </div>
      </div>
    ),
  };

  return translation[localStorage.getItem("i18nextLng")] ?? translation["gb"];
}

export default TpHowToPlay;
