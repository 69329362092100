import React from "react";
import "./RoadMap.scss";
import Road from "./road/Road";
import { getColor } from "../bet-panel/RouletteBetConst";

function RoadMap({ Data = undefined }) {
    return (
        <div className="road-map">
            {Data?.map((item, index) => {
                let result = {
                    color: getColor(item[0]?.replace("R", "")),
                    number: item[0].replace("R", ""),
                };

                return (
                    <div key={index} className="road-map-item-wrapper">
                        <Road item={result} />
                    </div>
                );
            })}
        </div>
    );
}

export default RoadMap;
