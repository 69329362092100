import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./menu.scss";
import { Drawer } from "antd";
import { CloseCircleFilled, LeftCircleFilled } from "@ant-design/icons";

import DefaultMenu from "./default/DefaultMenu";
import Settings from "./settings/Settings";
import ChangeLanguage from "./change-language/ChangeLanguage";
import GameHistory from "./game-history/GameHistory";
import HowToPlay from "./how-to-play/HowToPlay";

function Menu({ connection, openMenu, setOpenMenu, tableInfo }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [menuTitle, setMenuTitle] = useState();
  const [component, setComponent] = useState();

  useEffect(() => {
    handleUI("menu", "menu");
  }, []);

  function handleUI(key, title) {
    switch (key) {
      case "menu":
        setMenuTitle(title);
        setComponent(<DefaultMenu func={handleUI} setMenuTitle={setMenuTitle} />);
        break;
      case "setting":
        setMenuTitle(title);
        setComponent(<Settings connection={connection} func={handleUI} />);
        break;
      case "language":
        setMenuTitle(title);
        setComponent(<ChangeLanguage connection={connection} />);
        break;
      case "game-history":
        setMenuTitle(title);
        setComponent(<GameHistory connection={connection} />);
        break;
      case "how-to-play":
        setMenuTitle(title);
        setComponent(<HowToPlay />);
        break;
      case "lobby":
        let url = tableInfo?.split("&")[1].replaceAll("|", "/");
        url ? window.location.replace(url) : navigate(-1);
      default:
        break;
    }
  }

  function handleClose() {
    if (menuTitle === "menu") {
      setOpenMenu(false);
      return;
    }
    handleUI("menu", "menu");
  }

  const drawerSetting = {
    title: t(menuTitle),
    placement: "right",
    onClose: () => handleClose(),
    open: openMenu,
    width: "clamp(300px, 60%, 500px)",
    rootClassName: "menu-drawer",
    closeIcon: menuTitle === "menu" ? <CloseCircleFilled /> : <LeftCircleFilled />,
  };

  return <Drawer {...drawerSetting}>{component}</Drawer>;
}

export default Menu;
