import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

function GameNumber() {
    const { t } = useTranslation();

    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("gameNumber1")}</Paragraph>
                {/* <Paragraph>{t("gameHistory2")}</Paragraph> */}
                <Paragraph>{t("gameNumber2")}</Paragraph>
            </Space>
        </Typography>
    );
}

export default GameNumber;
