import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import { handleStartStreaming, handleStopStreaming } from "../../../utils/LiveStreaming";
import { handleCheckExpired, handleGetPlayerInfo } from "../../../utils/SignalR";

import { useSignalR } from "../../../hook/useSignalR";
import { useChips } from "../../../hook/useChips";
import { useBetPanel } from "../../../hook/useBetPanel";
import { message } from "antd";

export function useSicbo() {
    const { tableInfo } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLiveLoading, setIsLiveLoading] = useState(true);

    const { userData, setUserData, balance, setBalance } = useContext(AuthContext);

    const { connection, actionTime, setActionTime, StopConnect, chatMsg } = useSignalR(process.env.REACT_APP_SICBO_URL);
    const { chipsArr, handleCreateChip } = useChips();
    const { handleRemoveAllChip, handleDefaultPlaceChipFixed, handleConfirmBet, handleUpdateBetToSuccess } = useBetPanel();

    const [tableDetail, setTableDetail] = useState();
    const [betItems, setBetItems] = useState();
    const [roadMaps, setRoadMaps] = useState();
    const streamingRef = useRef();
    const [streamingObj, setStreamingObj] = useState();

    const [dealerSignal, setDealerSignal] = useState();
    const [totalBet, setTotalBet] = useState(0);
    const [betStatistic, setBetStatistic] = useState({ total: 0, p1: 0, p2: 0, p3: 0 });
    const [winList, setWinList] = useState();
    const [cardResult, setCardResult] = useState();
    const [labelBet, setLabelBet] = useState("totalBet");
    const [actionMsg, setActionMsg] = useState({ text: "nextRound", bgColor: "#000", totalWin: 0, showWinAmount: false, result: undefined });

    const [betContainerClass, setBetContainerClass] = useState(false);
    const [reviewCard, setReviewCard] = useState(false);
    const [isRevealVisible, setIsRevealVisible] = useState(true);

    const [receivedStopBet, setReceivedStopBet] = useState();
    const [gameType, setGameType] = useState();

    useEffect(() => {
        handleFirstLoad();
        return () => handleComponentUnmount();
    }, [connection]);

    function handleFirstLoad() {
        if (connection) {
            connection.invoke("GetTableDetail", {
                playerID: localStorage.getItem("playerID"),
                playerToken: localStorage.getItem("playerToken"),
                gameType: tableInfo?.split("_")[0],
                tableInfo: tableInfo?.split("_")[1],
            });

            handleListenSignalR();
            setActionTime(moment());
            setIsFirstLoad(false);
        }
    }

    function handleComponentUnmount() {
        StopConnect();
        handleStopStreaming(streamingRef, setIsLiveLoading);
    }

    function handleListenSignalR() {
        if (connection) {
            connection.on("table-detail", (result) => {
                if (result.status) {
                    handleCreateChip(result?.data?.chips);
                    handleStartStreaming(streamingRef, result?.data?.liveURL, 1080, setIsLiveLoading);

                    setTableDetail(result?.data);
                    setBetItems(result?.data2);
                    setStreamingObj({ ref: streamingRef, url: result?.data?.liveURL, graphic: 1080, setState: setIsLiveLoading });
                }
            });

            connection.on("game-route", (result) => {
                setRoadMaps((prev) => (result.status ? result : prev));
            });

            connection.on("received-signal", (result) => {
                if (result.tableCode === tableDetail?.tableCode) {
                    connection.off("bet-response");
                    setActionMsg((prev) => (prev.showWinAmount ? prev : { text: "", totalWin: 0, showWinAmount: false, result: undefined }));
                    setTimeout(() => {
                        setDealerSignal(result);
                    }, 300);
                }
            });

            connection.on("bet-statistic", (result) => {
                setBetStatistic(result);
            });

            connection.on("player-card", (result) => {
                result.status ? setCardResult(result.data) : setCardResult();
            });

            connection.on("bet-code-result", (result) => {
                if (result.status) {
                    let dice_arr = result?.data3?.split(",");
                    let total = dice_arr.length >= 3 ? Number(dice_arr[0]) + Number(dice_arr[1]) + Number(dice_arr[2]) : 0;
                    let isTriple = dice_arr.length >= 3 && Number(dice_arr[0]) === Number(dice_arr[1]) && Number(dice_arr[0]) === Number(dice_arr[2]);

                    setActionMsg({
                        text: `${total <= 10 ? t("small") : t("big")} ${total % 2 === 0 ? t("even") : t("odd")}`,
                        bgColor: isTriple ? "#faad14" : total <= 10 ? "#286abb" : "#b92a1f",
                        totalWin: 0,
                        showWinAmount: true,
                        result: dice_arr,
                    });
                    setWinList(result.data2);

                    result?.data?.forEach((item) => {
                        let arr = item.split("");
                        let result;

                        if (arr >= 3) {
                            result = arr[0] + arr[1] + arr[2] + "1";
                        } else {
                            result = item;
                        }
                        document.querySelector(`[data-id="${result}"]`)?.classList?.add("win");
                    });

                    setTotalBet(0);
                    result?.data2?.find((item) => {
                        if (item.playerID === userData?.playerID) {
                            setTotalBet(item.winAmount);
                        }
                    });
                }
            });
        }
    }

    useEffect(() => {
        handleDealerSignal(dealerSignal);
    }, [dealerSignal]);

    /**
     *
     * @param {object} signal dealer signal
     */
    async function handleDealerSignal(signal) {
        connection?.on("bet-response", async (result) => {
            setUserData(result.data2);
            // setBalance(Math.trunc(result.data2.balance * 100) / 100);
            if (!result.status) {
                Swal.fire({ text: t(result.message) });
                await handleRemoveAllChip(signal?.gameSummarySrno, true, 2, setTotalBet, connection, tableInfo);
            } else {
                setActionTime(moment);
                await handleUpdateBetToSuccess(signal?.gameSummarySrno, setTotalBet);
                message.success({ content: "Bet Success", key: "bet-success" });
            }
        });

        // if (signal?.gameSummarySrno && (signal?.action === "new-game" || signal?.action === "stop-bet")) {
        //     await handleDefaultPlaceChipFixed(chipsArr, signal).then((result) => {
        //         setTimeout(() => {
        //             setTotalBet((prev) => prev + result.totalBet);
        //         }, 300);
        //     });
        // }

        switch (signal?.action) {
            case "new-game":
                setActionMsg({ text: "startBet", totalWin: 0, showWinAmount: false, result: undefined });
                setBetContainerClass(true);

                setReceivedStopBet(false);
                setTotalBet(0);
                setBetStatistic({ total: 0, p1: 0, p2: 0, p3: 0 });
                handleCheckExpired(actionTime, setActionTime, tableInfo, t, navigate);
                handleRemoveClassName("win");
                await handleRemoveAllChip(signal?.gameSummarySrno, false, 0, setTotalBet, connection, tableInfo);
                setCardResult();
                break;
            case "stop-bet":
                setReceivedStopBet(true);
                setActionMsg({ text: "stopBet", totalWin: 0, showWinAmount: false, result: undefined });
                // handleConfirmBet(connection, signal, tableInfo);
                setReviewCard(true);

                handleRemoveClassName("win");
                setBetContainerClass(false);
                await handleRemoveAllChip(signal?.gameSummarySrno, true, 1, setTotalBet, connection, tableInfo);

                break;
            case "light-code":
                if (!receivedStopBet) {
                    await handleRemoveAllChip(signal?.gameSummarySrno, true, 1, setTotalBet, connection, tableInfo);
                }
                setLabelBet("lastWin");
                break;
            case "next-game":
                await handleRemoveAllChip(signal?.gameSummarySrno, false, 0, setTotalBet, connection, tableInfo);
                if (totalBet !== 0) {
                    handleGetPlayerInfo(connection);
                }

                setReceivedStopBet(false);
                setTotalBet(0);
                setCardResult();
                setWinList();
                setBetStatistic({ total: 0, p1: 0, p2: 0, p3: 0 });
                handleRemoveClassName("win");
                break;
            case "last-game":
                await handleRemoveAllChip(signal?.gameSummarySrno, false, 0, setTotalBet, connection, tableInfo);

                setReceivedStopBet(false);
                setTotalBet(0);
                setCardResult();
                setWinList();
                setBetStatistic({ total: 0, p1: 0, p2: 0, p3: 0 });
                handleRemoveClassName("win");
                break;
            case "cancel-round":
                setActionMsg({ text: "cancelRound", totalWin: 0, showWinAmount: false, result: undefined });
                await handleRemoveAllChip(signal?.gameSummarySrno, false, 0, setTotalBet, connection, tableInfo);

                setReceivedStopBet(false);
                setTotalBet(0);
                setCardResult();
                setWinList();
                setBetStatistic({ total: 0, p1: 0, p2: 0, p3: 0 });
                handleRemoveClassName("win");
                break;
            default:
                handleRemoveClassName("win");
                break;
        }
        setReceivedStopBet(dealerSignal);
    }

    function handleRemoveClassName(classname) {
        const elems = document.querySelectorAll(`.${classname}`);
        elems?.forEach((element) => {
            element.classList.remove(classname);
        });
    }

    return {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        setCardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        setActionTime,
        betContainerClass,
        setBetContainerClass,
        reviewCard,
        setReviewCard,
        isRevealVisible,
        setIsRevealVisible,
        gameType,
        setGameType,
        chatMsg,
    };
}
