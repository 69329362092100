import React from "react";
import { HourglassOutlined } from "@ant-design/icons";
import { renderToString } from "react-dom/server";
function TimerLobby({ dealerSignal }) {
  let total = dealerSignal?.second;

  var interval = setInterval(function () {
    total--;
    const timer = document.querySelector(`.${dealerSignal?.tableID}timer`);

    const time = document.querySelector(`[data-id=time${dealerSignal?.tableID}]`);
    if (time !== null) {
      time.innerHTML = +total;
      if (total <= 0) {
        time.innerHTML = renderToString(
          <div className="hour-glass">
            <HourglassOutlined />
          </div>
        );
        clearInterval(interval);
      }
    }
  }, 1000);

  return <div data-id={`time${dealerSignal?.tableID}`}>{dealerSignal?.second}</div>;
}

export default TimerLobby;
