import React from "react";
import { Result } from "antd";
import { useRouteError } from "react-router-dom";
import "./Style.scss";

function Error() {
    const error = useRouteError();
    console.log(error);
    return (
        <div className="error-bg">
            <Result
                status="warning"
                title={error ? error?.statusText : "Access Denied"}
                subTitle={error?.message ? error?.message : error?.error?.message}
            />
        </div>
    );
}

export default Error;
