import { Modal, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import "./chipsetting.scss";
import Chip from "../chip/Chip";
import close from "../../../assets/game/common/close.png";
import { updateSelectedChips } from "../../../services/login-api/LoginServices";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function ChipSetting({ chipSetting, setChipSetting, ChipsSet, tableDetail }) {
    const [isLoading, setIsLoading] = useState(false);
    const [newChipAmt, setNewChipAmt] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        let tmp = ChipsSet.map((chip) => {
            return chip["value"];
        });

        // setNewChipAmt(tmp);
        setNewChipAmt(tmp);
    }, []);

    async function handleConfirmEdit() {
        setIsLoading(true);

        let finalChipSet2 = ChipsSet.map((chip) => {
            return chip["value"];
        });

        const finalInput = {
            playerID: localStorage.getItem("playerID"),
            playerToken: localStorage.getItem("playerToken"),
            selectedChips: newChipAmt.sort((a, b) => a - b).join(),
        };

        if (newChipAmt.sort((a, b) => a - b).join() !== finalChipSet2.join()) {
            try {
                const result = await updateSelectedChips(finalInput);
                if (result.status) {
                    Swal.fire({ icon: "success", text: t("success") }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
            } catch (error) {
                message.error({ key: "error", content: error?.response?.data?.message });
            }
        } else {
            message.info({ key: "nothing", content: t("nothing") });
        }
        setIsLoading(false);
    }

    function handleAmountClick(amount) {
        if (newChipAmt?.includes(amount)) {
            let tmp = newChipAmt.filter((e) => {
                return e !== amount;
            });
            // setNewChipAmt(tmp);
            setNewChipAmt(tmp);
        } else {
            // setNewChipAmt([...newChipAmt, amount]);
            setNewChipAmt([...newChipAmt, amount]);
        }
    }

    return (
        <Modal
            className="chip-setting-modal"
            closeIcon={<img src={close} alt="" />}
            destroyOnClose={true}
            onCancel={() => setChipSetting(false)}
            open={chipSetting}
            footer={null}
            centered
        >
            <Spin spinning={isLoading}>
                <div className="chip-setting-container">
                    <div className="chip-wrapper">{t("selectChips")}</div>
                    <div className="agent-chips">
                        {tableDetail?.agentChips.split(",")?.map((amount, index) => (
                            <div
                                key={index}
                                className={`agent-chip ${newChipAmt?.includes(Number(amount))}`}
                                onClick={() => handleAmountClick(Number(amount))}
                            >
                                {amount}
                            </div>
                        ))}
                    </div>

                    <div className="confirm-edit-btn" onClick={() => handleConfirmEdit()}>
                        {t("update")}
                    </div>
                </div>
            </Spin>
        </Modal>
    );
}

export default ChipSetting;
