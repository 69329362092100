import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph, Text } = Typography;

function ScoreCard() {
    const { t } = useTranslation();

    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("score1")}</Paragraph>
                <Paragraph>{t("score2")}</Paragraph>
                <Paragraph>{t("score3")}</Paragraph>
                <Paragraph>
                    <Text strong>{t("score4")}</Text>
                    {t("score5")}
                </Paragraph>
                <Paragraph>{t("score6")} </Paragraph>
                <Paragraph>
                    <Text strong>{t("score7")}</Text>
                    {t("score8")}
                </Paragraph>
                <Paragraph>{t("score9")}</Paragraph>
                <Paragraph>{t("score10")}</Paragraph>
                <Paragraph>{t("score11")}</Paragraph>
                <Paragraph>{t("score12")}</Paragraph>
                {/* <Paragraph>Image</Paragraph> */}
                <Paragraph>
                    <Text strong>{t("score13")}</Text>
                    {t("score14")}
                </Paragraph>
                {/* <Paragraph>Image</Paragraph> */}
                <Paragraph>{t("score15")} </Paragraph>
                <Paragraph>
                    <Text strong>{t("score16")}</Text>
                    {t("score17")}
                </Paragraph>
                <Paragraph>
                    <Paragraph>{t("score18")} </Paragraph>
                    <Paragraph>{t("score19")} </Paragraph>
                    <Paragraph>{t("score20")} </Paragraph>
                    <Paragraph>{t("score21")} </Paragraph>
                    <Paragraph>{t("score22")} </Paragraph>
                    <Paragraph>{t("score23")} </Paragraph>
                </Paragraph>
                {/* <Paragraph>Image</Paragraph> */}
                <Paragraph>
                    <Text strong>{t("score24")}</Text>
                    {t("score25")}
                </Paragraph>
                {/* <Paragraph>Image</Paragraph> */}
            </Space>
        </Typography>
    );
}

export default ScoreCard;
