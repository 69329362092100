import moment from "moment";
import Swal from "sweetalert2";

/**
 *
 * @param {object} conn connection
 * @param {string} lang
 */
export function handleUpdateLanguage(conn, lang) {
  conn.invoke("ChangeLanguage", {
    playerID: localStorage.getItem("playerID"),
    playerToken: localStorage.getItem("playerToken"),
    lang: lang,
  });
}

/**
 *
 * @param {object} conn connection
 */
export function handleGetPlayerInfo(conn) {
  conn.invoke("GetPlayerInfo", {
    playerID: localStorage.getItem("playerID"),
    playerToken: localStorage.getItem("playerToken"),
  });
}

/**
 *
 * @param {object} conn connection
 * @param {int} page
 * @param {int} pageSize
 */
export function handleGetGameHistory(conn, page, pageSize) {
  conn.invoke("GetGameHistory", {
    playerID: localStorage.getItem("playerID"),
    playerToken: localStorage.getItem("playerToken"),
    page: page,
    pageSize: pageSize,
  });
}

/**
 *
 * @param {object} conn connection
 * @param {object} record
 */
export function handleGetGameHistoryDetail(conn, record) {
  conn.invoke("GetGameHistoryDetail", {
    playerID: localStorage.getItem("playerID"),
    playerToken: localStorage.getItem("playerToken"),
    gameSummarySrno: record?.gameSummarySrno,
    gameName: record?.gameName,
  });
}

/**
 *
 * @param {object} conn connection
 * @param {string} table_info direct get tableInfo from params without split
 */
export function handleGetTableList(conn, table_info, userData) {
  conn.invoke("GetTableList", {
    playerID: localStorage.getItem("playerID"),
    playerToken: localStorage.getItem("playerToken"),
    tableInfo: table_info?.split("_")[1],
    redirectUrl: table_info?.split("&")[1].replaceAll("|", "/"),
    lang: userData?.lang,
  });
}

/**
 *
 * @param {state} actionTime
 * @param {state} setActionTime
 * @param {string} table_info direct get tableInfo from params without split
 */
export function handleCheckExpired(actionTime, setActionTime, tableInfo, t, navigate) {
  if (moment().diff(actionTime, "minutes") > 10) {
    let timerInterval;
    Swal.fire({
      title: t("clickToStay"),
      html: t("clickToStayDesc"),
      timer: 10000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
        }, 50);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then(async (result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        let url = tableInfo?.split("&")[1].replaceAll("|", "/");
        url ? window.location.replace(url) : navigate(-1);
      } else {
        setActionTime(moment());
      }
    });
  }
}
