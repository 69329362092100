import React, { useState } from "react";
import ReactDOM from "react-dom";
import { renderToString } from "react-dom/server";

import Chip from "../components-game/chips-set/chip/Chip";

export const SelectedChipsVersion = 1;
export function useChips() {
    const BaseUrl = "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v5/";

    const [chipsArr, setChipsArr] = useState();
    const [selectedChip, setSelectedChip] = useState();

    //IndexedDB extension
    const CreateStore = (db) => {
        const store = db.createObjectStore("Log_Chips_Selected", {
            keyPath: "key",
        });
        store.createIndex("Key", ["key"], { unique: true });
    };
    //End extension

    /**
     *
     * @param {string} chips 2.5,5,10,50,100,500,1000
     */
    function handleCreateChip(chips) {
        const arr = chips?.split(",");
        const ChipsObj = arr?.map((item, index) => {
            if (arr.length > index) {
                // return { image: `${BaseUrl}${Number(item) >= 1000 ? "4" : "3"}/chip${index}.png`, value: Number(arr[index]) };
                return { image: `${BaseUrl}chip${index}.png`, value: Number(arr[index]) };
            }
        });
        setChipsArr(ChipsObj);
    }
    /**
     *
     * @param {object} chip {image: "", value: 10}
     */
    function handleSelectChip(chip) {
        //open database
        const request = window.indexedDB.open("Galaxy_Selected_Chip", SelectedChipsVersion);
        //create table if does not exist
        request.onupgradeneeded = (e) => {
            CreateStore(e.target.result);
        };
        //after create table or table already exist will direct come here
        request.onsuccess = (e) => {
            const db = e.target.result;

            const transaction = db.transaction("Log_Chips_Selected", "readwrite");
            const store = transaction.objectStore("Log_Chips_Selected");

            const check_exists = store.get(1);
            check_exists.onsuccess = (e) => {
                const data = e.target.result;
                if (data === undefined) {
                    store.add({ key: 1, image: chip?.image, value: chip?.value });
                } else {
                    data.image = chip?.image;
                    data.value = chip?.value;
                    store.put(data);
                }
                setSelectedChip(chip);
            };

            transaction.oncomplete = () => {
                db.close();
            };
        };
    }
    /**
     *
     * @param {array of object} chips_set array return by handleCreateChip
     */
    function handleDefaultSelectedChip(chips_set) {
        //open database
        const request = window.indexedDB.open("Galaxy_Selected_Chip", SelectedChipsVersion);
        //create table if does not exist
        request.onupgradeneeded = (e) => {
            CreateStore(e.target.result);
        };
        //after create table or table already exist will direct come here
        request.onsuccess = (e) => {
            const db = e.target.result;

            const transaction = db.transaction("Log_Chips_Selected", "readwrite");
            const store = transaction.objectStore("Log_Chips_Selected");

            const check_exists = store.get(1);
            check_exists.onsuccess = (e) => {
                const data = e.target.result;
                // console.log(data);
                if (data) {
                    const temp = chips_set?.filter((item) => item && item?.value === data.value);
                    if (temp.length > 0) {
                        setSelectedChip(temp[0]);
                    } else {
                        let result;
                        chips_set?.reduce((prev, curr) => {
                            if (curr) {
                                if (prev === 0) {
                                    result = curr;
                                    return curr;
                                } else {
                                    if (curr?.value - data?.value <= prev?.value - data?.value) {
                                        result = curr;
                                        return curr;
                                    } else {
                                        result = prev;
                                        return prev;
                                    }
                                }
                            }
                        }, 0);

                        data.image = result?.image;
                        data.value = result?.value;

                        store.put(data);
                        setSelectedChip(result);
                    }
                } else {
                    store.add({
                        key: 1,
                        image: chips_set[0]?.image,
                        value: chips_set[0]?.value,
                    });
                    setSelectedChip(chips_set[0]);
                }
            };

            transaction.oncomplete = (e) => {
                db.close();
            };
        };
    }
    function handleGetSelectedChip() {
        return new Promise((resolve, reject) => {
            let selected_chip; //request for get selected chip
            const request = window.indexedDB.open("Galaxy_Selected_Chip", SelectedChipsVersion);
            request.onupgradeneeded = () => {
                CreateStore(e.target.result);
                return reject("Please select chip");
            };
            request.onerror = () => {
                db.close();
                return reject("Unable to get selected chip");
            };
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction("Log_Chips_Selected", "readwrite");
                const store = transaction.objectStore("Log_Chips_Selected");

                const check_exists = store.get(1);
                check_exists.onsuccess = (e) => {
                    if (e.target.result) {
                        selected_chip = e.target.result;
                        db.close();
                        return resolve(selected_chip);
                    } else {
                        db.close();
                        return reject("Please select chip");
                    }
                };
            };
        });
    }

    /**
     *
     * current function not done
     */
    function handlePlaceChipClicked(key, event, selected_chip, unique_id, round_unique_id) {
        const temp_chip = "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip3.png";

        //create chip container with data-id
        const container = document.createElement("div");
        container.className = "chip-placed";
        container.setAttribute("data-id", roundUniqueID);
        ReactDOM.render(<Chip image={temp_chip} text="12" />, container);

        //get main container info
        const main_container = document.querySelector(`[data-id="${key}"]`);
        const main_container_position = main_container.getBoundingClientRect();

        //set chip container position
        container.style.top = `${event.clientY - main_container_position.top - container.clientHeight / 2 - 20}px`;
        container.style.left = `${event.clientX - main_container_position.left - container.clientWidth / 2 - 20}px`;
        main_container.appendChild(container);

        //check is chip container overflow than update
        const all_container = document.querySelectorAll(`[data-id="${gamesummarysrno}"]`);

        const container_to_check = all_container[all_container?.length - 1];
        const check_container_position = container_to_check?.getBoundingClientRect();
        if (check_container_position?.bottom > main_container_position?.bottom) {
            container_to_check.style.top = `${
                check_container_position.top + check_container_position.bottom - main_container_position.bottom + 10
            }px`;
        }
    }
    /**
     *
     * @param {array of object} chipArr array return by handleCreateChip
     * @param {string} key bet items's data-id
     * @param {object} selected_chip {image: "", value: 10}
     * @param {long} unique_id uniqueID return by handlePlaceBet
     * @param {long} round_unique_id gameSummarySrno
     */
    function handlePlaceChipFixed(chipArr, key, selected_chip, unique_id, round_unique_id) {
        const temp = chipArr?.findIndex((item) => item?.value > selected_chip?.value);

        //create chip container with data-id
        const container = document.createElement("div");
        container.className = "chip-placed";
        container.setAttribute("data-id", round_unique_id);
        container.setAttribute("data-id-unique", unique_id);
        container.innerHTML = renderToString(<Chip image={temp > 0 ? chipArr[temp - 1]?.image : selected_chip?.image} text={selected_chip?.value} />);

        //get main container info
        const main_container = document.querySelector(`[data-id="${key}"]`);

        main_container.appendChild(container);

        if (document.querySelectorAll(".shine")?.length === 0) {
            document.querySelector(`#confirm-btn`)?.classList?.add("shine");
        }
    }

    return {
        chipsArr,
        setChipsArr,
        selectedChip,
        handleCreateChip,
        handleSelectChip,
        handleDefaultSelectedChip,
        handleGetSelectedChip,
        handlePlaceChipFixed,
    };
}
