import { Descriptions } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

function Payouts() {
    const { t } = useTranslation();

    return (
        <Descriptions column={1} bordered>
            <Descriptions.Item label="BET">{t("payouts1")}</Descriptions.Item>
            <Descriptions.Item label="Player">1:1</Descriptions.Item>
            <Descriptions.Item label="Banker">0.95:1 (5% {t("payouts2")})</Descriptions.Item>
            <Descriptions.Item label="Tie">8:1</Descriptions.Item>
            <Descriptions.Item label="P Pair">11:1</Descriptions.Item>
            <Descriptions.Item label="B Pair">11:1</Descriptions.Item>
        </Descriptions>
    );
}

export default Payouts;
