import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../../context/AuthContext";

import "./action-message.scss";
import Road from "../road-map/road/Road";
import { getColor } from "../bet-panel/RouletteBetConst";

function ActionMessage({ text = "", color = "#fff", bgColor = "#000", totalWin = 0, showWinAmount = false, result = undefined }) {
    const { t } = useTranslation();
    const { userData } = useContext(AuthContext);

    const withoutResult = () => (
        <div className="action-message" style={{ color: color, background: bgColor }}>
            <div className="action-message-title">{t(text)}</div>
        </div>
    );

    let item = {
        color: getColor(result?.replace("R", "")),
        number: result?.replace("R", ""),
    };

    const withResult = () => (
        <div className="action-message" style={{ color: color, background: bgColor }}>
            <div className="action-message-title">
                <div className="result-message-wrapper">
                    <div className="road-wrapper">
                        <Road item={item} />
                    </div>
                </div>
                <div className="win-loss-message">{`${t("youWin")} : ${userData?.currency} ${totalWin}`}</div>
            </div>
        </div>
    );

    return showWinAmount ? withResult() : withoutResult();
}

export default ActionMessage;
