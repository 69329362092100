import React from "react";
import { useTranslation } from "react-i18next";

import { CircleFlag } from "react-circle-flags";

import "./settings.scss";
import { Col, Row, Card, Space } from "antd";
import { CaretRightFilled } from "@ant-design/icons";

function Settings({ connection, func }) {
  const { t, i18n } = useTranslation();

  const setting = [
    {
      key: t("general"),
      children: [
        {
          name: t("language"),
          key: "language",
          icon: (
            <Space size="small">
              <CircleFlag countryCode={i18n.language} height="20" />
              <CaretRightFilled style={{ color: "#bfbfbf" }} />
            </Space>
          ),
        },
      ],
    },
  ];

  function handleSettingClick(item) {
    if (item?.key === "quality" || item?.key === "language" || item?.key === "fullscreen") {
      func(item?.key, item?.name);
    }
  }

  return (
    <Row justify="center" align="middle" gutter={[0, 16]} className="setting-card">
      {setting?.map((menu, index) => (
        <Col xs={24} key={index}>
          <Space direction="vertical">
            {menu?.key}
            {menu?.children?.map((item, index) => (
              <Card hoverable key={index} onClick={() => handleSettingClick(item)}>
                <Row justify="space-between" align="middle">
                  <Col>{item.name}</Col>
                  <Col>{item?.icon}</Col>
                </Row>
              </Card>
            ))}
          </Space>
        </Col>
      ))}
    </Row>
  );
}

export default Settings;
