import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

function DisconnectionPolicy() {
    const { t } = useTranslation();

    return <Text strong>{t("disconnect1")}</Text>;
}

export default DisconnectionPolicy;
