import React from "react";

function Icon({ image = "" }) {
    return (
        <div style={{ aspectRatio: "1/1", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <svg width="100%" height="100%" style={{ background: "transparent" }}>
                <image href={image} height="100%" width="100%" />
            </svg>
        </div>
    );
}

export default Icon;
