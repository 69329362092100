import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Image, Result, Statistic } from "antd";
import "./Style.scss";
import logo from "../../assets/logo/logo-new.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

const { Countdown } = Statistic;

function Maintenance() {
    const { maintenanceInfo } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const deadline = Number(moment(maintenanceInfo?.maintenanceEnd).format("x"));

    const onFinish = () => {
        navigate(-1, { replace: true });
    };

    return (
        <div className="maintenance-bg">
            <Result
                icon={<Image src={logo} width={300} preview={false} />}
                title={t("maintenance")}
                subTitle={t("maintInProgressDesc")}
                // extra={<Countdown title={t("countdownDesc")} value={deadline} onFinish={onFinish} />}
            />
        </div>
    );
}

export default Maintenance;
