import { LockFilled, UserOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row, Space, Spin, Typography, message } from "antd";
import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AuthContext } from "../../context/AuthContext";
import { guestLogin, playerLogin } from "../../services/login-api/LoginServices";
import { trimFormInput } from "../../utils/Formatter";
import "./Style.scss";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo/logo-new.png";
import loginBtn from "../../assets/login/login-btn.png";

const { Text, Link } = Typography;

function Login() {
    const { isLogin, setIsLogin, setBalance, setUserData } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const { i18n } = useTranslation();
    const [checkbox, setCheckbox] = useState(window.localStorage.getItem("checked"));

    function handleCheckboxChange(e) {
        const checked = e.target.checked;
        setCheckbox(checked);
    }

    async function handleLogin(values) {
        setIsLoading(true);
        const finalUserInput = {
            playerID: values?.playerID,
            password: values?.password,
        };

        try {
            const result = await playerLogin(finalUserInput);
            if (result.status) {
                setUserData(result.data);
                // setBalance(Math.trunc(result.data.balance * 100) / 100);
                setBalance(result.data.balance);
                localStorage.setItem("playerID", result.data.playerID);
                localStorage.setItem("playerToken", result.data.playerToken);
                i18n.changeLanguage(result.data.lang);
                setIsLogin(true);
            }
        } catch (error) {
            Swal.fire({
                text: error?.response?.data?.message,
            });
        }
        setIsLoading(false);
    }

    async function handleGuestLogin() {
        setIsLoading(true);
        try {
            const result = await guestLogin();
            if (result.status) {
                setUserData(result.data);
                // setBalance(Math.trunc(result.data.balance * 100) / 100);
                setBalance(result.data.balance);
                localStorage.setItem("playerID", result.data.playerID);
                localStorage.setItem("playerToken", result.data.playerToken);
                i18n.changeLanguage(result.data.lang);
                setIsLogin(true);
            }
        } catch (error) {
            Swal.fire({
                text: error?.response?.data?.message,
            });
        }
        setIsLoading(false);
    }

    if (isLogin) {
        return <Navigate to="/lobby/games/all" replace />;
    }

    return (
        <div className="login-bg">
            <div className="login-wrapper">
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
                <Form className="login-form" layout="vertical" onFinish={handleLogin}>
                    <div className="login-form-wrapper">
                        <Form.Item
                            name="playerID"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Enter",
                                },
                            ]}
                        >
                            <Input size="large" prefix={<UserOutlined />} placeholder="Username"></Input>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Enter",
                                },
                            ]}
                        >
                            <Input size="large" type="password" prefix={<LockFilled />} placeholder="Password"></Input>
                        </Form.Item>
                        {/* <Form.Item>
                            <Checkbox style={{ color: "white" }} checked={checkbox} onChange={(e) => handleCheckboxChange(e)}>
                                Remember Me
                            </Checkbox>
                        </Form.Item> */}
                        <Form.Item>
                            <Button shape="round" type="primary" htmlType="submit" size="large" block>
                                LOGIN
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default Login;
