import React, { useState } from "react";

import "./RoadMap.scss";
import Dot from "./dots/Dot";

function RoadMap({ RoadType = "none", Data = undefined }) {
  const [road01Mode, setRoad01Mode] = useState(true);

  const RoadMap01 = () => {
    let StrokeRight = true;

    return (
      <div className="baccarat-road road-01" onClick={() => setRoad01Mode(!road01Mode)}>
        {Data?.map((row, index) => {
          StrokeRight = index < Data?.length - 1;

          return (
            <div className="row-item" key={index + row}>
              {row?.map((col, index) => {
                const value = col?.split("-");
                const fillColor = value[0] === "B" ? "#ee4043" : value[0] === "P" ? "#3788ff" : value[0] === "T" ? "#159252" : "none";

                return (
                  <div className="col-item" key={index + col}>
                    <Dot
                      StrokeColor="none"
                      FillColor={fillColor}
                      text={road01Mode ? value[0] : value[1]}
                      LineRight={StrokeRight}
                      LineBottom={index < row.length - 1}
                      BankerPair={value?.includes("BP")}
                      PlayerPair={value?.includes("PP")}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const RoadMap02 = () => {
    let StrokeRight = true;

    return (
      <div className="baccarat-road road-02">
        {Data?.map((row, index) => {
          StrokeRight = index < Data?.length - 1;

          return (
            <div className="row-item" key={index + row}>
              {row?.map((col, index) => {
                const value = col?.split("-");
                const StrokeColor = value[0] === "B" ? "#ee4043" : value[0] === "P" ? "#3788ff" : "none";

                const point_arr = col.split("(")[1]?.split(")")[0]?.split("=");
                const text = value[0] === "B" && point_arr[0] === "6" ? point_arr[0] : "";

                return (
                  <div className="col-item" key={index + col}>
                    <Dot
                      StrokeColor={StrokeColor}
                      text={text}
                      TextColor={value?.includes("BT") ? "#a31717" : value?.includes("SMT") ? "#b3b300" : "#ee4043"}
                      FillColor="none"
                      LineRight={StrokeRight}
                      LineBottom={index < row.length - 1}
                      BankerPair={value?.includes("BP")}
                      PlayerPair={value?.includes("PP")}
                      Tie={value?.includes("T")}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const RoadMap03 = () => {
    let StrokeRight = true;

    return (
      <div className="baccarat-road road-03">
        {Data?.map((row, index) => {
          StrokeRight = index % 2 !== 0 && index !== 0;

          return (
            <div className="row-item" key={index + row}>
              {row?.map((col, index) => {
                const value = col?.split("-");
                const StrokeColor = value[0] === "B" ? "#ee4043" : value[0] === "P" ? "#3788ff" : "none";

                return (
                  <div className="col-item" key={index + col}>
                    <Dot StrokeColor={StrokeColor} FillColor="none" LineRight={StrokeRight} LineBottom={index % 2 !== 0 && index !== 0} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const RoadMap04 = () => {
    let StrokeRight = true;

    return (
      <div className="baccarat-road road-04">
        {Data?.map((row, index) => {
          StrokeRight = index % 2 !== 0 && index !== 0;

          return (
            <div className="row-item" key={index + row}>
              {row?.map((col, index) => {
                const value = col?.split("-");
                const FillColor = value[0] === "B" ? "#ee4043" : value[0] === "P" ? "#3788ff" : "none";

                return (
                  <div className="col-item" key={index + col}>
                    <Dot StrokeColor="none" FillColor={FillColor} LineRight={StrokeRight} LineBottom={index % 2 !== 0 && index !== 0} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const RoadMap05 = () => {
    let StrokeRight = true;

    return (
      <div className="baccarat-road road-05">
        {Data?.map((row, index) => {
          StrokeRight = index % 2 !== 0 && index !== 0;

          return (
            <div className="row-item" key={index + row}>
              {row?.map((col, index) => {
                const value = col?.split("-");
                const TieColor = value[0] === "B" ? "#ee4043" : value[0] === "P" ? "#3788ff" : "none";
                return (
                  <div className="col-item" key={index + col}>
                    <Dot
                      StrokeColor="none"
                      FillColor="none"
                      LineRight={StrokeRight}
                      LineBottom={index % 2 !== 0 && index !== 0}
                      TieColor={TieColor}
                      Tie
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return RoadType === "01"
    ? RoadMap01()
    : RoadType === "02"
    ? RoadMap02()
    : RoadType === "03"
    ? RoadMap03()
    : RoadType === "04"
    ? RoadMap04()
    : RoadMap05();
}

export default RoadMap;
