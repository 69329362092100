import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { formatNumber } from "../../../utils/Formatter";
import { useThreePicture } from "../hook/useThreePicture";

import "./VThreePicture.scss";
import { Spin } from "antd";

import CFBtnView from "../../../components-game/navigator/CFBtnView";
import Timer from "../../../components-game/timer/Timer";
import BetPanel from "../component/bet-panel/BetPanel";
import TpRoadMap from "../component/road-map/TpRoadMap";
import WinList from "../../../components-game/win-list/WinList";
import ActionMessage from "../../../components-game/action-message/ActionMessage";
import ReviewCard from "../component/review-card/vertical/ReviewCard";
import Dot from "../component/road-map/dots/Dot";
import BankerCard from "../component/banker-card/BankerCard";

import TableList from "../../../components-game/table-list/TableList";

import LoadingPage from "../../../pages/loading-page/LoadingPage";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import Icon from "../../../components-game/navigator/icon/Icon";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";
import VChips from "../../../components-game/chips-set/new-vertical/VChips";

function VThreePicture() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        betType,
        setBetType,
        reviewCard,
        setReviewCard,
        betContainerClass,
        chatMsg,
    } = useThreePicture();
    const [openTableList, setOpenTableList] = useState(false);

    if (isFirstLoad) {
        return <LoadingPage />;
    }

    return (
        <div
            style={{ background: `linear-gradient(to bottom, ${tableDetail?.color?.split(",")[0]}, ${tableDetail?.color?.split(",")[1]})` }}
            id="vertical-three-picture"
        >
            <div className="header-container">
                <div className="header-title">
                    <div className="header-btn" onClick={() => navigate(-1)}>
                        <Icon image={back} />
                    </div>
                    {tableDetail?.tableName}
                </div>
                <div className="header-menu">
                    {streamingObj && (
                        <CFBtnView
                            BtnTable={true}
                            BtnGraphic={true}
                            connection={connection}
                            GraphicObj={streamingObj}
                            setOpenTableList={setOpenTableList}
                            tableDetail={tableDetail}
                            chatMsg={chatMsg}
                        />
                    )}
                </div>
            </div>

            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <div className="id-wrapper">
                        <div className="uid">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</div>
                        <div className="uid">ID: {userData?.playerID ? userData.playerID : "-"}</div>
                    </div>
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                    <div className="win-list-wrapper" style={{ display: winList ? "block" : "none" }}>
                        {winList && <WinList winList={winList} />}
                    </div>
                    {dealerSignal?.second ? (
                        <div className="timer-container">
                            <Timer dealerSignal={dealerSignal} />
                        </div>
                    ) : (
                        ""
                    )}
                    <button className="btn-bet-panel-type" onClick={() => setBetType((prev) => (prev === 1 ? 2 : 1))}>
                        {betType === 1 ? t("traditionalBet") : t("betPoint")}
                    </button>
                    <div className="banker-card-wrapper">
                        <div className="title" style={{ display: cardResult ? "block" : "none" }}>
                            {t("bankerCard")}
                        </div>
                        {cardResult && <BankerCard tableDetail={tableDetail} dealerSignal={dealerSignal} cardResult={cardResult[0]} />}
                    </div>
                </div>
            </Spin>

            <div className="bet-panel-container">
                <div className={`bet-panel 01 ${betType === 1}`}>
                    {betItems && (
                        <BetPanel
                            tableInfo={tableInfo}
                            connection={connection}
                            chipArr={chipsArr}
                            rate={betItems}
                            tableDetail={tableDetail}
                            dealerSignal={dealerSignal}
                            setTotalBet={setTotalBet}
                            betStatistic={betStatistic}
                            setLabelBet={setLabelBet}
                            cardResult={cardResult ? cardResult[1] : []}
                            type="traditional"
                            betContainerClass={betContainerClass}
                        />
                    )}
                </div>
                <div className={`bet-panel 02 ${betType === 2}`}>
                    {betItems && (
                        <BetPanel
                            tableInfo={tableInfo}
                            connection={connection}
                            chipArr={chipsArr}
                            rate={betItems}
                            tableDetail={tableDetail}
                            dealerSignal={dealerSignal}
                            setTotalBet={setTotalBet}
                            betStatistic={betStatistic}
                            setLabelBet={setLabelBet}
                            cardResult={cardResult}
                            type="more"
                            betContainerClass={betContainerClass}
                        />
                    )}
                </div>
            </div>

            <div className={`action-msg-container ${actionMsg}`}>
                <ActionMessage text={actionMsg} color="gold" totalWin={totalBet} />
            </div>

            {dealerSignal?.action === "stop-bet" && cardResult && reviewCard && (
                <ReviewCard tableDetail={tableDetail} result={cardResult} setReviewCard={setReviewCard} />
            )}

            <div className="chips-set-container">
                {chipsArr && (
                    <VChips
                        setLabelBet={setLabelBet}
                        connection={connection}
                        tableInfo={tableInfo}
                        ChipsSet={chipsArr}
                        tableDetail={tableDetail}
                        dealerSignal={dealerSignal}
                        totalBet={totalBet}
                        setTotalBet={setTotalBet}
                        roadMaps={roadMaps}
                    />
                )}
            </div>

            <div className="footer-container">
                <div className="container-01">
                    <span className="lbl lbl-total-bet">
                        {t(labelBet)}: {userData?.currency + " " + formatNumber(totalBet)}
                    </span>
                    <span className="lbl lbl-balance">Balance: {userData?.currency + " " + formatNumber(balance)}</span>
                </div>

                <div className="container-02">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</div>
            </div>
            <div className="road-maps-container">
                <div className="road-01">
                    <TpRoadMap Data={roadMaps?.data?.route_1} />
                </div>
            </div>
            <div className="road-map-statistic-container">
                <div className="statistic-count">
                    <div className="lbl lbl-title">{`${t("last")} ${roadMaps?.data2?.total} ${t("result")}`}</div>

                    <div className="lbl">
                        <div className="lbl-dot">
                            <Dot FillColor="#3788ff" text="P1" LineBottom={false} LineRight={false} />
                        </div>
                        <div className="content">{roadMaps?.data2?.player_1}</div>
                    </div>

                    <div className="lbl">
                        <div className="lbl-dot">
                            <Dot FillColor="#3788ff" text="P2" LineBottom={false} LineRight={false} />
                        </div>
                        <div className="content">{roadMaps?.data2?.player_2}</div>
                    </div>

                    <div className="lbl">
                        <div className="lbl-dot">
                            <Dot FillColor="#3788ff" text="P3" LineBottom={false} LineRight={false} />
                        </div>
                        <div className="content">{roadMaps?.data2?.player_3}</div>
                    </div>
                </div>
            </div>

            {/* <div className="tbl-btn-container">
                <div className="tbl-btn-wrapper" onClick={() => setOpenTableList(!openTableList)}>
                    <Icon image={table} />
                </div> */}

            <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />
            {/* </div> */}

            {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default VThreePicture;
