import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../../context/AuthContext";

import "./action-message.scss";
import Dice from "../Dice";

function ActionMessage({ text, color = "#fff", bgColor = "#000", totalWin = 0, showWinAmount = false, result = undefined }) {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  const withoutResult = () => (
    <div className="sicbo-action-message" style={{ color: color, background: bgColor }}>
      <div className="action-message-title">{t(text)}</div>
    </div>
  );

  const withResult = () => (
    <div className="action-message" style={{ color: color, background: bgColor }}>
      <div className="action-message-title">
        <div className="result-message-wrapper">
          {result?.map((items, index) => (
            <div key={index} className="dice-wrapper">
              <Dice point={Number(items)} />
            </div>
          ))}

          <span style={{ marginLeft: 10 }}>{text}</span>
        </div>
        <div className="win-loss-message">{`${t("youWin")} : ${userData?.currency} ${totalWin}`}</div>
      </div>
    </div>
  );

  return showWinAmount ? withResult() : withoutResult();
}

export default ActionMessage;
