import React from "react";
import { Button, Result } from "antd";
import { useNavigate, useRouteError } from "react-router-dom";
import "./Style.scss";
import { QuestionCircleFilled } from "@ant-design/icons";

function PageNotFound() {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <div className="error-bg">
      <Result
        icon={<QuestionCircleFilled />}
        title="Sorry, the page you visited does not exist."
        subTitle={error?.message ? error?.message : error?.error?.message}
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Back
          </Button>
        }
      />
    </div>
  );
}

export default PageNotFound;
