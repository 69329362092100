import React from "react";
import "./sb-how-to-play.scss";

function SbHowToPlay() {
  const translation = {
    gb: (
      <div className="sb-how-to-play">
        <div className="section">
          <div className="title">Overview:</div>
          <div className="content">SicBo is a lively game of chance played with three regular dice with face values from 1 to 6. The objective is to predict the outcome of the shake of the three dice.</div>
        </div>

        <div className="section">
          <div className="title">How To Play:</div>
          <div className="content">
            <p>The player places their bets on the bet spots of their choice then wait for stop bet. The dice are constantly shaken, with only a short pause to display the results of the round.</p>
            <p>After bets are placed, a number of bet spots — from zero to several — have multipliers randomly applied to them, while the speed of the dice shaker increases. When the dice come to rest, the winning bet spots are highlighted on the betting grid. If the player’s bet is placed on the bet spot with the applied multiplier, their bet is multiplied accordingly.</p>
          </div>
        </div>

        <div className="section">
          <div className="title">Betting Options and Odds:</div>
          <div className="content">
            <p>The player can place many kinds of bets on the Instant Super Sic Bo table, and each type of bet has its own payout. The player’s bet is returned on top of their winnings.</p>
            <ul>
              <li>
                <strong>Small/Big</strong> — place your bet on the total of the three dice being Small (4–10) or Big (11–17). Wins pay 1:1, but these bets lose to any Triple.
              </li>
              <li>
                <strong>Even/Odd</strong> — place your bet on the total of the three dice being Odd or Even. Wins pay 1:1, but these bets lose to any Triple.
              </li>
              <li>
                <strong>Total</strong> — place your bet on any of the 14 betting areas labelled 4–17. Total is the total of the three dice and excludes 3 and 18. You win if the total of the three dice adds up to the Total number on which you placed your bet. Payouts vary depending on the winning total.
              </li>
              <li>
                <strong>Single</strong> — place your bet on any of the six betting areas labelled ONE, TWO, THREE, FOUR, FIVE and SIX which represent the six face values of a dice.
                <ol>
                  <li>If 1 of 3 dice shows the number you bet on, you get paid 1:1.</li>
                  <li>If 2 of 3 dice show the number you bet on, you get paid 2:1.</li>
                  <li>If all 3 dice show the number you bet on, you get paid 3:1.</li>
                </ol>
              </li>
              <li>
                <strong>Double</strong> — place your bet on any of the six Double-labelled betting areas. To win, 2 of 3 dice must show the same number. Wins pay 8:1. Please note that regardless of whether 2 or 3 dice show the same number, the payout remains the same.
              </li>
              <li>
                <strong>Triple</strong> — place your bet on any of the six Triple-labelled betting areas. To win, all 3 dice must match the number chosen, and you get paid 150:1.
              </li>
              <li>
                <strong>Any Triple</strong> — place your bet on this box to cover all six different Triple bets at once. To win, all three dice must show the same number, and you get paid 30:1.
              </li>
              <li>
                <strong>Combination</strong> — place your bet on any or all 15 possible 2 dice combinations. Wins pay 5:1.
              </li>
            </ul>
            <p>After betting is closed, random bet spots will be highlighted, showing the multiplied payouts. The highlighted multipliers may be randomly applied to any bet spot except the Small, Big, Odd, and Even bet spots, which never receive a multiplier.</p>
          </div>
        </div>
      </div>
    ),
    cn: (
      <div className="sb-how-to-play">
        <div className="section">
          <div className="title">概述：</div>
          <div className="content">SicBo是一款使用三颗常规骰子（面值从1到6）进行的充满活力的机会游戏。其目标是预测三颗骰子摇动后的结果。</div>
        </div>

        <div className="section">
          <div className="title">玩法：</div>
          <div className="content">
            <p>玩家在选择的投注位置上下注，然后等待停止下注。骰子会不断地摇动，仅在短暂的停顿后显示回合结果。</p>
            <p>下注后，随机为零到多个下注位置的倍数随机应用，同时骰子摇动的速度增加。当骰子停下时，在投注网格上突出显示赢得的下注位置。如果玩家的下注位置上应用了倍数，他们的下注将相应地乘以倍数。</p>
          </div>
        </div>

        <div className="section">
          <div className="title">下注选项和赔率：</div>
          <div className="content">
            <p>玩家可以在Instant Super Sic Bo桌上进行多种类型的下注，每种类型的下注都有自己的赔率。玩家的下注将在他们的获胜之上返还。</p>
            <ul>
              <li>
                <strong>小/大</strong> — 在三颗骰子的总和为小（4-10）或大（11-17）上下注。赢得的下注支付1:1，但这些下注输给任何三同。
              </li>
              <li>
                <strong>单/双</strong> — 在三颗骰子的总和为奇数或偶数上下注。赢得的下注支付1:1，但这些下注输给任何三同。
              </li>
              <li>
                <strong>总和</strong> — 在标有4-17的14个投注区域中的任何一个上下注。总和是三颗骰子的总和，不包括3和18。如果三颗骰子的总和等于您下注的总和数字，则赢得。赔率根据获胜的总和而异。
              </li>
              <li>
                <strong>单一</strong> — 在标有ONE、TWO、THREE、FOUR、FIVE和SIX的六个下注区域中的任何一个上下注，代表骰子的六个面值。
                <ol>
                  <li>如果3颗骰子中有1颗显示您下注的数字，则支付1:1。</li>
                  <li>如果3颗骰子中有2颗显示您下注的数字，则支付2:1。</li>
                  <li>如果3颗骰子都显示您下注的数字，则支付3:1。</li>
                </ol>
              </li>
              <li>
                <strong>双</strong> — 在标有Double的六个下注区域中的任何一个上下注。要赢，3颗骰子中必须有2颗显示相同的数字。赢得的下注支付8:1。请注意，无论是2颗还是3颗骰子显示相同的数字，赔率都保持不变。
              </li>
              <li>
                <strong>三同</strong> — 在标有Triple的六个下注区域中的任何一个上下注。要赢，所有3颗骰子必须匹配所选数字，支付150:1。
              </li>
              <li>
                <strong>任意三同</strong> — 在此框中下注，一次覆盖所有六个不同的三同下注。要赢，三颗骰子必须都显示相同的数字，支付30:1。
              </li>
              <li>
                <strong>组合</strong> — 在15种可能的2颗骰子组合中的任何一个或全部上下注。赢得的下注支付5:1。
              </li>
            </ul>
            <p>下注关闭后，将突出显示随机的下注位置，显示倍增的支付。突出显示的倍数可能随机应用于除小、大、奇和偶数外的任何下注位置。</p>
          </div>
        </div>
      </div>
    ),
    my: (
      <div className="sb-how-to-play">
        <div className="section">
          <div className="title">Gambaran Keseluruhan:</div>
          <div className="content">SicBo adalah permainan peluang yang penuh dengan kehidupan dimainkan dengan tiga dadu biasa dengan nilai muka dari 1 hingga 6. Objektifnya adalah untuk meramalkan hasil guncangan tiga dadu tersebut.</div>
        </div>

        <div className="section">
          <div className="title">Cara Bermain:</div>
          <div className="content">
            <p>Pemain meletakkan pertaruhan mereka pada tempat pertaruhan pilihan mereka kemudian menunggu henti pertaruhan. Dadu sentiasa digoncang, dengan hanya singkatnya berhenti untuk memaparkan hasil ronde.</p>
            <p>Selepas pertaruhan diletakkan, beberapa tempat pertaruhan - dari sifar hingga beberapa - secara rawak diberikan penggandaan, sementara kelajuan penggoncangan dadu meningkat. Apabila dadu berhenti, tempat pertaruhan yang menang diterangkan di grid pertaruhan. Jika pertaruhan pemain diletakkan pada tempat pertaruhan dengan penggandaan yang diterapkan, pertaruhan mereka dikalikan secara sewajarnya.</p>
          </div>
        </div>

        <div className="section">
          <div className="title">Pilihan Pertaruhan dan Kebarangkalian:</div>
          <div className="content">
            <p>Pemain boleh meletakkan pelbagai jenis pertaruhan pada meja Instant Super Sic Bo, dan setiap jenis pertaruhan mempunyai bayaran sendiri. Pertaruhan pemain dikembalikan di atas kemenangan mereka.</p>
            <ul>
              <li>
                <strong>Kecil/Besar</strong> — letakkan pertaruhan anda pada jumlah ketiga dadu kecil (4-10) atau besar (11-17). Kemenangan membayar 1:1, tetapi pertaruhan ini kalah kepada apa-apa Triple.
              </li>
              <li>
                <strong>Ganjil/Genap</strong> — letakkan pertaruhan anda pada jumlah ketiga dadu yang ganjil atau genap. Kemenangan membayar 1:1, tetapi pertaruhan ini kalah kepada apa-apa Triple.
              </li>
              <li>
                <strong>Jumlah</strong> — letakkan pertaruhan anda pada mana-mana daripada 14 kawasan pertaruhan yang dilabelkan 4-17. Jumlah ialah jumlah ketiga dadu dan tidak termasuk 3 dan 18. Anda menang jika jumlah ketiga dadu menambah hingga kepada jumlah keseluruhan di mana anda meletakkan pertaruhan anda. Bayaran berbeza bergantung pada jumlah menang.
              </li>
              <li>
                <strong>Tunggal</strong> — letakkan pertaruhan anda pada mana-mana daripada enam kawasan pertaruhan yang dilabelkan SATU, DUA, TIGA, EMPAT, LIMA dan ENAM yang mewakili nilai muka enam dadu.
                <ol>
                  <li>Jika 1 daripada 3 dadu menunjukkan nombor yang anda pertaruhkan, anda dibayar 1:1.</li>
                  <li>Jika 2 daripada 3 dadu menunjukkan nombor yang anda pertaruhkan, anda dibayar 2:1.</li>
                  <li>Jika semua 3 dadu menunjukkan nombor yang anda pertaruhkan, anda dibayar 3:1.</li>
                </ol>
              </li>
              <li>
                <strong>Ganda</strong> — letakkan pertaruhan anda pada mana-mana daripada enam kawasan pertaruhan yang dilabelkan Ganda. Untuk menang, 2 daripada 3 dadu mesti menunjukkan nombor yang sama. Kemenangan membayar 8:1. Sila ambil perhatian bahawa tidak kira sama ada 2 atau 3 dadu menunjukkan nombor yang sama, bayaran tetap sama.
              </li>
              <li>
                <strong>Triple</strong> — letakkan pertaruhan anda pada mana-mana daripada enam kawasan pertaruhan yang dilabelkan Triple. Untuk menang, semua 3 dadu mesti sepadan dengan nombor yang dipilih, dan anda dibayar 150:1.
              </li>
              <li>
                <strong>Sebarang Triple</strong> — letakkan pertaruhan anda pada petak ini untuk merangkumi enam pertaruhan Triple yang berbeza sekaligus. Untuk menang, semua tiga dadu mesti menunjukkan nombor yang sama, dan anda dibayar 30:1.
              </li>
              <li>
                <strong>Kombinasi</strong> — letakkan pertaruhan anda pada mana-mana atau semua 15 kombinasi dua dadu yang mungkin. Kemenangan membayar 5:1.
              </li>
            </ul>
            <p>Selepas pertaruhan ditutup, tempat pertaruhan rawak akan diterangkan, menunjukkan pembayaran yang dilipatgandakan. Penggandaan yang diterangkan mungkin secara rawak diterapkan kepada mana-mana tempat pertaruhan kecuali tempat pertaruhan Kecil, Besar, Ganjil, dan Genap, yang tidak pernah menerima penggandaan.</p>
          </div>
        </div>
      </div>
    ),
    pt: (
      <div className="sb-how-to-play">
        <div className="section">
          <div className="title">Visão Geral:</div>
          <div className="content">SicBo é um jogo animado de chance jogado com três dados regulares com valores de face de 1 a 6. O objetivo é prever o resultado do lançamento dos três dados.</div>
        </div>

        <div className="section">
          <div className="title">Como Jogar:</div>
          <div className="content">
            <p>O jogador faz suas apostas nos locais desejados e aguarda o término das apostas. Os dados são constantemente agitados, com apenas uma breve pausa para exibir os resultados da rodada.</p>
            <p>Após as apostas serem feitas, vários locais de aposta - de zero a vários - têm multiplicadores aplicados aleatoriamente, enquanto a velocidade do agitador de dados aumenta. Quando os dados param, os locais de aposta vencedores são destacados na grade de apostas. Se a aposta do jogador estiver no local de aposta com o multiplicador aplicado, a aposta deles é multiplicada conforme necessário.</p>
          </div>
        </div>

        <div className="section">
          <div className="title">Opções de Aposta e Odds:</div>
          <div className="content">
            <p>O jogador pode fazer muitos tipos de apostas na mesa Instant Super Sic Bo, e cada tipo de aposta tem seu próprio pagamento. A aposta do jogador é devolvida além dos ganhos.</p>
            <ul>
              <li>
                <strong>Pequeno/Grande</strong> — faça sua aposta na soma dos três dados sendo Pequena (4-10) ou Grande (11-17). As vitórias pagam 1:1, mas essas apostas perdem para qualquer Trinca.
              </li>
              <li>
                <strong>Ímpar/Par</strong> — faça sua aposta na soma dos três dados sendo Ímpar ou Par. As vitórias pagam 1:1, mas essas apostas perdem para qualquer Trinca.
              </li>
              <li>
                <strong>Total</strong> — faça sua aposta em qualquer uma das 14 áreas de aposta rotuladas de 4 a 17. O Total é a soma dos três dados e exclui 3 e 18. Você ganha se a soma dos três dados resultar no número Total em que você fez sua aposta. Os pagamentos variam dependendo do total vencedor.
              </li>
              <li>
                <strong>Simples</strong> — faça sua aposta em qualquer uma das seis áreas de aposta rotuladas de UM, DOIS, TRÊS, QUATRO, CINCO e SEIS, que representam os seis valores de face de um dado.
                <ol>
                  <li>Se 1 dos 3 dados mostrar o número em que você apostou, você é pago 1:1.</li>
                  <li>Se 2 dos 3 dados mostrarem o número em que você apostou, você é pago 2:1.</li>
                  <li>Se todos os 3 dados mostrarem o número em que você apostou, você é pago 3:1.</li>
                </ol>
              </li>
              <li>
                <strong>Duplo</strong> — faça sua aposta em qualquer uma das seis áreas de aposta rotuladas como Duplo. Para ganhar, 2 dos 3 dados devem mostrar o mesmo número. As vitórias pagam 8:1. Por favor, note que, independentemente de 2 ou 3 dados mostrarem o mesmo número, o pagamento permanece o mesmo.
              </li>
              <li>
                <strong>Trinca</strong> — faça sua aposta em qualquer uma das seis áreas de aposta rotuladas como Trinca. Para ganhar, os 3 dados devem corresponder ao número escolhido, e você é pago 150:1.
              </li>
              <li>
                <strong>Qualquer Trinca</strong> — faça sua aposta nesta caixa para cobrir todas as seis apostas Trinca diferentes de uma vez. Para ganhar, os três dados devem mostrar o mesmo número, e você é pago 30:1.
              </li>
              <li>
                <strong>Combinação</strong> — faça sua aposta em qualquer uma ou todas as 15 combinações possíveis de 2 dados. As vitórias pagam 5:1.
              </li>
            </ul>
            <p>Após o fechamento das apostas, locais de aposta aleatórios serão destacados, mostrando os pagamentos multiplicados. Os multiplicadores destacados podem ser aplicados aleatoriamente a qualquer local de aposta, exceto os locais de aposta Pequeno, Grande, Ímpar e Par, que nunca recebem um multiplicador.</p>
          </div>
        </div>
      </div>
    ),
    vn: (
      <div className="sb-how-to-play">
        <div className="section">
          <div className="title">Tổng Quan:</div>
          <div className="content">SicBo là một trò chơi may rủi sôi động được chơi với ba viên xúc xắc thông thường có giá trị mặt từ 1 đến 6. Mục tiêu là dự đoán kết quả của cú lắc của ba viên xúc xắc.</div>
        </div>

        <div className="section">
          <div className="title">Cách Chơi:</div>
          <div className="content">
            <p>Người chơi đặt cược của họ trên các vị trí cược mà họ chọn sau đó đợi chờ dừng cược. Xúc xắc liên tục được lắc, chỉ có một thời gian ngắn để hiển thị kết quả của vòng.</p>
            <p>Sau khi cược được đặt, một số vị trí cược - từ không đến một số - có giá trị gấp đôi được áp dụng ngẫu nhiên cho chúng, trong khi tốc độ lắc xúc xắc tăng lên. Khi xúc xắc dừng lại, các vị trí cược chiến thắng được đánh dấu trên lưới cược. Nếu cược của người chơi được đặt trên vị trí cược có giá trị gấp đôi, cược của họ sẽ được nhân theo.</p>
          </div>
        </div>

        <div className="section">
          <div className="title">Tùy Chọn Cược và Tỷ Lệ Cược:</div>
          <div className="content">
            <p>Người chơi có thể đặt nhiều loại cược khác nhau trên bàn Instant Super Sic Bo, và mỗi loại cược đều có tỷ lệ thanh toán riêng. Số tiền cược của người chơi được trả lại cộng thêm vào số tiền thắng của họ.</p>
            <ul>
              <li>
                <strong>Nhỏ/Lớn</strong> — đặt cược của bạn vào tổng của ba viên xúc xắc là Nhỏ (4-10) hoặc Lớn (11-17). Thắng trả 1:1, nhưng những cược này thua trước bất kỳ Bộ Ba nào.
              </li>
              <li>
                <strong>Lẻ/Chẵn</strong> — đặt cược của bạn vào tổng của ba viên xúc xắc là Lẻ hoặc Chẵn. Thắng trả 1:1, nhưng những cược này thua trước bất kỳ Bộ Ba nào.
              </li>
              <li>
                <strong>Tổng</strong> — đặt cược của bạn vào bất kỳ một trong 14 khu vực cược được đánh số từ 4 đến 17. Tổng là tổng của ba viên xúc xắc và không tính 3 và 18. Bạn thắng nếu tổng của ba viên xúc xắc thêm lên thành số Tổng mà bạn đã đặt cược. Tỷ lệ thanh toán thay đổi tùy thuộc vào tổng chiến thắng.
              </li>
              <li>
                <strong>Đơn</strong> — đặt cược của bạn vào bất kỳ một trong sáu khu vực cược được đánh số MỘT, HAI, BA, BỐN, NĂM và SÁU, tương ứng với sáu giá trị mặt của một viên xúc xắc.
                <ol>
                  <li>Nếu 1 trong 3 viên xúc xắc hiển thị số bạn đã đặt cược, bạn nhận được thanh toán 1:1.</li>
                  <li>Nếu 2 trong 3 viên xúc xắc hiển thị số bạn đã đặt cược, bạn nhận được thanh toán 2:1.</li>
                  <li>Nếu tất cả 3 viên xúc xắc hiển thị số bạn đã đặt cược, bạn nhận được thanh toán 3:1.</li>
                </ol>
              </li>
              <li>
                <strong>Đôi</strong> — đặt cược của bạn vào bất kỳ một trong sáu khu vực cược được đánh dấu là Đôi. Để thắng, 2 trong 3 viên xúc xắc phải hiển thị cùng một số. Thắng trả 8:1. Lưu ý rằng, bất kỳ là 2 hoặc 3 viên xúc xắc hiển thị cùng một số, tỷ lệ thanh toán vẫn giữ nguyên.
              </li>
              <li>
                <strong>Bộ Ba</strong> — đặt cược của bạn vào bất kỳ một trong sáu khu vực cược được đánh dấu là Bộ Ba. Để thắng, cả 3 viên xúc xắc phải trùng với số đã chọn và bạn được thanh toán 150:1.
              </li>
              <li>
                <strong>Bộ Ba Bất Kỳ</strong> — đặt cược của bạn vào ô này để bao phủ tất cả sáu cược Bộ Ba khác nhau cùng một lúc. Để thắng, cả ba viên xúc xắc phải hiển thị cùng một số và bạn được thanh toán 30:1.
              </li>
              <li>
                <strong>Kết Hợp</strong> — đặt cược của bạn vào bất kỳ hoặc tất cả 15 tổ hợp có thể của 2 viên xúc xắc. Thắng trả 5:1.
              </li>
            </ul>
            <p>Sau khi cược đóng, các vị trí cược ngẫu nhiên sẽ được đánh dấu, hiển thị thanh toán nhân đôi. Các giá trị nhân đôi được đánh dấu có thể được áp dụng ngẫu nhiên cho bất kỳ vị trí cược nào ngoại trừ các vị trí cược Nhỏ, Lớn, Lẻ và Chẵn, không bao giờ nhận giá trị gấp đôi.</p>
          </div>
        </div>
      </div>
    ),
    th: (
      <div className="sb-how-to-play">
        <div className="section">
          <div className="title">ภาพรวม:</div>
          <div className="content">SicBo เป็นเกมโชคลาภที่น่าสนุกที่เล่นกับลูกเต๋าสามลูกที่มีค่าหน้าตามจำนวน 1 ถึง 6 เป้าหมายคือการทำนายผลลัพธ์ของการโยนลูกเต๋าสามลูกนั้น</div>
        </div>

        <div className="section">
          <div className="title">วิธีการเล่น:</div>
          <div className="content">
            <p>ผู้เล่นวางเงินเดิมพันในตำแหน่งที่เลือกแล้วรอให้หยุดการเดิมพัน ลูกเต๋าถูกเขย่าอย่างต่อเนื่อง มีพักสั้นเพื่อแสดงผลของรอบ</p>
            <p>หลังจากที่การเดิมพันถูกวางเรียบร้อยแล้ว มีการนำมัลติไพล์เออร์ให้เป็นไปตามหลาย ๆ แห่ง ขณะที่ความเร็วของลูกเต๋าเพิ่มขึ้น เมื่อลูกเต๋าหยุดทำงาน ตำแหน่งที่เดิมพันชนะจะถูกเน้นไว้บนกริดการเดิมพัน หากการเดิมพันของผู้เล่นวางไว้ที่ตำแหน่งที่ได้รับมัลติไพล์เออร์ การเดิมพันของพวกเขาจะถูกคูณตามที่เหมาะสม</p>
          </div>
        </div>

        <div className="section">
          <div className="title">ตัวเลือกการเดิมพันและอัตราการชำระเงิน:</div>
          <div className="content">
            <p>ผู้เล่นสามารถวางเดิมพันได้หลายประเภทในโต๊ะ Instant Super Sic Bo และแต่ละประเภทของเดิมพันมีการชำระเงินของตัวเอง การเดิมพันของผู้เล่นจะถูกคืนรวมกับการชนะของพวกเขา</p>
            <ul>
              <li>
                <strong>เล็ก/ใหญ่</strong> — วางเงินเดิมพันในผลรวมของลูกเต๋าสามลูกเป็นเล็ก (4-10) หรือใหญ่ (11-17) ชนะจะได้รับการชำระเงิน 1:1 แต่เดิมพันเหล่านี้แพ้ทุกรายการไตร๊ค
              </li>
              <li>
                <strong>คี่/คู่</strong> — วางเงินเดิมพันในผลรวมของลูกเต๋าสามลูกเป็นคี่หรือคู่ ชนะจะได้รับการชำระเงิน 1:1 แต่เดิมพันเหล่านี้แพ้ทุกรายการไตร๊ค
              </li>
              <li>
                <strong>รวม</strong> — วางเงินเดิมพันในเขตการเดิมพัน 14 แห่งที่ถูกตั้งชื่อว่า 4-17 รวมคือผลรวมของลูกเต๋าสามลูกและไม่รวม 3 และ 18 คุณชนะถ้าผลรวมของลูกเต๋าสามลูกเท่ากับจำนวนรวมที่คุณวางเดิมพัน การชำระเงินจะแตกต่างขึ้นอยู่กับผลรวมที่ชนะ
              </li>
              <li>
                <strong>เดี่ยว</strong> — วางเงินเดิมพันในเขตการเดิมพันหนึ่งในหกที่ถูกตั้งชื่อว่า ONE, TWO, THREE, FOUR, FIVE และ SIX ซึ่งแทนค่าหน้าหกของลูกเต๋า
                <ol>
                  <li>หาก 1 ใน 3 ลูกเต๋าแสดงตัวเลขที่คุณวางเดิมพัน คุณได้รับการชำระเงิน 1:1</li>
                  <li>หาก 2 ใน 3 ลูกเต๋าแสดงตัวเลขที่คุณวางเดิมพัน คุณได้รับการชำระเงิน 2:1</li>
                  <li>หากทั้ง 3 ลูกเต๋าแสดงตัวเลขที่คุณวางเดิมพัน คุณได้รับการชำระเงิน 3:1</li>
                </ol>
              </li>
              <li>
                <strong>คู่</strong> — วางเงินเดิมพันในเขตการเดิมพันหนึ่งในหกที่ถูกตั้งชื่อว่า คู่ เพื่อชนะ 2 ใน 3 ลูกเต๋าต้องแสดงตัวเลขเดียวกัน ชนะจะได้รับการชำระเงิน 8:1 โปรดทราบว่าไม่ว่าจะ 2 หรือ 3 ลูกเต๋าแสดงตัวเลขเดียวกัน การชำระเงินจะยังคงเป็นไปตามเดิม
              </li>
              <li>
                <strong>ไตร๊ค</strong> — วางเงินเดิมพันในเขตการเดิมพันหนึ่งในหกที่ถูกตั้งชื่อว่า ไตร๊ค เพื่อชนะ ทั้ง 3 ลูกเต๋าต้องตรงกับตัวเลขที่เลือก และคุณจะได้รับการชำระเงิน 150:1
              </li>
              <li>
                <strong>ไตร๊คใดก็ได้</strong> — วางเงินเดิมพันในกล่องนี้เพื่อครอบคลุมทุกรายการไตร๊คทั้งหกที่แตกต่างกันในครั้งเดียว ในการชนะทั้งสามลูกเต๋าต้องแสดงตัวเลขเดียวกันและคุณจะได้รับการชำระเงิน 30:1
              </li>
              <li>
                <strong>ผสม</strong> — วางเงินเดิมพันในเขตการเดิมพัน 15 ผลลัพธ์ผสมที่เป็นไปได้ของ 2 ลูกเต๋า ชนะจะได้รับการชำระเงิน 5:1
              </li>
            </ul>
            <p>หลังจากการเดิมพันปิด ตำแหน่งเดิมพันที่สุ่มจะถูกเน้นขึ้นมาแสดงผลการชำระเงินที่ถูกคูณ เหล่ามัลติไพล์เออร์ที่เน้นไว้อาจถูกนำมาใช้สุ่มในตำแหน่งเดิมพันใด ๆ ยกเว้นตำแหน่งเดิมพันเล็ก, ใหญ่, คี่, และ คู่ ซึ่งไม่เคยได้รับการคูณ</p>
          </div>
        </div>
      </div>
    ),
    kr: (
      <div className="sb-how-to-play">
        <div className="section">
          <div className="title">개요:</div>
          <div className="content">SicBo는 1에서 6까지의 값이 있는 세 개의 일반 주사위로 진행되는 즐거운 운빨 게임입니다. 목표는 세 개의 주사위를 던진 결과를 예측하는 것입니다.</div>
        </div>

        <div className="section">
          <div className="title">게임 방법:</div>
          <div className="content">
            <p>플레이어는 선택한 베팅 위치에 베팅한 다음 베팅이 멈출 때까지 기다립니다. 주사위는 결과를 표시하기 위해 잠시 멈춘 것을 제외하고 계속해서 흔들립니다.</p>
            <p>베팅 후에는 무작위로 여러 베팅 위치에 곱셈이 적용되고 주사위 흔들림의 속도가 증가합니다. 주사위가 멈출 때, 이긴 베팅 위치는 베팅 그리드에서 강조됩니다. 플레이어의 베팅이 적용된 위치에 베팅되면 해당 베팅이 곱해집니다.</p>
          </div>
        </div>

        <div className="section">
          <div className="title">베팅 옵션 및 배당률:</div>
          <div className="content">
            <p>플레이어는 Instant Super Sic Bo 테이블에서 여러 종류의 베팅을 할 수 있으며 각 베팅 유형에는 자체적인 지불률이 있습니다. 플레이어의 베팅은 승리에 추가로 반환됩니다.</p>
            <ul>
              <li>
                <strong>작은/큰</strong> — 세 주사위의 합이 작은 경우 (4-10) 또는 큰 경우 (11-17) 베팅하세요. 이길 경우 1:1로 지불되지만, 이러한 베팅은 어떤 트리플에도 지게 됩니다.
              </li>
              <li>
                <strong>홀/짝</strong> — 세 주사위의 합이 홀수 또는 짝수인 경우 베팅하세요. 이길 경우 1:1로 지불되지만, 이러한 베팅은 어떤 트리플에도 지게 됩니다.
              </li>
              <li>
                <strong>합계</strong> — 4에서 17까지 레이블이 지정된 14개의 베팅 영역 중 하나에 베팅하세요. 합계는 세 주사위의 합계이며 3과 18은 제외됩니다. 세 주사위의 합계가 베팅한 총액과 일치하면 승리합니다. 이길 경우 승리한 총액에 따라 지불률이 달라집니다.
              </li>
              <li>
                <strong>단일</strong> — 주사위 여섯 면의 값인 ONE, TWO, THREE, FOUR, FIVE 및 SIX 중 하나에 베팅하세요.
                <ol>
                  <li>3개 중 1개의 주사위가 베팅한 숫자를 표시하면 1:1로 지불됩니다.</li>
                  <li>3개 중 2개의 주사위가 베팅한 숫자를 표시하면 2:1로 지불됩니다.</li>
                  <li>3개의 주사위가 모두 베팅한 숫자를 표시하면 3:1로 지불됩니다.</li>
                </ol>
              </li>
              <li>
                <strong>더블</strong> — 레이블이 된 여섯 개의 더블 베팅 영역 중 하나에 베팅하세요. 이기려면 3개 중 2개의 주사위가 동일한 숫자를 표시해야 합니다. 승리할 경우 8:1로 지불됩니다. 주의: 2개 또는 3개의 주사위가 동일한 숫자를 표시하더라도 지불률은 동일합니다.
              </li>
              <li>
                <strong>트리플</strong> — 레이블이 된 여섯 개의 트리플 베팅 영역 중 하나에 베팅하세요. 이기려면 3개의 주사위가 선택한 숫자와 일치해야 하며, 150:1로 지불됩니다.
              </li>
              <li>
                <strong>아무 트리플</strong> — 이 상자에 베팅하여 한 번에 여섯 가지 다른 트리플 베팅을 모두 커버하세요. 이기려면 세 주사위가 모두 동일한 숫자를 표시해야 하며, 30:1로 지불됩니다.
              </li>
              <li>
                <strong>조합</strong> — 2개의 주사위의 가능한 15가지 조합 중 하나 또는 모두에 베팅하세요. 승리할 경우 5:1로 지불됩니다.
              </li>
            </ul>
            <p>베팅이 마감되면 무작위로 베팅 위치가 강조되어 배당률이 곱해집니다. 강조된 배수는 절대로 작은, 큰, 홀수 및 짝수 베팅 위치에는 적용되지 않습니다.</p>
          </div>
        </div>
      </div>
    ),
  };

  return translation[localStorage.getItem("i18nextLng")] ?? translation["gb"];
}

export default SbHowToPlay;
