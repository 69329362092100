import React from "react";

//if no need color must pass 'none'
function Dot({ StrokeColor = "none", FillColor = "#159252", text = "", textColor = "#ddd", LineBottom = true, LineRight = true }) {
    return (
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", aspectRatio: "1/1" }}>
            <svg width="100%" height="100%" preserveAspectRatio="none" style={{ background: "transparent" }}>
                <circle cx="50%" cy="50%" r="40%" stroke={StrokeColor} strokeWidth="1" fill={FillColor} />

                {text && (
                    <text x="50%" y="50%" fontSize="80%" fill={textColor} textAnchor="middle" dy=".38em">
                        {text}
                    </text>
                )}

                {LineBottom && <line x1="0%" y1="100%" x2="100%" y2="100%" stroke="#c5c5c5" strokeWidth="3.5" strokeOpacity={0.5} />}
                {LineRight && <line x1="100%" y1="0%" x2="100%" y2="100%" stroke="#c5c5c5" strokeWidth="3.5" strokeOpacity={0.5} />}
            </svg>
        </div>
    );
}

export default Dot;
