import React, { useState } from "react";
import Icon from "../../../components-game/navigator/icon/Icon";
import TableList from "../../../components-game/table-list/TableList";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import WinList from "../../../components-game/win-list/WinList";
import VChips from "../../../components-game/chips-set/vertical/VChips";
import Timer from "../../../components-game/timer/Timer";
import ActionMessage from "../../../components-game/action-message/ActionMessage";
import CFBtnView from "../../../components-game/navigator/CFBtnView";
import { Spin } from "antd";
import { useDreamCatcher } from "../hook/useDreamCatcher";
import LoadingPage from "../../../pages/loading-page/LoadingPage";
import { useBetPanel } from "../../../hook/useBetPanel";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/Formatter";
import BetPanel from "../component/bet-panel/BetPanel";
import "./HDreamCatcher.scss";
import HChips from "../../../components-game/chips-set/new-horizontal/HChips";
import CheckableTag from "antd/es/tag/CheckableTag";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";

function HDreamCatcher() {
    const navigate = useNavigate();
    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        setCardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        betType,
        setBetType,
        reviewCard,
        setReviewCard,
        betContainerClass,
        setBetContainerClass,
        jackpotDescription,
        setJackpotDescription,
        gameType,
        setGameType,
        chatMsg,
    } = useDreamCatcher();

    const { handleDisplayRate } = useBetPanel();

    const [openTableList, setOpenTableList] = useState(false);
    const { t } = useTranslation();
    if (isFirstLoad) {
        return <LoadingPage />;
    }
    function handleChangeBetPanelSkew() {
        if ((dealerSignal?.action === "new-game" && !betContainerClass) || dealerSignal?.action !== "new-game") {
            setBetContainerClass((prev) => !prev);
        }
    }

    function handleChangeContainerBetPanelSkew(e) {
        if (dealerSignal?.action === "new-game" && e.target.className === "horizontal-dream-catcher-container") {
            setBetContainerClass((prev) => (!betContainerClass ? prev : !prev));
            console.log(e.target.className, betContainerClass);
        }
        console.log(dealerSignal?.action);
    }

    return (
        <div className="horizontal-dream-catcher">
            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                </div>
            </Spin>

            <div className="horizontal-dream-catcher-container" onClick={(e) => handleChangeContainerBetPanelSkew(e)}>
                <div className="header-container">
                    <div className="header-title">
                        <div className="header-btn" onClick={() => navigate(-1)}>
                            <Icon image={back} />
                        </div>
                        {tableDetail?.tableName}
                    </div>
                    <div className="header-menu">
                        {streamingObj && (
                            <CFBtnView BtnGraphic connection={connection} GraphicObj={streamingObj} tableDetail={tableDetail} chatMsg={chatMsg} />
                        )}
                    </div>
                </div>

                <div className={`action-msg-container ${actionMsg}`}>
                    {actionMsg && <ActionMessage text={actionMsg} color="gold" totalWin={totalBet} />}
                </div>

                {dealerSignal?.second ? (
                    <div className="timer-container">
                        <Timer dealerSignal={dealerSignal} />
                    </div>
                ) : (
                    ""
                )}

                <div className="win-list-wrapper" style={{ display: winList?.length > 0 ? "block" : "none" }}>
                    {winList?.length > 0 && <WinList winList={winList} />}
                </div>

                <div className={`bet-wrapper ${betContainerClass ? "new-game" : "stop-bet"}`} onClick={() => handleChangeBetPanelSkew()}>
                    <div className="bet-panel-container">
                        <div className="bet-panel">
                            {betItems && (
                                <BetPanel
                                    tableInfo={tableInfo}
                                    connection={connection}
                                    chipArr={chipsArr}
                                    rate={betItems}
                                    tableDetail={tableDetail}
                                    dealerSignal={dealerSignal}
                                    setTotalBet={setTotalBet}
                                    betStatistic={betStatistic}
                                    setLabelBet={setLabelBet}
                                    cardResult={cardResult ? cardResult : []}
                                />
                            )}
                        </div>
                    </div>

                    <div className="chips-wrapper">
                        {chipsArr && (
                            <HChips
                                setLabelBet={setLabelBet}
                                connection={connection}
                                tableInfo={tableInfo}
                                ChipsSet={chipsArr}
                                tableDetail={tableDetail}
                                dealerSignal={dealerSignal}
                                totalBet={totalBet}
                                setTotalBet={setTotalBet}
                                gameType={gameType}
                                setGameType={setGameType}
                                showComm={false}
                                roadMaps={roadMaps}
                            />
                        )}
                    </div>
                </div>

                <div className="footer-container total-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-total-bet">{t(labelBet) + ": " + userData?.currency + " " + formatNumber(totalBet)}</span>
                        <span className="lbl lbl-balance">{t("balance") + ": " + userData?.currency + " " + formatNumber(balance)}</span>
                    </div>
                </div>

                <div className="footer-container max-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-max-bet">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</span>
                        <div className="id-wrapper">
                            <span className="lbl lbl-id">ID: {userData?.playerID ? userData.playerID : "-"}</span>
                            <span className="lbl lbl-id">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default HDreamCatcher;
