import React from "react";

import "./RoadMap.scss";
import Dot from "./dots/Dot";

function RoadMap({ Data = [[]], ShowText = true }) {
  const reversed = [...Data].reverse();
  return (
    <div className="four-seasons-road road-01">
      <div className="row-item">
        <div className="col-item">
          <Dot
            StrokeColor="none"
            FillColor="none"
            text2="B"
            textColor="#000"
            bankerPlayer={true}
            LineRight
            LineBottom
          />
        </div>

        <div className="col-item">
          <Dot
            StrokeColor="none"
            FillColor="none"
            text2="P1"
            textColor="#000"
            bankerPlayer={true}
            LineRight
            LineBottom
          />
        </div>

        <div className="col-item">
          <Dot
            StrokeColor="none"
            FirstColor="none"
            LastColor="none"
            text2="P2"
            textColor="#000"
            bankerPlayer={true}
            LineRight
            LineBottom
          />
        </div>

        <div className="col-item">
          <Dot
            StrokeColor="none"
            FillColor="none"
            text2="P3"
            textColor="#000"
            bankerPlayer={true}
            LineRight
            LineBottom
          />
        </div>
      </div>

      {reversed?.map((row, index) => {
        const StrokeRight = index < Data?.length - 1;

        return (
          <div className="row-item" key={index + row}>
            {row?.map((col, index) => {
              const value = col?.split("-");
              const firstColor =
                value[0] === "dealer"
                  ? "#ee4043"
                  : value[0] === "win"
                  ? "#3788ff"
                  : value[0] === "lose"
                  ? "#ee4043"
                  : value[0] === "tie"
                  ? "#159252"
                  : "none";
              const lastColor =
                value[2] === "dealer"
                  ? "#ee4043"
                  : value[2] === "win"
                  ? "#3788ff"
                  : value[2] === "lose"
                  ? "#ee4043"
                  : value[2] === "tie"
                  ? "#159252"
                  : "none";
              const textColor = value[0] === "lose" ? "#000" : "#ddd";
              return (
                <div className="col-item" key={index + col}>
                  <Dot
                    StrokeColor="none"
                    FirstColor={firstColor}
                    LastColor={lastColor}
                    text={
                      value[1] === "11"
                        ? "J"
                        : value[1] === "12"
                        ? "Q"
                        : value[1] === "13"
                        ? "K"
                        : value[1] === "14"
                        ? "A"
                        : value[1]
                    }
                    text2={value[3]}
                    Jackpot={value[3] === "J" ? true : false}
                    bankerPlayer={value[3] === "J" ? true : false}
                    textColor={textColor}
                    LineRight={StrokeRight}
                    LineBottom={index < row.length - 1}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default RoadMap;
