import React from "react";

function Chip({ image = "", text = "", isSelected = false }) {
    let windowWidth = window.innerWidth;
    let isVertical = window.innerWidth < window.innerHeight;

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                overflow: "hidden",
            }}
        >
            <svg width="100%" height="100%" style={{ background: "transparent" }}>
                <image href={image} height="100%" width="100%" />
                <text
                    x="50%"
                    y="48%"
                    fontSize={`${windowWidth >= 667 && windowWidth < 1024 ? "60%" : windowWidth >= 1024 && windowWidth < 1366 ? "80%" : "90%"}`}
                    stroke="white"
                    strokeWidth={0}
                    fontWeight={600}
                    fill="white"
                    textAnchor="middle"
                    dy=".39em"
                >
                    {text >= 1000000 ? `${text / 1000000}m` : text >= 10000 ? `${text / 1000}k` : text}
                </text>

                {isSelected && (
                    <circle
                        cx="50%"
                        cy="50%"
                        r={windowWidth <= 1366 ? (isVertical && window.innerWidth <= 700 ? "38%" : "48%") : windowWidth <= 1600 ? "45%" : "50%"}
                        fill="none"
                        stroke="white"
                        strokeWidth="1.5"
                        pathLength="0"
                        // filter="url(#shadow)"
                    />
                )}
            </svg>
        </div>
    );
}

export default Chip;
