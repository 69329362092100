import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { formatDateTime, formatNumber, formatString } from "../../../../../../utils/Formatter";

import "./four-seasons.scss";
import { Card, Col, Image, Row, Space, Table, Tag, Typography } from "antd";
import { green, gold, red, blue } from "@ant-design/colors";

function FourSeasons({ record, data }) {
    const { t } = useTranslation();

    const columns = [
        {
            title: t("betCode"),
            dataIndex: "betCodeName",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return formatString(text);
            },
        },
        {
            title: t("bet"),
            dataIndex: "bet",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return "MYR " + formatNumber(text);
            },
        },
        {
            title: t("win"),
            dataIndex: "win",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return "MYR " + formatNumber(text);
            },
        },
    ];

    function getWinLose(index) {
        switch (record?.winCode?.split(",")[index]?.split("-")[1]) {
            case "dealer":
                return <Tag color={gold[4]}>Banker</Tag>;
            case "win":
                return <Tag color={blue[4]}>Player Win</Tag>;
            case "tie":
                return <Tag color={green[4]}>Tie</Tag>;
            case "lose":
                return <Tag color={red[4]}>Banker Win</Tag>;
            case "jackpot":
                return <Tag color={red[4]}>Jackpot</Tag>;
            default:
                break;
        }
    }

    return (
        <Space direction="vertical" style={{ width: "100%" }} className="game-hitsory-detail">
            <Typography.Text>Game Resolved: {formatDateTime(moment(record?.endDate).add(8, "h"))}</Typography.Text>
            <Typography.Text>Game Number: {record?.gameSummarySrno}</Typography.Text>
            {data?.data?.map((item, index) => (
                <Card align="center" key={index}>
                    <Row justify="center" align="middle">
                        <Col xs={4}>
                            <Typography.Text>{item?.seat === "B" ? "Banker" : `Player ${index}`}</Typography.Text>
                        </Col>

                        <Col xs={12}>
                            <Space size="large">
                                <div className={`jackpot ${item.isJackpot === 1 ? true : false}`}>
                                    {item?.card?.map((itemCard, index) => (
                                        <Image
                                            key={index}
                                            src={`${process.env.REACT_APP_DIGITALOCEAN_URL}/card-set/3/${itemCard?.value}`}
                                            preview={false}
                                            width={30}
                                        />
                                    ))}
                                </div>
                            </Space>
                        </Col>

                        <Col xs={6} style={{ textAlign: "start" }}>
                            {item.winLossFourSeason?.split(",")?.map((items, index) => {
                                return (
                                    <Tag
                                        style={{ marginTop: "5px" }}
                                        color={
                                            items === "dealer"
                                                ? gold[4]
                                                : items === "win"
                                                ? blue[4]
                                                : items === "lose"
                                                ? red[4]
                                                : items === "tie"
                                                ? green[4]
                                                : ""
                                        }
                                        key={index}
                                    >
                                        {items === "dealer" ? "Dealer" : items === "win" ? "Players Win" : items === "tie" ? "Tie" : "Banker Win"}
                                    </Tag>
                                );
                            })}
                        </Col>
                    </Row>
                </Card>
            ))}
            <Table
                bordered
                columns={columns}
                dataSource={data?.data2}
                rowKey="betCode"
                scroll={{ x: true }}
                pagination={false}
                summary={(pageData) => {
                    let totalBet = 0;
                    let totalWin = 0;
                    pageData.forEach(({ bet, win }) => {
                        totalBet += bet;
                        totalWin += win;
                    });
                    return (
                        <Table.Summary.Row align="center">
                            <Table.Summary.Cell index={0}>TOTAL</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>MYR {formatNumber(totalBet)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>MYR {formatNumber(totalWin)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Space>
    );
}

export default FourSeasons;
