import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { formatNumber } from "../../../utils/Formatter";
import { useThreePicture } from "../hook/useThreePicture";

import "./HThreePicture.scss";
import { Spin } from "antd";
import { SwapOutlined } from "@ant-design/icons";

import CFBtnView from "../../../components-game/navigator/CFBtnView";
import Timer from "../../../components-game/timer/Timer";
import BetPanel from "../component/bet-panel/BetPanel";
import TpRoadMap from "../component/road-map/TpRoadMap";
import WinList from "../../../components-game/win-list/WinList";
import ActionMessage from "../../../components-game/action-message/ActionMessage";
import Dot from "../component/road-map/dots/Dot";
import ReviewCard from "../component/review-card/horizontal/ReviewCard";
import BankerCard from "../component/banker-card/BankerCard";

import TableList from "../../../components-game/table-list/TableList";

import LoadingPage from "../../../pages/loading-page/LoadingPage";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import Icon from "../../../components-game/navigator/icon/Icon";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";
import HChips from "../../../components-game/chips-set/new-horizontal/HChips";

function HThreePicture() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        setCardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        betType,
        setBetType,
        reviewCard,
        setReviewCard,
        betContainerClass,
        setBetContainerClass,
        gameType,
        setGameType,
        chatMsg,
    } = useThreePicture();
    const [openTableList, setOpenTableList] = useState(false);

    if (isFirstLoad) {
        return <LoadingPage />;
    }

    function handleChangeComm(e) {
        return;
    }

    function handleChangeBetPanelSkew() {
        if ((dealerSignal?.action === "new-game" && !betContainerClass) || dealerSignal?.action !== "new-game") {
            setBetContainerClass((prev) => !prev);
            setBetType(dealerSignal?.action === "new-game" ? betType : 1);
        }
    }

    function handleChangeContainerBetPanelSkew(e) {
        if (dealerSignal?.action === "new-game" && e.target.className === "horizontal-three-picture-container") {
            setBetContainerClass((prev) => (!betContainerClass ? prev : !prev));
        }
    }

    return (
        <div id="horizontal-three-picture">
            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                </div>
            </Spin>

            <div className="horizontal-three-picture-container" onClick={(e) => handleChangeContainerBetPanelSkew(e)}>
                <div className="header-container">
                    <div className="header-title">
                        <div className="header-btn" onClick={() => navigate(-1)}>
                            <Icon image={back} />
                        </div>
                        {tableDetail?.tableName}
                    </div>
                    <div className="header-menu">
                        {streamingObj && (
                            <CFBtnView
                                BtnTable={true}
                                BtnGraphic={true}
                                connection={connection}
                                GraphicObj={streamingObj}
                                setOpenTableList={setOpenTableList}
                                tableDetail={tableDetail}
                                chatMsg={chatMsg}
                            />
                        )}
                    </div>
                </div>

                <div className={`action-msg-container ${actionMsg}`}>
                    {actionMsg && <ActionMessage text={actionMsg} color="gold" totalWin={totalBet} />}
                </div>

                {dealerSignal?.second ? (
                    <div className="timer-container">
                        <Timer dealerSignal={dealerSignal} />
                    </div>
                ) : (
                    ""
                )}

                <div className="win-list-wrapper" style={{ display: winList?.length > 0 ? "block" : "none" }}>
                    {winList?.length > 0 && <WinList winList={winList} />}
                </div>

                <div className={`bet-wrapper ${betContainerClass ? "new-game" : "stop-bet"}`}>
                    <div className="bet-panel-container" onClick={() => handleChangeBetPanelSkew()}>
                        <div className={`bet-panel 01 ${betType === 1}`}>
                            {betItems && (
                                <BetPanel
                                    tableInfo={tableInfo}
                                    connection={connection}
                                    chipArr={chipsArr}
                                    rate={betItems}
                                    tableDetail={tableDetail}
                                    dealerSignal={dealerSignal}
                                    setTotalBet={setTotalBet}
                                    betStatistic={betStatistic}
                                    setLabelBet={setLabelBet}
                                    cardResult={cardResult ? cardResult[1] : []}
                                    type="traditional"
                                    betContainerClass={betContainerClass}
                                />
                            )}
                        </div>
                        <div className={`bet-panel 02 ${betType === 2}`}>
                            {betItems && (
                                <BetPanel
                                    tableInfo={tableInfo}
                                    connection={connection}
                                    chipArr={chipsArr}
                                    rate={betItems}
                                    tableDetail={tableDetail}
                                    dealerSignal={dealerSignal}
                                    setTotalBet={setTotalBet}
                                    betStatistic={betStatistic}
                                    setLabelBet={setLabelBet}
                                    cardResult={cardResult}
                                    type="more"
                                    betContainerClass={betContainerClass}
                                />
                            )}
                        </div>

                        <div className="btn-bet-panel-method" onClick={() => setBetType((prev) => (prev === 1 ? 2 : 1))}>
                            <SwapOutlined style={{ transform: "rotateZ(90deg)" }} />
                        </div>
                        <div className="banker-card-wrapper">
                            <div className="title" style={{ display: cardResult ? "block" : "none" }}>
                                {t("bankerCard")}
                            </div>
                            {cardResult && <BankerCard tableDetail={tableDetail} dealerSignal={dealerSignal} cardResult={cardResult[0]} />}
                        </div>
                    </div>
                    <div className="bet-panel-btn-wrapper">
                        <div className="chips-set-wrapper">
                            {chipsArr && (
                                <HChips
                                    setLabelBet={setLabelBet}
                                    connection={connection}
                                    tableInfo={tableInfo}
                                    ChipsSet={chipsArr}
                                    tableDetail={tableDetail}
                                    dealerSignal={dealerSignal}
                                    totalBet={totalBet}
                                    setTotalBet={setTotalBet}
                                    gameType={gameType}
                                    setGameType={setGameType}
                                    showComm={false}
                                    roadMaps={roadMaps}
                                />
                            )}
                        </div>
                    </div>

                    <div className="road-maps-statistic-container">
                        <div className="statistic-count">
                            <div className="lbl lbl-title">{`${t("last")} ${roadMaps?.data2?.total} ${t("result")}`}</div>

                            <div className="lbl">
                                <div className="lbl-dot">
                                    <Dot FillColor="#3788ff" text="P1" LineBottom={false} LineRight={false} />
                                </div>
                                <div className="content">{roadMaps?.data2?.player_1}</div>
                            </div>

                            <div className="lbl">
                                <div className="lbl-dot">
                                    <Dot FillColor="#3788ff" text="P2" LineBottom={false} LineRight={false} />
                                </div>
                                <div className="content">{roadMaps?.data2?.player_2}</div>
                            </div>

                            <div className="lbl">
                                <div className="lbl-dot">
                                    <Dot FillColor="#3788ff" text="P3" LineBottom={false} LineRight={false} />
                                </div>
                                <div className="content">{roadMaps?.data2?.player_3}</div>
                            </div>
                        </div>
                    </div>

                    <div className={`road-maps-container`}>
                        <div className="road-1">
                            <TpRoadMap Data={roadMaps?.data?.route_1} />
                        </div>
                    </div>
                </div>

                <div className="footer-container total-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-total-bet">{t(labelBet) + ": " + userData?.currency + " " + formatNumber(totalBet)}</span>
                        <span className="lbl lbl-balance">{t("balance") + ": " + userData?.currency + " " + formatNumber(balance)}</span>
                    </div>
                </div>

                <div className="footer-container max-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-max-bet">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</span>
                        <div className="id-wrapper">
                            <span className="lbl lbl-id">ID: {userData?.playerID ? userData.playerID : "-"}</span>
                            <span className="lbl lbl-id">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</span>
                        </div>
                    </div>
                </div>

                <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />

                {dealerSignal?.action === "stop-bet" && cardResult && reviewCard && (
                    <ReviewCard tableDetail={tableDetail} result={cardResult} setReviewCard={setReviewCard} />
                )}
                {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
            </div>
        </div>
    );
}

export default HThreePicture;
