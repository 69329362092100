import React, { useContext } from "react";

import "./actionmessage.scss";

import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";

function ActionMessage({ text, color = "#000000", totalWin = 0 }) {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  function getBackground() {
    switch (text) {
      case "playerWin":
        return "#286abb";
      case "bankerWin":
        return "#b92a1f";
      case "tie":
        return "#44992a";
      case "freeSpin":
        return "#44992a";
      default:
        return "rgb(30, 30, 30)";
    }
  }

  return (
    <div className="action-message" style={{ background: getBackground() }}>
      <div className="action-message-title" style={{ color: "#FFFFFF" }}>
        {text?.toLowerCase() !== "onlywinlossmsg" ? (
          <div style={{ color: "#FFFFFF" }}>{t(text)}</div>
        ) : null}

        {(text === "playerWin" ||
          text === "bankerWin" ||
          text === "tie" ||
          text === "onlywinlossmsg") && (
          <div className="win-loss-message">{`${t("youWin")} : ${
            userData?.currency
          } ${totalWin}`}</div>
        )}
      </div>
    </div>
  );
}

export default ActionMessage;
