import React, { useContext } from "react";

import "./BetPanel.scss";
import { useTranslation } from "react-i18next";
import { useBetPanel } from "../../../../hook/useBetPanel";
import { Progress, message } from "antd";
import { formatNumber } from "../../../../utils/Formatter";
import { AuthContext } from "../../../../context/AuthContext";
import { useChips } from "../../../../hook/useChips";
import { useFourSeasonBetPanel } from "../../hook/useFourSeasonBetPanel";
import Card from "../../../../components-game/card/Card";
import FourSeasonCard from "../four-seaons-card/FourSeasonCard";
function BetPanel({
  tableInfo,
  connection,
  chipArr,
  rate,
  tableDetail,
  dealerSignal,
  setTotalBet,
  betStatistic,
  setLabelBet,
  cardResult,
}) {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  const { handleDisplayRate, handlePlaceBet } = useBetPanel();
  const {
    handlePlaceBetFourSeason,
    handlePlaceChipFourSeasonFixed,
    handleUpdateBetStatistic,
  } = useFourSeasonBetPanel();

  async function handleBet(key, e) {
    if (dealerSignal?.action === "new-game") {
      try {
        const callApi = handlePlaceBet(
          key,
          tableDetail,
          dealerSignal,
          undefined
        );
        const result = await callApi;
        setTotalBet(result?.totalBet);
        handlePlaceChipFourSeasonFixed(
          chipArr,
          key,
          { image: result?.image, value: result?.curBetCode },
          result?.uniqueID,
          result?.roundUniqueId
        );
        handleUpdateBetStatistic(
          connection,
          tableInfo,
          "AddBetStatistic",
          key,
          result
        );
        setLabelBet("totalBet");
      } catch (error) {
        message.info({ content: error, key: error });
      }
    }
  }

  return (
    <div className="bet-panel-four-seasons">
      <div className="banker-player-jackpot-container">
        <div
          className="banker-jackpot-container"
          data-id="BS1J"
          onClick={() => handleBet("BS1J")}
        >
          <div>
            {t("banker")} {t("jackpot")}
          </div>
        </div>

        <div
          className="player-jackpot-container"
          data-id="PS1J"
          onClick={() => handleBet("PS1J")}
        >
          <div>
            {t("player")} 1 {t("jackpot")}
          </div>
        </div>
        <div
          className="player-jackpot-container"
          data-id="PS2J"
          onClick={() => handleBet("PS2J")}
        >
          <div>
            {t("player")} 2 {t("jackpot")}
          </div>
        </div>
        <div
          className="player-jackpot-container"
          data-id="PS3J"
          onClick={() => handleBet("PS3J")}
        >
          <div>
            {t("player")} 3 {t("jackpot")}
          </div>
        </div>
      </div>

      <div className="bet-items-container">
        <div className="bet-items">
          <div className="progress">
            <Progress
              type="circle"
              trailColor="#595959"
              percent={formatNumber(
                ((betStatistic?.p1 + betStatistic?.b1) / betStatistic?.total) *
                  100,
                0
              )}
              size={35}
              strokeColor="#4e95ed"
              strokeWidth={6}
              status="normal"
            />
            <div className="seat-amount">
              <div className="seat">{t("bankerVSPlayer")} 1</div>
              <div className="amount">
                {userData?.currency +
                  " " +
                  (betStatistic?.p1 + betStatistic?.b1)}
              </div>
            </div>

            <div className="card-result">
              {cardResult &&
                cardResult[1]?.map(
                  (item, index) =>
                    index === 0 &&
                    item.card?.map((items, index) => (
                      <div
                        className={`card ${
                          item.isJackpot === 0 ? false : true
                        }`}
                        key={index}
                      >
                        <FourSeasonCard
                          isReveal={
                            dealerSignal?.action === "show-card" ||
                            dealerSignal?.action === "light-code"
                          }
                          card={tableDetail?.cardUrl + items.value}
                          cardResult={item}
                        />
                      </div>
                    ))
                )}
            </div>
          </div>

          <div className="auto-container banker">
            <div
              className="auto-title"
              onClick={() => handleBet("BS1F-BS1S")}
              data-id="BS1F-BS1S"
            >
              <div>Auto Split</div> <div>{t("banker")}</div>
            </div>
            <div className="btn-bet-container">
              <div className="btn-bet" data-id="BS1F">
                1 : {handleDisplayRate(rate, "BS1F")}
              </div>
              <div className="btn-bet" data-id="BS1S">
                1 : {handleDisplayRate(rate, "BS1S")}
              </div>
            </div>
          </div>

          <div className="auto-container player">
            <div
              className="auto-title"
              onClick={() => handleBet("PS1F-PS1S")}
              data-id="PS1F-PS1S"
            >
              <div>Auto Split</div> {t("player")} 1
            </div>
            <div className="btn-bet-container">
              <div className="btn-bet" data-id="PS1F">
                1 : {handleDisplayRate(rate, "PS1F")}
              </div>
              <div className="btn-bet" data-id="PS1S">
                1 : {handleDisplayRate(rate, "PS1S")}
              </div>
            </div>
          </div>
        </div>

        <div className="bet-items">
          <div className="progress">
            <Progress
              type="circle"
              trailColor="#595959"
              percent={formatNumber(
                ((betStatistic?.p2 + betStatistic?.b2) / betStatistic?.total) *
                  100,
                0
              )}
              size={35}
              strokeColor="#4e95ed"
              strokeWidth={6}
              status="normal"
            />
            <div className="seat-amount">
              <div className="seat">{t("bankerVSPlayer")} 2</div>
              <div className="amount">
                {userData?.currency +
                  " " +
                  (betStatistic?.p2 + betStatistic?.b2)}
              </div>
            </div>

            <div className="card-result">
              {cardResult &&
                cardResult[1]?.map(
                  (item, index) =>
                    index === 1 &&
                    item.card?.map((items, index) => (
                      <div
                        className={`card ${
                          item.isJackpot === 0 ? false : true
                        }`}
                        key={index}
                      >
                        <FourSeasonCard
                          isReveal={
                            dealerSignal?.action === "show-card" ||
                            dealerSignal?.action === "light-code"
                          }
                          card={tableDetail?.cardUrl + items.value}
                          cardResult={item}
                        />
                      </div>
                    ))
                )}
            </div>
          </div>

          <div className="auto-container banker">
            <div
              className="auto-title"
              onClick={() => handleBet("BS2F-BS2S")}
              data-id="BS2F-BS2S"
            >
              <div>Auto Split</div> {t("banker")}
            </div>
            <div className="btn-bet-container">
              <div className="btn-bet" data-id="BS2F">
                1 : {handleDisplayRate(rate, "BS2F")}
              </div>
              <div className="btn-bet" data-id="BS2S">
                1 : {handleDisplayRate(rate, "BS2S")}
              </div>
            </div>
          </div>

          <div className="auto-container player">
            <div
              className="auto-title"
              onClick={() => handleBet("PS2F-PS2S")}
              data-id="PS2F-PS2S"
            >
              <div>Auto Split</div> {t("player")} 2
            </div>
            <div className="btn-bet-container">
              <div className="btn-bet" data-id="PS2F">
                1 : {handleDisplayRate(rate, "PS2F")}
              </div>
              <div className="btn-bet" data-id="PS2S">
                1 : {handleDisplayRate(rate, "PS2S")}
              </div>
            </div>
          </div>
        </div>

        <div className="bet-items">
          <div className="progress">
            <Progress
              type="circle"
              trailColor="#595959"
              percent={formatNumber(
                ((betStatistic?.p3 + betStatistic?.b3) / betStatistic?.total) *
                  100,
                0
              )}
              size={35}
              strokeColor="#4e95ed"
              strokeWidth={6}
              status="normal"
            />
            <div className="seat-amount">
              <div className="seat">{t("bankerVSPlayer")} 3</div>
              <div className="amount">
                {userData?.currency +
                  " " +
                  (betStatistic?.p3 + betStatistic?.b3)}
              </div>
            </div>

            <div className="card-result">
              {cardResult &&
                cardResult[1]?.map(
                  (item, index) =>
                    index === 2 &&
                    item.card?.map((items, index) => (
                      <div
                        className={`card ${
                          item.isJackpot === 0 ? false : true
                        }`}
                        key={index}
                      >
                        <FourSeasonCard
                          isReveal={
                            dealerSignal?.action === "show-card" ||
                            dealerSignal?.action === "light-code"
                          }
                          card={tableDetail?.cardUrl + items.value}
                          cardResult={item}
                        />
                      </div>
                    ))
                )}
            </div>
          </div>

          <div className="auto-container banker">
            <div
              className="auto-title"
              onClick={() => handleBet("BS3F-BS3S")}
              data-id="BS3F-BS3S"
            >
              <div>Auto Split</div> {t("banker")}
            </div>
            <div className="btn-bet-container">
              <div className="btn-bet" data-id="BS3F">
                1 : {handleDisplayRate(rate, "BS3F")}
              </div>
              <div className="btn-bet" data-id="BS3S">
                1 : {handleDisplayRate(rate, "BS3S")}
              </div>
            </div>
          </div>
          <div className="auto-container player">
            <div
              className="auto-title"
              onClick={() => handleBet("PS3F-PS3S")}
              data-id="PS3F-PS3S"
            >
              <div>Auto Split</div> {t("player")} 3
            </div>
            <div className="btn-bet-container">
              <div className="btn-bet" data-id="PS3F">
                1 : {handleDisplayRate(rate, "PS3F")}
              </div>
              <div className="btn-bet" data-id="PS3S">
                1 : {handleDisplayRate(rate, "PS3S")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BetPanel;
