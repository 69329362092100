import React from "react";
import { Image, Result } from "antd";
import "./comingsoon.scss";
import logo from "../../assets/logo/logo-new.png";

function ComingSoon() {
    return (
        <div className="coming-soon-bg">
            <Result icon={<Image src={logo} width={300} preview={false} />} title="Coming Soon" subTitle="New Game In Development" />
        </div>
    );
}

export default ComingSoon;
