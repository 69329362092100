import { Descriptions, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

function ReturnToPlayer() {
    const { t } = useTranslation();

    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("return1")}</Paragraph>
                <Paragraph>{t("return2")}</Paragraph>
                <Paragraph>
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="Side Bet">{t("return3")}</Descriptions.Item>
                        <Descriptions.Item label="P Pair">93.44%</Descriptions.Item>
                        <Descriptions.Item label="B Pair">93.44%</Descriptions.Item>
                    </Descriptions>
                </Paragraph>
                <Paragraph>{t("return4")}</Paragraph>
            </Space>
        </Typography>
    );
}

export default ReturnToPlayer;
