import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

function Shuffling() {
    const { t } = useTranslation();
    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("shuffling1")}</Paragraph>
                <Paragraph>{t("shuffling2")}</Paragraph>
            </Space>
        </Typography>
    );
}

export default Shuffling;
