import React, { useEffect, useState } from "react";
import { handleGetGameHistoryDetail } from "../../../../../utils/SignalR";
import { Skeleton } from "antd";

import Baccarat from "./baccarat/Baccarat";
import ThreePicture from "./three-picture/ThreePicture";
import FourSeasons from "./four-seasons/FourSeasons";
import Roulette from "./roulette/Roulette";
import Sicbo from "./sicbo/Sicbo";

function Description({ connection, record, setIsLoading }) {
    const [component, setComponent] = useState();

    useEffect(() => {
        getDetail();
    }, []);

    function getDetail() {
        handleUI("loading", undefined);
        setIsLoading(true);
        connection.off("game-history-detail");

        handleGetGameHistoryDetail(connection, record);
        connection.on("game-history-detail", (result) => {
            handleUI(record?.gameName, result);
            setIsLoading(false);
        });
    }

    function handleUI(key, result) {
        switch (key?.toLowerCase()) {
            case "baccarat":
                setComponent(<Baccarat record={record} data={result} />);
                break;
            case "three picture":
                setComponent(<ThreePicture record={record} data={result} />);
                break;
            case "four seasons":
                setComponent(<FourSeasons record={record} data={result} />);
                break;
            case "roulette":
                setComponent(<Roulette record={record} data={result} />);
                break;
            case "sicbo":
                setComponent(<Sicbo record={record} data={result} />);
                break;
            default:
                setComponent(<Skeleton />);
                break;
        }
    }

    return <div>{component}</div>;
}

export default Description;
