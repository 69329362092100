import React from "react";
import { useTranslation } from "react-i18next";

import { useBetPanel } from "../../../../../hook/useBetPanel";
import { useChips } from "../../../../../hook/useChips";

import "./VBetPanel.scss";
import Dice from "../../Dice";

function VBetPanel({ tableInfo, connection, chipArr, rate, tableDetail, dealerSignal, setTotalBet, setLabelBet, betContainerClass }) {
  const { t } = useTranslation();

  const { handleDisplayRate, handlePlaceBet, handleUpdateBetStatistic } = useBetPanel();
  const { handlePlaceChipFixed } = useChips();

  async function handleBet(key) {
    if (tableDetail && dealerSignal && dealerSignal?.action === "new-game" && betContainerClass) {
      try {
        const result = await handlePlaceBet(key, tableDetail, dealerSignal);
        setTotalBet(result?.totalBet);
        handlePlaceChipFixed(chipArr, key, { image: result?.image, value: result?.curBetCode }, result?.uniqueID, result?.roundUniqueId);
        handleUpdateBetStatistic(connection, tableInfo, "AddBetStatistic", key, result);
        setLabelBet("totalBet");
      } catch (error) {
        message.info({ content: error, key: error });
      }
    }
  }

  return (
    <div className="sicbo-v-bet-panel">
      <div className="bet-items-01">
        <div className="bet-small-odd-wrapper">
          <div className="lbl-title">1:{handleDisplayRate(rate, "SS")}</div>

          <div className="bet-small-odd">
            <div className="btn-bet-item bet-small" data-id="SS" onClick={() => handleBet("SS")}>
              {t("small")}
            </div>
            <div className="btn-bet-item bet-odd" data-id="SO" onClick={() => handleBet("SO")}>
              {t("odd")}
            </div>
          </div>
        </div>

        <div className="bet-any-triple-wrapper">
          <div className="lbl-title">1:{handleDisplayRate(rate, "SSSA")}</div>
          <div className="btn-bet-item bet-any-triple" data-id="SSSA" onClick={() => handleBet("SSSA")}>
            {t("anyTriple")}
          </div>
        </div>

        <div className="bet-big-even-wrapper">
          <div className="lbl-title">1:{handleDisplayRate(rate, "SB")}</div>

          <div className="bet-big-even">
            <div className="btn-bet-item bet-even" data-id="SE" onClick={() => handleBet("SE")}>
              {t("even")}
            </div>
            <div className="btn-bet-item bet-big" data-id="SB" onClick={() => handleBet("SB")}>
              {t("big")}
            </div>
          </div>
        </div>
      </div>

      <div className="bet-items-02 bet-double-wrapper">
        <div className="lbl-title">{`1:${handleDisplayRate(rate, "SS1")} ${t("eachDouble")}`}</div>

        <div className="bet-double">
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <div key={index} className="btn-bet-item btn-double" data-id={`SS${item}`} onClick={() => handleBet(`SS${item}`)}>
              <div className="dice-wrapper">
                <Dice point={item} />
              </div>
              <div className="dice-wrapper">
                <Dice point={item} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bet-items-03 bet-triple-wrapper">
        <div className="lbl-title">{`1:${handleDisplayRate(rate, "SSS1")} ${t("eachTriple")}`}</div>

        <div className="bet-triple">
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <div key={index} className="btn-bet-item btn-triple" data-id={`SSS${item}`} onClick={() => handleBet(`SSS${item}`)}>
              <div className="dice-wrapper">
                <Dice point={item} />
              </div>
              <div className="dice-wrapper">
                <Dice point={item} />
              </div>
              <div className="dice-wrapper">
                <Dice point={item} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bet-items-04 bet-point-wrapper">
        {[4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((item, index) => (
          <div key={index} className="btn-bet-item btn-point" data-id={`S${item}`} onClick={() => handleBet(`S${item}`)}>
            {item}
            <div className="lbl-rate">1:{handleDisplayRate(rate, `S${item}`)}</div>
          </div>
        ))}
      </div>

      <div className="bet-items-05 bet-two-dice-wrapper">
        <div className="lbl-title">{`${t("twoDice")} 1:${handleDisplayRate(rate, "S1S2")}`}</div>

        <div className="bet-two-dice">
          {["1,2", "1,3", "1,4", "1,5", "1,6", "2,3", "2,4", "2,5", "2,6", "3,4", "3,5", "3,6", "4,5", "4,6", "5,6"].map((item, index) => {
            const dice = item.split(",");
            return (
              <div key={index} className="btn-bet-item btn-two-dice" data-id={`S${Number(dice[0])}S${Number(dice[1])}`} onClick={() => handleBet(`S${Number(dice[0])}S${Number(dice[1])}`)}>
                <div className="dice-wrapper">
                  <Dice point={Number(dice[0])} />
                </div>
                <div className="dice-wrapper">
                  <Dice point={Number(dice[1])} />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="bet-items-06 bet-dice-wrapper">
        <div className="lbl-title-wrapper">
          <div className="lbl-title">{`1:${handleDisplayRate(rate, "S1E1")} ${t("onOneDice")}`}</div>
          <div className="lbl-title">{`1:${handleDisplayRate(rate, "S1E2")} ${t("onTwoDice")}`}</div>
          <div className="lbl-title">{`1:${handleDisplayRate(rate, "S1E3")} ${t("onThreeDice")}`}</div>
        </div>

        <div className="bet-dice">
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <div key={index} className="btn-bet-item btn-dice" data-id={`S${item}E1`} onClick={() => handleBet(`S${item}E1`)}>
              {item}
              <div className="dice-wrapper">
                <Dice point={item} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VBetPanel;
