import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../../../../context/AuthContext";
import { formatSpaceAndToLower } from "../../../../utils/Formatter";

import "./Style1.scss";
import { Card, Col, Row, Skeleton, Space, Typography } from "antd";
import { StarFilled, StarOutlined, UserOutlined } from "@ant-design/icons";

import RoadMap from "../../../../page-game/baccarat/component/road-map/RoadMap";
import TpRoadMap from "../../../../page-game/three-picture/component/road-map/TpRoadMap";
import RoadMapFourSeasons from "../../../../page-game/four-season/component/road-map/RoadMap";
import SbRoadMap from "../../../../page-game/sicbo/component/road-map/SbRoadMap";
import RtRoadMap from "../../../../page-game/roulette/component/road-map/RoadMap";

import { HourglassOutlined } from "@ant-design/icons";

function TableList1({ isFavorite, setIsFavorite, activeUser, tableListRef, connection, isCatLoading, setIsCatLoading, tableList }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { category } = useParams();
    const { setIsMuted, userData } = useContext(AuthContext);
    const [subCat, setSubCat] = useState("allGame");
    // const [tableList, setTableList] = useState([]);
    const currentRef = useRef();

    // if (tableListRef.current !== tableList) {
    //   console.log("1", tableListRef);
    //   setTableList(tableListRef.current);
    // }

    // useEffect(() => {
    //   console.log(tableListRef);
    //   setTableList(tableListRef.current);
    // }, [tableListRef]);

    async function handleOpenTableWithSound(table) {
        connection.stop();
        setIsMuted(false);
        navigate(`/game-table/${formatSpaceAndToLower(table?.gameType)}_${table?.redirectUrl}`);
    }

    function groupBy(arr, property) {
        return arr?.reduce((memo, x) => {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    function renderTableList() {
        function getTableRoad(table) {
            // console.log(table?.roadmap);
            switch (formatSpaceAndToLower(table?.gameType)) {
                case "baccarat":
                    return (
                        <div className="road-maps-container">
                            <div className="road-1">
                                <RoadMap RoadType="01" Data={table?.roadmap?.route_1} />
                            </div>

                            <div className="roads-croc-small">
                                <div className="roads-croc road-2">
                                    <RoadMap RoadType="02" Data={table?.roadmap?.route_2} />
                                </div>

                                <div className="roads-small">
                                    <div className="roads-small-item road-3">
                                        <RoadMap RoadType="03" Data={table?.roadmap?.route_3} />
                                    </div>

                                    <div className="roads-small-item road-4">
                                        <RoadMap RoadType="04" Data={table?.roadmap?.route_4} />
                                    </div>

                                    <div className="roads-small-item road-5">
                                        <RoadMap RoadType="05" Data={table?.roadmap?.route_5} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                case "three-picture":
                    return <TpRoadMap Data={table?.roadmap?.route_1?.slice(0, 15)} ShowText={false} />;
                case "four-seasons":
                    return (
                        <div className="four-seasons-road-map">
                            <RoadMapFourSeasons Data={table?.roadmap?.route_1?.slice(0, 11)} ShowText={false} />
                        </div>
                    );
                case "sicbo":
                    if (table?.roadmap?.route_2?.length > 0) {
                        return <SbRoadMap Type="C" Data={table?.roadmap?.route_2} />;
                    }
                    break;
                case "roulette":
                    return <RtRoadMap Data={table?.roadmap?.route_1?.slice(0, 15)} />;
                default:
                    break;
            }
        }

        function getTableRoadStatistic(table) {
            // console.log(table?.roadmap);
            switch (formatSpaceAndToLower(table?.gameType)) {
                case "baccarat":
                    return (
                        <div className="road-map-statistic-container">
                            <div className="statistic-count">
                                {table?.roadmap?.cumulative ? (
                                    <>
                                        <div className="lbl">
                                            <span className="total">T</span>
                                            {table?.roadmap?.cumulative?.total}
                                        </div>
                                        <div className="lbl">
                                            <span className="banker">B</span>
                                            {table?.roadmap?.cumulative?.banker}
                                        </div>
                                        <div className="lbl">
                                            <span className="player">P</span>
                                            {table?.roadmap?.cumulative?.player}
                                        </div>
                                        <div className="lbl">
                                            <span className="tie">T</span>
                                            {table?.roadmap?.cumulative?.tie}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    );
                case "three-picture":
                    return (
                        <div className="road-map-statistic-container">
                            <div className="statistic-count">
                                {table?.roadmap?.cumulative ? (
                                    <>
                                        <div className="lbl">
                                            <span className="total">T</span>
                                            {table?.roadmap?.cumulative?.total}
                                        </div>
                                        {/* <div className="lbl">
                      <span className="banker">P1</span>
                      {table?.roadmap?.cumulative?.p1}
                    </div>
                    <div className="lbl">
                      <span className="player">P2</span>
                      {table?.roadmap?.cumulative?.p2}
                    </div>
                    <div className="lbl">
                      <span className="tie">P3</span>
                      {table?.roadmap?.cumulative?.p3}
                    </div> */}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    );
                case "four-seasons":
                    return (
                        <div className="road-map-statistic-container">
                            <div className="statistic-count">
                                {table?.roadmap?.cumulative ? (
                                    <>
                                        <div className="lbl">
                                            <span className="total">T</span>
                                            {table?.roadmap?.cumulative?.total}
                                        </div>
                                        {/* <div className="lbl">
                      <span className="banker">P1</span>
                      {table?.roadmap?.cumulative?.p1}
                    </div>
                    <div className="lbl">
                      <span className="player">P2</span>
                      {table?.roadmap?.cumulative?.p2}
                    </div>
                    <div className="lbl">
                      <span className="tie">P3</span>
                      {table?.roadmap?.cumulative?.p3}
                    </div> */}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    );
                case "roulette":
                    return (
                        <div className="road-map-statistic-container">
                            <div className="statistic-count"></div>
                        </div>
                    );
                case "sicbo":
                    return (
                        <div className="road-map-statistic-container">
                            <div className="statistic-count"></div>
                        </div>
                    );
                case "dream-catcher":
                    return (
                        <div className="road-map-statistic-container">
                            <div className="statistic-count"></div>
                        </div>
                    );
                default:
                    break;
            }
        }

        function handleAddRemoveFavourite(table, status) {
            setIsCatLoading(true);
            connection.invoke("UpdateTableFavorite", {
                playerID: localStorage.getItem("playerID"),
                playerToken: localStorage.getItem("playerToken"),
                tableDetail: table,
                status: status,
            });
            setIsCatLoading(false);
        }

        if (tableList?.length > 0) {
            let newTableList = groupBy(tableList, "type");

            return (
                <div className="table-list-data-container">
                    {Object.keys(newTableList).map((title, index) => (
                        <div key={index}>
                            <div key={index} id={title} className="table-list-data-title">
                                {t(title)}
                            </div>

                            <div className="table-table-wrapper">
                                {newTableList[title].map((table, index) =>
                                    title === "ROULETTE" ? (
                                        <div className="roulette-table" key={index}>
                                            <div className="table-table-header" onClick={() => handleOpenTableWithSound(table)}>
                                                <div className="table-table-currency">
                                                    <div className="table-timer">
                                                        <div
                                                            data-id={`timer-${table?.tableCode}`}
                                                            className="timer-container"
                                                            style={{
                                                                color: "#c2b557",
                                                                fontSize: "0.8rem",
                                                                borderRadius: "50%",
                                                                width: "25px",
                                                                height: "25px",
                                                                textAlign: "center",
                                                                border: "1px solid #ffffff",
                                                                background: "#000000",
                                                                padding: "5px",
                                                            }}
                                                        >
                                                            <div className="hour-glass">
                                                                <HourglassOutlined />
                                                            </div>
                                                        </div>
                                                        <div className="action-container" data-id={`action-${table?.tableCode}`}>
                                                            {t("stopBet")}
                                                        </div>
                                                    </div>
                                                    <div className="table-name">{table?.tableCode}</div>
                                                    <div className="table-index">{table?.tableName}</div>
                                                    <div>
                                                        <div>
                                                            <UserOutlined />{" "}
                                                            {activeUser?.map((items) =>
                                                                items?.includes(table?.tableCode) ? Number(items.split(":")[1]) : 0
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-table-image" onClick={() => handleOpenTableWithSound(table)}>
                                                <img alt="" src={table?.imgUrl} />
                                                <div className="table-table-roadmap">{getTableRoad(table)}</div>
                                            </div>

                                            <div className="table-table-footer">
                                                <div className="table-table-favorite">
                                                    <div className="currency">
                                                        {userData?.currency + " " + table?.minBet + " - " + " " + table?.maxBet}
                                                    </div>
                                                    {table?.isFavorite ? (
                                                        <StarFilled
                                                            style={{ color: "goldenrod" }}
                                                            onClick={() => handleAddRemoveFavourite(table, 0)}
                                                        />
                                                    ) : (
                                                        <StarOutlined onClick={() => handleAddRemoveFavourite(table, 1)} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="table-table" key={index}>
                                            <div className="table-table-header" onClick={() => handleOpenTableWithSound(table)}>
                                                <div className="table-table-currency">
                                                    <div className="table-timer">
                                                        <div
                                                            data-id={`timer-${table?.tableCode}`}
                                                            className="timer-container"
                                                            style={{
                                                                color: "#c2b557",
                                                                fontSize: "0.8rem",
                                                                borderRadius: "50%",
                                                                width: "25px",
                                                                height: "25px",
                                                                textAlign: "center",
                                                                border: "1px solid #ffffff",
                                                                background: "#000000",
                                                                padding: "5px",
                                                            }}
                                                        >
                                                            <div className="hour-glass">
                                                                <HourglassOutlined />
                                                            </div>
                                                        </div>
                                                        <div className="action-container" data-id={`action-${table?.tableCode}`}>
                                                            {t("stopBet")}
                                                        </div>
                                                    </div>

                                                    <div className="table-name">{table?.tableCode}</div>
                                                    <div className="table-index">{table?.tableName}</div>
                                                    <div>
                                                        <div>
                                                            <UserOutlined />{" "}
                                                            {activeUser?.map((items) =>
                                                                items?.includes(table?.tableCode) ? Number(items.split(":")[1]) : 0
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-table-roadmap" onClick={() => handleOpenTableWithSound(table)}>
                                                {getTableRoad(table)}
                                                <div className="table-table-image">
                                                    <img alt="" src={table?.imgUrl} />
                                                </div>
                                            </div>

                                            <div className="table-table-footer">
                                                {getTableRoadStatistic(table)}

                                                <div className="table-table-favorite">
                                                    <div className="currency">
                                                        {userData?.currency + " " + table?.minBet + " - " + " " + table?.maxBet}
                                                    </div>
                                                    {table?.isFavorite ? (
                                                        <StarFilled
                                                            style={{ color: "goldenrod" }}
                                                            onClick={() => handleAddRemoveFavourite(table, 0)}
                                                        />
                                                    ) : (
                                                        <StarOutlined onClick={() => handleAddRemoveFavourite(table, 1)} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="coming-soon">
                    <img alt="" src={`https://galaxy-game.sgp1.digitaloceanspaces.com/game-card/coming-soon/${category}.png`} />
                </div>
            );
        }
    }

    function handleSelectFavourite() {
        setIsFavorite(!isFavorite);
    }

    function handleSubMenuClick(cat) {
        const element = document.getElementById(cat);
        element.scrollIntoView();
    }

    return (
        <div className="table-list-cont">
            <Skeleton loading={isCatLoading} active>
                <Row justify="space-between" align="middle" gutter={[0, 10]}>
                    <Col xs={21}>
                        {/* <Row justify="start" align="middle" gutter={[10, 10]}>
                            {Object?.keys(groupBy(tableList ?? [], "type"))?.map((item, index) => (
                                <Col key={index} className={`${subCat === item ? "active" : ""}`}>
                                    <Card hoverable onClick={() => handleSubMenuClick(item)}>
                                        {t(item)}
                                    </Card>
                                </Col>
                            ))}
                        </Row> */}
                    </Col>
                    <Col className={`${isFavorite ? "active" : ""}`}>
                        <Card hoverable onClick={() => handleSelectFavourite()}>
                            {isFavorite ? <StarFilled /> : <StarOutlined />}
                        </Card>
                    </Col>
                </Row>
            </Skeleton>

            <Skeleton loading={isCatLoading} active>
                {renderTableList()}
            </Skeleton>
        </div>
    );
}

export default TableList1;
