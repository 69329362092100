import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { formatNumber } from "../../../utils/Formatter";
import { useSicbo } from "../hook/useSicbo";

import "./HSicbo.scss";
import { Spin } from "antd";

import CFBtnView from "../../../components-game/navigator/CFBtnView";
import HChips from "../../../components-game/chips-set/new-horizontal/HChips";
import Timer from "../../../components-game/timer/Timer";
import HBetPanel from "../component/bet-panel/horizontal/HBetPanel";
import WinList from "../../../components-game/win-list/WinList";
import ActionMessage from "../component/action-message/ActionMessage";

import TableList from "../../../components-game/table-list/TableList";

import LoadingPage from "../../../pages/loading-page/LoadingPage";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import SbRoadMap from "../component/road-map/SbRoadMap";
import Icon from "../../../components-game/navigator/icon/Icon";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";

function HSicbo() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        winList,
        labelBet,
        setLabelBet,
        actionMsg,
        betContainerClass,
        setBetContainerClass,
        gameType,
        setGameType,
        chatMsg,
    } = useSicbo();
    const [openTableList, setOpenTableList] = useState(false);

    if (isFirstLoad) {
        return <LoadingPage />;
    }

    function handleChangeBetPanelSkew() {
        if ((dealerSignal?.action === "new-game" && !betContainerClass) || dealerSignal?.action !== "new-game") {
            setBetContainerClass((prev) => !prev);
        }
    }

    function handleChangeContainerBetPanelSkew(e) {
        if (dealerSignal?.action === "new-game" && e.target.className === "horizontal-sicbo-container") {
            setBetContainerClass((prev) => (!betContainerClass ? prev : !prev));
        }
    }

    return (
        <div id="horizontal-sicbo">
            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                </div>
            </Spin>

            <div className="horizontal-sicbo-container" onClick={(e) => handleChangeContainerBetPanelSkew(e)}>
                <div className="header-container">
                    <div className="header-title">
                        <div className="header-btn" onClick={() => navigate(-1)}>
                            <Icon image={back} />
                        </div>
                        {tableDetail?.tableName}
                    </div>
                    <div className="header-menu">
                        {streamingObj && (
                            <CFBtnView
                                BtnTable={true}
                                BtnGraphic={true}
                                connection={connection}
                                GraphicObj={streamingObj}
                                setOpenTableList={setOpenTableList}
                                tableDetail={tableDetail}
                                chatMsg={chatMsg}
                            />
                        )}
                    </div>
                </div>

                <div className={`action-msg-container ${actionMsg?.text}`}>
                    <ActionMessage
                        text={actionMsg?.text}
                        bgColor={actionMsg?.bgColor}
                        totalWin={totalBet}
                        showWinAmount={actionMsg?.showWinAmount}
                        result={actionMsg?.result}
                    />
                </div>

                {dealerSignal?.second ? (
                    <div className="timer-container">
                        <Timer dealerSignal={dealerSignal} />
                    </div>
                ) : (
                    ""
                )}

                <div className="win-list-wrapper" style={{ display: winList?.length > 0 ? "block" : "none" }}>
                    {winList?.length > 0 && <WinList winList={winList} />}
                </div>

                <div className={`bet-wrapper ${betContainerClass ? "new-game" : "stop-bet"}`}>
                    <div className="road-map-wrapper">
                        <SbRoadMap Type="A" Data={roadMaps?.data?.route_1} />
                    </div>

                    <div className="bet-panel-container" onClick={() => handleChangeBetPanelSkew()}>
                        {betItems && (
                            <HBetPanel
                                tableInfo={tableInfo}
                                connection={connection}
                                chipArr={chipsArr}
                                rate={betItems}
                                tableDetail={tableDetail}
                                dealerSignal={dealerSignal}
                                setTotalBet={setTotalBet}
                                setLabelBet={setLabelBet}
                                betContainerClass={betContainerClass}
                            />
                        )}
                    </div>

                    <div className="chips-wrapper">
                        {chipsArr && (
                            <HChips
                                setLabelBet={setLabelBet}
                                connection={connection}
                                tableInfo={tableInfo}
                                ChipsSet={chipsArr}
                                tableDetail={tableDetail}
                                dealerSignal={dealerSignal}
                                totalBet={totalBet}
                                setTotalBet={setTotalBet}
                                gameType={gameType}
                                setGameType={setGameType}
                                showComm={false}
                                roadMaps={roadMaps}
                            />
                        )}
                    </div>
                </div>

                <div className="footer-container total-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-total-bet">{t(labelBet) + ": " + userData?.currency + " " + formatNumber(totalBet)}</span>
                        <span className="lbl lbl-balance">{t("balance") + ": " + userData?.currency + " " + formatNumber(balance)}</span>
                    </div>
                </div>

                <div className="footer-container max-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-max-bet">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</span>
                        <div className="id-wrapper">
                            <span className="lbl lbl-id">ID: {userData?.playerID ? userData.playerID : "-"}</span>
                            <span className="lbl lbl-id">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</span>
                        </div>
                    </div>
                </div>

                <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />
            </div>

            {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default HSicbo;
