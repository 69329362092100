import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph, Text } = Typography;

function GameHistory() {
    const { t } = useTranslation();

    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("gameHistory1")}</Paragraph>
                {/* <Paragraph>{t("gameHistory2")}</Paragraph> */}
                <Paragraph>{t("gameHistory3")}</Paragraph>
                <Paragraph>
                    <ul>
                        <li>
                            <Text>{t("gameHistory4")}</Text>
                        </li>
                        <li>
                            <Text>{t("gameHistory5")}</Text>
                        </li>
                    </ul>
                </Paragraph>
            </Space>
        </Typography>
    );
}

export default GameHistory;
