import React from "react";
import "./four-seasons-card.scss";

function FourSeasonCard({ isReveal = false, card = "", cardResult = "" }) {
  return (
    <div className={`card-container ${isReveal ? "flip" : ""}`}>
      <div className="card-front">
        <img
          src={
            "https://galaxy-game.sgp1.digitaloceanspaces.com/card-set/3/cover.png"
          }
          alt=""
        />
      </div>

      <div className="card-back">
        <img src={card} alt="" />
      </div>
    </div>
  );
}

export default FourSeasonCard;
