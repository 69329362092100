import { Image, Modal } from "antd";
import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
// import tryFullscreen from "../../../assets/other/try-full.png";
import "./Style.scss";
import { useTranslation } from "react-i18next";

function TryFullscreenModal() {
    const { tryFull, setTryFull } = useContext(AuthContext);
    const { i18n } = useTranslation();

    const handleCancel = () => {
        setTryFull(false);
    };

    return (
        <Modal className="try-modal" destroyOnClose={true} open={tryFull} footer={null} onCancel={handleCancel} centered>
            {/* <Image src={tryFullscreen} preview={false} /> */}
            <Image src={`https://galaxy-game.sgp1.digitaloceanspaces.com/banner/confirmBet.png`} preview={false} />
        </Modal>
    );
}

export default TryFullscreenModal;
