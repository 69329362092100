import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { formatNumber } from "../../../utils/Formatter";
import { useBaccarat } from "../hook/useBaccarat";

import "./HBaccarat.scss";
import { Switch, Spin } from "antd";

import CFBtnView from "../../../components-game/navigator/CFBtnView";
import VChips from "../../../components-game/chips-set/vertical/VChips";
import Timer from "../../../components-game/timer/Timer";
import BetPanel from "../component/bet-panel/BetPanel";
import RoadMap from "../component/road-map/RoadMap";
import WinList from "../../../components-game/win-list/WinList";
import ActionMessage from "../../../components-game/action-message/ActionMessage";
import Dot from "../component/road-map/dots/Dot";

import TableList from "../../../components-game/table-list/TableList";

import LoadingPage from "../../../pages/loading-page/LoadingPage";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import Icon from "../../../components-game/navigator/icon/Icon";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";
import GameTypeSelect from "../component/game-type-select/GameTypeSelect";
import HChips from "../../../components-game/chips-set/new-horizontal/HChips";
// import HChips from "../../../components-game/chips-set/horizontal/HChips";

function HBaccarat() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        betContainerClass,
        setBetContainerClass,
        gameType,
        setGameType,
        chatMsg,
        connectionState,
    } = useBaccarat();
    const [openTableList, setOpenTableList] = useState(false);

    if (isFirstLoad) {
        return <LoadingPage />;
    }

    function handleChangeBetPanelSkew() {
        if ((dealerSignal?.action === "new-game" && !betContainerClass) || dealerSignal?.action !== "new-game") {
            setBetContainerClass((prev) => !prev);
        }
    }

    function handleChangeContainerBetPanelSkew(e) {
        if (dealerSignal?.action === "new-game" && e.target.className === "horizontal-baccarat-container") {
            setBetContainerClass((prev) => (!betContainerClass ? prev : !prev));
        }
    }

    return (
        <div id="horizontal-baccarat">
            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                </div>
            </Spin>

            <div className="horizontal-baccarat-container" onClick={(e) => handleChangeContainerBetPanelSkew(e)}>
                <div className="header-container">
                    <div className="header-title">
                        <div className="header-btn" onClick={() => navigate(-1)}>
                            <Icon image={back} />
                        </div>
                        {tableDetail?.tableName}
                    </div>
                    <div className="header-menu">
                        {streamingObj && (
                            <CFBtnView
                                BtnTable={true}
                                BtnGraphic={true}
                                connection={connection}
                                GraphicObj={streamingObj}
                                setOpenTableList={setOpenTableList}
                                tableDetail={tableDetail}
                                chatMsg={chatMsg}
                            />
                        )}
                    </div>
                </div>

                <div className={`action-msg-container ${actionMsg}`}>
                    <ActionMessage text={actionMsg} color="gold" totalWin={totalBet} />
                </div>

                {dealerSignal?.second ? (
                    <div className="timer-container">
                        <Timer dealerSignal={dealerSignal} />
                    </div>
                ) : (
                    ""
                )}

                <div className="win-list-wrapper" style={{ display: winList?.length > 0 ? "block" : "none" }}>
                    {winList?.length > 0 && <WinList winList={winList} />}
                </div>

                <div className={`bet-wrapper ${betContainerClass ? "new-game" : "stop-bet"}`} onClick={() => handleChangeBetPanelSkew()}>
                    <div className="bet-panel-container">
                        {betItems && (
                            <BetPanel
                                tableInfo={tableInfo}
                                connection={connection}
                                chipArr={chipsArr}
                                rate={betItems}
                                tableDetail={tableDetail}
                                dealerSignal={dealerSignal}
                                setTotalBet={setTotalBet}
                                betStatistic={betStatistic}
                                roadMaps={roadMaps}
                                setLabelBet={setLabelBet}
                                cardResult={cardResult}
                                betContainerClass={betContainerClass}
                                gameType={gameType}
                            />
                        )}
                    </div>

                    <div className="bet-panel-btn-wrapper">
                        {/* <div className="comm-btn"></div> */}
                        <div className="chips-set-wrapper">
                            {chipsArr && (
                                <HChips
                                    setLabelBet={setLabelBet}
                                    connection={connection}
                                    tableInfo={tableInfo}
                                    ChipsSet={chipsArr}
                                    tableDetail={tableDetail}
                                    dealerSignal={dealerSignal}
                                    totalBet={totalBet}
                                    setTotalBet={setTotalBet}
                                    gameType={gameType}
                                    setGameType={setGameType}
                                    showComm={true}
                                    roadMaps={roadMaps}
                                />
                            )}
                        </div>
                        {/* <div className="comm-btn">
                            <Switch
                                checkedChildren="No Comm"
                                unCheckedChildren="Comm"
                                checked={gameType !== "COMMISSION"}
                                onChange={handleChangeComm}
                            />
                        </div> */}
                    </div>

                    <div className="road-maps-statistic-container">
                        <div className="statistic-forcast">
                            <div className="statistic-count">
                                <div className="lbl"># {roadMaps?.data2?.total}</div>

                                <div className="lbl">
                                    <div className="lbl-dot">
                                        <Dot FillColor="#3788ff" text="P" LineBottom={false} LineRight={false} />
                                    </div>
                                    <div className="content">{roadMaps?.data2?.player}</div>
                                </div>

                                <div className="lbl">
                                    <div className="lbl-dot">
                                        <Dot FillColor="#ee4043" text="B" LineBottom={false} LineRight={false} />
                                    </div>
                                    <div className="content">{roadMaps?.data2?.banker}</div>
                                </div>

                                <div className="lbl">
                                    <div className="lbl-dot">
                                        <Dot FillColor="#159252" text="T" LineBottom={false} LineRight={false} />
                                    </div>
                                    <div className="content">{roadMaps?.data2?.tie}</div>
                                </div>
                            </div>

                            <div className="forcast-wrapper">
                                <div className="player-forcast">
                                    <div className="lbl-text">P?</div>
                                    {roadMaps?.data?.forcast
                                        ?.split(",")[1]
                                        ?.split("-")
                                        .map((items, index) => (
                                            <div key={index} className="lbl-dot" hidden={items === ""}>
                                                {index === 0 ? (
                                                    <Dot
                                                        FillColor="none"
                                                        StrokeColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                        text=""
                                                        LineBottom={false}
                                                        LineRight={false}
                                                    />
                                                ) : index === 1 ? (
                                                    <Dot
                                                        StrokeColor="none"
                                                        FillColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                        text=""
                                                        LineBottom={false}
                                                        LineRight={false}
                                                    />
                                                ) : (
                                                    <Dot
                                                        FillColor="none"
                                                        StrokeColor="none"
                                                        Tie
                                                        TieColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                        text=""
                                                        LineBottom={false}
                                                        LineRight={false}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </div>

                                <div className="banker-forcast">
                                    <div className="lbl-text">B?</div>
                                    {roadMaps?.data?.forcast
                                        ?.split(",")[0]
                                        ?.split("-")
                                        .map((items, index) => (
                                            <div key={index} className="lbl-dot" hidden={items === ""}>
                                                {index === 0 ? (
                                                    <Dot
                                                        FillColor="none"
                                                        StrokeColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                        text=""
                                                        LineBottom={false}
                                                        LineRight={false}
                                                    />
                                                ) : index === 1 ? (
                                                    <Dot
                                                        StrokeColor="none"
                                                        FillColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                        text=""
                                                        LineBottom={false}
                                                        LineRight={false}
                                                    />
                                                ) : (
                                                    <Dot
                                                        FillColor="none"
                                                        StrokeColor="none"
                                                        Tie
                                                        TieColor={items === "B" ? "#ee4043" : "#3788ff"}
                                                        text=""
                                                        LineBottom={false}
                                                        LineRight={false}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`road-maps-container`}>
                        <div className="road-1">
                            <RoadMap RoadType="01" Data={roadMaps?.data?.route_1} />
                        </div>
                        <div className="roads-croc-small">
                            <div className="roads-croc road-2">
                                <RoadMap RoadType="02" Data={roadMaps?.data?.route_2} />
                            </div>

                            <div className="roads-small">
                                <div className="roads-small-item road-3">
                                    <RoadMap RoadType="03" Data={roadMaps?.data?.route_3} />
                                </div>

                                <div className="roads-small-item road-4">
                                    <RoadMap RoadType="04" Data={roadMaps?.data?.route_4} />
                                </div>

                                <div className="roads-small-item road-5">
                                    <RoadMap RoadType="05" Data={roadMaps?.data?.route_5} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-container total-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-total-bet">{t(labelBet) + ": " + userData?.currency + " " + formatNumber(totalBet)}</span>
                        <span className="lbl lbl-balance">{t("balance") + ": " + userData?.currency + " " + formatNumber(balance)}</span>
                    </div>
                </div>

                <div className="footer-container max-bet">
                    <div className="footer-wrapper">
                        <span className="lbl lbl-max-bet">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</span>
                        <div className="id-wrapper">
                            <span className="lbl lbl-id">ID: {userData?.playerID ? userData.playerID : "-"}</span>
                            <span className={`lbl lbl-id ${connectionState}`}>
                                UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}
                            </span>
                        </div>
                    </div>
                </div>

                {/* <div className="tbl-btn-container">
                    <div className="tbl-btn-wrapper" onClick={() => setOpenTableList(!openTableList)}>
                        <Icon image={table} />
                    </div> */}

                {openTableList && (
                    <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />
                )}
                {/* </div> */}
            </div>

            {!gameType && <GameTypeSelect gameType={gameType} setGameType={setGameType} />}

            {gameType && /iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default HBaccarat;
