import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { formatDateTime, formatNumber, formatString } from "../../../../../../utils/Formatter";

import "./baccarat.scss";
import { Card, Col, Image, Row, Space, Table, Tag, Typography } from "antd";
import { green, gold } from "@ant-design/colors";

function Baccarat({ record, data }) {
    const { t } = useTranslation();

    const columns = [
        {
            title: t("betCode"),
            dataIndex: "betCodeName",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return formatString(text);
            },
        },
        {
            title: t("bet"),
            dataIndex: "bet",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return "MYR " + formatNumber(text);
            },
        },
        {
            title: t("win"),
            dataIndex: "win",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return "MYR " + formatNumber(text);
            },
        },
    ];

    function getWinLose(index) {
        if (index) {
            switch (record?.winCode?.split(",")[1]?.split("-")[1]) {
                case "lose":
                    return <Tag color={gold[4]}>Win</Tag>;
                case "tie":
                    return <Tag color={green[4]}>Tie</Tag>;
                default:
                    break;
            }
        } else {
            switch (record?.winCode?.split(",")[1]?.split("-")[1]) {
                case "win":
                    return <Tag color={gold[4]}>Win</Tag>;
                case "tie":
                    return <Tag color={green[4]}>Tie</Tag>;
                default:
                    break;
            }
        }
    }

    return (
        <Space direction="vertical" style={{ width: "100%" }} className="game-hitsory-detail">
            <Typography.Text>Game Resolved: {formatDateTime(moment(record?.endDate).add(8, "h"))}</Typography.Text>
            <Typography.Text>Game Number: {record?.gameSummarySrno}</Typography.Text>
            {data?.data?.map((item, index) => (
                <Card align="center" key={index}>
                    <Row justify="center" align="middle">
                        <Col xs={6}>
                            <Typography.Text>{item?.seat === "B" ? "Banker" : "Player"}</Typography.Text>
                        </Col>
                        <Col xs={12}>
                            <Space size="large">
                                <Row justify="center" align="middle" gutter={5}>
                                    {item?.card?.map((item, index) => (
                                        <Col key={index}>
                                            <div className={`div-${item?.cName}`}>
                                                <Image
                                                    src={`${process.env.REACT_APP_DIGITALOCEAN_URL}/card-set/3/${item?.value}`}
                                                    preview={false}
                                                    width={30}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Space>
                        </Col>
                        <Col xs={2}>
                            <Typography.Text>{item?.totalPoint % 10}</Typography.Text>
                        </Col>
                        <Col xs={4}>{getWinLose(index)}</Col>
                    </Row>
                </Card>
            ))}
            <Table
                bordered
                columns={columns}
                dataSource={data?.data2}
                rowKey="betCode"
                scroll={{ x: true }}
                pagination={false}
                summary={(pageData) => {
                    let totalBet = 0;
                    let totalWin = 0;
                    pageData.forEach(({ bet, win }) => {
                        totalBet += bet;
                        totalWin += win;
                    });
                    return (
                        <Table.Summary.Row align="center">
                            <Table.Summary.Cell index={0}>TOTAL</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>MYR {formatNumber(totalBet)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>MYR {formatNumber(totalWin)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Space>
    );
}

export default Baccarat;
