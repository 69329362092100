import React, { useRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { HourglassOutlined } from "@ant-design/icons";
import "./timer.scss";

function Timer({
  dealerSignal,
  colors = "gold",
  fontSize = "1.6rem",
  trailColor = "#D3D3D3",
}) {
  const isNewTimeFirstTick = useRef(false);
  const prevTime = useRef(null);
  const currentTime = useRef(dealerSignal?.second);

  const renderTime = ({ remainingTime, color }) => {
    if (remainingTime === 0) {
      return (
        <p
          className="timer-loading"
          style={{
            color: colors,
          }}
        >
          <HourglassOutlined />
        </p>
      );
    }

    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
    } else {
      isNewTimeFirstTick.current = false;
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
      <div className="time-wrapper">
        <div
          key={remainingTime}
          className={`time ${isTimeUp ? "up" : ""}`}
          style={{ color: color, fontSize: fontSize }}
        >
          {remainingTime}
        </div>
        {prevTime.current !== null && (
          <div
            key={prevTime.current}
            className={`time ${!isTimeUp ? "down" : ""}`}
          >
            {prevTime.current}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="timer">
      <CountdownCircleTimer
        isPlaying
        duration={dealerSignal?.second}
        colors={["#52c41a", "#fa8c16", "#f5222d", "#f5222d"]}
        colorsTime={[10, 4, 2, 0]}
        trailColor={trailColor}
        style={{ width: "100%", height: "100%" }}
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  );
}

export default Timer;
