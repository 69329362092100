export const arrBetPanel = [
  {
    title: "ONE",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/1.png",
    color: "#0000ff",
  },
  {
    title: "SEVEN",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/7.png",
    color: "#dd1a22",
  },
  {
    title: "TWO",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/2.png",
    color: "#0000ff",
  },
  {
    title: "EIGHT",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/8.png",
    color: "#dd1a22",
  },
  {
    title: "THREE",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/3.png",
    color: "#0000ff",
  },
  {
    title: "NINE",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/9.png",
    color: "#dd1a22",
  },
];

export const arrBetPanel2 = [
  {
    title: "FOUR",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/4.png",
    color: "#0000ff",
  },
  {
    title: "TEN",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/10.png",
    color: "#dd1a22",
  },
  {
    title: "FIVE",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/5.png",
    color: "#0000ff",
  },
  {
    title: "ELEVEN",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/11.png",
    color: "#dd1a22",
  },
  {
    title: "SIX",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/6.png",
    color: "#0000ff",
  },
  {
    title: "TWELVE",
    image:
      "https://galaxy-game.sgp1.digitaloceanspaces.com/pages-game/dream-catcher/12.png",
    color: "#dd1a22",
  },
];

export const arrBetPanel3 = [
  { title: "RED", color: "#dd1a22" },
  { title: "ODD", color: "black" },
  { title: "EVEN", color: "black" },
  { title: "BLUE", color: "#0000ff" },
];
