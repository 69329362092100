import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ScratchCard, { CUSTOM_BRUSH_PRESET } from "react-scratchcard-v2";
import "./review-card.scss";
import { CloseCircleOutlined } from "@ant-design/icons";

import Card from "../../../../components-game/card/Card";
import { disableScroll, enableScroll } from "../../../../utils/Common";

function ReviewCard({ tableDetail, result, setReviewCard }) {
  const { t } = useTranslation();

  const [newArr, setNewArr] = useState(result?.length > 1 ? result[1] : []);
  const [selectedSet, setSelectedSet] = useState();
  const [doneCount, setDoneCount] = useState(0);

  useEffect(() => {
    disableScroll();
    if (doneCount >= newArr?.length) {
      setTimeout(() => {
        setReviewCard(false);
      }, 1000);
    }
    return () => enableScroll();
  }, [doneCount]);

  function handleFinishScratch(card) {
    let temp = newArr;
    let revealCount = 0;

    temp.forEach((seat) => {
      if (seat.seat === selectedSet.seat) {
        seat?.card?.forEach((item) => {
          if (item.value === card.value) {
            item.isReveal = true;
          }
          if (item.isReveal) {
            revealCount++;
          }
        });
        return;
      }
    });
    setNewArr(temp);
    if (revealCount >= 4) {
      setTimeout(() => {
        setDoneCount((prev) => prev + 1);
        setSelectedSet();
      }, 1000);
    }
  }

  const renderAll = () => (
    <div className="all-card-set">
      <div className="content-wrapper">
        {newArr?.map((items, index) => (
          <div className="card-set" key={index}>
            <div className="title">{`${t("player")} ${index + 1}`}</div>

            <div className="content" onClick={() => setSelectedSet(items)}>
              {items?.card?.map((card, index) => (
                <div key={index} className="card-wrapper">
                  <Card isReveal={card.isReveal} card={tableDetail?.cardUrl + (card.isReveal ? card?.value : "cover.png")} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const scratchCard = () => (
    <div className="scratch-card-set">
      <div className="result-card-top">
        <div className="card-wrappers">
          <ScratchCard width={160.13} height={240.19} image={tableDetail?.scratchUrl} finishPercent={50} customBrush={CUSTOM_BRUSH_PRESET} onComplete={() => handleFinishScratch(selectedSet?.card[0])}>
            <img src={tableDetail?.cardUrl + selectedSet?.card[0]?.value} alt={selectedSet?.card[0]?.value} />
          </ScratchCard>
        </div>
      </div>
      <div className="result-card-bottom">
        {selectedSet?.card?.map(
          (items, index) =>
            index >= 1 && (
              <div key={index} className="card-wrappers">
                <ScratchCard width={160.13} height={240.19} image={tableDetail?.scratchUrl} finishPercent={50} customBrush={CUSTOM_BRUSH_PRESET} onComplete={() => handleFinishScratch(items)}>
                  <img src={tableDetail?.cardUrl + items.value} alt={items.value} />
                </ScratchCard>
              </div>
            )
        )}
      </div>
    </div>
  );

  return (
    <div className="fs-h-review-card">
      <CloseCircleOutlined className="close-icon" onClick={() => setReviewCard(false)} />
      {!selectedSet ? renderAll() : scratchCard()}
    </div>
  );
}

export default ReviewCard;
