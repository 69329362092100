import "./Style.scss";
import { Outlet } from "react-router-dom";

function LobbyLayout() {
    return (
        <div className="lobby-layout">
            <div className="lobby-content">
                <Outlet />
            </div>
        </div>
    );
}

export default LobbyLayout;
