import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../loading-page/LoadingPage";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

function MerchantLogin() {
    const { gameType, tableInfo } = useParams();
    const navigate = useNavigate();
    const { isLogin } = useContext(AuthContext);

    // useEffect(() => {
    //     if (isLogin) {
    //         const page = gameType?.split("_");

    //         const redirectGameType = page.length > 1 ? page[1].toLowerCase() : "all";

    //         if (page[0] === "lobby") {
    //             navigate(`/lobby/games/${redirectGameType}`);
    //         } else {
    //             navigate(`/game-table/${gameType}_${tableInfo}`);
    //         }
    //     }
    // }, []);

    useEffect(() => {
        if (isLogin) {
            if (tableInfo?.charAt(0) === "&") {
                navigate(`/lobby/games/${gameType === "lobby" ? "all" : gameType}`);
            } else {
                navigate(`/game-table/${gameType}_${tableInfo}`);
            }
        }
    }, []);

    return <LoadingPage />;
}

export default MerchantLogin;
