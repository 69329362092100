import React from "react";
import { useTranslation } from "react-i18next";

import { useBetPanel } from "../../../../../hook/useBetPanel";
import { useChips } from "../../../../../hook/useChips";

import "./HBetPanel.scss";
import Dice from "../../Dice";

function HBetPanel({ tableInfo, connection, chipArr, rate, tableDetail, dealerSignal, setTotalBet, setLabelBet, betContainerClass }) {
    const { t } = useTranslation();

    const { handleDisplayRate, handlePlaceBet, handleUpdateBetStatistic } = useBetPanel();
    const { handlePlaceChipFixed } = useChips();

    async function handleBet(key, e) {
        if (tableDetail && dealerSignal && dealerSignal?.action === "new-game" && betContainerClass) {
            try {
                const result = await handlePlaceBet(key, tableDetail, dealerSignal);
                setTotalBet(result?.totalBet);
                handlePlaceChipFixed(chipArr, key, { image: result?.image, value: result?.curBetCode }, result?.uniqueID, result?.roundUniqueId);
                handleUpdateBetStatistic(connection, tableInfo, "AddBetStatistic", key, result);
                setLabelBet("totalBet");
            } catch (error) {
                message.info({ content: error, key: error });
            }
        }
    }

    return (
        <div className="sicbo-h-bet-panel">
            <div className="bet-items-01">
                <div className="bet-small-odd">
                    <div className="bet-small" data-id="SS" onClick={(e) => handleBet(`SS`, e)}>
                        {t("small")}
                        <div className="lbl-point">{`4 ${t("to")} 10`}</div>
                        <div className="lbl-rate">1:{handleDisplayRate(rate, "SS")}</div>
                    </div>

                    <div className="bet-odd" data-id="SO" onClick={(e) => handleBet(`SO`, e)}>
                        {t("odd")}
                        <div className="lbl-rate">1:{handleDisplayRate(rate, "SO")}</div>
                    </div>
                </div>

                <div className="bet-double">
                    <div className="bet-double-title">1 : {handleDisplayRate(rate, "SS1")}</div>

                    {[1, 2, 3].map((item, index) => (
                        <div key={index} className="bet-double-item" data-id={`SS${item}`} onClick={(e) => handleBet(`SS${item}`, e)}>
                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>

                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="bet-triple">
                    <div className="bet-triple-title">1 : {handleDisplayRate(rate, "SSS1")}</div>

                    {[1, 2, 3].map((item, index) => (
                        <div key={index} className="bet-triple-item" data-id={`SSS${item}`} onClick={(e) => handleBet(`SSS${item}`, e)}>
                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>

                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>

                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="bet-all-triple" data-id="SSSA" onClick={(e) => handleBet(`SSSA`, e)}>
                    {t("anyTriple")}
                    <div className="bet-all-triple-title">1 : {handleDisplayRate(rate, "SSSA")}</div>
                </div>

                <div className="bet-triple">
                    <div className="bet-triple-title">1 : {handleDisplayRate(rate, "SSS1")}</div>

                    {[4, 5, 6].map((item, index) => (
                        <div key={index} className="bet-triple-item" data-id={`SSS${item}`} onClick={(e) => handleBet(`SSS${item}`, e)}>
                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>

                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>

                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="bet-double">
                    <div className="bet-double-title">1 : {handleDisplayRate(rate, "SS1")}</div>

                    {[4, 5, 6].map((item, index) => (
                        <div key={index} className="bet-double-item" data-id={`SS${item}`} onClick={(e) => handleBet(`SS${item}`, e)}>
                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>

                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="bet-big-even">
                    <div className="bet-big" data-id="SB" onClick={(e) => handleBet(`SB`, e)}>
                        {t("big")}
                        <div className="lbl-point">{`11 ${t("to")} 17`}</div>
                        <div className="lbl-rate">1:{handleDisplayRate(rate, "SB")}</div>
                    </div>

                    <div className="bet-even" data-id="SE" onClick={(e) => handleBet(`SE`, e)}>
                        {t("even")}
                        <div className="lbl-rate">1:{handleDisplayRate(rate, "SE")}</div>
                    </div>
                </div>
            </div>

            <div className="bet-items-02">
                {[4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((item, index) => (
                    <div className="BP" key={index} data-id={`S${item}`} onClick={(e) => handleBet(`S${item}`, e)}>
                        {item}
                        <div className="lbl-rate">1 : {handleDisplayRate(rate, `S${item}`)}</div>
                    </div>
                ))}
            </div>

            <div className="bet-items-03">
                <div className="bet-two-dice-title">{`${t("twoDice")} 1 : ${handleDisplayRate(rate, "S1S2")}`}</div>

                <div className="bet-two-dice">
                    {["1,2", "1,3", "1,4", "1,5", "1,6", "2,3", "2,4", "2,5", "2,6", "3,4", "3,5", "3,6", "4,5", "4,6", "5,6"].map((item, index) => {
                        const dice = item.split(",");

                        return (
                            <div
                                key={index}
                                className="bet-two-dice-item"
                                data-id={`S${Number(dice[0])}S${Number(dice[1])}`}
                                onClick={(e) => handleBet(`S${Number(dice[0])}S${Number(dice[1])}`, e)}
                            >
                                <div className="dice-wrapper">
                                    <Dice point={Number(dice[0])} />
                                </div>

                                <div className="dice-wrapper">
                                    <Dice point={Number(dice[1])} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="bet-items-04">
                <div className="bet-dice">
                    {[1, 2, 3, 4, 5, 6].map((item, index) => (
                        <div className="bet-dice-item" data-id={`S${item}E1`} key={index} onClick={(e) => handleBet(`S${item}E1`, e)}>
                            <div>{item}</div>
                            <div className="dice-wrapper">
                                <Dice point={item} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="bet-dice-title">
                    <div className="bet-dice-title-item">{`1:${handleDisplayRate(rate, "S1E1")} ${t("onOneDice")}`}</div>
                    <div className="bet-dice-title-item">{`1:${handleDisplayRate(rate, "S1E2")} ${t("onTwoDice")}`}</div>
                    <div className="bet-dice-title-item">{`1:${handleDisplayRate(rate, "S1E3")} ${t("onThreeDice")}`}</div>
                </div>
            </div>
        </div>
    );
}

export default HBetPanel;
