import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useChips } from "../../../hook/useChips";
import { getBrowserAgent } from "../../../utils/Common";
import { BetHistoryVersion } from "../../../hook/useBetPanel";

function useRouletteBetPanel() {
    const { balance, setBalance } = useContext(AuthContext);
    const { handleGetSelectedChip } = useChips();

    //IndexedDB extension
    const DeleteRow = async (store, key) => {
        return new Promise((resolve, reject) => {
            const delete_request = store?.delete(key);

            delete_request.onerror = (e) => {
                return reject(e.target.error);
            };
            delete_request.onsuccess = (e) => {
                return resolve();
            };
        });
    };

    const GenerateRandomText = (length) => {
        let result = "";
        for (var i = 0; i < length; i++) {
            result += Math.floor(Math.random() * 10).toString();
        }
        return result;
    };

    const CreateStore = (db) => {
        // const store = db.createObjectStore("Log_Chips_Placed", { keyPath: "UniqueID" });
        const store = db.createObjectStore(`Log_Chips_Placed-${BetHistoryVersion}`, { keyPath: "Key", autoIncrement: true });

        store.createIndex("UniqueID", ["UniqueID"], { unique: true });
        store.createIndex("TableID", ["TableID"], { unique: false });
        store.createIndex("RoundUniqueID", ["RoundUniqueID"], { unique: false });
        store.createIndex("ChipImage", ["ChipImage"], { unique: false });
        store.createIndex("ChipAmount", ["ChipAmount"], { unique: false });
        store.createIndex("BetCode", ["BetCode"], { unique: false });
    };
    //End Extension

    /**
     * @param {string} key bet items's data-id
     * @param {object} selected_chip {image: "", value: 10}
     * @param {object} table_detail
     * @param {object} dealer_signal
     * @returns
     */
    function handleValidateRouletteBet(key, selected_chip, table_detail, dealer_signal) {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
            request.onupgradeneeded = (e) => {
                CreateStore(e.target.result);
            };
            request.onsuccess = (e) => {
                const db = e.target.result;
                const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                const round_unique_id_index = store.index("RoundUniqueID");
                const round_unique_id_query = round_unique_id_index.getAll(
                    getBrowserAgent() === "Safari" ? dealer_signal?.gameSummarySrno : [dealer_signal?.gameSummarySrno]
                );

                round_unique_id_query.onsuccess = async (e) => {
                    let total_bet = 0;
                    let cur_betCode = 0;
                    for (const data of e.target.result) {
                        total_bet += data.ChipAmount;

                        if (data.BetCode === key && data.RoundUniqueID === dealer_signal?.gameSummarySrno) {
                            cur_betCode += data.ChipAmount;
                        }
                    }

                    if (total_bet < table_detail?.maxBet && balance >= selected_chip?.value) {
                        if (total_bet + selected_chip?.value <= table_detail?.maxBet && balance >= selected_chip?.value) {
                            setBalance((prev) => prev - selected_chip?.value);
                            return resolve({
                                curBetCode: cur_betCode + selected_chip?.value,
                                totalBet: total_bet + selected_chip?.value,
                                curValue: selected_chip?.value,
                            });
                        }

                        if (total_bet + selected_chip?.value > table_detail?.maxBet) {
                            let amount = table_detail?.maxBet - total_bet;
                            setBalance((prev) => prev - amount);
                            return resolve({
                                curBetCode: cur_betCode + amount,
                                totalBet: table_detail?.maxBet,
                                curValue: amount,
                            });
                        }

                        if (balance < selected_chip?.value) {
                            let temp = Math.trunc(balance);
                            setBalance((prev) => prev - temp);
                            return resolve({
                                curBetCode: cur_betCode + temp,
                                totalBet: total_bet + temp,
                                curValue: temp,
                            });
                        }
                    }

                    if (balance < selected_chip?.value) {
                        return reject("Insufficient Balance");
                    }

                    return reject("Bet limit exceed");
                };
                transaction.oncomplete = (e) => {
                    db.close();
                };
            };
        });
    }

    /**
     *
     * @param {string} key bet item's data-id
     * @param {object} table_detail
     * @param {object} dealer_signal
     * @returns
     */
    function handleRoulettePlaceBet(key, table_detail, dealer_signal, tempChip = undefined) {
        return new Promise(async (resolve, reject) => {
            try {
                //request_1 for get selected chip
                let selected_chip =
                    tempChip ??
                    (await handleGetSelectedChip().catch((error) => {
                        return reject(error);
                    }));

                let validate_bet = await handleValidateRouletteBet(key, selected_chip, table_detail, dealer_signal).catch((error) => {
                    return reject(error);
                });

                const result = await Promise.all([selected_chip, validate_bet]);
                if (result[0] && result[1]) {
                    //open database || add bet item
                    const request = window.indexedDB.open("Galaxy_Bet_History", BetHistoryVersion);
                    //create table if does not exist
                    request.onupgradeneeded = (e) => {
                        CreateStore(e.target.result);
                    };

                    //after create table or table already exist will direct come here
                    request.onsuccess = (e) => {
                        const db = e.target.result;
                        const transaction = db.transaction(`Log_Chips_Placed-${BetHistoryVersion}`, "readwrite");
                        const store = transaction.objectStore(`Log_Chips_Placed-${BetHistoryVersion}`);

                        //delete previous record
                        const table_id_index = store.index("TableID");
                        // const table_id_query = table_id_index.getAll(`${table_detail?.tableCode}-${table_detail?.type}`);
                        // const table_id_query = table_id_index.getAll([`${table_detail?.tableCode}-${table_detail?.type}`]);
                        const table_id_query = table_id_index.getAll(
                            getBrowserAgent() === "Safari"
                                ? `${table_detail?.tableCode}-${table_detail?.type}`
                                : [`${table_detail?.tableCode}-${table_detail?.type}`]
                        );

                        table_id_query.onsuccess = async (e) => {
                            for (const data of e.target.result) {
                                if (data.RoundUniqueID !== dealer_signal?.gameSummarySrno) {
                                    await DeleteRow(store, data.Key);
                                }
                            }
                        };

                        //add new record
                        const unique_id = `${dealer_signal?.gameSummarySrno ?? GenerateRandomText(8)}${GenerateRandomText(4)}`;
                        store.add({
                            UniqueID: unique_id,
                            RoundUniqueID: dealer_signal?.gameSummarySrno,
                            TableID: `${table_detail?.tableCode}-${table_detail?.type}`,
                            ChipImage: selected_chip?.image,
                            ChipAmount: validate_bet?.curValue,
                            BetCode: key,
                            Status: 1,
                        });

                        transaction.oncomplete = () => {
                            db.close();
                        };
                        // console.log({
                        //     ...selected_chip,
                        //     uniqueID: unique_id,
                        //     roundUniqueId: dealer_signal?.gameSummarySrno,
                        //     totalBet: validate_bet?.totalBet,
                        //     curBetCode: validate_bet?.curBetCode,
                        //     curValue: validate_bet?.curValue,
                        // });
                        return resolve({
                            ...selected_chip,
                            uniqueID: unique_id,
                            roundUniqueId: dealer_signal?.gameSummarySrno,
                            totalBet: validate_bet?.totalBet,
                            curBetCode: validate_bet?.curBetCode,
                            curValue: validate_bet?.curValue,
                        });
                    };
                }
            } catch (error) {
                console.log(error);
                return reject(error);
            }
        });
    }

    return { handleRoulettePlaceBet };
}

export default useRouletteBetPanel;
