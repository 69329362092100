import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { formatNumber } from "../../../utils/Formatter";
import { useRoulette } from "../hook/useRoulette";

import "./VRoulette.scss";
import { Spin } from "antd";
import { SwapOutlined } from "@ant-design/icons";

import CFBtnView from "../../../components-game/navigator/CFBtnView";
import Timer from "../../../components-game/timer/Timer";
import WinList from "../../../components-game/win-list/WinList";
import ActionMessage from "../component/action-message/ActionMessage";

import TableList from "../../../components-game/table-list/TableList";

import LoadingPage from "../../../pages/loading-page/LoadingPage";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import { AuthContext } from "../../../context/AuthContext";
import RoadMap from "../component/road-map/RoadMap";
import Icon from "../../../components-game/navigator/icon/Icon";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";
import VBetPanel from "../component/bet-panel/vertical/VBetPanel";
import VChips from "../../../components-game/chips-set/new-vertical/VChips";

function VRoulette() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        betType,
        setBetType,
        betContainerClass,
        setBetContainerClass,
        roadMaps,
        chatMsg,
    } = useRoulette();

    const { windowWidth } = useContext(AuthContext);

    const [openTableList, setOpenTableList] = useState(false);

    if (isFirstLoad) {
        return <LoadingPage />;
    }

    function handleChangeBetPanelSkew() {
        if (dealerSignal?.action !== "new-game") {
            setBetContainerClass(betContainerClass === "new-game" ? "stop-bet" : "new-game");
            setBetType((prev) => (betContainerClass === "new-game" ? 1 : prev));
        }
    }

    return (
        <div
            style={{ background: `linear-gradient(to bottom, ${tableDetail?.color?.split(",")[0]}, ${tableDetail?.color?.split(",")[1]})` }}
            id="vertical-roulette"
        >
            <div className="header-container">
                <div className="header-title">
                    <div className="header-btn" onClick={() => navigate(-1)}>
                        <Icon image={back} />
                    </div>
                    {tableDetail?.tableName}
                </div>
                <div className="header-menu">
                    {streamingObj && (
                        <CFBtnView
                            BtnTable={true}
                            BtnGraphic={true}
                            connection={connection}
                            GraphicObj={streamingObj}
                            setOpenTableList={setOpenTableList}
                            tableDetail={tableDetail}
                            chatMsg={chatMsg}
                        />
                    )}
                </div>
            </div>
            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <div className="road-map-wrapper">
                        <RoadMap Data={roadMaps?.route_1} />
                    </div>
                    <div className="id-wrapper">
                        <div className="uid">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</div>
                        <div className="uid">ID: {userData?.playerID ? userData.playerID : "-"}</div>
                    </div>
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                    <div className="win-list-wrapper" style={{ display: winList ? "block" : "none" }}>
                        {winList && <WinList winList={winList} />}
                    </div>

                    {dealerSignal?.second ? (
                        <div className="timer-container">
                            <Timer dealerSignal={dealerSignal} />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </Spin>

            <div className={`bet-panel-wrapper ${betContainerClass ? "new-game" : "stop-bet"}`}>
                <div className={`bet-panel 01 ${betType === 1}`}>
                    {betItems && (
                        <VBetPanel
                            tableInfo={tableInfo}
                            connection={connection}
                            chipArr={chipsArr}
                            rate={betItems}
                            tableDetail={tableDetail}
                            dealerSignal={dealerSignal}
                            setTotalBet={setTotalBet}
                            betStatistic={betStatistic}
                            setLabelBet={setLabelBet}
                            cardResult={cardResult ? cardResult[1] : []}
                            type="normal"
                            betContainerClass={betContainerClass}
                        />
                    )}
                </div>
                <div className={`bet-panel 02 ${betType === 2}`}>
                    {betItems && (
                        <VBetPanel
                            tableInfo={tableInfo}
                            connection={connection}
                            chipArr={chipsArr}
                            rate={betItems}
                            tableDetail={tableDetail}
                            dealerSignal={dealerSignal}
                            setTotalBet={setTotalBet}
                            betStatistic={betStatistic}
                            setLabelBet={setLabelBet}
                            cardResult={cardResult}
                            type="oval"
                            betContainerClass={betContainerClass}
                        />
                    )}
                </div>
                <div className="btn-bet-panel-method" onClick={() => setBetType((prev) => (prev === 1 ? 2 : 1))}>
                    <SwapOutlined style={{ transform: "rotateZ(90deg)" }} />
                </div>
            </div>

            <div className={`action-msg-container ${actionMsg.text}`}>
                <ActionMessage
                    text={actionMsg.text}
                    color="gold"
                    totalWin={totalBet}
                    showWinAmount={actionMsg.showWinAmount}
                    result={actionMsg.result}
                />
            </div>

            <div className="chips-set-container">
                {chipsArr && (
                    <VChips
                        setLabelBet={setLabelBet}
                        connection={connection}
                        tableInfo={tableInfo}
                        ChipsSet={chipsArr}
                        tableDetail={tableDetail}
                        dealerSignal={dealerSignal}
                        totalBet={totalBet}
                        setTotalBet={setTotalBet}
                        roadMaps={roadMaps}
                    />
                )}
            </div>

            <div className="footer-container">
                <div className="container-01">
                    <span className="lbl lbl-total-bet">
                        {t(labelBet)}: {userData?.currency + " " + formatNumber(totalBet)}
                    </span>
                    <span className="lbl lbl-balance">Balance: {userData?.currency + " " + formatNumber(balance)}</span>
                </div>

                <div className="container-02">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</div>
            </div>

            <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />

            {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default VRoulette;
