import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

function GameObjectives() {
    const { t } = useTranslation();

    return <Text>{t("gameObjectives1")}</Text>;
}

export default GameObjectives;
