import { Image } from "antd";
import React, { useContext } from "react";
import "./Style.scss";
import { useNavigate, useParams } from "react-router-dom";
import { formatSpaceAndToLower } from "../../../utils/Formatter";
import { AuthContext } from "../../../context/AuthContext";

function TableCategory({ setIsFavorite, categoryList }) {
    const { category } = useParams();
    const navigate = useNavigate();
    const { userData } = useContext(AuthContext);

    function handleMenuClick(categoryName) {
        setIsFavorite(false);
        navigate(`/lobby/games/${formatSpaceAndToLower(categoryName)}`);
    }

    return (
        <div className="category">
            <img
                src={`${process.env.REACT_APP_DIGITALOCEAN_URL}/category-icon/${userData?.lang}/${category === "all" ? "gold" : "grey"}/all.png`}
                alt=""
                onClick={() => handleMenuClick("all")}
            />
            {/* <Image
                onClick={() => handleMenuClick("all")}
                src={`${process.env.REACT_APP_DIGITALOCEAN_URL}/category-icon/${userData?.lang}/${category === "all" ? "gold" : "grey"}/all.png`}
                preview={false}
            /> */}
            {categoryList?.map((item, index) => (
                <img
                    key={index}
                    src={`${process.env.REACT_APP_DIGITALOCEAN_URL}/category-icon/${userData?.lang}/${
                        category === formatSpaceAndToLower(item?.categoryName) ? "gold" : "grey"
                    }/${formatSpaceAndToLower(item?.categoryName)}.png`}
                    alt=""
                    onClick={() => handleMenuClick(item?.categoryName)}
                />

                // <Image
                //     key={index}
                //     onClick={() => handleMenuClick(item?.categoryName)}
                //     src={`${process.env.REACT_APP_DIGITALOCEAN_URL}/category-icon/${userData?.lang}/${
                //         category === formatSpaceAndToLower(item?.categoryName) ? "gold" : "grey"
                //     }/${formatSpaceAndToLower(item?.categoryName)}.png`}
                //     preview={false}
                // />
            ))}
        </div>
    );
}

export default TableCategory;
