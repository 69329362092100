import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";

import "./GameLayout.scss";

import HBaccarat from "../../page-game/baccarat/horizontal/HBaccarat";
import VBaccarat from "../../page-game/baccarat/vertical/VBaccarat";
// import HPeekBaccarat from "../../page-game/peek-baccarat/horizontal/HPeekBaccarat";
// import VPeekBaccarat from "../../page-game/peek-baccarat/vertical/VPeekBaccarat";
import HThreePicture from "../../page-game/three-picture/horizontal/HThreePicture";
import VThreePicture from "../../page-game/three-picture/vertical/VThreePicture";
import HFourSeason from "../../page-game/four-season/horizontal/HFourSeason";
import VFourSeason from "../../page-game/four-season/vertical/VFourSeason";
import HRoulette from "../../page-game/roulette/horizontal/HRoulette";
import VRoulette from "../../page-game/roulette/vertical/VRoulette";
import HSicbo from "../../page-game/sicbo/horizontal/HSicbo";
import VSicbo from "../../page-game/sicbo/vertical/VSicbo";

import LoadingPage from "../../pages/loading-page/LoadingPage";
import ComingSoon from "../coming-soon/ComingSoon";
import VDreamCatcher from "../../page-game/dream-catcher/vertical/VDreamCatcher";
import HDreamCatcher from "../../page-game/dream-catcher/horizontal/HDreamCatcher";

function GameLayout() {
    const { tableInfo } = useParams();
    const { isVertical } = useContext(AuthContext);

    const [component, setComponent] = useState();

    useEffect(() => {
        setComponent();

        setTimeout(() => {
            handleUI();
        }, 500);
    }, [tableInfo, isVertical]);

    function handleUI() {
        const gameType = tableInfo?.split("_")[0];

        switch (gameType?.toLowerCase()) {
            case "baccarat":
                setComponent(isVertical ? <VBaccarat /> : <HBaccarat />);
                // setComponent(isVertical ? <VPeekBaccarat /> : <HPeekBaccarat />);
                break;
            case "three-picture":
                setComponent(isVertical ? <VThreePicture /> : <HThreePicture />);
                break;
            case "four-seasons":
                setComponent(isVertical ? <VFourSeason /> : <HFourSeason />);
                break;
            case "roulette":
                setComponent(isVertical ? <VRoulette /> : <HRoulette />);
                break;
            case "sicbo":
                setComponent(isVertical ? <VSicbo /> : <HSicbo />);
                break;
            case "dream-catcher":
                setComponent(isVertical ? <VDreamCatcher /> : <HDreamCatcher />);
                break;
            default:
                setComponent(<ComingSoon />);
                break;
        }
    }

    if (!component) {
        return <LoadingPage />;
    }

    return <div id="game-layout">{component}</div>;
}

export default GameLayout;
