import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import { formatSpaceAndToLower } from "../../utils/Formatter";
import { handleGetTableList } from "../../utils/SignalR";

import "./TableList.scss";
import { Card, Drawer, Skeleton, Space } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";

import RoadMap from "../../page-game/baccarat/component/road-map/RoadMap";
import TpRoadMap from "../../page-game/three-picture/component/road-map/TpRoadMap";
import RoadMapFourSeason from "../../page-game/four-season/component/road-map/RoadMap";
import SbRoadMap from "../../page-game/sicbo/component/road-map/SbRoadMap";

const { Meta } = Card;

function TableList({ connection, tableInfo, openTableList, setOpenTableList }) {
    const navigate = useNavigate();

    const { userData } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    const [apiData, setApiData] = useState();
    const [apiData2, setApiData2] = useState();
    const drawerSetting = {
        placement: "right",
        onClose: () => setOpenTableList(!openTableList),
        open: openTableList,
        width: "clamp(300px, 60%, 500px)",
        rootClassName: "tbl-list-drawer",
        closable: false,
        destroyOnClose: true,
    };

    useEffect(() => {
        getTableList();
    }, []);

    function getTableList() {
        connection.off("table-list");
        connection.off("table-list-route");
        setIsLoading(true);

        handleGetTableList(connection, tableInfo, userData);
        connection.on("table-list", (result) => {
            setApiData(result);
            setIsLoading(false);
        });
        connection.on("table-list-route", (result) => {
            setApiData2(result);
        });
    }

    useEffect(() => {
        handleRoadMapChange();
    }, [apiData, apiData2]);

    function handleRoadMapChange() {
        apiData2?.forEach((element) => {
            apiData?.reduce((obj, item, index) => {
                if (element.tableCode === item.tableCode && element.type === item.type) {
                    apiData[index].roadmap = element.roadmap;
                }
            }, []);
        });
    }

    function getTableRoad(table) {
        switch (formatSpaceAndToLower(table?.gameType)) {
            case "baccarat":
                if (table?.roadmap?.route_2?.length > 0) {
                    return <RoadMap RoadType="02" Data={table?.roadmap?.route_2} />;
                }
                break;
            case "three-picture":
                if (table?.roadmap?.route_1?.length > 0) {
                    return <TpRoadMap Data={table?.roadmap?.route_1} ShowText={false} />;
                }
                break;
            case "four-seasons":
                if (table?.roadmap?.route_1?.length > 0) {
                    return <RoadMapFourSeason Data={table?.roadmap?.route_1} ShowText={false} />;
                }
                break;
            case "sicbo":
                if (table?.roadmap?.route2?.length > 0) {
                    return <SbRoadMap Type="C" Data={table?.roadmap?.route_2} />;
                }
                break;
            default:
                break;
        }
    }

    async function handleOpenTable(table) {
        navigate(`/game-table/${formatSpaceAndToLower(table?.gameType)}_${table?.redirectUrl}`, { replace: true });
    }

    return (
        <Drawer {...drawerSetting}>
            <Skeleton loading={isLoading}>
                <Space direction="vertical" size="large" style={{ width: "100%" }} className="tbl-list-item">
                    {apiData?.map((table, index) => (
                        <Card key={index} hoverable onClick={() => handleOpenTable(table)}>
                            {getTableRoad(table)}
                            <Meta
                                description={
                                    <div className="tbl-container">
                                        <div className="tbl-detail-container">
                                            <div className="tbl-detail-title">{table?.tableName}</div>
                                            <div className="tbl-detail-minmax">
                                                {userData?.currency + " " + table?.minBet + " - " + table?.maxBet}
                                            </div>
                                        </div>
                                        <div className="tbl-icon">
                                            <RightCircleOutlined />
                                        </div>
                                    </div>
                                }
                            />
                        </Card>
                    ))}
                </Space>
            </Skeleton>
        </Drawer>
    );
}

export default TableList;
