import { Col, Collapse, Row, Typography } from "antd";
import React from "react";
import { CaretRightFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import GameObjectives from "./game-objectives/GameObjectives";
import GameRules from "./game-rules/GameRules";
import SideBets from "./side-bets/SideBets";
import Payouts from "./payouts/Payouts";
import ReturnToPlayer from "./return-to-player/ReturnToPlayer";
import PlaceBets from "./place-bets/PlaceBets";
import BetStatistics from "./bet-statistics/BetStatistics";
import ScoreCard from "./score-card/ScoreCard";
import GameNumber from "./game-number/GameNumber";
import GameHistory from "./game-history/GameHistory";
import Settings from "./settings/Settings";
import ErrorHandling from "./error-handling/ErrorHandling";
import DisconnectionPolicy from "./disconnection-policy/DisconnectionPolicy";
import Shuffling from "./shuffling/Shuffling";
import MoreGames from "./more-games/MoreGames";
import "./Style.scss";

const { Text } = Typography;

function BcHowToPlay() {
  const { t } = useTranslation();

  const howToPlay = [
    {
      key: "game-objectives",
      name: "gameObjectives",
      children: <GameObjectives />,
    },
    {
      key: "game-rules",
      name: "gameRules",
      children: <GameRules />,
    },
    {
      key: "side-bets",
      name: "sideBets",
      children: <SideBets />,
    },
    {
      key: "payouts",
      name: "payouts",
      children: <Payouts />,
    },
    // {
    //     key: "red-envelope",
    //     name: "redEnvelope",
    // },
    {
      key: "return-to-player",
      name: "playerReturn",
      children: <ReturnToPlayer />,
    },
    {
      key: "place-bets",
      name: "placeBets",
      children: <PlaceBets />,
    },
    {
      key: "bet-statistics",
      name: "betStat",
      children: <BetStatistics />,
    },
    // {
    //     key: "chat",
    //     name: "chat",
    // },
    {
      key: "score-card",
      name: "scoreCard",
      children: <ScoreCard />,
    },
    {
      key: "game-number",
      name: "gameNumber",
      children: <GameNumber />,
    },
    {
      key: "game-history",
      name: "gameHistory",
      children: <GameHistory />,
    },
    {
      key: "settings",
      name: "setting",
      children: <Settings />,
    },
    {
      key: "error-handling",
      name: "errorHandling",
      children: <ErrorHandling />,
    },
    {
      key: "disconnection-policy",
      name: "policy",
      children: <DisconnectionPolicy />,
    },
    {
      key: "shuffling",
      name: "shuffling",
      children: <Shuffling />,
    },
    {
      key: "more-games",
      name: "moreGames",
      children: <MoreGames />,
    },
  ];

  return (
    <div className="collapse-title">
      <Collapse accordion>
        {howToPlay?.map((item, index) => (
          <Collapse.Panel
            key={index}
            showArrow={false}
            header={
              <Row justify="space-between" align="middle">
                <Col>{t(item.name)}</Col>
                <Col>
                  <CaretRightFilled style={{ color: "#bfbfbf" }} />
                </Col>
              </Row>
            }
          >
            {item.children}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
}

export default BcHowToPlay;
