import React from "react";

import "./RoadMap.scss";
import Dot from "./dots/Dot";

function TpRoadMap({ Data = undefined, ShowText = true }) {
  return (
    <div className="three-picture-road road-01">
      <div className="row-item">
        <div className="col-item">
          <Dot StrokeColor="none" FillColor="none" text="B" textColor="#000" LineRight LineBottom />
        </div>

        <div className="col-item">
          <Dot StrokeColor="none" FillColor="none" text="P1" textColor="#000" LineRight LineBottom />
        </div>

        <div className="col-item">
          <Dot StrokeColor="none" FillColor="none" text="P2" textColor="#000" LineRight LineBottom />
        </div>

        <div className="col-item">
          <Dot StrokeColor="none" FillColor="none" text="P3" textColor="#000" LineRight LineBottom />
        </div>
      </div>

      {Data?.map((row, index) => {
        const StrokeRight = index < Data?.length - 1;

        return (
          <div className="row-item" key={index + row}>
            {row?.map((col, index) => {
              const value = col?.split("-");
              const fillColor = value[0] === "dealer" ? "#ee4043" : value[0] === "win" ? "#3788ff" : value[0] === "tie" ? "#159252" : "none";
              const textColor = value[0] === "lose" ? "#000" : "#ddd";

              return (
                <div className="col-item" key={index + col}>
                  <Dot StrokeColor="none" FillColor={fillColor} text={ShowText ? value[1] : value[1]?.charAt(value[1].length - 1)} textColor={textColor} LineRight={StrokeRight} LineBottom={index < row.length - 1} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default TpRoadMap;
