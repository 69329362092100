import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import LoadingPage from "../pages/loading-page/LoadingPage";
import { validateToken } from "../services/login-api/LoginServices";
import { useTranslation } from "react-i18next";
import VBaccarat from "../page-game/baccarat/vertical/VBaccarat";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const [userData, setUserData] = useState();
    const [balance, setBalance] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isVertical, setIsVertical] = useState(false);
    const [bgMusic, setBgMusic] = useState();
    const [videoQuality, setVideoQuality] = useState("720");
    const [isMuted, setIsMuted] = useState(true);
    let initialChip = {
        amount: 5,
        icon: "https://galaxy-game.sgp1.digitaloceanspaces.com/chips/v2/chip0.png",
    };
    const [selectedChip, setSelectedChip] = useState(localStorage.getItem("chip") ? JSON.parse(localStorage.getItem("chip")) : initialChip);
    const [maintenanceInfo, setMaintenanceInfo] = useState();
    const [tryFull, setTryFull] = useState(true);
    const { i18n } = useTranslation();

    const url = window.location.pathname;
    const pathname = url.split("/");
    const path1 = pathname[1];
    const path2 = pathname[2];
    const path3 = pathname[3];
    const path5 = pathname[5];
    const path6 = pathname[6];

    useEffect(() => {
        if (process.env.REACT_APP_ALLOW_DEBUG === "false" && localStorage.getItem("playerID")?.substring(0, 4) !== "tst1") {
            (function () {
                (function a() {
                    try {
                        (function b(i) {
                            if (("" + i / i).length !== 1 || i % 20 === 0) {
                                (function () {}).constructor("debugger")();
                            } else {
                                debugger;
                            }
                            b(++i);
                        })(0);
                    } catch (e) {
                        setTimeout(a, 1000);
                    }
                })();
            })();
        }
        handleValidatePlayerToken();
    }, []);

    useEffect(() => {
        function handleWindowWidth() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
            setIsVertical(windowWidth <= windowHeight);
        }
        handleWindowWidth();
        window.addEventListener("resize", handleWindowWidth);

        return () => {
            window.removeEventListener("resize", handleWindowWidth);
        };
    }, [windowWidth]);

    async function handleValidatePlayerToken() {
        const finalUserInput = {
            playerID: path1 === "merchant-login" ? path2 : localStorage.getItem("playerID"),
            playerToken: path1 === "merchant-login" ? path3 : localStorage.getItem("playerToken"),
        };

        try {
            const result = await validateToken(finalUserInput);
            if (result.status) {
                setUserData(result.data);
                // setBalance(Math.trunc/(result.data.balance * 100) / 100);
                setBalance(result.data.balance);
                localStorage.setItem("playerID", result.data.playerID);
                localStorage.setItem("playerToken", result.data.playerToken);
                if (path1 === "merchant-login") {
                    localStorage.setItem("redirectUrl", path5?.split("&")[1]);
                    i18n.changeLanguage(path6);
                } else {
                    localStorage.setItem("redirectUrl", "");
                    i18n.changeLanguage(result.data.lang);
                }
                setIsLogin(true);
            }
        } catch (error) {
            // console.log(error);
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return <LoadingPage />;
    }

    const values = {
        isLoading,
        setIsLoading,
        isLogin,
        setIsLogin,
        userData,
        balance,
        setBalance,
        setUserData,
        windowWidth,
        windowHeight,
        bgMusic,
        setBgMusic,
        videoQuality,
        setVideoQuality,
        isMuted,
        setIsMuted,
        selectedChip,
        setSelectedChip,
        maintenanceInfo,
        setMaintenanceInfo,
        tryFull,
        setTryFull,
        isVertical,
        setIsVertical,
    };

    return <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>;
};
