import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { formatDateTime, formatNumber, formatString } from "../../../../../../utils/Formatter";

import "./roulette.scss";
import { Space, Table, Typography } from "antd";
import Road from "../../../../../../page-game/roulette/component/road-map/road/Road";
import useRouletteBetPanel from "../../../../../../page-game/roulette/hook/useRouletteBetPanel";
import { getColor } from "../../../../../../page-game/roulette/component/bet-panel/RouletteBetConst";

function Roulette({ record, data }) {
    const { t } = useTranslation();

    let item = {
        color: getColor(data?.data?.replace("R", "")),
        number: data?.data?.replace("R", ""),
    };

    const columns = [
        {
            title: t("betCode"),
            dataIndex: "betCodeName",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return formatString(text);
            },
        },
        {
            title: t("bet"),
            dataIndex: "bet",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return "MYR " + formatNumber(text);
            },
        },
        {
            title: t("win"),
            dataIndex: "win",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return "MYR " + formatNumber(text);
            },
        },
    ];

    return (
        <Space direction="vertical" style={{ width: "100%" }} className="game-hitsory-detail">
            <Typography.Text>Game Resolved: {formatDateTime(moment(record?.endDate).add(8, "h"))}</Typography.Text>
            <Typography.Text>Game Number: {record?.gameSummarySrno}</Typography.Text>
            <div className="roulette-road">
                <div className="road-wrapper">
                    <Road item={item} />
                </div>
            </div>
            <Table
                bordered
                columns={columns}
                dataSource={data?.data2}
                rowKey="betCode"
                scroll={{ x: true }}
                pagination={false}
                summary={(pageData) => {
                    let totalBet = 0;
                    let totalWin = 0;
                    pageData.forEach(({ bet, win }) => {
                        totalBet += bet;
                        totalWin += win;
                    });
                    return (
                        <Table.Summary.Row align="center">
                            <Table.Summary.Cell index={0}>TOTAL</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>MYR {formatNumber(totalBet)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>MYR {formatNumber(totalWin)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Space>
    );
}

export default Roulette;
