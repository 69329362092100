import apiBaseUrl from "../ApiBaseUrl";

export async function validateToken(userInput) {
    const response = await apiBaseUrl().post("/game/player/validate-token", userInput);
    const data = response.data;

    return data;
}

export async function playerLogin(userInput) {
    const response = await apiBaseUrl().post("/game/player/player-login", userInput);
    const data = response.data;

    return data;
}

export async function guestLogin(userInput) {
    const response = await apiBaseUrl().post("/game/player/guest-login", userInput);
    const data = response.data;

    return data;
}

export async function updateSelectedChips(userInput) {
    const response = await apiBaseUrl().post("/game/player/update-selected-chips", userInput);
    const data = response.data;

    return data;
}

export async function giveFeedback(userInput) {
    const response = await apiBaseUrl().post("/game/player/give-feedback", userInput);
    const data = response.data;

    return data;
}
