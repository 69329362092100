import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../../context/AuthContext";
import { formatNumber } from "../../../../utils/Formatter";

import { useBetPanel } from "../../../../hook/useBetPanel";
import { useChips } from "../../../../hook/useChips";

import "./BetPanel.scss";
import { Progress, message } from "antd";

import Card from "../../../../components-game/card/Card";

function BetPanel({ tableInfo, connection, chipArr, rate, tableDetail, dealerSignal, setTotalBet, betStatistic, setLabelBet, cardResult, type = "traditional", betContainerClass }) {
  const { t } = useTranslation();
  const { userData } = useContext(AuthContext);

  const { handleDisplayRate, handlePlaceBet, handleUpdateBetStatistic } = useBetPanel();
  const { handlePlaceChipFixed } = useChips();

  async function handleBet(key, e) {
    if (tableDetail && dealerSignal && dealerSignal?.action === "new-game" && betContainerClass) {
      try {
        const result = await handlePlaceBet(key, tableDetail, dealerSignal);
        setTotalBet(result?.totalBet);
        handlePlaceChipFixed(chipArr, key, { image: result?.image, value: result?.curBetCode }, result?.uniqueID, result?.roundUniqueId);
        handleUpdateBetStatistic(connection, tableInfo, "AddBetStatistic", key, result);
        setLabelBet("totalBet");
      } catch (error) {
        message.info({ content: error, key: error });
      }
    }
  }

  const traditionalBet = () => (
    <div className="traditional-bet">
      <div className="traditional-bet-item-wrapper-TP">
        <div className="TP-items" data-id="BS1TP" onClick={() => handleBet("BS1TP")}>
          <div>
            {t("banker")} 1:{handleDisplayRate(rate, "BS1TP")}
          </div>
          <div>{t("threeP")}</div>
        </div>
        <div className="TP-items" data-id="PS1TP" onClick={() => handleBet("PS1TP")}>
          <div>
            {t("player")}1 1:{handleDisplayRate(rate, "PS1TP")}
          </div>
          <div>{t("threeP")}</div>
        </div>
        <div className="TP-items" data-id="PS2TP" onClick={() => handleBet("PS2TP")}>
          <div>
            {t("player")}2 1:{handleDisplayRate(rate, "PS2TP")}
          </div>
          <div>{t("threeP")}</div>
        </div>
        <div className="TP-items" data-id="PS3TP" onClick={() => handleBet("PS3TP")}>
          <div>
            {t("player")}3 1:{handleDisplayRate(rate, "PS3TP")}
          </div>
          <div>{t("threeP")}</div>
        </div>
      </div>

      <div className="traditional-bet-item-wrapper">
        <div className="traditional-bet-item">
          <div className="progress">
            <Progress type="circle" trailColor="#595959" percent={formatNumber((betStatistic?.p1 / betStatistic?.total) * 100, 0)} size={35} strokeColor="#4e95ed" strokeWidth={6} status="normal" />
            <div className="seat-amount">
              <div className="seat">{t("bankerVSPlayer")} 1</div>
              <div className="amount">{userData?.currency + " " + betStatistic?.p1}</div>
            </div>

            <div className="card-results">
              {cardResult &&
                cardResult[0]?.card?.map((items, index) => (
                  <div className="card-item" key={index}>
                    <Card isReveal={dealerSignal?.action === "show-card" || dealerSignal?.action === "light-code"} card={tableDetail?.cardUrl + items.value} />
                  </div>
                ))}
            </div>
          </div>
          <div className="btn-bet-item PW" data-id="PS1" onClick={() => handleBet("PS1")}>
            <div>{t("player")}</div>
            <div>1:{handleDisplayRate(rate, "PS1")}</div>
          </div>

          <div className="btn-bet-item BW" data-id="BS1" onClick={() => handleBet("BS1")}>
            <div>{t("banker")}</div>
            <div>1:{handleDisplayRate(rate, "BS1")}</div>
          </div>

          <div className="btn-bet-item T" data-id="S1T" onClick={() => handleBet("S1T")}>
            <div>{t("tie")}</div>
            <div>1:{handleDisplayRate(rate, "S1T")}</div>
          </div>
        </div>

        <div className="traditional-bet-item">
          <div className="progress">
            <Progress type="circle" trailColor="#595959" percent={formatNumber((betStatistic?.p2 / betStatistic?.total) * 100, 0)} size={35} strokeColor="#4e95ed" strokeWidth={6} status="normal" />
            <div className="seat-amount">
              <div className="seat">{t("bankerVSPlayer")} 2</div>
              <div className="amount">{userData?.currency + " " + betStatistic?.p2}</div>
            </div>

            <div className="card-results">
              {cardResult &&
                cardResult[1]?.card?.map((items, index) => (
                  <div className="card-item" key={index}>
                    <Card isReveal={dealerSignal?.action === "show-card" || dealerSignal?.action === "light-code"} card={tableDetail?.cardUrl + items.value} />
                  </div>
                ))}
            </div>
          </div>

          <div className="btn-bet-item PW" data-id="PS2" onClick={() => handleBet("PS2")}>
            <div>{t("player")}</div>
            <div>1:{handleDisplayRate(rate, "PS2")}</div>
          </div>

          <div className="btn-bet-item BW" data-id="BS2" onClick={() => handleBet("BS2")}>
            <div>{t("banker")}</div>
            <div>1:{handleDisplayRate(rate, "BS2")}</div>
          </div>

          <div className="btn-bet-item T" data-id="S2T" onClick={() => handleBet("S2T")}>
            <div>{t("tie")}</div>
            <div>1:{handleDisplayRate(rate, "S2T")}</div>
          </div>
        </div>

        <div className="traditional-bet-item">
          <div className="progress">
            <Progress type="circle" trailColor="#595959" percent={formatNumber((betStatistic?.p3 / betStatistic?.total) * 100, 0)} size={35} strokeColor="#4e95ed" strokeWidth={6} status="normal" />
            <div className="seat-amount">
              <div className="seat">{t("bankerVSPlayer")} 3</div>
              <div className="amount">{userData?.currency + " " + betStatistic?.p3}</div>
            </div>

            <div className="card-results">
              {cardResult &&
                cardResult[2]?.card?.map((items, index) => (
                  <div className="card-item" key={index}>
                    <Card isReveal={dealerSignal?.action === "show-card" || dealerSignal?.action === "light-code"} card={tableDetail?.cardUrl + items.value} />
                  </div>
                ))}
            </div>
          </div>

          <div className="btn-bet-item PW" data-id="PS3" onClick={() => handleBet("PS3")}>
            <div>{t("player")}</div>
            <div>1:{handleDisplayRate(rate, "PS3")}</div>
          </div>

          <div className="btn-bet-item BW" data-id="BS3" onClick={() => handleBet("BS3")}>
            <div>{t("banker")}</div>
            <div>1:{handleDisplayRate(rate, "BS3")}</div>
          </div>

          <div className="btn-bet-item T" data-id="S3T" onClick={() => handleBet("S3T")}>
            <div>{t("tie")}</div>
            <div>1:{handleDisplayRate(rate, "S3T")}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const moreBet = () => (
    <div className="more-bet">
      <div className="more-bet-item-wrapper">
        <div className="lbl-title-wrapper">
          <div className="lbl-title">
            {t("banker")} {t("point")} 1:{handleDisplayRate(rate, "B0")}
          </div>

          <div className="lbl-title">
            {t("player")} 1 {t("point")} 1:{handleDisplayRate(rate, "P10")}
          </div>
        </div>

        <div className="bet-item-wrapper">
          <div className="bet-items B">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
              <div key={index} className="btn-bet-item" data-id={`B${item}`} onClick={() => handleBet(`B${item}`)}>
                {item}
              </div>
            ))}
          </div>

          <div className="bet-items P1">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
              <div key={index} className="btn-bet-item" data-id={`P1${item}`} onClick={() => handleBet(`P1${item}`)}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="more-bet-item-wrapper">
        <div className="lbl-title-wrapper">
          <div className="lbl-title">
            {t("player")} 2 {t("point")} 1:{handleDisplayRate(rate, "P20")}
          </div>

          <div className="lbl-title">
            {t("player")} 3 {t("point")} 1:{handleDisplayRate(rate, "P30")}
          </div>
        </div>

        <div className="bet-item-wrapper">
          <div className="bet-items P2">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
              <div key={index} className="btn-bet-item" data-id={`P2${item}`} onClick={() => handleBet(`P2${item}`)}>
                {item}
              </div>
            ))}
          </div>

          <div className="bet-items P3">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
              <div key={index} className="btn-bet-item" data-id={`P3${item}`} onClick={() => handleBet(`P3${item}`)}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return <div className="three-picture-bet-panel">{type === "traditional" ? traditionalBet() : moreBet()}</div>;
}

export default BetPanel;
