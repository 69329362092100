import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { formatDateTime, formatNumber, formatString } from "../../../../utils/Formatter";
import { handleGetGameHistory } from "../../../../utils/SignalR";

import "./game-history.scss";
import { Table } from "antd";
import { green, red } from "@ant-design/colors";

import Description from "./description/Description";

function GameHistory({ connection }) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [apiData, setApiData] = useState();
    const [apiDataPagination, setApiDataPagination] = useState({ currentPage: 1, perPage: 10, total: "" });

    useEffect(() => {
        getHistory(1, 10);
    }, []);

    function getHistory(page = 1, pageSize = 10) {
        connection.off("game-history");
        setIsLoading(true);

        handleGetGameHistory(connection, page, pageSize);

        connection.on("game-history", (result) => {
            setApiData(result.data);
            setApiDataPagination(result.data3);
            setIsLoading(false);
        });
    }

    const columns = [
        {
            title: t("game"),
            dataIndex: "gameName",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return formatString(text);
            },
        },
        {
            title: t("date"),
            dataIndex: "endDate",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return formatDateTime(moment(text).add(8, "h"));
            },
        },
        {
            title: t("bet"),
            dataIndex: "betAmount",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return formatNumber(text);
            },
        },
        {
            title: t("winLoss"),
            dataIndex: "winLoss",
            ellipsis: true,
            align: "center",
            render: (text, record) => {
                if (text !== 0) {
                    return <div style={{ color: green[6] }}>{formatNumber(text)}</div>;
                } else {
                    return <div style={{ color: red[6] }}>-{formatNumber(record.betAmount)}</div>;
                }
            },
        },
    ];

    return (
        <div className="game-history">
            <Table
                loading={isLoading}
                bordered
                columns={columns}
                dataSource={apiData}
                rowKey="gameSummarySrno"
                scroll={{ x: true }}
                expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expended, record) => (expended ? setExpandedRowKeys([record.gameSummarySrno]) : setExpandedRowKeys([])),
                    expandedRowRender: (record) =>
                        record.gameSummarySrno === expandedRowKeys[0] ? (
                            <Description connection={connection} record={record} setIsLoading={setIsLoading} />
                        ) : null,
                }}
                pagination={{
                    current: apiDataPagination.currentPage,
                    total: apiDataPagination.total,
                    showSizeChanger: false,
                    pageSize: apiDataPagination.perPage,
                    onChange: (page, pageSize) => getHistory(page, pageSize),
                }}
            />
        </div>
    );
}

export default GameHistory;
