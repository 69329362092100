import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../context/AuthContext";
import { formatNumber } from "../../../utils/Formatter";
import { useSicbo } from "../hook/useSicbo";

import "./VSicbo.scss";
import { Spin } from "antd";

import CFBtnView from "../../../components-game/navigator/CFBtnView";
import Timer from "../../../components-game/timer/Timer";
import WinList from "../../../components-game/win-list/WinList";
import ActionMessage from "../component/action-message/ActionMessage";
import VBetPanel from "../component/bet-panel/vertical/VBetPanel";
import HBetPanel from "../component/bet-panel/horizontal/HBetPanel";

import TableList from "../../../components-game/table-list/TableList";

import LoadingPage from "../../../pages/loading-page/LoadingPage";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import SbRoadMap from "../component/road-map/SbRoadMap";
import Icon from "../../../components-game/navigator/icon/Icon";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";
import VChips from "../../../components-game/chips-set/new-vertical/VChips";

function VSicbo() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { windowWidth } = useContext(AuthContext);
    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        winList,
        labelBet,
        setLabelBet,
        actionMsg,
        betContainerClass,
        chatMsg,
    } = useSicbo();

    const [openTableList, setOpenTableList] = useState(false);

    if (isFirstLoad) {
        return <LoadingPage />;
    }
    return (
        <div
            style={{ background: `linear-gradient(to bottom, ${tableDetail?.color?.split(",")[0]}, ${tableDetail?.color?.split(",")[1]})` }}
            id="vertical-sicbo"
        >
            <div className="header-container">
                <div className="header-title">
                    <div className="header-btn" onClick={() => navigate(-1)}>
                        <Icon image={back} />
                    </div>
                    {tableDetail?.tableName}
                </div>
                <div className="header-menu">
                    {streamingObj && (
                        <CFBtnView
                            BtnTable={true}
                            BtnGraphic={true}
                            connection={connection}
                            GraphicObj={streamingObj}
                            setOpenTableList={setOpenTableList}
                            tableDetail={tableDetail}
                            chatMsg={chatMsg}
                        />
                    )}
                </div>
            </div>

            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <div className="id-wrapper">
                        <div className="uid">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</div>
                        <div className="uid">ID: {userData?.playerID ? userData.playerID : "-"}</div>
                    </div>
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />

                    <div className="win-list-wrapper" style={{ display: winList ? "block" : "none" }}>
                        {winList && <WinList winList={winList} />}
                    </div>

                    {dealerSignal?.second ? (
                        <div className="timer-container">
                            <Timer dealerSignal={dealerSignal} />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="road-map-wrapper">
                        <SbRoadMap Type="B" Data={roadMaps?.data?.route_1} />
                    </div>
                </div>
            </Spin>

            {windowWidth <= 700 ? (
                <div className="bet-panel-container">
                    {betItems && (
                        <VBetPanel
                            tableInfo={tableInfo}
                            connection={connection}
                            chipArr={chipsArr}
                            rate={betItems}
                            tableDetail={tableDetail}
                            dealerSignal={dealerSignal}
                            setTotalBet={setTotalBet}
                            setLabelBet={setLabelBet}
                            betContainerClass={betContainerClass}
                        />
                    )}
                </div>
            ) : (
                <div className="bet-panel-container">
                    {betItems && (
                        <HBetPanel
                            tableInfo={tableInfo}
                            connection={connection}
                            chipArr={chipsArr}
                            rate={betItems}
                            tableDetail={tableDetail}
                            dealerSignal={dealerSignal}
                            setTotalBet={setTotalBet}
                            setLabelBet={setLabelBet}
                            betContainerClass={betContainerClass}
                        />
                    )}
                </div>
            )}
            <div className={`action-msg-container ${actionMsg?.text}`}>
                <ActionMessage
                    text={actionMsg?.text}
                    bgColor={actionMsg?.bgColor}
                    totalWin={totalBet}
                    showWinAmount={actionMsg?.showWinAmount}
                    result={actionMsg?.result}
                />
            </div>

            <div className="chips-set-container">
                {chipsArr && (
                    <VChips
                        setLabelBet={setLabelBet}
                        connection={connection}
                        tableInfo={tableInfo}
                        ChipsSet={chipsArr}
                        tableDetail={tableDetail}
                        dealerSignal={dealerSignal}
                        totalBet={totalBet}
                        setTotalBet={setTotalBet}
                        roadMaps={roadMaps}
                    />
                )}
            </div>

            <div className="footer-container">
                <div className="container-01">
                    <span className="lbl lbl-total-bet">
                        {t(labelBet)}: {userData?.currency + " " + formatNumber(totalBet)}
                    </span>
                    <span className="lbl lbl-balance">Balance: {userData?.currency + " " + formatNumber(balance)}</span>
                </div>

                <div className="container-02">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</div>
            </div>

            <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />
            {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default VSicbo;
