import { Descriptions, Space, Table, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph, Text } = Typography;

function GameRules() {
    const { t } = useTranslation();

    const data = [];
    for (let i = 0; i < 10; i++) {
        data.push({
            key: i,
            banker: i,
            player1: i > 6 ? "S" : "D",
            player2: i > 4 ? "S" : "D",
            player3: i > 4 ? "S" : "D",
            player4: i > 5 ? "S" : "D",
            player5: i > 5 ? "S" : "D",
            player6: i > 6 ? "S" : "D",
            player7: i > 6 ? "S" : "D",
            player8: i > 7 ? "S" : "D",
            player9: i > 7 ? "S" : "D",
            player10: i > 3 ? "S" : "D",
            player11: i > 4 ? "S" : "D",
        });
    }

    const columns = [
        {
            title: "Banker's initial two-card hand",
            dataIndex: "banker",
            key: "banker",
            align: "center",
        },
        {
            title: "Value of third card drawn by Player",
            align: "center",
            children: [
                {
                    title: "No third card",
                    dataIndex: "player1",
                    align: "center",
                },
                {
                    title: "0",
                    dataIndex: "player2",
                    align: "center",
                },
                {
                    title: "1",
                    dataIndex: "player3",
                    align: "center",
                },
                {
                    title: "2",
                    dataIndex: "player4",
                    align: "center",
                },
                {
                    title: "3",
                    dataIndex: "player5",
                    align: "center",
                },
                {
                    title: "4",
                    dataIndex: "player6",
                    align: "center",
                },
                {
                    title: "5",
                    dataIndex: "player7",
                    align: "center",
                },
                {
                    title: "6",
                    dataIndex: "player8",
                    align: "center",
                },
                {
                    title: "7",
                    dataIndex: "player9",
                    align: "center",
                },
                {
                    title: "8",
                    dataIndex: "player10",
                    align: "center",
                },
                {
                    title: "9",
                    dataIndex: "player11",
                    align: "center",
                },
            ],
        },
    ];
    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("gameRules1")}</Paragraph>
                <Paragraph>
                    <ul>
                        <li>
                            <Text>{t("gameRules2")}</Text>
                        </li>
                        <li>
                            <Text>{t("gameRules3")}</Text>
                        </li>
                        <li>
                            <Text>{t("gameRules4")}</Text>
                        </li>
                    </ul>
                </Paragraph>
                <Paragraph>{t("gameRules5")}</Paragraph>
                <Paragraph>{t("gameRules6")}</Paragraph>
                <Paragraph>{t("gameRules7")}</Paragraph>
                <Paragraph>{t("gameRules8")}</Paragraph>
                <Paragraph>{t("gameRules9")}</Paragraph>
                <Paragraph>{t("gameRules10")}</Paragraph>
                <Paragraph>{t("gameRules11")}</Paragraph>
                <Paragraph>{t("gameRules12")}</Paragraph>
                <Paragraph>{t("gameRules13")}</Paragraph>
                <Paragraph>{t("gameRules14")}</Paragraph>
                <Paragraph>
                    <Text>{t("gameRules15")}</Text>
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label={t("gameRules16")}></Descriptions.Item>
                        <Descriptions.Item label="0-1-2-3-4-5">{t("gameRules17")}</Descriptions.Item>
                        <Descriptions.Item label="6-7">{t("gameRules18")}</Descriptions.Item>
                        <Descriptions.Item label="8-9 (a 'natural')">{t("gameRules19")}</Descriptions.Item>
                    </Descriptions>
                </Paragraph>
                <Paragraph>
                    <Text>{t("gameRules20")}</Text>
                    <Table columns={columns} dataSource={data} bordered pagination={false} />
                </Paragraph>
                <Paragraph>{t("gameRules21")}</Paragraph>
                <Paragraph>{t("gameRules22")}</Paragraph>
                <Paragraph>{t("gameRules23")}</Paragraph>
                <Paragraph>{t("gameRules27")}</Paragraph>
                <Paragraph>{t("gameRules28")}</Paragraph>
                <Paragraph>{t("gameRules29")}</Paragraph>
                <Paragraph>{t("gameRules30")}</Paragraph>
                <Paragraph>{t("gameRules31")}</Paragraph>
                <Paragraph>{t("gameRules32")}</Paragraph>
                <Paragraph>{t("gameRules33")}</Paragraph>
                <Paragraph>{t("gameRules34")}</Paragraph>
                <Paragraph>{t("gameRules35")}</Paragraph>
                <Paragraph>{t("gameRules36")}</Paragraph>
                <Paragraph>{t("gameRules37")}</Paragraph>
                <Paragraph>{t("gameRules38")}</Paragraph>
                <Paragraph>{t("gameRules39")}</Paragraph>
            </Space>
        </Typography>
    );
}

export default GameRules;
