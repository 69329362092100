import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useFourSeason } from "../hook/useFourSeason";

import "./VFourSeason.scss";

import CFBtnView from "../../../components-game/navigator/CFBtnView";
import { Spin } from "antd";
import Timer from "../../../components-game/timer/Timer";
import BetPanel from "../component/bet-panel/BetPanel";
import RoadMap from "../component/road-map/RoadMap";
import Icon from "../../../components-game/navigator/icon/Icon";
import TableList from "../../../components-game/table-list/TableList";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import { formatNumber } from "../../../utils/Formatter";
import table from "../../../assets/game/common/table.png";
import ReviewCard from "../component/reveal-card/ReviewCard";
import Dot from "../component/road-map/dots/Dot";
import ActionMessage from "../../../components-game/action-message/ActionMessage";
import LoadingPage from "../../../pages/loading-page/LoadingPage";
import BankerCard from "../component/banker-card/BankerCard";
import VerticalReviewCard from "../component/reveal-card/vertical/VerticalReviewCard";
import back from "../../../assets/game/common/back.png";
import { useNavigate } from "react-router-dom";
import VChips from "../../../components-game/chips-set/new-vertical/VChips";
import WinList from "../../../components-game/win-list/WinList";

function VFourSeason() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tableInfo, isFirstLoad, isLiveLoading, userData, balance, connection, chipsArr, tableDetail, betItems, roadMaps, streamingObj, dealerSignal, totalBet, setTotalBet, betStatistic, winList, cardResult, labelBet, setLabelBet, actionMsg, reviewCard, setReviewCard, jackpotDescription, setJackpotDescription, chatMsg } = useFourSeason();

  const [openTableList, setOpenTableList] = useState(false);

  if (isFirstLoad) {
    return <LoadingPage />;
  }

  return (
    <div
      style={{
        background: `linear-gradient(to bottom, ${tableDetail?.color?.split(",")[0]}, ${tableDetail?.color?.split(",")[1]})`,
      }}
      className="vertical-four-season"
    >
      <div className="header-container">
        <div className="header-title">
          <div className="header-btn" onClick={() => navigate(-1)}>
            <Icon image={back} />
          </div>
          {tableDetail?.tableName}
        </div>
        <div className="header-menu">{streamingObj && <CFBtnView BtnTable={true} BtnGraphic={true} connection={connection} GraphicObj={streamingObj} setOpenTableList={setOpenTableList} tableDetail={tableDetail} chatMsg={chatMsg} />}</div>
      </div>

      <Spin spinning={isLiveLoading}>
        <div className="live-streaming-container">
          <div className="id-wrapper">
            <div className="uid">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</div>
            <div className="uid">ID: {userData?.playerID ? userData.playerID : "-"}</div>
          </div>
          <canvas id="video" style={{ width: "100%", height: "100%" }} />
          <div className="win-list-wrapper" style={{ display: winList ? "block" : "none" }}>
            {winList && <WinList winList={winList} />}
          </div>

          {dealerSignal?.second && (
            <div className="timer-container">
              <Timer dealerSignal={dealerSignal} />
            </div>
          )}

          <div className="banker-card-wrapper">
            <div className="title" style={{ display: cardResult ? "block" : "none" }}>
              {t("bankerCard")}
            </div>
            {cardResult && <BankerCard tableDetail={tableDetail} dealerSignal={dealerSignal} cardResult={cardResult[0]} />}
          </div>
        </div>
      </Spin>

      <div className="bet-panel-container">{betItems && <BetPanel tableInfo={tableInfo} connection={connection} chipArr={chipsArr} rate={betItems} tableDetail={tableDetail} dealerSignal={dealerSignal} setTotalBet={setTotalBet} betStatistic={betStatistic} setLabelBet={setLabelBet} cardResult={cardResult ? cardResult : []} betContainerClass="" jackpotDescription={jackpotDescription} setJackpotDescription={setJackpotDescription} />}</div>

      <div className={`action-msg-container ${actionMsg}`}>
        <ActionMessage text={actionMsg} color="gold" totalWin={totalBet} />
      </div>

      {dealerSignal?.action === "stop-bet" && cardResult && reviewCard && <VerticalReviewCard tableDetail={tableDetail} result={cardResult} setReviewCard={setReviewCard} />}

      <div className="chips-set-container">{chipsArr && <VChips setLabelBet={setLabelBet} connection={connection} tableInfo={tableInfo} ChipsSet={chipsArr} tableDetail={tableDetail} dealerSignal={dealerSignal} totalBet={totalBet} setTotalBet={setTotalBet} roadMaps={roadMaps} />}</div>
      <div className="footer-container">
        <div className="container-01">
          <span className="lbl lbl-total-bet">
            {t(labelBet)}: {userData?.currency + " " + formatNumber(totalBet)}
          </span>
          <span className="lbl lbl-balance">Balance: {userData?.currency + " " + formatNumber(balance)}</span>
        </div>

        <div className="container-02">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</div>
      </div>
      <div className="road-maps-container">
        <div className="road-01">
          <RoadMap Data={roadMaps?.data?.route_1} />
        </div>
      </div>
      <div className="road-map-statistic-container">
        <div className="statistic-count">
          <div className="lbl lbl-title">{`${t("last")} ${roadMaps?.data2?.total} ${t("result")}`}</div>

          <div className="lbl">
            <div className="lbl-dot">
              <Dot FillColor="#3788ff" text2="P1" Jackpot bankerPlayer LineBottom={false} LineRight={false} />
            </div>
            <div className="content">{roadMaps?.data2?.player_1}</div>
          </div>

          <div className="lbl">
            <div className="lbl-dot">
              <Dot FillColor="#3788ff" text2="P2" Jackpot bankerPlayer LineBottom={false} LineRight={false} />
            </div>
            <div className="content">{roadMaps?.data2?.player_2}</div>
          </div>

          <div className="lbl">
            <div className="lbl-dot">
              <Dot FillColor="#3788ff" text2="P3" Jackpot bankerPlayer LineBottom={false} LineRight={false} />
            </div>
            <div className="content">{roadMaps?.data2?.player_3}</div>
          </div>
        </div>
      </div>

      <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />

      {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
    </div>
  );
}

export default VFourSeason;
