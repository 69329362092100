import React from "react";

import "./SbRoadMap.scss";
import Dot from "../../../baccarat/component/road-map/dots/Dot";

function SbRoadMap({ Type = "A", Data }) {
  const typeA = () => (
    <div className="sb-road-map-A">
      {Data?.map((items, index) => {
        if (index < 7) {
          return (
            <div key={index} className="road-map-item">
              <div className="item total">{items[0]}</div>
              <div className="item dice dice-1">{items[1]}</div>
              <div className="item dice dice-2">{items[2]}</div>
              <div className="item dice dice-3">{items[3]}</div>
            </div>
          );
        }
      })}
    </div>
  );

  const typeB = () => (
    <div className="sb-road-map-B">
      {Data?.map((items, index) => (
        <div className="item" key={index}>
          {items[0]}
        </div>
      ))}
    </div>
  );

  const typeC = () => {
    let StrokeRight = true;

    return (
      <div className="sb-road-map-C">
        {Data?.map((row, index) => {
          StrokeRight = index < Data?.length - 1;

          return (
            <div className="row-item" key={index + row}>
              {row?.map((col, index) => {
                const value = col?.split("-");
                const FillColor = value[0] === "B" ? "#ee4043" : value[0] === "S" ? "#3788ff" : value[0] === "T" ? "#52c41a" : "none";

                return (
                  <div className="col-item" key={index + col}>
                    <Dot StrokeColor="none" FillColor={FillColor} text={value[0] !== "T" ? value[1] : value[0]} textColor={value[0] === "T" ? "#000" : "#ddd"} LineRight={StrokeRight} LineBottom={index < row.length - 1} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return Type === "A" ? typeA() : Type === "B" ? typeB() : Type === "C" ? typeC() : undefined;
}

export default SbRoadMap;
