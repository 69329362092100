import React from "react";
import "./rt-how-to-play.scss";

function RtHowToPlay() {
    const translation = {
        gb: (
            <div className="rt-how-to-play">
                <div className="section">
                    <div className="title">Game Rules:</div>
                    <div className="content">
                        <div>
                            The objective in Roulette is to predict the number on which the ball will land by placing one or more bets that cover that
                            particular number. The wheel in Roulette includes the numbers 1-36 plus a single 0 (zero).
                        </div>
                        <div>
                            After betting time has expired, the ball is spun within the Roulette wheel. The ball will eventually come to rest in one
                            of the numbered pockets within the wheel. You win if you have placed a bet that covers that particular number.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Bet Types:</div>
                    <div className="content">
                        <div>
                            You can place many different kinds of bets on the Roulette table. Bets can cover a single number or a certain range of
                            numbers, and each type of bet has its own payout rate.
                        </div>
                        <div>
                            Bets made on the numbered spaces on the betting area or the lines between them are called Inside Bets, while bets made on
                            the special boxes below and to the side of the main grid of numbers are called Outside Bets.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Inside Bets:</div>
                    <div className="content">
                        <ul>
                            <li>Straight Up — place your chip directly on any single number (including zero).</li>
                            <li>Split Bet — place your chip on the line between any two numbers, either on the vertical or horizontal.</li>
                            <li>Street Bet — place your chip at the end of any row of numbers. A Street Bet covers three numbers.</li>
                            <li>
                                Corner Bet — place your chip at the corner (central intersection) where four numbers meet. All four numbers are
                                covered.
                            </li>
                            <li>
                                Line Bet — place your chip at the end of two rows on the intersection between the two rows. A line bet covers all the
                                numbers in both rows, a total of six numbers.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Outside Bets:</div>
                    <div className="content">
                        <ul>
                            <li>
                                Column Bet — place your chip in one of the boxes marked "2 to 1" at the end of the column that covers all 12 numbers
                                in that column. The zero is not covered by any column bet.
                            </li>
                            <li>
                                Dozen Bet — place your chip in one of the three boxes marked "1st 12," "2nd 12" or "3rd 12" to cover the 12 numbers
                                alongside the box.
                            </li>
                            <li>
                                Red/Black — place your chip in the Red or Black box to cover the 18 red or 18 black numbers. The zero is not covered
                                by these bets.
                            </li>
                            <li>
                                Even/Odd — place your chip in one of these boxes to cover the 18 even or 18 odd numbers. The zero is not covered by
                                these bets.
                            </li>
                            <li>
                                1-18/19-36 — place your chip in either of these boxes to cover the first or second set of 18 numbers. The zero is not
                                covered by these bets.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Neighbour Bets:</div>
                    <div className="content">
                        <div>
                            Click/tap the NEIGHBOUR BETS button to view a special oval or racetrack-shaped betting area that allows you to place
                            neighbour bets and other special bets more easily. Re-click/tap the button to close/re-open this feature.
                        </div>
                        <div>Each bet covers a different set of numbers and offers different payout odds. Bet spots will be highlighted.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Tiers du Cylindre:</div>
                    <div className="content">
                        <div>
                            This bet covers a total of 12 numbers that include 27, 33, and the numbers that lie between them on the side of the
                            Roulette wheel opposite to zero. 6 chips are placed as follows:
                        </div>
                        <ul>
                            <li>1 chip on the 5/8 split</li>
                            <li>1 chip on the 10/11 split</li>
                            <li>1 chip on the 13/16 split</li>
                            <li>1 chip on the 23/24 split</li>
                            <li>1 chip on the 27/30 split</li>
                            <li>1 chip on the 33/36 split</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Voisins du Zero:</div>
                    <div className="content">
                        <div>
                            This bet covers a total of 17 numbers that include 22, 25, and the numbers that lie between them on the side of the
                            Roulette wheel that contains zero. 9 chips are placed as follows:
                        </div>
                        <ul>
                            <li>2 chips on the 0/2/3 street</li>
                            <li>1 chip on the 4/7 split</li>
                            <li>1 chip on the 12/15 split</li>
                            <li>1 chip on the 18/21 split</li>
                            <li>1 chip on the 19/22 split</li>
                            <li>2 chips on the 25/26/28/29 corner</li>
                            <li>1 chip on the 32/35 split</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Orphelins a Cheval:</div>
                    <div className="content">
                        <div>
                            This bet covers a total of 8 numbers on the two segments of the Roulette wheel not covered by the voisins du zero and
                            tiers du cylindre bets above. 5 chips are placed as follows:
                        </div>
                        <ul>
                            <li>1 chip on 1 (straight up)</li>
                            <li>1 chip on the 6/9 split</li>
                            <li>1 chip on the 14/17 split</li>
                            <li>1 chip on the 17/20 split</li>
                            <li>1 chip on the 31/34 split</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Jeu Zero:</div>
                    <div className="content">
                        <div>
                            This bet covers zero and the 6 numbers in close proximity to zero on the Roulette wheel: 12, 35, 3, 26, 0, 32, and 15. 4
                            chips are placed as follows:
                        </div>
                        <ul>
                            <li>1 chip on the 0/3 split</li>
                            <li>1 chip on the 12/15 split</li>
                            <li>1 chip on 26 (straight up)</li>
                            <li>1 chip on the 32/35 split</li>
                        </ul>
                        <div>
                            A neighbour bet covers a particular number as well as other numbers that lie in close proximity to it on the Roulette
                            wheel. To place a neighbour bet, click/tap a specific number on the racetrack. A chip will be placed on the chosen number
                            and on numbers that neighbour it to the right and left. Click/tap on the circular "-" or "+" button to increase or
                            decrease the set of neighbours to the right and left of the chosen number.
                        </div>
                    </div>
                </div>
                {/* split 1 */}
                <div className="section">
                    <div className="title">Favourite & Special Bets:</div>
                    <div className="content">
                        <div className="content-title">FAVOURITE BETS</div>
                        <div>
                            The optional Favourite Bets feature allows you to save a preferred bet or combination of different types of bets for
                            easier placement in future rounds at any Roulette table. You can save and edit a list of up to 30 of your favourite bets
                            under different names.
                        </div>
                        <div className="content-title">SAVE A FAVOURITE BET</div>
                        <div>
                            To open the Favourite Bets menu, click/tap the FAVOURITE BETS button. Re-click/tap the button to close this feature.
                        </div>
                        <div>
                            After you have placed a favourite bet or combination of bets on the Roulette table, click/tap the SAVE LAST BET link in
                            the Favourite Bets menu. A default name for this bet will be suggested, but you are welcome to enter a more easily
                            recognisable name. You can then save and add this bet to your list of favourite bets by clicking/tapping the SAVE button
                            or by pressing “Enter” on your keyboard.
                        </div>
                        <div className="content-title">PLACE A FAVOURITE BET</div>
                        <div>
                            When you wish to place a favourite bet during the betting phase of a Roulette round, open the Favourite Bets menu to view
                            a list of all bets you previously saved. They will be listed in chronological order, with the favourite bet you saved
                            earliest listed first. You can hover your cursor over the name of any listed bet to see how chips will be placed on the
                            Roulette table accordingly. Click/tap the name of each desired bet to place it. You can also multiply (double, triple,
                            quadruple...) the amount of any favourite bet you have placed by clicking/tapping its name more than once.
                        </div>
                        <div className="content-title">RENAME OR DELETE A FAVOURITE BET</div>
                        <div>When the Favourite Bets menu is open, you can click/tap the EDIT button to delete or rename any listed bet.</div>
                        <div>
                            Rename any listed bet by first clicking/tapping within the grey text box that surrounds its current name. You can then
                            enter a new name and save it by clicking/tapping the SAVE button or by pressing “Enter” on your keyboard.
                        </div>
                        <div>
                            Delete any bet you no longer wish to keep in your list of favourite bets by clicking/tapping its respective DELETE button.
                        </div>
                        <div>
                            When you are finished editing your list of favourite bets, click/tap the SAVE button in the top right corner of the
                            Favourite Bets menu or click/tap the FAVOURITE BETS button.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Special Bets:</div>
                    <div className="content">
                        <div>Under the second tab in Favourite Bets, you can place Finale en plein and Finale a cheval bets more easily.</div>
                        <div className="content-title">Finale en plein</div>
                        <ul>
                            <li>Finale en plein 0 — 4-chip bet covers 0+10+20+30, each with 1 chip</li>
                            <li>Finale en plein 1 — 4-chip bet covers 1+11+21+31, each with 1 chip</li>
                            <li>Finale en plein 2 — 4-chip bet covers 2+12+22+32, each with 1 chip</li>
                            <li>Finale en plein 3 — 4-chip bet covers 3+13+23+33, each with 1 chip</li>
                            <li>Finale en plein 4 — 4-chip bet covers 4+14+24+34, each with 1 chip</li>
                            <li>Finale en plein 5 — 4-chip bet covers 5+15+25+35, each with 1 chip</li>
                            <li>Finale en plein 6 — 4-chip bet covers 6+16+26+36, each with 1 chip</li>
                            <li>Finale en plein 7 — 3-chip bet covers 7+17+27, each with 1 chip</li>
                            <li>Finale en plein 8 — 3-chip bet covers 8+18+28, each with 1 chip</li>
                            <li>Finale en plein 9 — 3-chip bet covers 9+19+29, each with 1 chip</li>
                        </ul>
                        <div className="content-title">Finale a cheval</div>
                        <ul>
                            <li>Finale a cheval 0/3 — 4-chip bet covers 0/3+10/13+20/23+30/33, each with 1 chip</li>
                            <li>Finale a cheval 1/4 — 4-chip bet covers 1/4+11/14+21/24+31/34, each with 1 chip</li>
                            <li>Finale a cheval 2/5 — 4-chip bet covers 2/5+12/15+22/25+32/35, each with 1 chip</li>
                            <li>Finale a cheval 3/6 — 4-chip bet covers 3/6+13/16+23/26+33/36, each with 1 chip</li>
                            <li>Finale a cheval 4/7 — 4-chip bet covers 4/7+14/17+24/27+34, each with 1 chip</li>
                            <li>Finale a cheval 5/8 — 4-chip bet covers 5/8+15/18+25/28+35, each with 1 chip</li>
                            <li>Finale a cheval 6/9 — 4-chip bet covers 6/9+16/19+26/29+36, each with 1 chip</li>
                            <li>Finale a cheval 7/10 — 3-chip bet covers 7/10+17/20+27/30, each with 1 chip</li>
                            <li>Finale a cheval 8/11 — 3-chip bet covers 8/11+18/21+28/31, each with 1 chip</li>
                            <li>Finale a cheval 9/12 — 3-chip bet covers 9/12+19/22+29/32, each with 1 chip</li>
                        </ul>
                        <div className="content-title">Complete Bets</div>
                        <div>A Complete Bet places all of the inside bets on a specific number.</div>
                        <div>
                            For example, a Complete Bet on number 36 will place 18 chips to completely cover it, as follows: 1 chip on Straight Up 36,
                            2 chips on each of Split bets 33/36 and 35/36, 3 chips on Street bet 34/35/36, 4 chips on 32/33/35/36 Corner bet and 6
                            chips on Line bet 31/32/33/34/35/36.
                        </div>
                        <div className="content-title">Straight Up Bets</div>
                        <div>
                            Straight Up Bets place all straight bets of the selected type (Red, Black, Odd, Even etc.), covering each number with one
                            chip.
                        </div>
                        <div>For example, Straight Up Bet RED will place 18 chips to cover all red numbers.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Winning Numbers:</div>
                    <div className="content">
                        <div>The WINNING NUMBERS display shows the most recent winning numbers.</div>
                        <div>The result of the most recently completed round is listed on the left.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Your recent results:</div>
                    <div className="content">
                        <div>
                            Click/tap STATISTICS button to view a table of the winning numbers in up to 500 of the most recent game rounds. Use the
                            slider to alter the number of past rounds to apply it.
                        </div>
                        <div>
                            When you hover your cursor over any part of the statistics diagram, the spot on the betting table where a chip would be
                            placed is highlighted. Simply click/tap the bet to place your chip.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Payouts:</div>
                    <div className="content">
                        <div>Your payout depends on the type of placed bet.</div>
                        <div className="content-title">INSIDE BETS</div>
                        <div>BET TYPE PAYOUT</div>
                        <table>
                            <tr>
                                <th>Straight Up</th>
                                <th>35:1</th>
                            </tr>
                            <tr>
                                <th>Split</th>
                                <th>17:1</th>
                            </tr>
                            <tr>
                                <th>Street</th>
                                <th>11:1</th>
                            </tr>
                            <tr>
                                <th>Corner</th>
                                <th>8:1</th>
                            </tr>
                            <tr>
                                <th>Line</th>
                                <th>5:1</th>
                            </tr>
                        </table>
                        <div className="content-title">OUTSIDE BETS</div>
                        <div>BET TYPE PAYOUT</div>
                        <table>
                            <tr>
                                <th>Column</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>Dozen</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>Red/Black</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>Even/Odd</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>1-18/19/36</th>
                                <th>1:1</th>
                            </tr>
                        </table>
                        <div>Malfunction voids all pays and play.</div>
                    </div>
                </div>
                {/* split 2 */}
                <div className="section">
                    <div className="title">Return to Player:</div>
                    <div className="content">
                        <div>The optimal theoretical return-to-player is 97.30%.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Place Bets:</div>
                    <div className="content">
                        <div>
                            The BET LIMITS panel shows the minimum and maximum allowed bet limits at the table, which may change from time to time.
                            Open the Bet Limits to check your current limits.
                        </div>
                        <div>Emperor Roulette MYR 2.50 - 10,000</div>
                        <div>
                            To participate in the game, you must have sufficient funds to cover your bets. You can see your current BALANCE on your
                            screen.
                        </div>
                        <div>BALANCE MYR 100,000</div>
                        <div>
                            In the Immersive view, the TIMER informs you of the duration of betting time. After it expires, betting is closed and no
                            more bets are accepted.
                        </div>
                        <div>
                            In the Classic view, the TRAFFIC LIGHTS tell you the current status in the game round by informing you when you can bet
                            (GREEN light), when betting time is nearly over (YELLOW light), and when betting time has expired (RED light).
                        </div>
                        <div>PLACE YOUR BETS</div>
                        <div>
                            The CHIP DISPLAY allows you to select the value of each chip you wish to bet. Only chips of denominations that can be
                            covered by your current balance will be enabled.
                        </div>
                        <div>
                            Once you have selected a chip, place your bet by simply clicking/tapping the appropriate bet spot on the game table. Each
                            time you click/tap the bet spot, the amount of your bet increases by the value of the selected chip or up to the maximum
                            limit for the type of bet you have selected. Once you have bet the maximum limit, no additional funds will be accepted for
                            that bet, and a message will appear above your bet to notify you that you have bet the maximum.
                        </div>
                        <div>
                            NOTE: Please do not minimise your browser or open any other tab in your browser while betting time remains and you have
                            placed bets on the table. Such actions may be interpreted as leaving the game, and your bets will therefore be declined
                            for that particular game round.
                        </div>
                        <div>
                            The DOUBLE (2x) button becomes available after you have placed any bet. Each click/tap doubles all your bets up to the
                            maximum limit. Note that you must have a sufficient account balance to double ALL your placed bets.
                        </div>
                        <div>
                            The REPEAT button allows you to repeat all bets from the previous game round. This button is available only before the
                            first chip is placed.
                        </div>
                        <div>The UNDO button removes the last bet you placed.</div>
                        <div>
                            You can click/tap the UNDO button repeatedly to remove bets, one by one, in the reverse order in which they were placed.
                            You can clear all your bets by holding the UNDO button.
                        </div>
                        <div>The TOTAL BET indicator displays the total amount of all bets placed in the current round.</div>
                        <div>TOTAL BET MYR 500</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Live Support:</div>
                    <div className="content">
                        <div>Contact Live Support for game-related inquiries.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Disconnection Policy:</div>
                    <div className="content">
                        <div>
                            If you are disconnected from a game round, any placed bets remain valid and are settled in your absence. Upon
                            reconnecting, you can view the bet outcomes in the History window.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Autoplay:</div>
                    <div className="content">
                        <div>
                            Once you have placed a bet, Autoplay allows you to repeat your selected bet or bets for a chosen number of game rounds.
                        </div>
                        <div>To start Autoplay, place your bets and then click/tap the Autoplay button.</div>
                        <div>
                            In the Autoplay panel, you can start Autoplay by selecting the number of rounds you want your bets to be repeated for.
                        </div>
                        <div>
                            Your selected number of Autoplay rounds will be displayed on the Autoplay button. The number of remaining Autoplay rounds
                            will be updated, once Autoplay is started.
                        </div>
                        <div>Autoplay BET PER ROUND MYR 10</div>
                        <div>
                            Your total Autoplay bet amount is calculated by multiplying the number of Autoplay rounds with the value of your total
                            bet. It is indicated under each Autoplay rounds card. Your remaining Autoplay limit will also be displayed under each
                            Autoplay rounds card, once Autoplay has started.
                        </div>
                        <div>
                            Your automatic game rounds will continue until either your chosen number of Autoplay rounds are complete, or you choose to
                            stop Autoplay by clicking/tapping STOP.
                        </div>
                        <div>STOP</div>
                        <div>
                            When Autoplay is stopped, you will be able to repeat previously selected bets and rounds by clicking/tapping REPEAT.
                        </div>
                        <div>REPEAT</div>
                        <div>
                            If you place additional bets on the betting grid or double your bets while Autoplay is running, a message will appear that
                            will allow you to choose to continue Autoplay with your changed bets. If you don't confirm to continue, Autoplay will be
                            stopped.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Chat:</div>
                    <div className="content">
                        <div>Chat with a game presenter and other players.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Game Number:</div>
                    <div className="content">
                        <div>Each game round is identified by a unique GAME NUMBER.</div>
                        <div>
                            This number reflects when the game round began in terms of GMT by hour: minute: second. Please use this game number for
                            reference (or take a screenshot of the game number) if you wish to contact Customer Service regarding a particular round.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Sound:</div>
                    <div className="content">
                        <div>Sound controls all sounds in the game.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Game History:</div>
                    <div className="content">
                        <div>Game History displays your Inno game rounds and results.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Settings:</div>
                    <div className="content">
                        <div>Settings let you customize user preferences that are saved to your profile.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Error Handling:</div>
                    <div className="content">
                        <div>
                            If there is an error in the game, system or game procedure, the game round will be temporarily paused while the game host
                            notifies the service manager. You and other players will be notified via Chat, or by an on-screen pop-up message, that the
                            issue is being investigated. If the manager can immediately resolve the error, the game round will continue as normal. If
                            immediate resolution is not possible, the game round will be cancelled, and the initial bets will be refunded to all the
                            players who participated in the game round.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Roulette Valid Spin Rule:</div>
                    <div className="content">
                        <div>The winning number is valid only when the spin was deemed valid.</div>
                        <div>
                            To constitute a valid spin the Roulette ball must be spun by the dealer in the direction opposite to the rotation of the
                            wheel and must complete at least 3 full revolutions around the track of the wheel before dropping and coming to rest in a
                            numbered pocket on the wheel.
                        </div>
                        <div>An invalid spin will be declared in the following cases:</div>
                        <ul>
                            <li>The ball made less than 3 full revolutions</li>
                            <li>The ball was spun in the same direction as the wheel rotation</li>
                            <li>The wheel stopped rotating during the spin</li>
                            <li>The ball came out of the wheel during the spin</li>
                            <li>Any foreign object entered the wheel during the spin.</li>
                        </ul>
                        <div>In the case of an invalid spin, the dealer will perform a re-spin.</div>
                        <div>In the case of any irregularities, please provide the Game Number of the round in question to Live Support.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">More Games:</div>
                    <div className="content">
                        <div>Inno Lobby — easy game selection without exiting your current game until a new game is chosen.</div>
                    </div>
                </div>
            </div>
        ),
        cn: (
            <div className="rt-how-to-play">
                <div className="section">
                    <div className="title">游戏规则：</div>
                    <div className="content">
                        <div>在轮盘中的目标是通过下注来预测小球最终停在哪个数字上。轮盘包括1-36的数字和一个单独的0（零）。</div>
                        <div>下注时间结束后，小球在轮盘中旋转。小球最终会停在轮盘内的某个编号口袋中。如果你下注的范围包括该数字，你就赢了。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">下注类型：</div>
                    <div className="content">
                        <div>你可以在轮盘桌上下许多不同类型的注。下注可以涵盖一个数字或一定范围的数字，每种类型的注都有自己的支付率。</div>
                        <div>在投注区域的数字空间或它们之间的线上下的注被称为内部注，而在主要数字网格下方和侧面的特殊框内下的注被称为外部注。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">内部注：</div>
                    <div className="content">
                        <ul>
                            <li>直注 - 在任何单个数字上（包括零）直接放置筹码。</li>
                            <li>分注 - 在任何两个数字之间的线上，可以是垂直或水平方向上放置筹码。</li>
                            <li>街注 - 在任何一排数字的末尾放置筹码。街注包含三个数字。</li>
                            <li>角注 - 在四个数字交汇的角落（中央交叉点）放置筹码。所有四个数字都被覆盖。</li>
                            <li>线注 - 在两排之间的交叉点上放置筹码。线注覆盖这两排的所有数字，总共六个数字。</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">外部注：</div>
                    <div className="content">
                        <ul>
                            <li>列注 - 在覆盖该列中的所有12个数字的列末尾标有“2赔1”的框中放置筹码。零不包含在任何列注中。</li>
                            <li>打注 - 在标有“1st 12”、“2nd 12”或“3rd 12”的三个框中放置筹码，以覆盖框旁边的12个数字。</li>
                            <li>红/黑 - 在红色或黑色框中放置筹码，以覆盖18个红色或18个黑色数字。零不包含在这些下注中。</li>
                            <li>单/双 - 在这些框中放置筹码，以覆盖18个偶数或18个奇数数字。零不包含在这些下注中。</li>
                            <li>1-18/19-36 - 在这两个框中放置筹码，以覆盖前18个数字或后18个数字。零不包含在这些下注中。</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">邻位下注：</div>
                    <div className="content">
                        <div>
                            点击/轻点“邻位下注”按钮，查看一个特殊的椭圆形或赛马场形状的投注区域，使您更轻松地放置邻位下注和其他特殊下注。再次点击/轻点按钮以关闭/重新打开此功能。
                        </div>
                        <div>每个下注涵盖不同的数字集，并提供不同的支付赔率。下注位置将被突出显示。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">轮筒部分：</div>
                    <div className="content">
                        <div>该下注涵盖总共12个数字，包括27、33以及与它们相邻的轮盘上零的对面的数字。共放置6个筹码，方式如下：</div>
                        <ul>
                            <li>在5/8交界处放置1个筹码</li>
                            <li>在10/11交界处放置1个筹码</li>
                            <li>在13/16交界处放置1个筹码</li>
                            <li>在23/24交界处放置1个筹码</li>
                            <li>在27/30交界处放置1个筹码</li>
                            <li>在33/36交界处放置1个筹码</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">零的邻位：</div>
                    <div className="content">
                        <div>该下注涵盖总共17个数字，包括22、25以及它们之间的轮盘上包含零的一侧的数字。共放置9个筹码，方式如下：</div>
                        <ul>
                            <li>在0/2/3街上放置2个筹码</li>
                            <li>在4/7交界处放置1个筹码</li>
                            <li>在12/15交界处放置1个筹码</li>
                            <li>在18/21交界处放置1个筹码</li>
                            <li>在19/22交界处放置1个筹码</li>
                            <li>在25/26/28/29交界处放置2个筹码</li>
                            <li>在32/35交界处放置1个筹码</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">车轮孤注：</div>
                    <div className="content">
                        <div>该下注涵盖轮盘上邻位零和轮筒部分注未覆盖的两个部分上的总共8个数字。共放置5个筹码，方式如下：</div>
                        <ul>
                            <li>在1号（直注）上放置1个筹码</li>
                            <li>在6/9交界处放置1个筹码</li>
                            <li>在14/17交界处放置1个筹码</li>
                            <li>在17/20交界处放置1个筹码</li>
                            <li>在31/34交界处放置1个筹码</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">零的游戏：</div>
                    <div className="content">
                        <div>该下注涵盖零和轮盘上靠近零的6个数字：12、35、3、26、0、32和15。共放置4个筹码，方式如下：</div>
                        <ul>
                            <li>在0/3交界处放置1个筹码</li>
                            <li>在12/15交界处放置1个筹码</li>
                            <li>在26（直注）上放置1个筹码</li>
                            <li>在32/35交界处放置1个筹码</li>
                        </ul>
                        <div>
                            邻位下注涵盖一个特定数字以及在轮盘上与之相邻的其他数字。要下邻位注，请点击/轻点赛马道上的特定数字。筹码将放置在所选数字及其右侧和左侧相邻的数字上。点击/轻点圆形的“-”或“+”按钮以增加或减少所选数字右侧和左侧的邻位。
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">收藏和特殊投注：</div>
                    <div className="content">
                        <div className="content-title">收藏投注</div>
                        <div>
                            可选的收藏投注功能允许您保存首选的投注或不同类型的组合，以便在将来的任何轮次轮盘桌上更轻松地进行投注。您可以保存和编辑多达30个不同名称的收藏投注列表。
                        </div>
                        <div className="content-title">保存收藏投注</div>
                        <div>要打开“收藏投注”菜单，请单击/轻触“收藏投注”按钮。再次单击/轻触该按钮以关闭此功能。</div>
                        <div>
                            在轮盘桌上放置了收藏投注或不同投注组合后，单击/轻触“收藏投注”菜单中的“保存上次投注”链接。将建议此投注的默认名称，但您可以输入一个更容易识别的名称。然后，通过单击/轻触“保存”按钮或按下键盘上的“Enter”将此投注保存并添加到您的收藏投注列表中。
                        </div>
                        <div className="content-title">放置收藏投注</div>
                        <div>
                            在轮盘轮次的投注阶段，当您想要放置收藏投注时，打开“收藏投注”菜单以查看您先前保存的所有投注列表。它们将按时间顺序列出，您先保存的收藏投注将首先列出。您可以将光标悬停在任何列出的投注名称上，以查看在轮盘桌上如何放置筹码。单击/轻触每个所需投注的名称以放置它。您还可以通过多次单击/轻触其名称来将任何收藏投注的金额乘以2、3、4倍等。
                        </div>
                        <div className="content-title">重命名或删除收藏投注</div>
                        <div>在“收藏投注”菜单打开时，您可以单击/轻触“编辑”按钮以删除或重命名任何列出的投注。</div>
                        <div>
                            通过首先单击/轻触包围其当前名称的灰色文本框，然后输入新名称并通过单击/轻触“保存”按钮或按下键盘上的“Enter”将其保存，重命名任何列出的投注。
                        </div>
                        <div>通过单击/轻触相应的“删除”按钮删除您不再希望保存在收藏投注列表中的任何投注。</div>
                        <div>当您完成编辑收藏投注列表时，请单击/轻触“收藏投注”菜单右上角的“保存”按钮或单击/轻触“收藏投注”按钮。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">特殊投注：</div>
                    <div className="content">
                        <div>在“收藏投注”的第二个选项卡下，您可以更轻松地进行Finale en plein和Finale a cheval投注。</div>
                        <div className="content-title">Finale en plein</div>
                        <ul>
                            <li>Finale en plein 0 — 4筹码投注覆盖0+10+20+30，每个1筹码</li>
                            <li>Finale en plein 1 — 4筹码投注覆盖1+11+21+31，每个1筹码</li>
                            <li>Finale en plein 2 — 4筹码投注覆盖2+12+22+32，每个1筹码</li>
                            <li>Finale en plein 3 — 4筹码投注覆盖3+13+23+33，每个1筹码</li>
                            <li>Finale en plein 4 — 4筹码投注覆盖4+14+24+34，每个1筹码</li>
                            <li>Finale en plein 5 — 4筹码投注覆盖5+15+25+35，每个1筹码</li>
                            <li>Finale en plein 6 — 4筹码投注覆盖6+16+26+36，每个1筹码</li>
                            <li>Finale en plein 7 — 3筹码投注覆盖7+17+27，每个1筹码</li>
                            <li>Finale en plein 8 — 3筹码投注覆盖8+18+28，每个1筹码</li>
                            <li>Finale en plein 9 — 3筹码投注覆盖9+19+29，每个1筹码</li>
                        </ul>
                        <div className="content-title">Finale a cheval</div>
                        <ul>
                            <li>Finale a cheval 0/3 — 4筹码投注覆盖0/3+10/13+20/23+30/33，每个1筹码</li>
                            <li>Finale a cheval 1/4 — 4筹码投注覆盖1/4+11/14+21/24+31/34，每个1筹码</li>
                            <li>Finale a cheval 2/5 — 4筹码投注覆盖2/5+12/15+22/25+32/35，每个1筹码</li>
                            <li>Finale a cheval 3/6 — 4筹码投注覆盖3/6+13/16+23/26+33/36，每个1筹码</li>
                            <li>Finale a cheval 4/7 — 4筹码投注覆盖4/7+14/17+24/27+34，每个1筹码</li>
                            <li>Finale a cheval 5/8 — 4筹码投注覆盖5/8+15/18+25/28+35，每个1筹码</li>
                            <li>Finale a cheval 6/9 — 4筹码投注覆盖6/9+16/19+26/29+36，每个1筹码</li>
                            <li>Finale a cheval 7/10 — 3筹码投注覆盖7/10+17/20+27/30，每个1筹码</li>
                            <li>Finale a cheval 8/11 — 3筹码投注覆盖8/11+18/21+28/31，每个1筹码</li>
                            <li>Finale a cheval 9/12 — 3筹码投注覆盖9/12+19/22+29/32，每个1筹码</li>
                        </ul>
                        <div className="content-title">全方位投注</div>
                        <div>全方位投注在特定数字上放置所有内部投注。</div>
                        <div>
                            例如，对36号的全方位投注将投下18个筹码，具体如下：直注36上的1筹码，分注33/36和35/36上的每个2筹码，街注34/35/36上的每个3筹码，32/33/35/36的角注上的每个4筹码，以及31/32/33/34/35/36上的每个6筹码的线注。
                        </div>

                        <div className="content-title">直注</div>
                        <div>直注覆盖所选类型（红、黑、奇、偶等）的所有直注，每个数字都有一筹码。</div>
                        <div>例如，红色的直注将投下18个筹码，以覆盖所有红色数字。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">中奖号码：</div>
                    <div className="content">
                        <div>中奖号码显示最近的中奖号码。</div>
                        <div>最近完成的轮次的结果在左侧列出。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">您最近的结果：</div>
                    <div className="content">
                        <div>单击/轻触“统计”按钮以查看最近最多500个游戏轮次中的中奖号码表。使用滑块更改要应用的过去轮次的数量。</div>
                        <div>当您将光标悬停在统计图的任何部分时，将突出显示筹码将被放置的赌桌上的位置。只需单击/轻触赌注以放置您的筹码。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">支付：</div>
                    <div className="content">
                        <div>您的支付取决于所下注的类型。</div>
                        <div className="content-title">内部投注</div>
                        <div>投注类型 支付</div>
                        <table>
                            <tr>
                                <th>直注</th>
                                <th>35:1</th>
                            </tr>
                            <tr>
                                <th>分注</th>
                                <th>17:1</th>
                            </tr>
                            <tr>
                                <th>街注</th>
                                <th>11:1</th>
                            </tr>
                            <tr>
                                <th>角注</th>
                                <th>8:1</th>
                            </tr>
                            <tr>
                                <th>线注</th>
                                <th>5:1</th>
                            </tr>
                        </table>

                        <div className="content-title">外部投注</div>
                        <div>投注类型 支付</div>
                        <table>
                            <tr>
                                <th>列</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>打组</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>红/黑</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>奇/偶</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>1-18/19/36</th>
                                <th>1:1</th>
                            </tr>
                        </table>
                        <div>故障会导致所有支付和游戏无效。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">回报玩家：</div>
                    <div className="content">
                        <div>最佳理论回报率为97.30%。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">下注：</div>
                    <div className="content">
                        <div>BET LIMITS面板显示了桌子上允许的最小和最大下注限制，这些限制可能会不时更改。打开下注限制以查看当前限制。</div>
                        <div>皇帝轮盘 MYR 2.50 - 10,000</div>
                        <div>要参与游戏，您必须有足够的资金来支付您的下注。您可以在屏幕上看到当前的余额。</div>
                        <div>余额 MYR 100,000</div>
                        <div>在沉浸式视图中，计时器会告诉您下注时间的持续时间。时间到期后，下注将关闭，不再接受下注。</div>
                        <div>
                            在经典视图中，交通灯通过通知您何时可以下注（绿灯），何时下注时间即将结束（黄灯）以及何时下注时间已经结束（红灯）来告诉您游戏轮次的当前状态。
                        </div>
                        <div>放置您的下注</div>
                        <div>CHIP DISPLAY允许您选择您希望下注的每个筹码的价值。只有当前余额可以支付的面额的筹码才会启用。</div>
                        <div>
                            选择了一个筹码后，只需点击/轻拍游戏桌上相应的下注位置，即可下注。每次点击/轻拍下注位置时，您的下注金额将增加到所选筹码的价值，或者增加到您所选下注类型的最大限制。一旦您下注达到最大限制，将不再接受额外资金用于该下注，并且将在您的下注上方显示一条消息，通知您已达到最大限制。
                        </div>
                        <div>
                            注意：在下注时间尚未结束且您已在桌上下注时，请不要最小化浏览器或在浏览器中打开其他标签。这样的操作可能被解释为离开游戏，因此您的下注将在该特定游戏轮次中被拒绝。
                        </div>
                        <div>
                            在您下注后，DOUBLE（2x）按钮将变为可用状态。每次点击/轻拍该按钮，您所有的下注都会翻倍，直至最大限制。请注意，您必须有足够的账户余额来翻倍您所有下注的金额。
                        </div>
                        <div>REPEAT按钮允许您重复上一轮游戏的所有下注。该按钮仅在放置第一个筹码之前可用。</div>
                        <div>UNDO按钮会删除您上次下的注。</div>
                        <div>您可以重复点击/轻拍UNDO按钮，以相反的顺序一次删除一个下注。通过长按UNDO按钮，您可以清除所有下注。</div>
                        <div>TOTAL BET指示器显示了当前轮次中所有下注的总金额。</div>
                        <div>TOTAL BET MYR 500</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">在线支持：</div>
                    <div className="content">
                        <div>有关游戏的咨询，请联系在线支持。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">断线政策：</div>
                    <div className="content">
                        <div>
                            如果您从游戏轮次中断开连接，任何已下注的下注仍然有效，并在您不在场时结算。重新连接后，您可以在历史窗口中查看下注结果。
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">自动播放：</div>
                    <div className="content">
                        <div>一旦您下注，Autoplay允许您重复选择的下注或下注一定数量的游戏轮次。</div>
                        <div>要启动Autoplay，请下注，然后点击/轻拍Autoplay按钮。</div>
                        <div>在Autoplay面板中，您可以通过选择要重复下注的轮次来启动Autoplay。</div>
                        <div>您选择的Autoplay轮次数量将显示在Autoplay按钮上。一旦启动了Autoplay，剩余的Autoplay轮次数量将更新。</div>
                        <div>Autoplay BET PER ROUND MYR 10</div>
                        <div>
                            您的总Autoplay下注金额通过将Autoplay轮次数与您总下注的价值相乘来计算。它在每个Autoplay轮次卡下方显示。一旦启动了Autoplay，您剩余的Autoplay限制也将显示在每个Autoplay轮次卡下方。
                        </div>
                        <div>您的自动游戏轮次将持续进行，直到您选择的Autoplay轮次完成，或者您选择通过点击/轻拍STOP停止Autoplay。</div>
                        <div>STOP</div>
                        <div>停止Autoplay后，您将能够通过点击/轻拍REPEAT重复先前选择的下注和轮次。</div>
                        <div>REPEAT</div>
                        <div>
                            如果在Autoplay运行时在投注网格上下注或将下注翻倍，将显示一条消息，允许您选择是否继续以更改的下注进行Autoplay。如果不确认继续，Autoplay将停止。
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">聊天：</div>
                    <div className="content">
                        <div>与游戏主持人和其他玩家聊天。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">游戏编号：</div>
                    <div className="content">
                        <div>每个游戏轮次都由唯一的游戏编号标识。</div>
                        <div>
                            该数字反映了游戏轮次在GMT时间中开始的时间：小时：分钟：秒。如果您希望就某一轮联系客户服务，请使用此游戏编号作为参考（或截取游戏编号的屏幕截图）。
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">声音：</div>
                    <div className="content">
                        <div>声音控制游戏中的所有声音。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">游戏历史：</div>
                    <div className="content">
                        <div>游戏历史显示您的Inno游戏轮次和结果。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">设置：</div>
                    <div className="content">
                        <div>设置允许您自定义保存到您的个人资料的用户首选项。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">错误处理：</div>
                    <div className="content">
                        <div>
                            如果游戏、系统或游戏程序发生错误，游戏轮次将在游戏主持人通知服务经理时暂时暂停。通过聊天或屏幕弹出消息，您和其他玩家将被通知问题正在调查中。如果经理可以立即解决错误，则游戏轮次将继续进行。如果无法立即解决问题，则游戏轮次将被取消，并且参与游戏轮次的所有玩家的初始下注将退还。
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">轮盘有效旋转规则：</div>
                    <div className="content">
                        <div>只有在旋转被视为有效时，才能获胜的号码才有效。</div>
                        <div>
                            要构成有效的旋转，荷官必须按与轮盘旋转方向相反的方向旋转轮盘，并且在轮盘轨道上完成至少3次完整的旋转，然后才会掉落并停在轮盘上的编号口袋中。
                        </div>
                        <div>在以下情况下将宣布旋转无效：</div>
                        <ul>
                            <li>球的旋转次数少于3次</li>
                            <li>球与轮盘旋转方向相同</li>
                            <li>在旋转过程中轮盘停止旋转</li>
                            <li>球在旋转中脱离轮盘</li>
                            <li>在旋转过程中有任何外来物体进入轮盘。</li>
                        </ul>
                        <div>在旋转无效的情况下，荷官将执行重新旋转。</div>
                        <div>在任何不正常情况下，请向在线支持提供有关该轮次的游戏编号。</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">更多游戏：</div>
                    <div className="content">
                        <div>Evolution大堂 —— 轻松选择游戏，无需退出当前游戏，直到选择新游戏。</div>
                    </div>
                </div>
            </div>
        ),
        my: (
            <div className="rt-how-to-play">
                <div className="section">
                    <div className="title">Peraturan Permainan:</div>
                    <div className="content">
                        <div>
                            Objektif dalam Roulette adalah untuk meramalkan nombor di mana bola akan berhenti dengan meletakkan satu atau lebih
                            pertaruhan yang menutupi nombor tersebut. Roda dalam Roulette termasuk nombor 1-36 dan satu 0 (sifar).
                        </div>
                        <div>
                            Selepas tamat tempoh pertaruhan, bola dipusing dalam roda Roulette. Bola akhirnya akan berhenti di salah satu poket
                            berberangka dalam roda itu. Anda menang jika anda meletakkan pertaruhan yang menutupi nombor tersebut.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Jenis Pertaruhan:</div>
                    <div className="content">
                        <div>
                            Anda boleh meletakkan pelbagai jenis pertaruhan di atas meja Roulette. Pertaruhan boleh menutupi satu nombor atau julat
                            tertentu nombor, dan setiap jenis pertaruhan mempunyai kadar bayaran sendiri.
                        </div>
                        <div>
                            Pertaruhan yang dibuat pada ruang berangka di kawasan pertaruhan atau garis di antara mereka dipanggil Pertaruhan Dalaman,
                            manakala pertaruhan yang dibuat pada kotak-kotak khas di bawah dan di sisi kisi utama nombor dipanggil Pertaruhan Luar.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Pertaruhan Dalaman:</div>
                    <div className="content">
                        <ul>
                            <li>Straight Up — letakkan cip anda terus pada mana-mana nombor tunggal (termasuk sifar).</li>
                            <li>Split Bet — letakkan cip anda pada garis di antara dua nombor, sama ada secara menegak atau mendatar.</li>
                            <li>Street Bet — letakkan cip anda di hujung mana-mana baris nombor. Pertaruhan Street menutupi tiga nombor.</li>
                            <li>
                                Corner Bet — letakkan cip anda di sudut (persimpangan tengah) di mana empat nombor bertemu. Semua empat nombor
                                diliputi.
                            </li>
                            <li>
                                Line Bet — letakkan cip anda di hujung dua baris pada persimpangan antara dua baris. Pertaruhan line menutupi semua
                                nombor dalam kedua-dua baris, jumlah enam nombor.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Pertaruhan Luar:</div>
                    <div className="content">
                        <ul>
                            <li>
                                Pertaruhan Column — letakkan cip anda dalam salah satu kotak yang ditandai "2 hingga 1" di hujung lajur yang meliputi
                                semua 12 nombor dalam lajur itu. Sifar tidak diliputi oleh mana-mana pertaruhan lajur.
                            </li>
                            <li>
                                Pertaruhan Dozen — letakkan cip anda dalam salah satu tiga kotak yang ditandai "1st 12," "2nd 12," atau "3rd 12" untuk
                                meliputi 12 nombor sebelah kotak itu.
                            </li>
                            <li>
                                Merah/Hitam — letakkan cip anda dalam kotak Merah atau Hitam untuk meliputi 18 nombor merah atau 18 nombor hitam.
                                Sifar tidak diliputi oleh pertaruhan ini.
                            </li>
                            <li>
                                Genap/Ganjil — letakkan cip anda dalam satu daripada kotak ini untuk meliputi 18 nombor genap atau 18 nombor ganjil.
                                Sifar tidak diliputi oleh pertaruhan ini.
                            </li>
                            <li>
                                1-18/19-36 — letakkan cip anda dalam mana-mana kotak ini untuk meliputi set pertama atau kedua 18 nombor. Sifar tidak
                                diliputi oleh pertaruhan ini.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Pertaruhan Jiran:</div>
                    <div className="content">
                        <div>
                            Klik/tap butang PERTARUHAN JIRAN untuk melihat kawasan pertaruhan khas berbentuk oval atau trek pacuan yang membolehkan
                            anda meletakkan pertaruhan jiran dan pertaruhan khas lain dengan lebih mudah. Klik/tap semula butang untuk menutup/membuka
                            semula ciri ini.
                        </div>
                        <div>
                            Setiap pertaruhan meliputi set nombor yang berbeza dan menawarkan peluang bayaran yang berbeza. Tempat pertaruhan akan
                            disorot.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Tiers du Cylindre:</div>
                    <div className="content">
                        <div>
                            Pertaruhan ini meliputi jumlah 12 nombor yang termasuk 27, 33, dan nombor-nombor yang terletak di antara mereka di sisi
                            roda Roulette berlawanan dengan sifar. 6 cip diletakkan seperti berikut:
                        </div>
                        <ul>
                            <li>1 cip pada belahan 5/8</li>
                            <li>1 cip pada belahan 10/11</li>
                            <li>1 cip pada belahan 13/16</li>
                            <li>1 cip pada belahan 23/24</li>
                            <li>1 cip pada belahan 27/30</li>
                            <li>1 cip pada belahan 33/36</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Voisins du Zero:</div>
                    <div className="content">
                        <div>
                            Pertaruhan ini meliputi jumlah 17 nombor yang termasuk 22, 25, dan nombor-nombor yang terletak di antara mereka di sisi
                            roda Roulette yang mengandungi sifar. 9 cip diletakkan seperti berikut:
                        </div>
                        <ul>
                            <li>2 cip pada belahan 0/2/3</li>
                            <li>1 cip pada belahan 4/7</li>
                            <li>1 cip pada belahan 12/15</li>
                            <li>1 cip pada belahan 18/21</li>
                            <li>1 cip pada belahan 19/22</li>
                            <li>2 cip pada sudut 25/26/28/29</li>
                            <li>1 cip pada belahan 32/35</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Orphelins a Cheval:</div>
                    <div className="content">
                        <div>
                            Pertaruhan ini meliputi jumlah 8 nombor pada dua segmen roda Roulette yang tidak diliputi oleh pertaruhan voisins du zero
                            dan tiers du cylindre di atas. 5 cip diletakkan seperti berikut:
                        </div>
                        <ul>
                            <li>1 cip pada 1 (straight up)</li>
                            <li>1 cip pada belahan 6/9</li>
                            <li>1 cip pada belahan 14/17</li>
                            <li>1 cip pada belahan 17/20</li>
                            <li>1 cip pada belahan 31/34</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Jeu Zero:</div>
                    <div className="content">
                        <div>
                            Pertaruhan ini meliputi sifar dan 6 nombor yang berdekatan dengan sifar di roda Roulette: 12, 35, 3, 26, 0, 32, dan 15. 4
                            cip diletakkan seperti berikut:
                        </div>
                        <ul>
                            <li>1 cip pada belahan 0/3</li>
                            <li>1 cip pada belahan 12/15</li>
                            <li>1 cip pada 26 (straight up)</li>
                            <li>1 cip pada belahan 32/35</li>
                        </ul>
                        <div>
                            Pertaruhan jiran meliputi nombor tertentu serta nombor lain yang terletak berdekatan dengannya di roda Roulette. Untuk
                            meletakkan pertaruhan jiran, klik/tap pada nombor tertentu pada trek pacuan. Cip akan diletakkan pada nombor yang dipilih
                            dan pada nombor yang berdekatan dengannya di sebelah kanan dan kiri. Klik/tap pada butang "-" atau "+" bulat untuk
                            menambah atau mengurangkan set jiran di sebelah kanan dan kiri nombor yang dipilih.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Bersuka Ria & Bertaruh Khas:</div>
                    <div className="content">
                        <div className="content-title">TARUHAN KEGEMARAN</div>
                        <div>
                            Ciri Pilihan Taruhan Kegemaran membolehkan anda menyimpan taruhan kegemaran atau kombinasi taruhan yang berbeza untuk
                            memudahkan penempatan pada pusingan akan datang di mana-mana meja Rolet. Anda boleh menyimpan dan mengedit senarai
                            sehingga 30 taruhan kegemaran di bawah pelbagai nama.
                        </div>
                        <div className="content-title">SIMPAN TARUHAN KEGEMARAN</div>
                        <div>
                            Untuk membuka menu Taruhan Kegemaran, klik/tap butang TARUHAN KEGEMARAN. Klik/tap semula butang untuk menutup ciri ini.
                        </div>
                        <div>
                            Selepas anda meletakkan taruhan kegemaran atau kombinasi taruhan pada meja Rolet, klik/tap pautan SIMPAN TARUHAN TERAKHIR
                            dalam menu Taruhan Kegemaran. Satu nama lalai untuk taruhan ini akan dicadangkan, tetapi anda dialu-alukan untuk
                            memasukkan nama yang lebih mudah dikenali. Anda boleh menyimpan dan menambah taruhan ini ke senarai taruhan kegemaran anda
                            dengan klik/tap butang SIMPAN atau dengan menekan "Enter" pada papan kekunci anda.
                        </div>
                        <div className="content-title">MELETAK TARUHAN KEGEMARAN</div>
                        <div>
                            Apabila anda ingin meletakkan taruhan kegemaran semasa fasa pertaruhan pusingan Rolet, buka menu Taruhan Kegemaran untuk
                            melihat senarai semua taruhan yang anda simpan sebelum ini. Mereka akan disenaraikan mengikut urutan kronologi, dengan
                            taruhan kegemaran yang anda simpan paling awal disenaraikan terlebih dahulu. Anda boleh mengarahkan kursor anda ke atas
                            nama mana-mana taruhan yang disenaraikan untuk melihat bagaimana cip akan diletakkan pada meja Rolet. Klik/tap nama setiap
                            taruhan yang diingini untuk meletakkannya. Anda juga boleh menggandakan (menggandakan, menggandakan tiga kali,
                            menggandakan empat kali...) jumlah mana-mana taruhan kegemaran yang anda letakkan dengan klik/tap nama tersebut lebih dari
                            sekali.
                        </div>
                        <div className="content-title">UBAHSUAI NAMA ATAU PADAM TARUHAN KEGEMARAN</div>
                        <div>
                            Apabila menu Taruhan Kegemaran terbuka, anda boleh klik/tap butang EDIT untuk memadam atau mengubah nama mana-mana taruhan
                            yang disenaraikan.
                        </div>
                        <div>
                            Ubah nama mana-mana taruhan yang disenaraikan dengan klik/tap dalam kotak teks kelabu yang mengelilingi nama semasa. Anda
                            boleh kemudian memasukkan nama baru dan menyimpannya dengan klik/tap butang SIMPAN atau dengan menekan "Enter" pada papan
                            kekunci anda.
                        </div>
                        <div>
                            Padam taruhan mana-mana yang tidak lagi ingin disimpan dalam senarai taruhan kegemaran anda dengan klik/tap butang PADAM
                            yang bersesuaian.
                        </div>
                        <div>
                            Apabila anda sudah selesai mengedit senarai taruhan kegemaran anda, klik/tap butang SIMPAN di bahagian kanan atas menu
                            Taruhan Kegemaran atau klik/tap butang TARUHAN KEGEMARAN.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Taruhan Khas:</div>
                    <div className="content">
                        <div>
                            Di bawah tab kedua dalam Taruhan Kegemaran, anda boleh meletakkan taruhan Finale en plein dan Finale a cheval dengan lebih
                            mudah.
                        </div>
                        <div className="content-title">Finale en plein</div>
                        <ul>
                            <li>Finale en plein 0 — 4 cip bertaruh meliputi 0+10+20+30, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 1 — 4 cip bertaruh meliputi 1+11+21+31, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 2 — 4 cip bertaruh meliputi 2+12+22+32, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 3 — 4 cip bertaruh meliputi 3+13+23+33, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 4 — 4 cip bertaruh meliputi 4+14+24+34, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 5 — 4 cip bertaruh meliputi 5+15+25+35, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 6 — 4 cip bertaruh meliputi 6+16+26+36, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 7 — 3 cip bertaruh meliputi 7+17+27, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 8 — 3 cip bertaruh meliputi 8+18+28, masing-masing dengan 1 cip</li>
                            <li>Finale en plein 9 — 3 cip bertaruh meliputi 9+19+29, masing-masing dengan 1 cip</li>
                        </ul>
                        <div className="content-title">Finale a cheval</div>
                        <ul>
                            <li>Finale a cheval 0/3 — 4 cip bertaruh meliputi 0/3+10/13+20/23+30/33, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 1/4 — 4 cip bertaruh meliputi 1/4+11/14+21/24+31/34, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 2/5 — 4 cip bertaruh meliputi 2/5+12/15+22/25+32/35, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 3/6 — 4 cip bertaruh meliputi 3/6+13/16+23/26+33/36, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 4/7 — 4 cip bertaruh meliputi 4/7+14/17+24/27+34, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 5/8 — 4 cip bertaruh meliputi 5/8+15/18+25/28+35, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 6/9 — 4 cip bertaruh meliputi 6/9+16/19+26/29+36, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 7/10 — 3 cip bertaruh meliputi 7/10+17/20+27/30, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 8/11 — 3 cip bertaruh meliputi 8/11+18/21+28/31, masing-masing dengan 1 cip</li>
                            <li>Finale a cheval 9/12 — 3 cip bertaruh meliputi 9/12+19/22+29/32, masing-masing dengan 1 cip</li>
                        </ul>
                        <div className="content-title">Taruhan Penuh</div>
                        <div>Taruhan Penuh meletakkan semua taruhan dalam pada satu nombor tertentu.</div>
                        <div>
                            Sebagai contoh, Taruhan Penuh pada nombor 36 akan meletakkan 18 cip untuk meliputi sepenuhnya, seperti berikut: 1 cip pada
                            Nombor Terus 36, 2 cip pada setiap taruhan Split 33/36 dan 35/36, 3 cip pada taruhan Street 34/35/36, 4 cip pada Corner
                            bet 32/33/35/36 dan 6 cip pada taruhan Line 31/32/33/34/35/36.
                        </div>
                        <div className="content-title">Taruhan Terus</div>
                        <div>
                            Taruhan Terus meletakkan semua taruhan terus pada jenis yang dipilih (Merah, Hitam, Ganjil, Genap, dll.), meliputi setiap
                            nombor dengan satu cip.
                        </div>
                        <div>Sebagai contoh, Taruhan Terus MERAH akan meletakkan 18 cip untuk meliputi semua nombor merah.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Nombor Pemenang:</div>
                    <div className="content">
                        <div>PAPARAN NOMBOR PEMENANG menunjukkan nombor pemenang yang paling baru.</div>
                        <div>Hasil pusingan yang baru sahaja tamat disenaraikan di sebelah kiri.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Keputusan Terkini Anda:</div>
                    <div className="content">
                        <div>
                            Klik/tap butang STATISTIK untuk melihat jadual nombor pemenang dalam sehingga 500 pusingan permainan yang paling baru.
                            Gunakan alat geser untuk mengubah jumlah pusingan laluannya.
                        </div>
                        <div>
                            Apabila anda mengarahkan kursor anda ke mana-mana bahagian diagram statistik, tempat di atas meja pertaruhan di mana cip
                            akan diletakkan dipaparkan. Cukup klik/tap taruhan untuk meletakkan cip anda.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Bayaran:</div>
                    <div className="content">
                        <div>Bayaran anda bergantung pada jenis taruhan yang diletakkan.</div>
                        <div className="content-title">TARUHAN DALAM</div>
                        <div>JENIS TARUHAN BAYARAN</div>
                        <table>
                            <tr>
                                <th>Terus</th>
                                <th>35:1</th>
                            </tr>
                            <tr>
                                <th>Split</th>
                                <th>17:1</th>
                            </tr>
                            <tr>
                                <th>Street</th>
                                <th>11:1</th>
                            </tr>
                            <tr>
                                <th>Corner</th>
                                <th>8:1</th>
                            </tr>
                            <tr>
                                <th>Line</th>
                                <th>5:1</th>
                            </tr>
                        </table>
                        <div className="content-title">TARUHAN LUAR</div>
                        <div>JENIS TARUHAN BAYARAN</div>
                        <table>
                            <tr>
                                <th>Kolum</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>Dozen</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>Merah/Hitam</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>Ganjil/Genap</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>1-18/19/36</th>
                                <th>1:1</th>
                            </tr>
                        </table>
                        <div>Kerosakan menidakkan semua bayaran dan permainan.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Pulangan kepada Pemain:</div>
                    <div className="content">
                        <div>Pulangan kepada pemain secara teori yang optimal ialah 97.30%.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Letakkan Pertaruhan:</div>
                    <div className="content">
                        <div>
                            Panel HAD PERTARUHAN menunjukkan had pertaruhan minimum dan maksimum yang dibenarkan di meja, yang boleh berubah dari
                            semasa ke semasa. Buka Had Pertaruhan untuk memeriksa had semasa anda.
                        </div>
                        <div>Emperor Roulette MYR 2.50 - 10,000</div>
                        <div>
                            Untuk menyertai permainan, anda harus mempunyai dana yang mencukupi untuk menampung pertaruhan anda. Anda boleh melihat
                            BAKI SEMASA anda pada skrin anda.
                        </div>
                        <div>BAKI MYR 100,000</div>
                        <div>
                            Dalam pandangan Immersive, PENGHITUNG MASA memberitahu anda tentang tempoh masa pertaruhan. Selepas tempoh itu tamat,
                            pertaruhan ditutup dan tidak akan menerima pertaruhan lagi.
                        </div>
                        <div>
                            Dalam pandangan Klasik, LAMPU LALUAN memberitahu anda status semasa dalam pusingan permainan dengan memberitahu anda bila
                            anda boleh bertaruh (lampu HIJAU), bila masa pertaruhan hampir tamat (lampu KUNING), dan bila masa pertaruhan telah tamat
                            (lampu MERAH).
                        </div>
                        <div>LETAKKAN PERTARUHAN ANDA</div>
                        <div>
                            PAPARAN CIP membolehkan anda memilih nilai setiap cip yang ingin anda pertaruhkan. Hanya cip dengan denominasi yang boleh
                            ditampung oleh baki semasa anda akan diaktifkan.
                        </div>
                        <div>
                            Setelah anda memilih cip, letakkan pertaruhan anda dengan hanya mengklik/mengetuk tempat pertaruhan yang sesuai di atas
                            meja permainan. Setiap kali anda mengklik/mengetuk tempat pertaruhan, jumlah pertaruhan anda akan bertambah mengikut nilai
                            cip yang dipilih atau sehingga had maksimum untuk jenis pertaruhan yang anda pilih. Setelah anda meletakkan pertaruhan
                            maksimum, dana tambahan tidak akan diterima untuk pertaruhan itu, dan mesej akan muncul di atas pertaruhan anda untuk
                            memberitahu anda bahawa anda telah meletakkan maksimum.
                        </div>
                        <div>
                            NOTA: Sila jangan mengurangkan tetingkap pelayar atau membuka tab lain dalam pelayar anda semasa tempoh pertaruhan
                            berlangsung dan anda telah meletakkan pertaruhan di atas meja. Tindakan seperti itu mungkin diinterpretasikan sebagai
                            meninggalkan permainan, dan pertaruhan anda akan ditolak untuk pusingan permainan tersebut.
                        </div>
                        <div>
                            Butang GANDA (2x) menjadi boleh digunakan setelah anda meletakkan sebarang pertaruhan. Setiap klik/mengetuk akan
                            menggandakan semua pertaruhan anda sehingga had maksimum. Perhatikan bahawa anda harus mempunyai baki akaun yang mencukupi
                            untuk menggandakan SEMUA pertaruhan yang diletakkan.
                        </div>
                        <div>
                            Butang ULANG membolehkan anda mengulangi semua pertaruhan dari pusingan permainan sebelumnya. Butang ini hanya tersedia
                            sebelum cip pertama diletakkan.
                        </div>
                        <div>Butang BATAL menghapuskan pertaruhan terakhir yang anda letakkan.</div>
                        <div>
                            Anda boleh mengklik/mengetuk butang BATAL berulang kali untuk menghapuskan pertaruhan, satu persatu, secara berbalik dari
                            cara mereka diletakkan. Anda boleh memadamkan semua pertaruhan anda dengan menahan butang BATAL.
                        </div>
                        <div>
                            PENYATA PERTARUHAN KESULURUHAN menunjukkan jumlah keseluruhan semua pertaruhan yang diletakkan dalam pusingan semasa.
                        </div>
                        <div>PERTARUHAN KESULURUHAN MYR 500</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Sokongan Langsung:</div>
                    <div className="content">
                        <div>Hubungi Sokongan Langsung untuk pertanyaan berkaitan permainan.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Dasar Putus Hubungan:</div>
                    <div className="content">
                        <div>
                            Jika anda terputus dari pusingan permainan, sebarang pertaruhan yang diletakkan tetap sah dan akan diselesaikan dalam
                            ketiadaan anda. Setelah menyambung semula, anda boleh melihat hasil pertaruhan dalam tetingkap Sejarah.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Autoplay:</div>
                    <div className="content">
                        <div>
                            Setelah anda meletakkan pertaruhan, Autoplay membolehkan anda mengulangi pertaruhan atau pertaruhan yang dipilih untuk
                            bilangan pusingan permainan yang dipilih.
                        </div>
                        <div>Untuk memulakan Autoplay, letakkan pertaruhan anda dan kemudian klik/mengetuk butang Autoplay.</div>
                        <div>
                            Dalam panel Autoplay, anda boleh memulakan Autoplay dengan memilih bilangan pusingan yang anda ingin pertaruhan anda
                            diulangi untuk.
                        </div>
                        <div>
                            Bilangan pusingan Autoplay yang dipilih akan dipaparkan pada butang Autoplay. Bilangan pusingan Autoplay yang tinggal akan
                            dikemaskini, setelah Autoplay dimulakan.
                        </div>
                        <div>MYR 10 SETIAP PERTARUHAN Autoplay</div>
                        <div>
                            Jumlah pertaruhan Autoplay anda diira dengan mengalikan bilangan pusingan Autoplay dengan nilai pertaruhan keseluruhan
                            anda. Ia ditunjukkan di bawah setiap kad pusingan Autoplay. Had Autoplay yang tinggal juga akan dipaparkan di bawah setiap
                            kad pusingan Autoplay, setelah Autoplay dimulakan.
                        </div>
                        <div>
                            Pusingan permainan automatik anda akan berterusan sehingga sama ada bilangan pusingan Autoplay yang dipilih selesai, atau
                            anda memilih untuk menghentikan Autoplay dengan mengklik/mengetuk STOP.
                        </div>
                        <div>BERHENTI</div>
                        <div>
                            Apabila Autoplay dihentikan, anda akan dapat mengulangi pertaruhan dan pusingan yang telah dipilih sebelum ini dengan
                            mengklik/mengetuk ULANG.
                        </div>
                        <div>ULANG</div>
                        <div>
                            Jika anda meletakkan pertaruhan tambahan di grid pertaruhan atau menggandakan pertaruhan anda semasa Autoplay berjalan,
                            satu mesej akan muncul yang membolehkan anda memilih untuk meneruskan Autoplay dengan pertaruhan yang telah diubah. Jika
                            anda tidak mengesahkan untuk meneruskan, Autoplay akan dihentikan.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Sembang:</div>
                    <div className="content">
                        <div>Sembang dengan penyaji permainan dan pemain lain.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Nombor Permainan:</div>
                    <div className="content">
                        <div>Setiap pusingan permainan dikenali dengan NOMBOR PERMAINAN yang unik.</div>
                        <div>
                            Nombor ini mencerminkan bila pusingan permainan bermula dari segi GMT mengikut jam: minit: saat. Sila gunakan nombor
                            permainan ini sebagai rujukan (atau ambil tangkapan skrin nombor permainan) jika anda ingin menghubungi Perkhidmatan
                            Pelanggan berkenaan pusingan tertentu.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Bunyi:</div>
                    <div className="content">
                        <div>Kawal bunyi semua bunyi dalam permainan.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Sejarah Permainan:</div>
                    <div className="content">
                        <div>Sejarah Permainan memaparkan pusingan dan keputusan permainan Inno anda.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Tetapan:</div>
                    <div className="content">
                        <div>Tetapan membolehkan anda menyesuaikan keutamaan pengguna yang disimpan dalam profil anda.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Kawal Selenggaraan Ralat:</div>
                    <div className="content">
                        <div>
                            Jika terdapat ralat dalam permainan, sistem atau prosedur permainan, pusingan permainan akan ditangguhkan sementara hos
                            permainan memberitahu pengurus perkhidmatan. Anda dan pemain lain akan diberitahu melalui Sembang, atau melalui mesej
                            pop-up di skrin, bahawa isu tersebut sedang disiasat. Jika pengurus boleh menyelesaikan ralat dengan segera, pusingan
                            permainan akan berterusan seperti biasa. Jika penyelesaian segera tidak mungkin, pusingan permainan akan dibatalkan, dan
                            pertaruhan awal akan dikembalikan kepada semua pemain yang mengambil bahagian dalam pusingan permainan tersebut.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Peraturan Putaran Roulette yang Sah:</div>
                    <div className="content">
                        <div>Nombor yang menang sah hanya apabila putaran dianggap sah.</div>
                        <div>
                            Untuk menjadi putaran yang sah, bola Roulette harus diputar oleh dealer dalam arah yang bertentangan dengan putaran roda
                            dan harus menyelesaikan sekurang-kurangnya 3 putaran penuh sekitar trek roda sebelum jatuh dan berhenti di dalam poket
                            bernombor pada roda.
                        </div>
                        <div>Putaran yang tidak sah akan diisytiharkan dalam kes berikut:</div>
                        <ul>
                            <li>Bola melakukan kurang dari 3 putaran penuh</li>
                            <li>Bola diputar dalam arah yang sama dengan putaran roda</li>
                            <li>Roda berhenti berputar semasa putaran</li>
                            <li>Bola keluar dari roda semasa putaran</li>
                            <li>Mana-mana objek asing masuk ke dalam roda semasa putaran.</li>
                        </ul>
                        <div>Dalam kes putaran yang tidak sah, dealer akan melakukan putaran semula.</div>
                        <div>Dalam kes tidak konsisten, sila berikan Nombor Permainan bagi pusingan yang berkenaan kepada Sokongan Langsung.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Lebih Banyak Permainan:</div>
                    <div className="content">
                        <div>Lobi Inno — pemilihan permainan yang mudah tanpa keluar dari permainan semasa anda sehingga permainan baru dipilih.</div>
                    </div>
                </div>
            </div>
        ),
        pt: (
            <div className="rt-how-to-play">
                <div className="section">
                    <div className="title">Regras do Jogo:</div>
                    <div className="content">
                        <div>
                            O objetivo na Roleta é prever o número no qual a bola irá parar, fazendo uma ou mais apostas que cubram esse número
                            específico. A roda na Roleta inclui os números de 1 a 36, além do zero (0).
                        </div>
                        <div>
                            Após o término do tempo de apostas, a bola é girada na roda da Roleta. A bola eventualmente descansará em um dos bolsos
                            numerados da roda. Você ganha se tiver feito uma aposta que cobre esse número específico.
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">Tipos de Apostas:</div>
                    <div className="content">
                        <div>
                            Você pode fazer muitos tipos diferentes de apostas na mesa de Roleta. As apostas podem cobrir um único número ou uma faixa
                            específica de números, e cada tipo de aposta possui sua própria taxa de pagamento.
                        </div>
                        <div>
                            As apostas feitas nos espaços numerados na área de apostas ou nas linhas entre eles são chamadas de Apostas Internas,
                            enquanto as apostas feitas nas caixas especiais abaixo e ao lado da grade principal de números são chamadas de Apostas
                            Externas.
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">Apostas Internas:</div>
                    <div className="content">
                        <ul>
                            <li>Straight Up — coloque sua ficha diretamente em qualquer número único (incluindo zero).</li>
                            <li>Split Bet — coloque sua ficha na linha entre dois números, seja na vertical ou na horizontal.</li>
                            <li>Street Bet — coloque sua ficha no final de qualquer linha de números. Uma Street Bet cobre três números.</li>
                            <li>
                                Corner Bet — coloque sua ficha no canto (interseção central) onde quatro números se encontram. Todos os quatro números
                                estão cobertos.
                            </li>
                            <li>
                                Line Bet — coloque sua ficha no final de duas fileiras na interseção entre as duas fileiras. Uma aposta em linha cobre
                                todos os números em ambas as fileiras, totalizando seis números.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="title">Apostas Externas:</div>
                    <div className="content">
                        <ul>
                            <li>
                                Column Bet — coloque sua ficha em uma das caixas marcadas como "2 para 1" no final da coluna que cobre todos os 12
                                números dessa coluna. O zero não é coberto por nenhuma aposta de coluna.
                            </li>
                            <li>
                                Dozen Bet — coloque sua ficha em uma das três caixas marcadas como "1ª 12", "2ª 12" ou "3ª 12" para cobrir os 12
                                números ao lado da caixa.
                            </li>
                            <li>
                                Vermelho/Preto — coloque sua ficha na caixa Vermelha ou Preta para cobrir os 18 números vermelhos ou 18 números
                                pretos. O zero não é coberto por essas apostas.
                            </li>
                            <li>
                                Par/Ímpar — coloque sua ficha em uma dessas caixas para cobrir os 18 números pares ou 18 números ímpares. O zero não é
                                coberto por essas apostas.
                            </li>
                            <li>
                                1-18/19-36 — coloque sua ficha em uma dessas caixas para cobrir o primeiro ou segundo conjunto de 18 números. O zero
                                não é coberto por essas apostas.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="title">Apostas Vizinhas:</div>
                    <div className="content">
                        <div>
                            Clique/toque no botão APOSTAS VIZINHAS para visualizar uma área de apostas especial em forma de oval ou de pista de
                            corrida que permite que você faça apostas vizinhas e outras apostas especiais mais facilmente. Clique/toque novamente no
                            botão para fechar/reabrir essa funcionalidade.
                        </div>
                        <div>
                            {" "}
                            Cada aposta cobre um conjunto diferente de números e oferece probabilidades de pagamento diferentes. Os pontos de aposta
                            serão destacados.
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">Tiers du Cylindre:</div>
                    <div className="content">
                        <div>
                            Esta aposta cobre um total de 12 números que incluem 27, 33 e os números que ficam entre eles no lado da roda da Roleta
                            oposto ao zero. 6 fichas são colocadas da seguinte forma:
                        </div>
                        <ul>
                            <li>1 ficha no split 5/8</li>
                            <li>1 ficha no split 10/11</li>
                            <li>1 ficha no split 13/16</li>
                            <li>1 ficha no split 23/24</li>
                            <li>1 ficha no split 27/30</li>
                            <li>1 ficha no split 33/36</li>
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="title">Voisins du Zero:</div>
                    <div className="content">
                        <div>
                            Esta aposta cobre um total de 17 números que incluem 22, 25 e os números que ficam entre eles no lado da roda da Roleta
                            que contém o zero. 9 fichas são colocadas da seguinte forma:
                        </div>
                        <ul>
                            <li>2 fichas na street 0/2/3</li>
                            <li>1 ficha no split 4/7</li>
                            <li>1 ficha no split 12/15</li>
                            <li>1 ficha no split 18/21</li>
                            <li>1 ficha no split 19/22</li>
                            <li>2 fichas no corner 25/26/28/29</li>
                            <li>1 ficha no split 32/35</li>
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="title">Orphelins a Cheval:</div>
                    <div className="content">
                        <div>
                            Esta aposta cobre um total de 8 números nos dois segmentos da roda da Roleta não cobertos pelas apostas voisins du zero e
                            tiers du cylindre acima. 5 fichas são colocadas da seguinte forma:
                        </div>
                        <ul>
                            <li>1 ficha no 1 (straight up)</li>
                            <li>1 ficha no split 6/9</li>
                            <li>1 ficha no split 14/17</li>
                            <li>1 ficha no split 17/20</li>
                            <li>1 ficha no split 31/34</li>
                        </ul>
                    </div>
                </div>

                <div className="section">
                    <div className="title">Jeu Zero:</div>
                    <div className="content">
                        <div>
                            Esta aposta cobre o zero e os 6 números próximos ao zero na roda da Roleta: 12, 35, 3, 26, 0, 32 e 15. 4 fichas são
                            colocadas da seguinte forma:
                        </div>
                        <ul>
                            <li>1 ficha no split 0/3</li>
                            <li>1 ficha no split 12/15</li>
                            <li>1 ficha no 26 (straight up)</li>
                            <li>1 ficha no split 32/35</li>
                        </ul>
                        <div>
                            Uma aposta vizinha cobre um número específico, bem como outros números que estão próximos a ele na roda da Roleta. Para
                            fazer uma aposta vizinha, clique/toque em um número específico na pista de corrida. Uma ficha será colocada no número
                            escolhido e nos números que o cercam à direita e à esquerda. Clique/toque no botão circular "-" ou "+" para aumentar ou
                            diminuir o conjunto de vizinhos à direita e à esquerda do número escolhido.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Apostas Favoritas e Especiais:</div>
                    <div className="content">
                        <div className="content-title">APOSTAS FAVORITAS</div>
                        <div>
                            A opção de Apostas Favoritas permite que você salve uma aposta preferida ou uma combinação de diferentes tipos de apostas
                            para facilitar sua colocação em rodadas futuras em qualquer mesa de Roleta. Você pode salvar e editar uma lista de até 30
                            das suas apostas favoritas com nomes diferentes.
                        </div>
                        <div className="content-title">SALVAR UMA APOSTA FAVORITA</div>
                        <div>
                            Para abrir o menu de Apostas Favoritas, clique/toque no botão APOSTAS FAVORITAS. Clique/toque novamente no botão para
                            fechar esta funcionalidade.
                        </div>
                        <div>
                            Depois de realizar uma aposta favorita ou uma combinação de apostas na mesa de Roleta, clique/toque no link SALVAR ÚLTIMA
                            APOSTA no menu de Apostas Favoritas. Um nome padrão para esta aposta será sugerido, mas você pode inserir um nome mais
                            facilmente reconhecível. Em seguida, você pode salvar e adicionar esta aposta à sua lista de apostas favoritas
                            clicando/tocando no botão SALVAR ou pressionando "Enter" no seu teclado.
                        </div>
                        <div className="content-title">REALIZAR UMA APOSTA FAVORITA</div>
                        <div>
                            Quando desejar fazer uma aposta favorita durante a fase de apostas em uma rodada de Roleta, abra o menu de Apostas
                            Favoritas para visualizar uma lista de todas as apostas que você salvou anteriormente. Elas serão listadas em ordem
                            cronológica, com a aposta favorita que você salvou primeiro aparecendo no topo. Passe o cursor sobre o nome de qualquer
                            aposta listada para ver como as fichas serão colocadas na mesa de Roleta. Clique/toque no nome de cada aposta desejada
                            para realizá-la. Você também pode multiplicar (dobrar, triplicar, quadruplicar...) o valor de qualquer aposta favorita que
                            você tenha feito clicando/tocando em seu nome mais de uma vez.
                        </div>
                        <div className="content-title">RENOMEAR OU EXCLUIR UMA APOSTA FAVORITA</div>
                        <div>
                            Quando o menu de Apostas Favoritas estiver aberto, você pode clicar/tocar no botão EDITAR para excluir ou renomear
                            qualquer aposta listada.
                        </div>
                        <div>
                            Renomeie qualquer aposta listada clicando/tocando primeiro na caixa de texto cinza que envolve o nome atual. Em seguida,
                            você pode inserir um novo nome e salvá-lo clicando/tocando no botão SALVAR ou pressionando "Enter" no seu teclado.
                        </div>
                        <div>
                            Exclua qualquer aposta que você não queira mais manter em sua lista de apostas favoritas clicando/tocando no respectivo
                            botão EXCLUIR.
                        </div>
                        <div>
                            Quando terminar de editar sua lista de apostas favoritas, clique/toque no botão SALVAR no canto superior direito do menu
                            de Apostas Favoritas ou clique/toque no botão APOSTAS FAVORITAS.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Apostas Especiais:</div>
                    <div className="content">
                        <div>
                            Sob a segunda aba em Apostas Favoritas, você pode realizar apostas Finale en plein e Finale a cheval com mais facilidade.
                        </div>
                        <div className="content-title">Finale en plein</div>
                        <ul>
                            <li>Finale en plein 0 - Aposta de 4 fichas cobre 0+10+20+30, cada uma com 1 ficha</li>
                            <li>Finale en plein 1 - Aposta de 4 fichas cobre 1+11+21+31, cada uma com 1 ficha</li>
                            <li>Finale en plein 2 - Aposta de 4 fichas cobre 2+12+22+32, cada uma com 1 ficha</li>
                            <li>Finale en plein 3 - Aposta de 4 fichas cobre 3+13+23+33, cada uma com 1 ficha</li>
                            <li>Finale en plein 4 - Aposta de 4 fichas cobre 4+14+24+34, cada uma com 1 ficha</li>
                            <li>Finale en plein 5 — Aposta de 4 fichas cobre 5+15+25+35, cada uma com 1 ficha</li>
                            <li>Finale en plein 6 — Aposta de 4 fichas cobre 6+16+26+36, cada uma com 1 ficha</li>
                            <li>Finale en plein 7 — Aposta de 3 fichas cobre 7+17+27, cada uma com 1 ficha</li>
                            <li>Finale en plein 8 — Aposta de 3 fichas cobre 8+18+28, cada uma com 1 ficha</li>
                            <li>Finale en plein 9 — Aposta de 3 fichas cobre 9+19+29, cada uma com 1 ficha</li>
                        </ul>
                        <div className="content-title">Finale a cheval</div>
                        <ul>
                            <li>Finale a cheval 0/3 — Aposta de 4 fichas cobre 0/3+10/13+20/23+30/33, cada uma com 1 ficha</li>
                            <li>Finale a cheval 1/4 — Aposta de 4 fichas cobre 1/4+11/14+21/24+31/34, cada uma com 1 ficha</li>
                            <li>Finale a cheval 2/5 — Aposta de 4 fichas cobre 2/5+12/15+22/25+32/35, cada uma com 1 ficha</li>
                            <li>Finale a cheval 3/6 — Aposta de 4 fichas cobre 3/6+13/16+23/26+33/36, cada uma com 1 ficha</li>
                            <li>Finale a cheval 4/7 — Aposta de 4 fichas cobre 4/7+14/17+24/27+34, cada uma com 1 ficha</li>
                            <li>Finale a cheval 5/8 — Aposta de 4 fichas cobre 5/8+15/18+25/28+35, cada uma com 1 ficha</li>
                            <li>Finale a cheval 6/9 — Aposta de 4 fichas cobre 6/9+16/19+26/29+36, cada uma com 1 ficha</li>
                            <li>Finale a cheval 7/10 — Aposta de 3 fichas cobre 7/10+17/20+27/30, cada uma com 1 ficha</li>
                            <li>Finale a cheval 8/11 — Aposta de 3 fichas cobre 8/11+18/21+28/31, cada uma com 1 ficha</li>
                            <li>Finale a cheval 9/12 — Aposta de 3 fichas cobre 9/12+19/22+29/32, cada uma com 1 ficha</li>
                        </ul>
                        <div className="content-title">Apostas Completas</div>
                        <div>Uma Aposta Completa coloca todas as apostas internas em um número específico.</div>
                        <div>
                            Por exemplo, uma Aposta Completa no número 36 colocará 18 fichas para cobri-lo completamente, da seguinte forma: 1 ficha
                            em Straight Up 36, 2 fichas em cada aposta Dividida 33/36 e 35/36, 3 fichas na aposta de Rua 34/35/36, 4 fichas na aposta
                            de Canto 32/33/35/36 e 6 fichas na aposta de Linha 31/32/33/34/35/36.
                        </div>
                        <div className="content-title">Apostas Diretas</div>
                        <div>
                            Apostas Diretas cobrem todas as apostas diretas do tipo selecionado (Vermelho, Preto, Ímpar, Par, etc.), cobrindo cada
                            número com uma ficha.
                        </div>
                        <div>Por exemplo, a Aposta Direta em VERMELHO colocará 18 fichas para cobrir todos os números vermelhos.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Números Vencedores:</div>
                    <div className="content">
                        <div>A tela de NÚMEROS VENCEDORES mostra os números vencedores mais recentes.</div>
                        <div>O resultado da rodada mais recentemente concluída é listado à esquerda.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Seus resultados recentes:</div>
                    <div className="content">
                        <div>
                            Clique/toque no botão ESTATÍSTICAS para visualizar uma tabela dos números vencedores em até 500 das rodadas de jogo mais
                            recentes. Use o controle deslizante para alterar o número de rodadas passadas a serem aplicadas.
                        </div>
                        <div>
                            Ao passar o cursor sobre qualquer parte do diagrama de estatísticas, a posição na mesa de apostas onde uma ficha seria
                            colocada é destacada. Basta clicar/tocar na aposta desejada para colocar sua ficha.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Pagamentos:</div>
                    <div className="content">
                        <div>Seu pagamento depende do tipo de aposta realizada.</div>
                        <div className="content-title">APOSTAS INTERNAS</div>
                        <div>TIPO DE APOSTA PAGAMENTO</div>
                        <table>
                            <tr>
                                <th>Straight Up</th>
                                <th>35:1</th>
                            </tr>
                            <tr>
                                <th>Dividida</th>
                                <th>17:1</th>
                            </tr>
                            <tr>
                                <th>Rua</th>
                                <th>11:1</th>
                            </tr>
                            <tr>
                                <th>Canto</th>
                                <th>8:1</th>
                            </tr>
                            <tr>
                                <th>Linha</th>
                                <th>5:1</th>
                            </tr>
                        </table>
                        <div className="content-title">APOSTAS EXTERNAS</div>
                        <div>TIPO DE APOSTA PAGAMENTO</div>
                        <table>
                            <tr>
                                <th>Coluna</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>Dúzia</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>Vermelho/Preto</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>Ímpar/Par</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>1-18/19/36</th>
                                <th>1:1</th>
                            </tr>
                        </table>
                        <div>Mau funcionamento anula todos os pagamentos e jogadas.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Retorno ao Jogador:</div>
                    <div className="content">
                        <div>O retorno teórico ao jogador ideal é de 97,30%.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Faça suas Apostas:</div>
                    <div className="content">
                        <div>
                            O painel de LIMITES DE APOSTA mostra os limites mínimo e máximo permitidos na mesa, podendo ser alterados de tempos em
                            tempos. Abra os Limites de Aposta para verificar seus limites atuais.
                        </div>
                        <div>Emperor Roulette MYR 2,50 - 10.000</div>
                        <div>
                            Para participar do jogo, você deve ter fundos suficientes para cobrir suas apostas. Você pode ver seu SALDO atual na tela.
                        </div>
                        <div>SALDO MYR 100.000</div>
                        <div>
                            Na visualização Imersiva, o TEMPORIZADOR informa a duração do tempo de aposta. Após expirar, as apostas são encerradas e
                            não são mais aceitas.
                        </div>
                        <div>
                            Na visualização Clássica, os SEMÁFOROS indicam o status atual no round do jogo, informando quando você pode apostar
                            (semaforo VERDE), quando o tempo de aposta está quase acabando (semaforo AMARELO) e quando o tempo de aposta expirou
                            (semaforo VERMELHO).
                        </div>
                        <div>FAÇA SUAS APOSTAS</div>
                        <div>
                            O VISOR DE FICHAS permite que você escolha o valor de cada ficha que deseja apostar. Somente fichas de denominações que
                            podem ser cobertas pelo seu saldo atual serão habilitadas.
                        </div>
                        <div>
                            Depois de selecionar uma ficha, faça sua aposta clicando/tocando no local apropriado na mesa de jogo. Cada vez que
                            clicar/tocar no local de aposta, o valor da sua aposta aumenta pelo valor da ficha selecionada ou até o limite máximo para
                            o tipo de aposta que você escolheu. Depois de apostar o limite máximo, nenhum fundo adicional será aceito para essa
                            aposta, e uma mensagem aparecerá acima da sua aposta para notificá-lo de que você apostou o máximo.
                        </div>
                        <div>
                            NOTA: Por favor, não minimize seu navegador ou abra qualquer outra guia em seu navegador enquanto o tempo de aposta
                            permanecer e você tiver feito apostas na mesa. Essas ações podem ser interpretadas como sair do jogo, e suas apostas serão
                            recusadas para aquele round específico.
                        </div>
                        <div>
                            O botão DOBRO (2x) fica disponível depois que você fez qualquer aposta. Cada clique/toque dobra todas as suas apostas até
                            o limite máximo. Observe que você deve ter um saldo suficiente para dobrar TODAS as suas apostas feitas.
                        </div>
                        <div>
                            O botão REPETIR permite repetir todas as apostas do round anterior. Este botão está disponível apenas antes da primeira
                            ficha ser colocada.
                        </div>
                        <div>O botão DESFAZ desfaz a última aposta que você fez.</div>
                        <div>
                            Você pode clicar/tocar no botão DESFAZ repetidamente para remover apostas, uma por uma, na ordem inversa em que foram
                            feitas. Você pode limpar todas as suas apostas segurando o botão DESFAZ.
                        </div>
                        <div>O indicador DE APOSTA TOTAL exibe o valor total de todas as apostas feitas no round atual.</div>
                        <div>APOSTA TOTAL MYR 500</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Suporte ao Vivo:</div>
                    <div className="content">
                        <div>Entre em contato com o Suporte ao Vivo para dúvidas relacionadas ao jogo.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Política de Desconexão:</div>
                    <div className="content">
                        <div>
                            Se você for desconectado de um round do jogo, quaisquer apostas feitas permanecerão válidas e serão liquidadas em sua
                            ausência. Ao reconectar, você pode visualizar os resultados das apostas na janela de Histórico.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Jogo Automático:</div>
                    <div className="content">
                        <div>
                            Depois de fazer uma aposta, o Jogo Automático permite que você repita sua aposta ou apostas selecionadas por um número
                            escolhido de rounds do jogo.
                        </div>
                        <div>Para iniciar o Jogo Automático, faça suas apostas e clique/toque no botão Jogo Automático.</div>
                        <div>
                            No painel de Jogo Automático, você pode iniciar o Jogo Automático selecionando o número de rounds nos quais deseja que
                            suas apostas sejam repetidas.
                        </div>
                        <div>
                            O número selecionado de rounds do Jogo Automático será exibido no botão Jogo Automático. O número de rounds restantes do
                            Jogo Automático será atualizado assim que o Jogo Automático for iniciado.
                        </div>
                        <div>Jogo Automático APOSTA POR ROUND MYR 10</div>
                        <div>
                            O valor total da sua aposta automática é calculado multiplicando o número de rounds do Jogo Automático pelo valor total da
                            sua aposta. Ele é indicado embaixo de cada cartão de rounds do Jogo Automático. Seu limite de Jogo Automático restante
                            também será exibido embaixo de cada cartão de rounds do Jogo Automático, assim que o Jogo Automático for iniciado.
                        </div>
                        <div>
                            Seus rounds automáticos continuarão até que o número escolhido de rounds do Jogo Automático seja concluído, ou você
                            escolha parar o Jogo Automático clicando/tocando em PARAR.
                        </div>
                        <div>PARAR</div>
                        <div>
                            Quando o Jogo Automático é interrompido, você poderá repetir apostas e rounds previamente selecionados clicando/tocando em
                            REPETIR.
                        </div>
                        <div>REPETIR</div>
                        <div>
                            Se você fizer apostas adicionais na grade de apostas ou dobrar suas apostas enquanto o Jogo Automático estiver em
                            andamento, uma mensagem aparecerá permitindo que você escolha continuar o Jogo Automático com suas apostas alteradas. Se
                            você não confirmar para continuar, o Jogo Automático será interrompido.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Chat:</div>
                    <div className="content">
                        <div>Converse com um apresentador de jogo e outros jogadores.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Número do Jogo:</div>
                    <div className="content">
                        <div>Cada round do jogo é identificado por um NÚMERO DE JOGO único.</div>
                        <div>
                            Este número reflete quando o round do jogo começou em termos de GMT por hora: minuto: segundo. Use este número de jogo
                            como referência (ou tire uma captura de tela do número de jogo) se desejar entrar em contato com o Atendimento ao Cliente
                            sobre um round específico.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Som:</div>
                    <div className="content">
                        <div>Os controles de som controlam todos os sons no jogo.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Histórico do Jogo:</div>
                    <div className="content">
                        <div>O Histórico do Jogo exibe seus rounds e resultados do jogo da Inno.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Configurações:</div>
                    <div className="content">
                        <div>As configurações permitem personalizar as preferências do usuário que são salvas em seu perfil.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Tratamento de Erros:</div>
                    <div className="content">
                        <div>
                            Se houver um erro no jogo, sistema ou procedimento do jogo, o round do jogo será temporariamente pausado enquanto o
                            apresentador do jogo notifica o gerente de serviço. Você e outros jogadores serão notificados via Chat ou por uma mensagem
                            pop-up na tela de que o problema está sendo investigado. Se o gerente puder resolver imediatamente o erro, o round do jogo
                            continuará normalmente. Se a resolução imediata não for possível, o round do jogo será cancelado, e as apostas iniciais
                            serão reembolsadas a todos os jogadores que participaram do round do jogo.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Regra de Giro Válido da Roleta:</div>
                    <div className="content">
                        <div>O número vencedor é válido apenas quando o giro foi considerado válido.</div>
                        <div>
                            Para constituir um giro válido, a bola de Roleta deve ser girada pelo crupiê na direção oposta à rotação da roda e deve
                            completar pelo menos 3 revoluções completas ao redor da pista da roda antes de cair e parar em um bolso numerado na roda.
                        </div>
                        <div>Um giro inválido será declarado nos seguintes casos:</div>
                        <ul>
                            <li>A bola fez menos de 3 revoluções completas</li>
                            <li>A bola foi girada na mesma direção que a rotação da roda</li>
                            <li>A roda parou de girar durante o giro</li>
                            <li>A bola saiu da roda durante o giro</li>
                            <li>Qualquer objeto estranho entrou na roda durante o giro.</li>
                        </ul>
                        <div>No caso de um giro inválido, o crupiê realizará um novo giro.</div>
                        <div>No caso de quaisquer irregularidades, forneça o Número do Jogo do round em questão ao Suporte ao Vivo.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Mais Jogos:</div>
                    <div className="content">
                        <div>Inno Lobby — seleção fácil de jogos sem sair do seu jogo atual até que um novo jogo seja escolhido.</div>
                    </div>
                </div>
            </div>
        ),
        vn: (
            <div className="rt-how-to-play">
                <div className="section">
                    <div className="title">Quy tắc trò chơi:</div>
                    <div className="content">
                        <div>
                            Mục tiêu trong Roulette là dự đoán số mà quả cầu sẽ dừng lại bằng cách đặt một hoặc nhiều cược che phủ số đó. Bánh xe
                            trong Roulette bao gồm các số từ 1-36 cùng với số 0 (zero).
                        </div>
                        <div>
                            Sau khi hết thời gian đặt cược, quả cầu sẽ quay trong bánh xe Roulette. Quả cầu sẽ cuối cùng dừng lại trong một trong các
                            ô có số trên bánh xe. Bạn thắng nếu bạn đã đặt cược che phủ số đó.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Loại cược:</div>
                    <div className="content">
                        <div>
                            Bạn có thể đặt nhiều loại cược khác nhau trên bàn Roulette. Cược có thể che phủ một số đơn hoặc một khoảng số cụ thể, và
                            mỗi loại cược có tỷ lệ thanh toán riêng.
                        </div>
                        <div>
                            Cược đặt trên các ô có số trên khu vực đặt cược hoặc các đường giữa chúng được gọi là Cược Bên Trong, trong khi cược đặt
                            trên các ô đặc biệt phía dưới và bên cạnh lưới chính của các số được gọi là Cược Bên Ngoài.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Cược Bên Trong:</div>
                    <div className="content">
                        <ul>
                            <li>Straight Up — đặt chip của bạn trực tiếp lên bất kỳ số đơn nào (bao gồm cả số 0).</li>
                            <li>Split Bet — đặt chip của bạn trên đường giữa hai số bất kỳ, hoặc theo chiều dọc hoặc chiều ngang.</li>
                            <li>Street Bet — đặt chip của bạn ở cuối bất kỳ hàng số nào. Một Street Bet che phủ ba số.</li>
                            <li>Corner Bet — đặt chip của bạn ở góc (giao điểm trung tâm) nơi bốn số gặp nhau. Bốn số đều được che phủ.</li>
                            <li>
                                Line Bet — đặt chip của bạn ở cuối hai hàng nằm ở giao điểm giữa hai hàng. Một Line Bet che phủ tất cả các số trong cả
                                hai hàng, tổng cộng là sáu số.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Cược Bên Ngoài:</div>
                    <div className="content">
                        <ul>
                            <li>
                                Column Bet — đặt chip của bạn trong một trong những ô được đánh dấu "2 đến 1" ở cuối cột che phủ tất cả 12 số trong
                                cột đó. Số 0 không được che phủ bởi bất kỳ cược cột nào.
                            </li>
                            <li>
                                Dozen Bet — đặt chip của bạn trong một trong ba ô được đánh dấu "1st 12," "2nd 12" hoặc "3rd 12" để che phủ 12 số bên
                                cạnh ô đó.
                            </li>
                            <li>
                                Đỏ/Đen — đặt chip của bạn trong ô Đỏ hoặc Đen để che phủ 18 số đỏ hoặc 18 số đen. Số 0 không được che phủ bởi những
                                cược này.
                            </li>
                            <li>
                                Chẵn/Lẻ — đặt chip của bạn trong một trong những ô này để che phủ 18 số chẵn hoặc 18 số lẻ. Số 0 không được che phủ
                                bởi những cược này.
                            </li>
                            <li>
                                1-18/19-36 — đặt chip của bạn trong ô này để che phủ bộ số đầu tiên hoặc thứ hai gồm 18 số. Số 0 không được che phủ
                                bởi những cược này.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Cược Hàng Xóm:</div>
                    <div className="content">
                        <div>
                            Nhấp/chạm vào nút CƯỢC HÀNG XÓM để xem một khu vực đặt cược đặc biệt hình dáng hình oval hoặc đường đua, giúp bạn dễ dàng
                            đặt cược hàng xóm và các cược đặc biệt khác hơn. Nhấp/chạm lại nút để đóng/mở lại tính năng này.
                        </div>
                        <div>
                            Cược mỗi loại che phủ một tập hợp khác nhau của số và cung cấp tỷ lệ thanh toán khác nhau. Các vị trí cược sẽ được đánh
                            dấu.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Tiers du Cylindre:</div>
                    <div className="content">
                        <div>
                            Cược này che phủ tổng cộng 12 số bao gồm 27, 33 và các số nằm giữa chúng ở bên cạnh bánh xe Roulette phía ngược lại với số
                            0. 6 chip được đặt như sau:
                        </div>
                        <ul>
                            <li>1 chip trên đường chia 5/8</li>
                            <li>1 chip trên đường chia 10/11</li>
                            <li>1 chip trên đường chia 13/16</li>
                            <li>1 chip trên đường chia 23/24</li>
                            <li>1 chip trên đường chia 27/30</li>
                            <li>1 chip trên đường chia 33/36</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Voisins du Zero:</div>
                    <div className="content">
                        <div>
                            Cược này che phủ tổng cộng 17 số bao gồm 22, 25 và các số nằm giữa chúng ở bên cạnh bánh xe Roulette chứa số 0. 9 chip
                            được đặt như sau:
                        </div>
                        <ul>
                            <li>2 chip trên đường chia 0/2/3</li>
                            <li>1 chip trên đường chia 4/7</li>
                            <li>1 chip trên đường chia 12/15</li>
                            <li>1 chip trên đường chia 18/21</li>
                            <li>1 chip trên đường chia 19/22</li>
                            <li>2 chip trên góc 25/26/28/29</li>
                            <li>1 chip trên đường chia 32/35</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Orphelins a Cheval:</div>
                    <div className="content">
                        <div>
                            Cược này che phủ tổng cộng 8 số trên hai đoạn của bánh xe Roulette không được che phủ bởi cược voisins du zero và tiers du
                            cylindre ở trên. 5 chip được đặt như sau:
                        </div>
                        <ul>
                            <li>1 chip trên số 1 (đặt cược trực tiếp)</li>
                            <li>1 chip trên đường chia 6/9</li>
                            <li>1 chip trên đường chia 14/17</li>
                            <li>1 chip trên đường chia 17/20</li>
                            <li>1 chip trên đường chia 31/34</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Jeu Zero:</div>
                    <div className="content">
                        <div>
                            Cược này che phủ số 0 và 6 số ở gần số 0 trên bánh xe Roulette: 12, 35, 3, 26, 0, 32 và 15. 4 chip được đặt như sau:
                        </div>
                        <ul>
                            <li>1 chip trên đường chia 0/3</li>
                            <li>1 chip trên đường chia 12/15</li>
                            <li>1 chip trên số 26 (đặt cược trực tiếp)</li>
                            <li>1 chip trên đường chia 32/35</li>
                        </ul>
                        <div>
                            Cược hàng xóm che phủ một số cụ thể cũng như các số khác nằm gần nó trên bánh xe Roulette. Để đặt cược hàng xóm, nhấp/chạm
                            vào một số cụ thể trên đường đua. Một chip sẽ được đặt trên số được chọn và các số hàng xóm nằm bên phải và bên trái.
                            Nhấp/chạm vào nút "+" hoặc "-" tròn để tăng hoặc giảm số hàng xóm bên phải và bên trái của số đã chọn.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Ưa thích & Cược Đặc biệt:</div>
                    <div className="content">
                        <div className="content-title">CÁC CƯỢC ƯA THÍCH</div>
                        <div>
                            Tính năng Cược Ưa thích cho phép bạn lưu lại cược hoặc kết hợp các loại cược khác nhau để dễ dàng đặt cược trong các vòng
                            sau tại bất kỳ bàn Roulette nào. Bạn có thể lưu và chỉnh sửa một danh sách gồm tối đa 30 cược ưa thích dưới các tên khác
                            nhau.
                        </div>
                        <div className="content-title">LƯU CƯỢC ƯA THÍCH</div>
                        <div>Để mở menu Cược Ưa thích, nhấn vào nút CƯỢC ƯA THÍCH. Nhấn lại nút này để đóng tính năng này.</div>
                        <div>
                            Sau khi bạn đã đặt một cược ưa thích hoặc kết hợp các loại cược trên bàn Roulette, nhấn vào liên kết LƯU CƯỢC CUỐI CÙNG
                            trong menu Cược Ưa thích. Một tên mặc định cho cược này sẽ được đề xuất, nhưng bạn có thể nhập một tên dễ nhận biết hơn.
                            Sau đó, bạn có thể lưu và thêm cược này vào danh sách cược ưa thích của mình bằng cách nhấn vào nút LƯU hoặc bằng cách
                            nhấn "Enter" trên bàn phím của bạn.
                        </div>
                        <div className="content-title">ĐẶT CƯỢC ƯA THÍCH</div>
                        <div>
                            Khi bạn muốn đặt một cược ưa thích trong giai đoạn đặt cược của một vòng Roulette, mở menu Cược Ưa thích để xem danh sách
                            tất cả các cược bạn đã lưu trước đó. Chúng sẽ được liệt kê theo thứ tự thời gian, với cược ưa thích bạn đã lưu trước nhất
                            được liệt kê đầu tiên. Bạn có thể di chuột qua tên của bất kỳ cược nào được liệt kê để xem cách chip sẽ được đặt trên bàn
                            Roulette tương ứng. Nhấn vào tên mỗi cược mong muốn để đặt cược. Bạn cũng có thể nhân bản (gấp đôi, gấp ba, gấp bốn...) số
                            tiền của bất kỳ cược ưa thích nào bạn đã đặt bằng cách nhấn vào tên nó nhiều hơn một lần.
                        </div>
                        <div className="content-title">ĐỔI TÊN HOẶC XÓA CƯỢC ƯA THÍCH</div>
                        <div>Khi menu Cược Ưa thích mở, bạn có thể nhấn vào nút CHỈNH SỬA để xóa hoặc đổi tên bất kỳ cược nào đã được liệt kê.</div>
                        <div>
                            Đổi tên bất kỳ cược đã được liệt kê bằng cách trước tiên nhấn vào trong ô văn bản mà bao quanh tên hiện tại của nó. Sau
                            đó, bạn có thể nhập một tên mới và lưu nó bằng cách nhấn vào nút LƯU hoặc bằng cách nhấn "Enter" trên bàn phím của bạn.
                        </div>
                        <div>Xóa bất kỳ cược nào bạn không muốn giữ trong danh sách cược ưa thích bằng cách nhấn vào nút XÓA tương ứng của nó.</div>
                        <div>
                            Khi bạn đã hoàn tất việc chỉnh sửa danh sách cược ưa thích của mình, nhấn vào nút LƯU ở góc phải trên cùng của menu Cược
                            Ưa thích hoặc nhấn vào nút CƯỢC ƯA THÍCH.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Cược Đặc biệt:</div>
                    <div className="content">
                        <div>Dưới tab thứ hai trong Cược Ưa thích, bạn có thể dễ dàng đặt cược Finale en plein và Finale a cheval.</div>
                        <div className="content-title">Finale en plein</div>
                        <ul>
                            <li>Finale en plein 0 — Cược 4 chip bao gồm 0+10+20+30, mỗi con 1 chip</li>
                            <li>Finale en plein 1 — Cược 4 chip bao gồm 1+11+21+31, mỗi con 1 chip</li>
                            <li>Finale en plein 2 — Cược 4 chip bao gồm 2+12+22+32, mỗi con 1 chip</li>
                            <li>Finale en plein 3 — Cược 4 chip bao gồm 3+13+23+33, mỗi con 1 chip</li>
                            <li>Finale en plein 4 — Cược 4 chip bao gồm 4+14+24+34, mỗi con 1 chip</li>
                            <li>Finale en plein 5 — Cược 4 chip bao gồm 5+15+25+35, mỗi con 1 chip</li>
                            <li>Finale en plein 6 — Cược 4 chip bao gồm 6+16+26+36, mỗi con 1 chip</li>
                            <li>Finale en plein 7 — Cược 3 chip bao gồm 7+17+27, mỗi con 1 chip</li>
                            <li>Finale en plein 8 — Cược 3 chip bao gồm 8+18+28, mỗi con 1 chip</li>
                            <li>Finale en plein 9 — Cược 3 chip bao gồm 9+19+29, mỗi con 1 chip</li>
                        </ul>
                        <div className="content-title">Finale a cheval</div>
                        <ul>
                            <li>Finale a cheval 0/3 — Cược 4 chip bao gồm 0/3+10/13+20/23+30/33, mỗi con 1 chip</li>
                            <li>Finale a cheval 1/4 — Cược 4 chip bao gồm 1/4+11/14+21/24+31/34, mỗi con 1 chip</li>
                            <li>Finale a cheval 2/5 — Cược 4 chip bao gồm 2/5+12/15+22/25+32/35, mỗi con 1 chip</li>
                            <li>Finale a cheval 3/6 — Cược 4 chip bao gồm 3/6+13/16+23/26+33/36, mỗi con 1 chip</li>
                            <li>Finale a cheval 4/7 — Cược 4 chip bao gồm 4/7+14/17+24/27+34, mỗi con 1 chip</li>
                            <li>Finale a cheval 5/8 — Cược 4 chip bao gồm 5/8+15/18+25/28+35, mỗi con 1 chip</li>
                            <li>Finale a cheval 6/9 — Cược 4 chip bao gồm 6/9+16/19+26/29+36, mỗi con 1 chip</li>
                            <li>Finale a cheval 7/10 — Cược 3 chip bao gồm 7/10+17/20+27/30, mỗi con 1 chip</li>
                            <li>Finale a cheval 8/11 — Cược 3 chip bao gồm 8/11+18/21+28/31, mỗi con 1 chip</li>
                            <li>Finale a cheval 9/12 — Cược 3 chip bao gồm 9/12+19/22+29/32, mỗi con 1 chip</li>
                        </ul>
                        <div className="content-title">Cược Hoàn chỉnh</div>
                        <div>Một Cược Hoàn chỉnh đặt tất cả các cược bên trong trên một số cụ thể.</div>
                        <div>
                            Ví dụ, một Cược Hoàn chỉnh trên số 36 sẽ đặt 18 chip để che phủ hoàn toàn, như sau: 1 chip trên Straight Up 36, 2 chip
                            trên mỗi cược Split 33/36 và 35/36, 3 chip trên cược Street 34/35/36, 4 chip trên Corner bet 32/33/35/36 và 6 chip trên
                            Line bet 31/32/33/34/35/36.
                        </div>
                        <div className="content-title">Cược Straight Up</div>
                        <div>Cược Straight Up đặt tất cả cược thẳng loại đã chọn (Đỏ, Đen, Lẻ, Chẵn, v.v.), che phủ mỗi số bằng một chip.</div>
                        <div>Ví dụ, Cược Straight Up ĐỎ sẽ đặt 18 chip để che phủ tất cả các số đỏ.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Số Thắng:</div>
                    <div className="content">
                        <div>Hiển thị SỐ THẮNG hiển thị các số thắng gần đây nhất.</div>
                        <div>Kết quả của vòng vừa hoàn tất gần đây nhất được liệt kê ở bên trái.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Kết quả Gần đây của bạn:</div>
                    <div className="content">
                        <div>
                            Nhấn vào nút THỐNG KÊ để xem một bảng các số thắng trong tối đa 500 vòng chơi gần đây nhất. Sử dụng thanh trượt để điều
                            chỉnh số vòng chơi trước đó áp dụng.
                        </div>
                        <div>
                            Khi bạn di chuột qua bất kỳ phần nào của biểu đồ thống kê, vị trí trên bàn cược nơi một chip sẽ được đặt sẽ được làm nổi
                            bật. Đơn giản nhấn vào cược để đặt chip của bạn.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Thanh Toán:</div>
                    <div className="content">
                        <div>Thanh toán của bạn phụ thuộc vào loại cược đã đặt.</div>
                        <div className="content-title">CÁC CƯỢC BÊN TRONG</div>
                        <div>LOẠI CƯỢC THANH TOÁN</div>
                        <table>
                            <tr>
                                <th>Straight Up</th>
                                <th>35:1</th>
                            </tr>
                            <tr>
                                <th>Split</th>
                                <th>17:1</th>
                            </tr>
                            <tr>
                                <th>Street</th>
                                <th>11:1</th>
                            </tr>
                            <tr>
                                <th>Corner</th>
                                <th>8:1</th>
                            </tr>
                            <tr>
                                <th>Line</th>
                                <th>5:1</th>
                            </tr>
                        </table>
                        <div className="content-title">CƯỢC NGOẠI Ô</div>
                        <div>LOẠI CƯỢC THANH TOÁN</div>
                        <table>
                            <tr>
                                <th>Cột</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>Dãy</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>Đỏ/Đen</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>Chẵn/Lẻ</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>1-18/19/36</th>
                                <th>1:1</th>
                            </tr>
                        </table>
                        <div>Lỗi kỹ thuật làm mất hiệu lực tất cả các thanh toán và ván chơi.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Tỷ lệ Trả lại Người chơi:</div>
                    <div className="content">
                        <div>Tỷ lệ trả lại người chơi lý tưởng là 97.30%.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Đặt Cược:</div>
                    <div className="content">
                        <div>
                            Bảng GIỚI HẠN CƯỢC hiển thị giới hạn cược tối thiểu và tối đa được phép tại bàn, có thể thay đổi từ thời điểm này sang
                            thời điểm khác. Mở Giới hạn Cược để kiểm tra giới hạn hiện tại của bạn.
                        </div>
                        <div>Emperor Roulette MYR 2.50 - 10,000</div>
                        <div>
                            Để tham gia trò chơi, bạn phải có đủ tiền để cover cược của mình. Bạn có thể xem SỐ DƯ hiện tại của mình trên màn hình.
                        </div>
                        <div>SỐ DƯ MYR 100,000</div>
                        <div>
                            Trong chế độ Xem Sâu, BẢNG ĐẾM THỜI GIAN thông báo thời gian cược. Sau khi hết giờ, cược sẽ đóng và sẽ không chấp nhận
                            cược nào thêm.
                        </div>
                        <div>
                            Trong chế độ Xem Cổ Điển, ĐÈN GIAO THÔNG cho bạn biết tình trạng hiện tại trong vòng chơi bằng cách thông báo khi bạn có
                            thể cược (đèn XANH), khi thời gian cược gần kết thúc (đèn VÀNG) và khi thời gian cược đã kết thúc (đèn ĐỎ).
                        </div>
                        <div>ĐẶT CƯỢC CỦA BẠN</div>
                        <div>
                            Bảng HIỂN THỊ CHIP cho phép bạn chọn giá trị của mỗi chip bạn muốn cược. Chỉ các chip có mệnh giá có thể được cover bởi số
                            dư hiện tại của bạn mới được kích hoạt.
                        </div>
                        <div>
                            Sau khi bạn đã chọn một chip, đặt cược của bạn bằng cách đơn giản nhấp/chạm vào vị trí cược thích hợp trên bàn chơi. Mỗi
                            lần bạn nhấp/ chạm vào vị trí cược, số tiền cược của bạn tăng lên theo giá trị của chip đã chọn hoặc lên đến giới hạn tối
                            đa cho loại cược bạn đã chọn. Sau khi bạn đã cược đạt đến giới hạn tối đa, không có thêm quỹ nào được chấp nhận cho cược
                            đó, và một thông báo sẽ xuất hiện trên cược của bạn để thông báo bạn đã cược đạt đến mức tối đa.
                        </div>
                        <div>
                            CHÚ Ý: Vui lòng không thu nhỏ trình duyệt hoặc mở bất kỳ tab nào khác trong trình duyệt của bạn trong khi thời gian cược
                            còn lại và bạn đã đặt cược trên bàn. Những hành động như vậy có thể được hiểu là bạn đã rời khỏi trò chơi, và do đó cược
                            của bạn sẽ bị từ chối cho vòng chơi cụ thể đó.
                        </div>
                        <div>
                            Nút DOUBLE (2x) trở nên khả dụng sau khi bạn đã đặt bất kỳ cược nào. Mỗi lần nhấp/chạm, tất cả các cược của bạn sẽ được
                            nhân đôi lên đến giới hạn tối đa. Lưu ý rằng bạn phải có số dư tài khoản đủ để nhân đôi TẤT CẢ các cược bạn đã đặt.
                        </div>
                        <div>
                            Nút REPEAT cho phép bạn lặp lại tất cả các cược từ vòng chơi trước. Nút này chỉ khả dụng trước khi đặt chip đầu tiên.
                        </div>
                        <div>Nút UNDO loại bỏ cược cuối cùng bạn đã đặt.</div>
                        <div>
                            Bạn có thể nhấp/chạm nút UNDO liên tục để loại bỏ cược, từng cái một, theo thứ tự ngược lại so với thứ tự chúng đã được
                            đặt. Bạn có thể xóa tất cả cược của mình bằng cách giữ nút UNDO.
                        </div>
                        <div>Chỉ số TỔNG CƯỢC hiển thị tổng số tiền của tất cả các cược đã đặt trong vòng chơi hiện tại.</div>
                        <div>TỔNG CƯỢC MYR 500</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Hỗ trợ Trực tuyến:</div>
                    <div className="content">
                        <div>Liên hệ với Hỗ trợ Trực tuyến để biết thông tin liên quan đến trò chơi.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Chính Sách Mất kết nối:</div>
                    <div className="content">
                        <div>
                            Nếu bạn bị mất kết nối trong một vòng chơi, mọi cược đã đặt vẫn có hiệu lực và được giải quyết trong khi bạn vắng mặt. Khi
                            kết nối lại, bạn có thể xem kết quả cược trong cửa sổ Lịch sử.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Chơi Tự động:</div>
                    <div className="content">
                        <div>Sau khi bạn đã đặt cược, Chơi Tự động cho phép bạn lặp lại cược đã chọn hoặc cược cho một số vòng chơi được chọn.</div>
                        <div>Để bắt đầu Chơi Tự động, đặt cược của bạn và sau đó nhấp/chạm vào nút Chơi Tự động.</div>
                        <div>Trong bảng Chơi Tự động, bạn có thể bắt đầu Chơi Tự động bằng cách chọn số vòng bạn muốn cược của bạn được lặp lại.</div>
                        <div>
                            Số vòng Chơi Tự động bạn đã chọn sẽ được hiển thị trên nút Chơi Tự động. Số vòng Chơi Tự động còn lại sẽ được cập nhật khi
                            Chơi Tự động bắt đầu.
                        </div>
                        <div>Chơi Tự động CƯỢC MỖI VÒNG MYR 10</div>
                        <div>
                            Tổng số tiền cược Chơi Tự động của bạn được tính bằng cách nhân số vòng Chơi Tự động với giá trị tổng cược của bạn. Nó
                            được chỉ định dưới mỗi thẻ vòng Chơi Tự động. Giới hạn Chơi Tự động còn lại của bạn cũng sẽ được hiển thị dưới mỗi thẻ
                            vòng Chơi Tự động, sau khi Chơi Tự động đã bắt đầu.
                        </div>
                        <div>
                            Các vòng chơi tự động của bạn sẽ tiếp tục cho đến khi hoặc số vòng Chơi Tự động bạn đã chọn hoàn tất, hoặc bạn chọn dừng
                            Chơi Tự động bằng cách nhấp/chạm vào DỪNG.
                        </div>
                        <div>DỪNG</div>
                        <div>Khi Chơi Tự động bị dừng, bạn sẽ có thể lặp lại cược và vòng chơi đã chọn trước đó bằng cách nhấp/chạm vào LẶP LẠI.</div>
                        <div>LẶP LẠI</div>
                        <div>
                            Nếu bạn đặt cược bổ sung trên lưới cược hoặc gấp đôi cược của bạn trong khi Chơi Tự động đang chạy, một thông báo sẽ xuất
                            hiện cho phép bạn chọn tiếp tục Chơi Tự động với cược thay đổi của bạn. Nếu bạn không xác nhận để tiếp tục, Chơi Tự động
                            sẽ dừng lại.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Trò chuyện:</div>
                    <div className="content">
                        <div>Trò chuyện với người trình bày trò chơi và người chơi khác.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Số Trò chơi:</div>
                    <div className="content">
                        <div>Mỗi vòng chơi được xác định bằng một SỐ TRÒ CHƠI duy nhất.</div>
                        <div>
                            Số này phản ánh thời điểm bắt đầu vòng chơi dựa trên GMT theo giờ: phút: giây. Vui lòng sử dụng số trò chơi này để tham
                            chiếu (hoặc chụp ảnh số trò chơi) nếu bạn muốn liên hệ với Dịch vụ Khách hàng về một vòng cụ thể.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Âm thanh:</div>
                    <div className="content">
                        <div>Âm thanh điều khiển tất cả các âm thanh trong trò chơi.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Lịch Sử Trò chơi:</div>
                    <div className="content">
                        <div>Lịch Sử Trò chơi hiển thị các vòng chơi và kết quả của bạn trong trò chơi Inno.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Cài đặt:</div>
                    <div className="content">
                        <div>Cài đặt cho phép bạn tùy chỉnh các ưu tiên người dùng được lưu vào hồ sơ của bạn.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Xử lý Lỗi:</div>
                    <div className="content">
                        <div>
                            Nếu có lỗi xảy ra trong trò chơi, hệ thống hoặc quy trình trò chơi, vòng chơi sẽ tạm thời tạm dừng trong khi người quản lý
                            trò chơi thông báo cho quản lý dịch vụ. Bạn và các người chơi khác sẽ được thông báo qua Chat hoặc thông báo xuất hiện
                            trên màn hình rằng vấn đề đang được điều tra. Nếu người quản lý có thể giải quyết ngay lập tức, vòng chơi sẽ tiếp tục bình
                            thường. Nếu giải quyết ngay lập tức không khả thi, vòng chơi sẽ bị hủy bỏ, và mức cược ban đầu sẽ được hoàn trả cho tất cả
                            người chơi tham gia trong vòng chơi đó.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Quy Tắc Quay Hợp Lệ của Roulette:</div>
                    <div className="content">
                        <div>Số chiến thắng chỉ được coi là hợp lệ khi vòng quay được coi là hợp lệ.</div>
                        <div>
                            Để tạo nên một vòng quay hợp lệ, quả cầu Roulette phải được người chia bài quay theo hướng ngược lại so với hướng quay của
                            bánh xe và phải hoàn thành ít nhất 3 vòng quay đầy đủ quanh đường đua của bánh xe trước khi rơi và ngưng lại trong một ô
                            có số trên bánh xe.
                        </div>
                        <div>Một vòng quay không hợp lệ sẽ được công bố trong các trường hợp sau:</div>
                        <ul>
                            <li>Quả cầu quay ít hơn 3 vòng đầy đủ</li>
                            <li>Quả cầu được quay theo cùng hướng với quay của bánh xe</li>
                            <li>Bánh xe ngừng quay trong lúc quay</li>
                            <li>Quả cầu rơi khỏi bánh xe trong lúc quay</li>
                            <li>Bất kỳ vật thể ngoại lai nào vào bánh xe trong lúc quay.</li>
                        </ul>
                        <div>Trong trường hợp quay không hợp lệ, người chia bài sẽ thực hiện quay lại (re-spin).</div>
                        <div>Trong trường hợp có bất kỳ bất thường nào, vui lòng cung cấp Số Trò chơi của vòng chơi đó cho Hỗ trợ Trực tuyến.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Các Trò Chơi Khác:</div>
                    <div className="content">
                        <div>Lễ tân Inno — lựa chọn trò chơi dễ dàng mà không cần thoát khỏi trò chơi hiện tại cho đến khi chọn trò chơi mới.</div>
                    </div>
                </div>
            </div>
        ),
        th: (
            <div className="rt-how-to-play">
                <div className="section">
                    <div className="title">กฏเกม:</div>
                    <div className="content">
                        <div>
                            วัตถุประสงค์ในเกมรูเล็ตคือการทายเลขที่ลูกบอลจะตกลงมาบนวงล้อโดยการวางเดิมพันหนึ่งหรือมากกว่าที่ครอบคลุมเลขนั้น ๆ
                            วงล้อในรูเล็ตรวมทั้งเลข 1-36 รวมถึงเลข 0 (ศูนย์) ด้วย
                        </div>
                        <div>
                            เมื่อเวลาเดิมพันหมดลง ลูกบอลจะถูกหมุนในวงล้อรูเล็ต ลูกบอลจะหยุดที่หนึ่งในช่องตัวเลขภายในวงล้อ
                            คุณจะชนะถ้าคุณได้วางเดิมพันที่ครอบคลุมเลขนั้น
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ประเภทของการเดิมพัน:</div>
                    <div className="content">
                        <div>
                            คุณสามารถวางเดิมพันหลายประเภทบนโต๊ะรูเล็ตได้ เดิมพันสามารถครอบคลุมเลขเดียวหรือช่วงเลขที่กำหนดได้
                            และแต่ละประเภทของการเดิมพันจะมีอัตราการจ่ายเงินต่าง ๆ
                        </div>
                        <div>
                            เดิมพันที่วางบนพื้นที่เลขบนโต๊ะหรือบนเส้นที่แบ่งระหว่างเลขเรียกว่าเดิมพันภายใน
                            ในขณะที่เดิมพันที่วางบนกล่องพิเศษด้านล่างและด้านข้างของกริดหลักของเลขเรียกว่าเดิมพันภายนอก
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">เดิมพันภายใน:</div>
                    <div className="content">
                        <ul>
                            <li>Straight Up — วางชิปของคุณตรงไปที่เลขใด ๆ (รวมถึงศูนย์)</li>
                            <li>Split Bet — วางชิปของคุณบนเส้นระหว่างเลขสองตัวใด ๆ ไม่ว่าจะเป็นแนวตั้งหรือแนวนอน</li>
                            <li>Street Bet — วางชิปของคุณที่ปลายแถวเลขใด ๆ แถวละสามเลขถูกครอบคลุม</li>
                            <li>Corner Bet — วางชิปของคุณที่มุม (จุดตัดกลาง) ที่สี่เลขพบกัน ทุกสี่เลขจะถูกครอบคลุม</li>
                            <li>
                                Line Bet — วางชิปของคุณที่ปลายสองแถวที่ตัดกันระหว่างแถวทั้งสอง การเดิมพันแบบไลน์ครอบคลุมทุกเลขในแถวทั้งสอง
                                รวมทั้งหกเลข
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">เดิมพันภายนอก:</div>
                    <div className="content">
                        <ul>
                            <li>
                                Column Bet — วางชิปของคุณในหนึ่งในกล่องที่มีเครื่องหมาย "2 to 1" ที่ปลายคอลัมน์ที่ครอบคลุมทั้ง 12 เลขในคอลัมน์นั้น ๆ
                                ศูนย์ไม่ถูกครอบคลุมโดยการเดิมพันใด ๆ ในคอลัมน์
                            </li>
                            <li>
                                Dozen Bet — วางชิปของคุณในหนึ่งในกล่องที่มีเครื่องหมาย "1st 12," "2nd 12" หรือ "3rd 12" เพื่อครอบคลุม 12
                                เลขตามข้างๆกล่อง
                            </li>
                            <li>
                                Red/Black — วางชิปของคุณในกล่องสีแดงหรือดำเพื่อครอบคลุม 18 เลขแดงหรือ 18 เลขดำ
                                ศูนย์ไม่ถูกครอบคลุมโดยการเดิมพันเหล่านี้
                            </li>
                            <li>
                                Even/Odd — วางชิปของคุณในหนึ่งในกล่องเหล่านี้เพื่อครอบคลุม 18 เลขคู่หรือ 18 เลขคี่
                                ศูนย์ไม่ถูกครอบคลุมโดยการเดิมพันเหล่านี้
                            </li>
                            <li>
                                1-18/19-36 — วางชิปของคุณในหนึ่งในกล่องเหล่านี้เพื่อครอบคลุมชุดแรกหรือชุดที่สองของ 18 เลข
                                ศูนย์ไม่ถูกครอบคลุมโดยการเดิมพันเหล่านี้
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">เดิมพันบ้านข้าง:</div>
                    <div className="content">
                        <div>
                            คลิก/แตะที่ปุ่ม NEIGHBOUR BETS
                            เพื่อดูพื้นที่เดิมพันที่มีรูปร่างเป็นวงรีหรือวงรถแข่งที่ช่วยให้คุณวางเดิมพันเพื่อบ้านข้างและเดิมพันพิเศษอื่น ๆ ได้ง่ายขึ้น
                            คลิก/แตะปุ่มอีกครั้งเพื่อปิด/เปิดคุณลักษณะนี้
                        </div>
                        <div>แต่ละการเดิมพันครอบคลุมเซตต่าง ๆ ของเลขและมีอัตราการจ่ายเงินต่าง ๆ พื้นที่เดิมพันจะถูกไฮไลท์</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Tiers du Cylindre:</div>
                    <div className="content">
                        <div>
                            การเดิมพันนี้ครอบคลุมทั้งหมด 12 เลขที่รวมถึง 27, 33, และเลขที่อยู่ระหว่างพวกนี้ที่ด้านข้างของวงล้อรูเล็ตที่ตรงข้ามกับศูนย์
                            วางชิป 6 ชิปดังนี้:
                        </div>
                        <ul>
                            <li>1 ชิปบนเส้นระหว่าง 5/8</li>
                            <li>1 ชิปบนเส้นระหว่าง 10/11</li>
                            <li>1 ชิปบนเส้นระหว่าง 13/16</li>
                            <li>1 ชิปบนเส้นระหว่าง 23/24</li>
                            <li>1 ชิปบนเส้นระหว่าง 27/30</li>
                            <li>1 ชิปบนเส้นระหว่าง 33/36</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Voisins du Zero:</div>
                    <div className="content">
                        <div>
                            การเดิมพันนี้ครอบคลุมทั้งหมด 17 เลขที่รวมถึง 22, 25, และเลขที่อยู่ระหว่างพวกนี้ที่ด้านข้างของวงล้อรูเล็ตที่มีศูนย์ วางชิป
                            9 ชิปดังนี้:
                        </div>
                        <ul>
                            <li>2 ชิปบนเส้นระหว่าง 0/2/3</li>
                            <li>1 ชิปบนเส้นระหว่าง 4/7</li>
                            <li>1 ชิปบนเส้นระหว่าง 12/15</li>
                            <li>1 ชิปบนเส้นระหว่าง 18/21</li>
                            <li>1 ชิปบนเส้นระหว่าง 19/22</li>
                            <li>2 ชิปบนมุม 25/26/28/29</li>
                            <li>1 ชิปบนเส้นระหว่าง 32/35</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ออร์แฟลินอา เชวาล:</div>
                    <div className="content">
                        <div>
                            การพนันนี้ครอบคลุมจำนวนทั้งหมด 8 ตัวเลขบนสองส่วนของวงล้อรูเล็ตที่ไม่ได้รับการครอบคลุมจากการพนัน voisins du zero และ tiers
                            du cylindre ด้านบน 5 ชิปถูกวางตามนี้:
                        </div>
                        <ul>
                            <li>1 ชิปบนตัวเลข 1 (ตรง)</li>
                            <li>1 ชิปบนตัวเลข 6/9 สปลิต</li>
                            <li>1 ชิปบนตัวเลข 14/17 สปลิต</li>
                            <li>1 ชิปบนตัวเลข 17/20 สปลิต</li>
                            <li>1 ชิปบนตัวเลข 31/34 สปลิต</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">เจอู เซโร:</div>
                    <div className="content">
                        <div>
                            การพนันนี้ครอบคลุมตัวเลขศูนย์และ 6 ตัวเลขที่อยู่ใกล้กับศูนย์บนวงล้อรูเล็ต: 12, 35, 3, 26, 0, 32, และ 15 ถูกวาง 4
                            ชิปตามนี้:
                        </div>
                        <ul>
                            <li>1 ชิปบนตัวเลข 0/3 สปลิต</li>
                            <li>1 ชิปบนตัวเลข 12/15 สปลิต</li>
                            <li>1 ชิปบนตัวเลข 26 (ตรง)</li>
                            <li>1 ชิปบนตัวเลข 32/35 สปลิต</li>
                        </ul>
                        <div>
                            การพนันเพื่อนบ้านครอบคลุมตัวเลขที่ระบุและตัวเลขอื่น ๆ ที่อยู่ใกล้กับมันบนวงล้อรูเล็ต
                            เพื่อวางเดิมพันเพื่อนบ้านคลิก/แตะที่ตัวเลขที่ระบุบน racetrack เฉพาะ
                            ชิปจะถูกวางที่ตัวเลขที่เลือกและที่อยู่ใกล้เคียงด้านขวาและด้านซ้าย คลิก/แตะที่ปุ่ม "+" หรือ "-"
                            ทราบในวงกลมเพื่อเพิ่มหรือลดชุดของตัวเลขเพื่อนบ้านทางด้านขวาและซ้ายของตัวเลขที่เลือก
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">การเดิมพันที่ชื่นชอบและพิเศษ:</div>
                    <div className="content">
                        <div className="content-title">การเดิมพันที่ชื่นชอบ</div>
                        <div>
                            คุณสามารถบันทึกการเดิมพันที่คุณชื่นชอบหรือการผสมผสานของการเดิมพันประเภทต่าง ๆ
                            เพื่อการวางเดิมพันที่ง่ายขึ้นในรอบต่อไปที่โต๊ะรูเล็ตใด ๆ คุณสามารถบันทึกและแก้ไขรายการของคุณสูงสุด 30
                            การเดิมพันที่คุณชื่นชอบ ภายใต้ชื่อที่แตกต่างกัน
                        </div>
                        <div className="content-title">บันทึกการเดิมพันที่ชื่นชอบ</div>
                        <div>เพื่อเปิดเมนูการเดิมพันที่ชื่นชอบ คลิก/แตะที่ปุ่ม FAVOURITE BETS คลิก/แตะปุ่มนี้อีกครั้งเพื่อปิดคุณลักษณะนี้</div>
                        <div>
                            หลังจากที่คุณได้วางเดิมพันที่ชื่นชอบหรือการผสมผสานของการเดิมพันที่รูเล็ตใด ๆ คลิก/แตะที่ลิงค์ SAVE LAST BET
                            ในเมนูการเดิมพันที่ชื่นชอบ ชื่อเริ่มต้นสำหรับการเดิมพันนี้จะถูกแนะนำ แต่คุณยินดีต้อนรับที่จะป้อนชื่อที่เข้าใจง่ายมากขึ้น
                            คุณจะสามารถบันทึกและเพิ่มการเดิมพันนี้ในรายการการเดิมพันที่ชื่นชอบของคุณได้ โดยคลิก/แตะปุ่ม SAVE หรือกด "Enter"
                            บนคีย์บอร์ดของคุณ
                        </div>
                        <div className="content-title">วางเดิมพันที่ชื่นชอบ</div>
                        <div>
                            เมื่อคุณต้องการวางเดิมพันที่ชื่นชอบในระหว่างเฟสการเดิมพันของรอบรูเล็ต
                            เปิดเมนูการเดิมพันที่ชื่นชอบเพื่อดูรายการของการเดิมพันที่คุณบันทึกไว้ก่อนหน้า พวกเขาจะถูกรายงานตามลำดับเวลา
                            กับการเดิมพันที่คุณชื่นชอบที่คุณบันทึกไว้แรกที่สุด
                            คุณสามารถเลื่อนเมาส์ของคุณเหนือชื่อของการเดิมพันที่ระบุเพื่อดูว่าชิปจะถูกวางไว้บนโต๊ะรูเล็ตตามที่เห็น
                            คลิก/แตะที่ชื่อของการเดิมพันที่ต้องการเพื่อวางมัน คุณยังสามารถทำให้จำนวนของการเดิมพันที่คุณชื่นชอบมากขึ้น (คูณ 2, 3, 4
                            เป็นต้น) โดยการคลิก/แตะชื่อของมันมากกว่าหนึ่งครั้ง
                        </div>
                        <div className="content-title">เปลี่ยนชื่อหรือลบการเดิมพันที่ชื่นชอบ</div>
                        <div>เมื่อเปิดเมนูการเดิมพันที่ชื่นชอบ คุณสามารถคลิก/แตะปุ่ม EDIT เพื่อลบหรือเปลี่ยนชื่อของการเดิมพันที่ระบุไว้</div>
                        <div>
                            เปลี่ยนชื่อของการเดิมพันที่ระบุไว้ก่อนโดยการคลิก/แตะภายในกล่องข้อความสีเทาที่คลุมรอบชื่อปัจจุบันของมัน
                            คุณจะสามารถป้อนชื่อใหม่และบันทึกได้โดยการคลิก/แตะปุ่ม SAVE หรือกด "Enter" บนคีย์บอร์ดของคุณ
                        </div>
                        <div>
                            ลบการเดิมพันที่คุณไม่ต้องการเก็บไว้ในรายการการเดิมพันที่ชื่นชอบของคุณได้ โดยการคลิก/แตะปุ่ม DELETE ของมันที่เกี่ยวข้อง
                        </div>
                        <div>
                            เมื่อคุณเสร็จสิ้นการแก้ไขรายการการเดิมพันที่ชื่นชอบของคุณ คลิก/แตะปุ่ม SAVE ที่มุมขวาบนของเมนูการเดิมพันที่ชื่นชอบ
                            หรือคลิก/แตะที่ปุ่ม FAVOURITE BETS
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">การเดิมพันพิเศษ:</div>
                    <div className="content">
                        <div>ภายใต้แท็บที่สองในเดิมพันที่ชื่นชอบคุณสามารถวางเดิมพัน Finale en plein และ Finale a cheval ได้ง่ายขึ้น</div>
                        <div className="content-title">Finale en plein</div>
                        <ul>
                            <li>Finale en plein 0 — การเดิมพัน 4 ชิป ครอบคลุม 0+10+20+30 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 1 — การเดิมพัน 4 ชิป ครอบคลุม 1+11+21+31 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 2 — การเดิมพัน 4 ชิป ครอบคลุม 2+12+22+32 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 3 — การเดิมพัน 4 ชิป ครอบคลุม 3+13+23+33 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 4 — การเดิมพัน 4 ชิป ครอบคลุม 4+14+24+34 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 5 — การเดิมพัน 4 ชิป ครอบคลุม 5+15+25+35 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 6 — การเดิมพัน 4 ชิป ครอบคลุม 6+16+26+36 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 7 — การเดิมพัน 3 ชิป ครอบคลุม 7+17+27 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 8 — การเดิมพัน 3 ชิป ครอบคลุม 8+18+28 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale en plein 9 — การเดิมพัน 3 ชิป ครอบคลุม 9+19+29 แต่ละตัวด้วย 1 ชิป</li>
                        </ul>
                        <div className="content-title">Finale a cheval</div>
                        <ul>
                            <li>Finale a cheval 0/3 — การเดิมพัน 4 ชิป ครอบคลุม 0/3+10/13+20/23+30/33 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 1/4 — การเดิมพัน 4 ชิป ครอบคลุม 1/4+11/14+21/24+31/34 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 2/5 — การเดิมพัน 4 ชิป ครอบคลุม 2/5+12/15+22/25+32/35 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 3/6 — การเดิมพัน 4 ชิป ครอบคลุม 3/6+13/16+23/26+33/36 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 4/7 — การเดิมพัน 4 ชิป ครอบคลุม 4/7+14/17+24/27+34 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 5/8 — การเดิมพัน 4 ชิป ครอบคลุม 5/8+15/18+25/28+35 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 6/9 — การเดิมพัน 4 ชิป ครอบคลุม 6/9+16/19+26/29+36 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 7/10 — การเดิมพัน 3 ชิป ครอบคลุม 7/10+17/20+27/30 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 8/11 — การเดิมพัน 3 ชิป ครอบคลุม 8/11+18/21+28/31 แต่ละตัวด้วย 1 ชิป</li>
                            <li>Finale a cheval 9/12 — การเดิมพัน 3 ชิป ครอบคลุม 9/12+19/22+29/32 แต่ละตัวด้วย 1 ชิป</li>
                        </ul>
                        <div className="content-title">การเดิมพันทั้งหมด</div>
                        <div>การเดิมพันทั้งหมดจะวางเดิมพันทุกประเภทที่อยู่ภายในที่เลือกลงบนหมายเลขเฉพาะ</div>
                        <div>
                            ตัวอย่างเช่น การเดิมพันทั้งหมดที่หมายเลข 36 จะวาง 18 ชิปเพื่อครอบคลุมมันทั้งหมด ดังนี้: 1 ชิปบน Straight Up 36, 2
                            ชิปบนแต่ละการเดิมพัน Split 33/36 และ 35/36, 3 ชิปบนการเดิมพัน Street 34/35/36, 4 ชิปบนการเดิมพัน Corner 32/33/35/36 และ 6
                            ชิปบนการเดิมพัน Line 31/32/33/34/35/36
                        </div>
                        <div className="content-title">การเดิมพัน Straight Up</div>
                        <div>
                            การเดิมพัน Straight Up จะวางเดิมพันทุกการเดิมพันตรงของประเภทที่เลือก (แดง, ดำ, คี่, คู่ ฯลฯ) ครอบคลุมแต่ละตัวด้วย 1 ชิป
                        </div>
                        <div>ตัวอย่างเช่น, การเดิมพัน Straight Up สีแดง จะวาง 18 ชิปเพื่อครอบคลุมตัวเลขทั้งหมดที่เป็นสีแดง</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ตัวเลขที่ชนะ:</div>
                    <div className="content">
                        <div>แสดงตัวเลขที่ชนะล่าสุดที่แสดง</div>
                        <div>ผลของรอบที่เสร็จสิ้นล่าสุดรายการทางซ้าย</div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">ผลลัพธ์ล่าสุดของคุณ:</div>
                    <div className="content">
                        <div>
                            คลิก/แตะที่ปุ่มสถิติเพื่อดูตารางของตัวเลขที่ชนะในรอบเกม 500 รอบที่ผ่านมา
                            ใช้ตัวเลื่อนเพื่อเปลี่ยนจำนวนรอบที่ผ่านมาเพื่อให้มันใช้
                        </div>
                        <div>
                            เมื่อคุณวางเมาส์หรือแตะบนส่วนใดของแผนภูมิสถิติ จุดบนโต๊ะเดิมพันที่ชิปจะถูกวางไว้จะถูกเน้น
                            คลิก/แตะที่เดิมพันเพื่อวางชิปของคุณ
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">การจ่ายเงิน:</div>
                    <div className="content">
                        <div>การจ่ายเงินของคุณขึ้นอยู่กับประเภทของการเดิมพันที่วาง</div>
                        <div className="content-title">การเดิมพันภายใน</div>
                        <div>ประเภทการเดิมพัน การจ่ายเงิน</div>
                        <table>
                            <tr>
                                <th>Straight Up</th>
                                <th>35:1</th>
                            </tr>
                            <tr>
                                <th>Split</th>
                                <th>17:1</th>
                            </tr>
                            <tr>
                                <th>Street</th>
                                <th>11:1</th>
                            </tr>
                            <tr>
                                <th>Corner</th>
                                <th>8:1</th>
                            </tr>
                            <tr>
                                <th>Line</th>
                                <th>5:1</th>
                            </tr>
                        </table>
                        <div className="content-title">การเดิมพันภายนอก</div>
                        <div>ประเภทการเดิมพัน การจ่ายเงิน</div>
                        <table>
                            <tr>
                                <th>คอลัมน์</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>โซน</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>แดง/ดำ</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>คี่/คู่</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>1-18/19/36</th>
                                <th>1:1</th>
                            </tr>
                        </table>
                        <div>ข้อผิดพลาดทางเทคนิคยกเลิกการจ่ายเงินและการเล่น</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">อัตราส่วนการจ่ายเงิน (Return to Player):</div>
                    <div className="content">
                        <div>การคืนเงินทฤษฎีที่ดีที่สุดคือ 97.30%.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">วางเดิมพัน (Place Bets):</div>
                    <div className="content">
                        <div>
                            แผง BET LIMITS แสดงขีดจำกัดการวางเดิมพันที่น้อยที่สุดและมากที่สุดที่อนุญาตให้วางเดิมพันที่โต๊ะ ซึ่งอาจเปลี่ยนไปตามบางครั้ง
                            เปิด Bet Limits เพื่อตรวจสอบขีดจำกัดปัจจุบันของคุณ
                        </div>
                        <div>Emperor Roulette MYR 2.50 - 10,000</div>
                        <div>
                            เพื่อเข้าร่วมเกม คุณต้องมีเงินทุนเพียงพอที่จะ cover การวางเดิมพันของคุณ คุณสามารถดู BALANCE
                            ปัจจุบันของคุณได้ที่หน้าจอของคุณ
                        </div>
                        <div>BALANCE MYR 100,000</div>
                        <div>
                            ในมุมมอง Immersive, ตัวจับเวลาแจ้งคุณเกี่ยวกับระยะเวลาในการวางเดิมพัน หลังจากหมดอายุ
                            การวางเดิมพันจะปิดและจะไม่มีการรับเดิมพันเพิ่มเติม
                        </div>
                        <div>
                            ในมุมมองคลาสสิก, ไฟจรวดแจ้งสถานะปัจจุบันในรอบเกมโดยแจ้งให้คุณทราบเมื่อคุณสามารถวางเดิมพัน (ไฟเขียว),
                            เมื่อเวลาการวางเดิมพันใกล้สิ้นสุด (ไฟเหลือง), และเมื่อเวลาการวางเดิมพันหมดอายุ (ไฟแดง)
                        </div>
                        <div>วางเดิมพันของคุณ</div>
                        <div>
                            แผง CHIP DISPLAY ช่วยให้คุณเลือกค่าของแต่ละชิปที่คุณต้องการวางเดิมพัน
                            จะเปิดให้ใช้เฉพาะชิปที่มีมูลค่าที่สามารถครอบคลุมได้จากยอดเงินปัจจุบันของคุณ
                        </div>
                        <div>
                            เมื่อคุณเลือกชิปแล้ว วางเดิมพันของคุณโดยการคลิก/แตะที่จุดเดิมพันที่เหมาะสมบนโต๊ะเกม ทุกครั้งที่คุณคลิก/แตะที่จุดเดิมพัน
                            จำนวนเงินของการเดิมพันของคุณจะเพิ่มขึ้นตามค่าของชิปที่เลือกหรือจนถึงขีดจำกัดสูงสุดสำหรับประเภทของการเดิมพันที่คุณเลือก
                            หลังจากวางเดิมพันถึงขีดจำกัดสูงสุดแล้ว เงินเพิ่มเติมจะไม่ได้รับการยอมรับสำหรับการเดิมพันนั้น ๆ
                            และข้อความจะปรากฎข้างบนการเดิมพันเพื่อแจ้งให้คุณทราบว่าคุณได้วางเดิมพันสูงสุด
                        </div>
                        <div>
                            หมายเหตุ: กรุณาอย่าย่อม browser หรือเปิดแท็บอื่นใน browser ขณะที่ยังเหลือเวลาการวางเดิมพันและคุณได้วางเดิมพันไว้ที่โต๊ะ
                            การกระทำดังกล่าวอาจถูกตีความว่าคุณออกจากเกม และการวางเดิมพันของคุณจะถูกปฏิเสธสำหรับรอบเกมนั้น ๆ
                        </div>
                        <div>
                            ปุ่ม DOUBLE (2x) จะมีให้ใช้งานหลังจากคุณได้วางเดิมพันใด ๆ ที่ลงไปแล้ว
                            การคลิก/แตะทุกรอบจะทำให้เดิมพันทั้งหมดของคุณเพิ่มขึ้นจนถึงขีดจำกัดสูงสุด
                            โปรดทราบว่าคุณต้องมียอดเงินเพียงพอเพื่อที่จะทำการเดิมพันทั้งหมดของคุณ
                        </div>
                        <div>ปุ่ม REPEAT ช่วยให้คุณทำซ้ำการเดิมพันทั้งหมดจากรอบเกมก่อนหน้านี้ ปุ่มนี้มีให้ใช้งานเฉพาะก่อนที่จะวางชิปครั้งแรก</div>
                        <div>ปุ่ม UNDO ลบการเดิมพันล่าสุดที่คุณวาง</div>
                        <div>
                            คุณสามารถคลิก/แตะปุ่ม UNDO ได้ซ้ำเพื่อลบการเดิมพันหนึ่งครั้งที่ละครั้งตามลำดับที่วางไว้
                            คุณสามารถล้างการเดิมพันทั้งหมดของคุณได้โดยการกดค้างปุ่ม UNDO
                        </div>
                        <div>ตัวบอกทั้งหมดแสดงจำนวนเงินรวมของการเดิมพันทั้งหมดที่วางในรอบปัจจุบัน</div>
                        <div>TOTAL BET MYR 500</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Live Support:</div>
                    <div className="content">
                        <div>ติดต่อ Live Support เพื่อข้อสงสัยที่เกี่ยวข้องกับเกม</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">นโยบายการตัดการเชื่อม:</div>
                    <div className="content">
                        <div>
                            หากคุณตัดการเชื่อมต่อจากรอบเกม การวางเดิมพันที่วางไว้ยังคงมีความถูกต้องและถูกตัดสินใจในการขาดทุนของคุณ
                            หลังจากเชื่อมต่อใหม่ คุณสามารถดูผลการเดิมพันในหน้าต่างประวัติ
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Autoplay:</div>
                    <div className="content">
                        <div>เมื่อคุณได้วางเดิมพันแล้ว Autoplay จะช่วยให้คุณทำซ้ำเดิมพันที่เลือกหรือเดิมพันสำหรับจำนวนรอบที่เลือกไว้</div>
                        <div>เพื่อเริ่ม Autoplay ให้วางเดิมพันและคลิก/แตะที่ปุ่ม Autoplay</div>
                        <div>ในแผง Autoplay คุณสามารถเริ่ม Autoplay โดยการเลือกจำนวนรอบที่คุณต้องการให้เดิมพันของคุณทำซ้ำ</div>
                        <div>
                            จำนวนรอบ Autoplay ที่คุณเลือกจะถูกแสดงบนปุ่ม Autoplay การเลือกจำนวนรอบ Autoplay ที่เหลือจะได้รับการอัปเดต เมื่อ Autoplay
                            เริ่ม
                        </div>
                        <div>Autoplay BET PER ROUND MYR 10</div>
                        <div>
                            จำนวนเงินเดิมพัน Autoplay ทั้งหมดของคุณคำนวณโดยการคูณจำนวนรอบ Autoplay กับค่าเดิมพันทั้งหมดของคุณ
                            มีการแสดงไว้ในแต่ละการ์ดรอบ Autoplay ด้านล่าง โดยจำนวนครั้ง Autoplay ที่เหลือจะถูกแสดงในแต่ละการ์ดรอบ Autoplay เมื่อ
                            Autoplay เริ่ม
                        </div>
                        <div>
                            รอบเกมอัตโนมัติของคุณจะดำเนินการต่อไปจนกว่าจะเสร็จสมบูรณ์จำนวนรอบ Autoplay ที่คุณเลือกหรือคุณเลือกหยุด Autoplay
                            โดยการคลิก/แตะที่ STOP
                        </div>
                        <div>STOP</div>
                        <div>เมื่อ Autoplay ถูกหยุดคุณจะสามารถทำซ้ำเดิมพันและรอบที่เลือกไว้ก่อนหน้าโดยการคลิก/แตะที่ REPEAT</div>
                        <div>REPEAT</div>
                        <div>
                            หากคุณวางเดิมพันเพิ่มเติมบนกริดเดิมพันหรือทำการเพิ่มเดิมพันขึ้นขณะที่ Autoplay กำลังทำงาน จะปรากฎข้อความที่จะช่วยให้คุณ
                            เลือกที่จะดำเนิน Autoplay ต่อด้วยเดิมพันที่เปลี่ยนแปลง หากคุณไม่ยืนยันที่จะดำเนิน Autoplay จะถูกหยุด
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Chat:</div>
                    <div className="content">
                        <div>พูดคุยกับผู้นำเกมและผู้เล่นอื่น ๆ</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Game Number:</div>
                    <div className="content">
                        <div>ทุกรอบเกมถูกระบุด้วยหมายเลขเกมที่ไม่ซ้ำกัน</div>
                        <div>
                            หมายเลขนี้แสดงถึงเมื่อรอบเกมเริ่มต้นในทาง GMT ตามชั่วโมง: นาที: วินาที โปรดใช้หมายเลขเกมนี้เป็นอ้างอิง
                            (หรือถ่ายภาพหมายเลขเกม) หากคุณต้องการติดต่อฝ่ายบริการเมื่อมีการถาม ถึงรอบที่เฉพาะเจาะจง
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">เสียง:</div>
                    <div className="content">
                        <div>การควบคุมเสียงทุกรายการในเกม</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ประวัติเกม:</div>
                    <div className="content">
                        <div>ประวัติเกมแสดงรอบและผลลัพธ์ของเกม Inno ของคุณ</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">การตั้งค่า:</div>
                    <div className="content">
                        <div>การตั้งค่าช่วยให้คุณปรับแต่งความชอบของผู้ใช้ที่ถูกบันทึกไว้ในโปรไฟล์ของคุณ</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Error Handling:</div>
                    <div className="content">
                        <div>
                            หากมีข้อผิดพลาดในเกม ระบบ หรือขั้นตอนการเล่นเกม รอบเกมจะถูกพักชั่วคราวในขณะที่โฮสต์เกมแจ้งผู้จัดการบริการ
                            คุณและผู้เล่นคนอื่น ๆ จะได้รับการแจ้งเตือนผ่านแชทหรือทางการป็อปอัพที่ปรากฏบนหน้าจอว่าปัญหานั้นกำลังได้รับการตรวจสอบ
                            หากผู้จัดการสามารถแก้ไขปัญหาได้ทันทีรอบเกมจะดำเนินการต่อไปเหมือนเดิม หากไม่สามารถแก้ไขได้ทันทีรอบเกมจะถูกยกเลิก
                            และเงินเดิมพันเริ่มต้นจะถูกคืนให้กับผู้เล่นทุกคนที่เข้าร่วมรอบเกม
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">กฎการหมุนรูเล็ตที่ถูกต้อง:</div>
                    <div className="content">
                        <div>หมายเลขที่ชนะมีความถูกต้องเมื่อการหมุนถูกพิจารณาว่าถูกต้อง</div>
                        <div>
                            เพื่อทำให้เกิดการหมุนที่ถูกต้องลูกบอลรูเล็ตต้องถูกหมุนโดยดีลเลอร์ในทิศทางตรงกันข้ามกับการหมุนของล้อ
                            และต้องเสร็จสิ้นอย่างน้อย 3 รอบเต็มรอบรอบที่ล้อกลับกลับวนลูกบอลก่อนที่จะตกลงและหยุดอยู่ในช่องเลขบนล้อ
                        </div>
                        <div>การหมุนที่ไม่ถูกต้องจะถูกประกาศในกรณีต่อไปนี้:</div>
                        <ul>
                            <li>ลูกบอลมีรอบน้อยกว่า 3 รอบเต็ม</li>
                            <li>ลูกบอลถูกหมุนในทิศทางเดียวกับการหมุนของล้อ</li>
                            <li>ล้อหยุดหมุนขณะการหมุน</li>
                            <li>ลูกบอลหลุดออกจากล้อขณะการหมุน</li>
                            <li>วัตถุประสงค์ภายนอกเข้าไปในล้อขณะการหมุน</li>
                        </ul>
                        <div>ในกรณีที่หมุนไม่ถูกต้อง ดีลเลอร์จะทำการหมุนใหม่</div>
                        <div>ในกรณีของความผิดปกติใด ๆ โปรดให้หมายเลขเกมของรอบนั้นถึงฝ่ายสนับสนุนสด</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">เกมเพิ่มเติม:</div>
                    <div className="content">
                        <div>Inno Lobby — เลือกเกมง่ายๆ โดยไม่ต้องออกจากเกมปัจจุบันของคุณจนกว่าจะเลือกเกมใหม่</div>
                    </div>
                </div>
            </div>
        ),
        mm: (
            <div className="rt-how-to-play">
                <div className="section">
                    <div className="title">ဂိမ် ပါဝင်မည့် အခြေအနေများ:</div>
                    <div className="content">
                        <div>
                            ရွေးချယ်ရန် Roulette မှ ဘယ်နှစ်သို့မဟုတ်ဘယ်နှစ်အတိုင်းကြီးထဲတွင်ဘယ်ရှိမည်ဆိုတဲ့ ကိန်းမှာ ပေါင်းထည့်နိုင်တဲ့နံပါတ်တွေကို
                            ရွေးချယ်ထားတဲ့အတိုင်းများကိုလည်းပါဝင်နိုင်သည်။ Roulette မှာ ကိန်းများသည် 1 မိနစ်မှ 36 အထိတို့ဖြစ်ပါသည်၊ တစ်ခါတည်းက 0
                            (သုံး) ပါဝင်သည်။
                        </div>
                        <div>ဘယ်နှစ်အတိုင်းအနားလိုက်တယ်ဆိုတာကိုပြသလိုအပ်ပါသည်။ ဘယ်နှစ်အတိုင်းအနားလိုက်တယ်ဆိုတာကိုပြသလိုအပ်ပါသည်။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ဘယ်နှစ်အတိုင်းအနားလိုက်များ:</div>
                    <div className="content">
                        <div>
                            မိနစ်နှစ်အတိုင်းကို Roulette ထဲမှ မိနစ်တစ်လုံးကို ရွေးချယ်ပါက ဘယ်နှစ်လုံးကိုကြိုက်နှင့်တွဲမှုများကို ပေးပို့ပေးနိုင်သည်။
                        </div>
                        <div>
                            မိနစ်များကိုမှတ်ပုံတင်ထားသော ဘယ်တိုက်များကို Inside Bets လို့ပြောင်းလဲထားသော ဘယ်မှာယူထားတဲ့ အနားလိုက်များများကိုလည်း
                            ဘယ်တိုက်များကို Outside Bets လို့ပြောင်းလဲထားသော ဘယ်တိုက်များများကိုလည်း ပေးပို့သည်။
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">အတွေ့အကြုံ များ:</div>
                    <div className="content">
                        <ul>
                            <li>စတုရန် လုံးဝ — တစ်ရက် (အသိပေးကြည့်ရန်လိုအပ်ပါသည်။ သီတင်းသောအခါအတွင်၏ အချိုးသော နံနက်ကိုလည်း ရွှေလျော့နှိပ်ပါ။)</li>
                            <li>
                                နှစ်ကြိမ် လုံးဝ — နှစ်ကြိမ်နှင့်သည်ပြောင်းလျော့နှိပ်သည့် နံနက်တွင် သုံးသောအရွယ်အစားတစ်ခုအထိ ရွှေလျော့နှိပ်ပါ။
                                (သို့မဟုတ်) အောက်တွင်ငွေလွှဲသည့် သီတင်းကြားတွင်၏ နံနက်များအား အကောင်းဆုံးအသံများအားလုံး ရွှေလျော့နှိပ်ပါ။)
                            </li>
                            <li>
                                နှစ်မြေလုံး လုံးဝ — နှစ်မြေလုံးများရှိတွင် ငွေကို အစားတိုးတွင် တွေ့ရန်လိုအပ်ပါသည်။ နှစ်မြေလုံးများ ၃ နံနက်အား
                                ရွှေလျော့နှိပ်ပါ။)
                            </li>
                            <li>
                                ကွမ်း လုံးဝ — နှစ်မြေလုံးများနှင့်သည်ပြောင်းလျော့နှိပ်သည့်အရွယ်အစားတစ်ခုတို့ကို အသားအရွယ် (အာကာသရှိနေသော နံနက်များအား
                                လည်း) ဆိုသည်။ အာကာသအား လည်း ရွှေလျော့နှိပ်သည့်အရွယ်အစားတစ်ခုတို့ကို အပြန်အလှန်အားလုံးပြင်ဆင်ခြင်းဖြစ်သည်။
                            </li>
                            <li>
                                လိုင် လုံးဝ — နှစ်မြေလုံးများအား နှစ်မြေတွင် နှစ်မြေလုံးတွင် တွေ့ရန်လိုအပ်ပါသည်။
                                လိုင်လုံးလျော့နှိပ်သည့်အရွယ်အစားတစ်ခုတို့ကို အပြန်အလှန်အားလုံးပြင်ဆင်ခြင်းဖြစ်သည်။ နံနက်အားလုံး ၆ နံနက်အား
                                ရွှေလျော့နှိပ်သည်။
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ပြည်သူများအား ထိန်းချုပ်များ:</div>
                    <div className="content">
                        <ul>
                            <li>
                                ကောလိပ်ထဲမှ တစ်ဆင့် "2 သိန်းအနက်" တွင် သင့်တစ်ဆင့်ရဲ့ ခရိုင်တစ်ရာအတွင်းမှာရှိနိုင်သည်။ ဤအဆင့်သည်
                                ဤအကြောင်းကိုသင့်တစ်ရာအတွင်းမှာ ပြင်ဆင်နိုင်ရန်လိုအပ်သည်။ သင့်တစ်ရာမှာရှိနိုင်ရန်မရပါ။
                            </li>
                            <li>
                                ကောလိပ်ထဲမှ တစ်ရာတစ်ခုစားပွဲ "1st 12," "2nd 12" သို့မဟုတ် "3rd 12" များတွင် သင့်တစ်ရာအတွင်းမှာရှိနိုင်သည်။ ဤအဆင့်သည်
                                ဤအကြောင်းကိုသင့်တစ်ရာအတွင်းမှာ ပြင်ဆင်နိုင်ရန်လိုအပ်သည်။
                            </li>
                            <li>
                                ကောလိပ်/အနက် — သင့်ခရိုင်တစ်ခုမှာရှိနိုင်သည် "ဂန်း" သို့မဟုတ် "အနက်" အနက်တစ်ခုတွင် သင့်တစ်ရာအတွင်းမှာရှိနိုင်သည်။
                                ဤအဆင့်သည် ဤအကြောင်းကိုသင့်တစ်ရာအတွင်းမှာ ပြင်ဆင်နိုင်ရန်လိုအပ်သည်။
                            </li>
                            <li>
                                အလကား/အထက် — သင့်ခရိုင်တစ်ခုမှာရှိနိုင်သည် အသက် သို့မဟုတ် အသွင်း အသက် အကြောင်းတွင် သင့်တစ်ရာအတွင်းမှာရှိနိုင်သည်။
                                ဤအဆင့်သည် ဤအကြောင်းကိုသင့်တစ်ရာအတွင်းမှာ ပြင်ဆင်နိုင်ရန်လိုအပ်သည်။
                            </li>
                            <li>
                                1-18/19-36 — သင့်ခရိုင်တစ်ခုမှာရှိနိုင်သည် အစက် သို့မဟုတ် အဆံ့အနက် တစ်ခုတွင် သင့်တစ်ရာအတွင်းမှာရှိနိုင်သည်။ ဤအဆင့်သည်
                                ဤအကြောင်းကိုသင့်တစ်ရာအတွင်းမှာ ပြင်ဆင်နိုင်ရန်လိုအပ်သည်။
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">မိမိမူလတ်ဝှက်များ:</div>
                    <div className="content">
                        <div>
                            NEIGHBOUR BETS ခလုတ်ကို တွေ့၍ သင့်အကြောင်းကို အထူးပြုလုပ်ရန် တိုက်ရိုက်ရိုက်နှိပ်ပြီးကြည့်ရန် NEIGHBOUR BETS ခလုတ်ကို
                            နှိပ်ပါ။ ဒါမှမဟုတ် ဤအရာကို ပိတ်ပိတ်ဖြင့်ဖြင့် ပြန်လည်ဆက်သွယ်ပါလိမ့်မယ်။
                        </div>
                        <div>
                            တစ်ခုလုံးသည် ပြည်ထောင်စုနံပါတ်များကို ကြေညာခြင်းနှင့် အခြားအကြာအဆိုပါလိုလျှင်၊ ပါဝင်သည့်နံပါတ်များကို ပြန်ပြင်လိမ့်မယ်။
                            ဘက်တာတို့သည် ပြန်လည်စားသောအရာများကိုပြပေးသည်။
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">Tiers du Cylindre:</div>
                    <div className="content">
                        <div>
                            ဤပ်က္ကသိုလ်ကိုကော်လံမူလတ်ဝှက်တစ်ခု ပြီးဆုံးရန် 27, 33 နှင့် ဒုတိယအောင်အစားအတွက် ပေါင်းရေးထားသော 12 နံပါတ်များကို
                            ဖြင့်ဖြင့် မိမိရဲ့ ရောဂါဆွေးလမ်းနှင့်တူညီလိုက်သည်။ ဒုတိယကို ဖြင့်ဖြင့် 6 ချိန် အရှည်အစိတ်ကို ထည့်ပေးသည်။
                        </div>
                        <ul>
                            <li>5/8 ရဲ့ အဆုံးပြောင်းမှ 1 ချိန်</li>
                            <li>10/11 ရဲ့ အဆုံးပြောင်းမှ 1 ချိန်</li>
                            <li>13/16 ရဲ့ အဆုံးပြောင်းမှ 1 ချိန်</li>
                            <li>23/24 ရဲ့ အဆုံးပြောင်းမှ 1 ချိန်</li>
                            <li>27/30 ရဲ့ အဆုံးပြောင်းမှ 1 ချိန်</li>
                            <li>33/36 ရဲ့ အဆုံးပြောင်းမှ 1 ချိန်</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ရွေးချယ်ရေး ကိုယ်အပြတ်:</div>
                    <div className="content">
                        <div>
                            ဤကဲ့သို့ ကျန်းမာရေးများမှ အသား ၁၇ အရှည်အနေနှင့် ၂၂ နှင့် အရှေ့မှာရှိသော ဂုဏ်ယူနစ်များကို ပါဝင်သည်။ ဤကျန်းများတွင်
                            ပုံစံအတိုင်း ၉ ကျော် တိုးတက်ထိုးသည်။
                        </div>
                        <ul>
                            <li>ပုံစံသော ၀/၂/၃ လမ်းတွင် ၂ ကျော်ထိုးသည်</li>
                            <li>ပုံစံသော ၄/၇ လွတ်ထိုးသည်</li>
                            <li>ပုံစံသော ၁၂/၁၅ လွတ်ထိုးသည်</li>
                            <li>ပုံစံသော ၁၈/၂၁ လွတ်ထိုးသည်</li>
                            <li>ပုံစံသော ၁၉/၂၂ လွတ်ထိုးသည်</li>
                            <li>ပုံစံသော ၂၅/၂၆/၂၈/၂၉ လွတ်ထိုးသည်</li>
                            <li>ပုံစံသော ၃၂/၃၅ လွတ်ထိုးသည်</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">အိုဖယ်လ်န်းများအဆာန်:</div>
                    <div className="content">
                        <div>
                            ဤကျန်းများသည် ရွေးချယ်ရေး ကိုယ်အပြတ်နှင့် တိယအတိုင်းအလျင်များမှ ကျန်းမာရေး ရွေးချယ်ရေးများအထိ မရှိသော ၈ နှစ်များကို
                            ပါဝင်သည်။ ဤကျန်းများတွင် ကိုယ်အနေနှင့် ထိန်းသိမ်းထိုးထားသော ၅ ကျော် တိုးတက်ထိုးသည်။
                        </div>
                        <ul>
                            <li>ပုံစံသော ၁ (လွတ်မှုတွင်)</li>
                            <li>ပုံစံသော ၆/၉ လွတ်ထိုးသည်</li>
                            <li>ပုံစံသော ၁၄/၁၇ လွတ်ထိုးသည်</li>
                            <li>ပုံစံသော ၁၇/၂၀ လွတ်ထိုးသည်</li>
                            <li>ပုံစံသော ၃၁/၃၄ လွတ်ထိုးသည်</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ဂျူ ဇီရို:</div>
                    <div className="content">
                        <div>
                            ဒီဘတ်သို့ လက်ရှိအတန်းအတွင်း ပါဝင်သော စာရင်းတစ်ခုဖြစ်သည်။ ထိုင်းတစ်မျိုး ကိုယ်တိုင်မြန်မာစွာရွေးချယ်ရန် ရွေးချယ်ပေးသော
                            နံပါတ်များတွင် အရှေ့အားပေးရမည်ဟုလည်းကိုယ်စားမပြတ်ချက်တွင်ဖြည့်သွင်းသော စာရင်းဖြစ်သည်:
                        </div>
                        <ul>
                            <li>0/3 ထိုင်းနှင့် 1 ချီး</li>
                            <li>12/15 ထိုင်းနှင့် 1 ချီး</li>
                            <li>26 (စလွှာသည်)</li>
                            <li>32/35 ထိုင်းနှင့် 1 ချီး</li>
                        </ul>
                        <div>
                            အတန်းအတွင်း နောက်တစ်လုံးကို ပြသပေးမည့် အမျိုးအစားလိုလျှင် ရွေးချယ်သော နံပါတ်တစ်လုံးတွင် ကိုယ်တိုင်မြန်မာစွာရွေးချယ်ထားသည်။
                            နံပါတ်တစ်လုံးတွင် ကိုယ်တိုင်မြန်မာစွာရွေးချယ်ထားမည်အတွက် ရွေးချယ်သော နံပါတ်တစ်လုံးတွင် နောက်တစ်လုံးအတွင်းသို့
                            ကိုယ်တိုင်မြန်မာစွာရွေးချယ်ထားမည်အတွက်ဖြည့်ရန် ရှိပါတယ်။ ရွေးချယ်သော နံပါတ်တစ်လုံးတွင်
                            ကိုယ်တိုင်မြန်မာစွာရွေးချယ်ထားမည်အတွက် ရိပ်သို့သော သတိမအပေါ်တွင် "-" သို့မဟုတ် "+" အကြားကို ကန့်သတ်မည်ဖြင့်
                            အများကြီးအတိုင်း ကိုယ်တိုင်မြန်မာစွာရွေးချယ်သည်။
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်များ:</div>
                    <div className="content">
                        <div className="content-title">အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်များ</div>
                        <div>
                            အရွယ်ရောက်လိုသော အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်များကို စိတ်ကြိုက်လိုပါက အရေးကြီးလိုက်ရိုက်ပေးပါ။ သင်သည် အကယ်၍
                            လက်ဆေးစတင်သူကို အရေးကြီးလိုက်ရိုက်ရန် သို့မဟုတ် အကယ်၍ မီးခြစ်နှင့် အထူးနိုင်သော အမှတ်တံဆိပ်များကို တစ်ဆင့် လက်ခံနိုင်သည်။
                            သင်သည် အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်များအတွက် တွင်ဖော်ပြထားသည် နှင့် တစ်ဦးမှ အထူးပေါ်မှသာ
                            ကျွန်တော်အားထဲတွင်အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်များအား သိမ်းဆည်းပေးပါရန်။
                        </div>
                        <div className="content-title">အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ် ကို သိတ်မြင်နိုင်ခြင်း</div>
                        <div>
                            အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်များကို ဖွင့်/နှိပ်ရန် FAVOURITE BETS ခလုတ်ကို နှိပ်ပြီးနှိပ်ရန်အတွက် ပေးပါ။ ဒီကာကို
                            ပိတ်ပင်ခလုတ်ကို နှိပ်ပြီးပြန်လည်သွားပါ။
                        </div>
                        <div>
                            အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်များ သို့မဟုတ် အမှတ်တံဆိပ်အား ပေးသွင်းပါလိမ့်မည်နှင့် ထိုအမှတ်တံဆိပ်ကို ဖြည့်သွင်းပါက
                            ဖော်ပြထားသည့် အမှတ်တံဆိပ်အတွက် အသစ်အသပ်အတိုင်း ရေးချနိုင်သည်။ Favourite Bets မှတ်တမ်းသို့ အနှစ်သို့ အထူးနိုင်သော
                            အမှတ်တံဆိပ်ကို ဆရာ/နည်းလမ်း တစ်ကြိမ်ထောင် ဖော်ပြထားသည့်အတွက် FAVOURITE BETS ခလုတ်ကို နှိပ်/နှိပ်ပြီး သိမ်းဆည်းသွားပါ။
                            ဤအနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်ကို ဆရာနှင့်နည်းလမ်း တစ်ကြိမ်ထောင် လက်ဆေးစတင်သူမှ အရွယ်ရောက်မှုအတွက် သိမ်းဆည်းပေးပါရန်
                            FAVOURITE BETS ခလုတ်အား နှိပ်/နှိပ်ပြီး သိမ်းဆည်းပါလိမ့်မည်။ ဤအမှတ်တံဆိပ်အတွက် အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်ကို
                            လက်ဆေးစတင်သူမှ အရွယ်ရောက်မှုအတွက် တွင် အနှစ်သို့ အထူးနိုင်သော အမှတ်တံဆိပ်များအား မျှဝေနိုင်သည့်အတွက် ဆော့ဝဲ ကို
                            ဖြည့်သွင်းပေးပါ။
                        </div>
                    </div>
                    <div className="content-title">အကြိုက်ဆုံး ဘေတ် ထည့်ရန်</div>
                    <div>
                        အဆိုပါရွေးချယ်မှုအချိန်မှ ဘေတ် ထည့်ရန်အတွက် Roulette ရဲ့ ပြိုင်ဆိုင်အံနောက်မှ ဘေတ်အတွက် Favourite Bets မီဒီယာကို ဖွင့်ပါ။
                        အားလုံးအတိအကျသောပေါ်မှာ ရသော ဘေတ်များအား စာရင်းပြင်းအလိုက် စာရင်းများအရ သတိပေးထားသောအခါမှာ အကြောင်းအရာကို ပြသရန် မော်နီရသည်။
                        အသင်းတစ်ကြိမ်ထည့်သွင်းရန် ရှေ့ကျထောင်ခြင်းနှင့် ထိန်းထိုးခြင်းအတွင်း ဘေတ်အတွက်ရှိထားသောအမည်တွေအား သွားပြီးသောအတိအကျ စာရင်းကို
                        အစားထိန်းသိမ်းထားပါ။ အကြိုက်ဆုံးထည့်သွင်းရန် လိုအပ်သော ဘေတ်အတွက် နှိပ်နိုင်ရန် ထိုက်တန်းကို နှိပ်ရန်နှင့်အတူ
                        ထိုက်တန်းနှင့်ဆုံးအတူ ဘေတ်အတွက် ကာလနှင့်မြှင့်ကို အမှတ်တစ်ခုခုဖြင့် ပြသလိုအပ်သည်။ အတိအကျရောက်မှာတိုင်းအစားထိန်းထားပါ။
                        နောက်ပြင်ဆင်နိုင်မှာ ဘေတ်အတွက် ထိုက်တန်းအမည်အား နှိပ်ခြင်းနှင့် ဘေတ်အတွက် ရှေ့ကျ ဘေတ်အမည်အား နှိပ်ခြင်းအတွင်း
                        အသံထိန်းသိမ်းထားပါ။
                    </div>
                    <div className="content-title">ဘေတ်အမည် သိမ်းခြင်း သို့မဟုတ် ဖျက်သိမ်းခြင်း</div>
                    <div>
                        ဖွင့်နိုင်ရန် Favourite Bets မီဒီယာ ဖွင့်ပါရန် EDIT ခလုတ်ကို နှိပ်ရန် တိုက်ရိုက် သော ဘေတ်အမည်တွေကို ဖျက်သွင်းနိုင်ပါတယ်။
                    </div>
                    <div>
                        ဘေတ်အမည်တွေကို ဖျက်သွင်းမှုအတိအကျ အလုပ်လုပ်နေသော ပြင်ဆင်နိုင်မှာတိုင်းအစားထိန်းထားပါ။ စာရင်းအပြင်ဆင်နိုင်ပါတယ်ဆိုရင်တော့
                        ဤမီဒီယာတွင် ဤနေရာတွင်ရှိသည့် မော်နီများအတွက်ပါ စာပြင်းကို တင်ပေးပါ။ နောက်ပြင်ဆင်မှာ စာရင်းကို အနှံ့မှ ဖောင်းပြနိုင်ခြင်းနှင့်
                        ဘေတ်အတွက် နှိပ်ခြင်းအတွင်း နှိပ်ထားသည်ကို မြင့်တင်နိုင်ပါတယ်။
                    </div>
                    <div>
                        သင့်ရဲ့ ဘေတ်အမည်တွေကို ဤဖျက်သိမ်းခြင်းမှာ မော်နီနှင့်ပတ်သတ်နိုင်သည့် အမည်တွေကို အကြောင်းအရာဖြင့် ရိုက်ထုတ်နိုင်ရန်
                        နှိပ်ခြင်းအတွင်း သင့်ပြုသော အမည်ကို ရိုက်ထုတ်နိုင်ပါတယ်။ သင့်ရဲ့ အမည်အား ရိုက်ထုတ်ခြင်းဖြင့် အသစ်ထပ် မော်နီရလိမ့်မှာ
                        ရွေးချယ်ထားပါတယ်။
                    </div>
                    <div>
                        သင်သည် သင့်ရဲ့ အကြိုက်ဆုံးရွေးချယ်စာရဲ့ စာရင်းကို တည်းဖြတ်လိုပါက၊ Favourite Bets မီနူးအတွင် ပေါ်တွင် ရွေးချယ် ခလုတ်မှာ
                        ရိုက်/ချပ်ပါလိမ့်မည်။ သင်သည် သင့်ရဲ့ Favourite Bets မီနူးတွင် ထပ်ကြိုက်ဆုံးပြင်ဆင်ရန် ရိုက်/ချပ်ခြင်းသို့ မည်သည့် စာရွက်အတွက်
                        SAVE ခလုတ်ကို နောက်ထပ် ခလုတ်အောက်တွင် ရွေးချယ်ပါ သို့မဟုတ် FAVOURITE BETS ခလုတ်ကို ရိုက်/ချပ်ပါလိမ့်မည်။
                    </div>
                </div>
                <div className="section">
                    <div className="title">အထူးဇာတ္လမ္းမ်ား:</div>
                    <div className="content">
                        <div>
                            Favourite Bets အတြင္းတုိင္းရင္ ဒုတိယ တုိင္းတာမွတာတြင္ Finale en plein နှင့္ Finale a cheval တို႔ကို အသံုးျပဳနိုင္သည္။
                        </div>
                        <div className="content-title">Finale en plein</div>
                        <ul>
                            <li>Finale en plein 0 — 0+10+20+30 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 1 — 1+11+21+31 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 2 — 2+12+22+32 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 3 — 3+13+23+33 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 4 — 4+14+24+34 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 5 — 5+15+25+35 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 6 — 6+16+26+36 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 7 — 7+17+27 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 3 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 8 — 8+18+28 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 3 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale en plein 9 — 9+19+29 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 3 ခ်စ္ဖြင့္ပါ</li>
                        </ul>
                        <div className="content-title">Finale a cheval</div>
                        <ul>
                            <li>Finale a cheval 0/3 — 0/3+10/13+20/23+30/33 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 1/4 — 1/4+11/14+21/24+31/34 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 2/5 — 2/5+12/15+22/25+32/35 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 3/6 — 3/6+13/16+23/26+33/36 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 4/7 — 4/7+14/17+24/27+34 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 5/8 — 5/8+15/18+25/28+35 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 6/9 — 6/9+16/19+26/29+36 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 4 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 7/10 — 7/10+17/20+27/30 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 3 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 8/11 — 8/11+18/21+28/31 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 3 ခ်စ္ဖြင့္ပါ</li>
                            <li>Finale a cheval 9/12 — 9/12+19/22+29/32 စနစ္မ်ားအား 1 ခ်စ္ဖြင့္ 3 ခ်စ္ဖြင့္ပါ</li>
                        </ul>
                        <div className="content-title">အပိုင္းအျခား အထူးဇာတ္လမ္းမ်ား</div>
                        <div>အပိုင္းအျခား အထူးဇာတ္လမ္းတစ္ခုကို တစ္ခုရွိရန္ အသံုးျပဳသင့္သည္။</div>
                        <div>
                            ဥပမာအတိုင္း အပိုင္းအျခား အထူးဇာတ္လမ္း 36 မွာ အခ်ိန္လိုက္ဖြင့္ ထိုသို႔ 18 ခ်စ္ဖြင့္ပါ: 1 ခ်စ္ဖြင့္ စတာဗြီဒီယို 36 ထိုသို႔၊
                            2 ခ်စ္ဖြင့္ ဆလိပ္လိပ္စတာဗြီဒီယို 33/36 နှင့္ 35/36 ၏အထိနည္းမ်ား၊ 3 ခ်စ္ဖြင့္ စတာဗြီဒီယို 34/35/36 နှင့္အျပည့္စတာဗြီဒီယို၊
                            4 ခ်စ္ဖြင့္ အျပည့္အျပည့္စတာဗြီဒီယို 32/33/35/36 ၏ကေလး၊ နှင့္ 6 ခ်စ္ဖြင့္ အျပည့္အျပည့္စတာဗြီဒီယို 31/32/33/34/35/36
                            ၏ကေလးတို႔ကို ထိန္းသိမ္းသင့္သည္။
                        </div>
                        <div className="content-title">နှစ်ခုတိုင်း ဘေးများ</div>
                        <div>
                            နှစ်ခုတိုင်း ဘေးများသည် ရွေးချယ်မှုအမျိုးအစားတစ်ခု (အန္တရာယ်, အန္တရာယ်မ, များသည်) အားလုံးကို
                            အတည်ပြုပြင်ဆင်ဖွဲ့ထားပေးပါသည်။ တစ်ခုကိုပါဝင်ပေးပါသည်။
                        </div>
                        <div>ဥပမာအန္တရာယ်အတွင်း နှစ်ခုများကို အလွတ်တင်စေခြင်းဖြင့် အားလုံးကို ပါဝင်ပေးပါသည်။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">အောင်မြင် နံပါတ်များ:</div>
                    <div className="content">
                        <div>ဆိုက်ရိုက် နောက်ဆုံးထိ ဆောင်ရွက်သည့် နံပါတ်များ ပြပေးပို့သည့်အခါ။</div>
                        <div>နောက်ဆုံး လက်ရှိ ပြုပြင်ပုံသည့် ကွန်ပျူတာအတွင် ခွဲခြားထားသည့်အခါ မြင့်မားထားပါသည်။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">သင့်ရဲ့ နောက်ဆုံး ရလဒ်များ:</div>
                    <div className="content">
                        <div>
                            နောက်ဆုံး ပြုပြင်ပုံစံကို ဖွင့်ရန် STATISTICS ခလုတ်ကို နှိပ်ပြီး အမှားတင်နိုင်ပါသည်။ အရှည်ကြီးကို ပြင်ဆင်ဖို့
                            ပြန်လည်နံပါတ်များရှိအောင် ဤတိုင်းကို အသုံးပြုနိုင်သည်။
                        </div>
                        <div>
                            သင်ရဲ့ cursor ကို statistics ပုံမှ အရောင်းနှင့် ထိန်းသိမ်းမည့်အချိန်တွင် ပျောက်ဆုံးပိုင်းနိုင်မည်အား အလွန်နိုင်သည်။
                            သင်လည်း သင့်ရဲ့ ခွေးသားအများကြီးတွင် ဇုန်တိုင်း နိုင်သော နှစ်ကိရိယာတွင် နှိုင်းယှဉ်မည်။ သို့မဟုတ် click/tap လုပ်ရန်
                            သင့်ရဲ့ chip ကို ထည့်ပေးသည်။
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ပက်ကစားမှုများ:</div>
                    <div className="content">
                        <div>
                            သင့်ထံမှ ရောင်းချခြင်းအတွက် သင့်ရဲ့ ပေါင်းတွက်အများကြီးသို့ ရှေ့သို့မဟုတ် ပေါင်းတွက်အများကြီးသို့
                            အထိအချိန်ကိုလက်ခံရရှိသည်။
                        </div>
                        <div className="content-title">အတွေ့အကြုပ်များ</div>
                        <div>အတွေ့အကြုပ် ပေါင်းတွက်</div>
                        <table>
                            <tr>
                                <th>နောက်မှာ အလွှတ်</th>
                                <th>35:1</th>
                            </tr>
                            <tr>
                                <th>အဆုတ်</th>
                                <th>17:1</th>
                            </tr>
                            <tr>
                                <th>လမ်း</th>
                                <th>11:1</th>
                            </tr>
                            <tr>
                                <th>ကွက်</th>
                                <th>8:1</th>
                            </tr>
                            <tr>
                                <th>စတုရန်</th>
                                <th>5:1</th>
                            </tr>
                        </table>
                        <div className="content-title">အောက်ပိုင်းအတွေ့အကြုပ်များ</div>
                        <div>အောက်ပိုင်းအတွေ့အကြုပ် ပေါင်းတွက်</div>
                        <table>
                            <tr>
                                <th>ကောလိပ်</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>ဆုံးဆယ်</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>အန္တရာယ်/အဝတ်</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>လက်ဆုံး/အလွတ်</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>1-18/19/36</th>
                                <th>1:1</th>
                            </tr>
                        </table>
                        <div>အလွတ်မှာလွှတ်ချက်များအားအထိအချိန်ကို ပိုမိုမြောက်ပတ်သက်သည်။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ပြန်စာအမည်:</div>
                    <div className="content">
                        <div>အကယ်၍ တည်နေရာအသစ်ဖြင့် လုပ်ဆောင်ရှိနိုင်သော ပရိုမိုးရှင်သည် 97.30% ဖြစ်သည်။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ဘတ်ကင်များ ထည့်သွင်းရန်:</div>
                    <div className="content">
                        <div>
                            BET LIMITS ပန်းချုပ်သည် တည်နေရာအသစ်တစ်ခုနှင့် တည်နေရာအဆင့်ကို နောက်ခံသည့် ပရိုမိုးရှင်များ၏
                            နိုင်ငံခြားတည်နေရာများအတွင်းကို ပြောင်းလဲနိုင်ခြင်းဖြင့် မျှဝေသည်။ သင်၏ လက်ရှိ ပေးပြီးမှတ်တမ်းကို စစ်ဆေးပါ။
                        </div>
                        <div>Emperor Roulette MYR 2.50 - 10,000</div>
                        <div>အဆိုပါအားကို အကြောင်းအရာတွင် လက်ကျန်မှာ သင်၏ လက်ကျန်ကို ကြည့်နိုင်သည်။</div>
                        <div>BALANCE MYR 100,000</div>
                        <div>
                            မြှင့်တိုင်းအားဖြင့် TIMER သည် တည်နေရာအသစ်တစ်ခုတည်းတက်သော အချိန်အခြားသင့်လျှင် ဘတ်အင်းအားလက်ခံနေသည်။ ဒီအချိန်မှာ သင်၏
                            ဘတ်အင်းကို ပိတ်ရန် နှိပ်သည်။
                        </div>
                        <div>
                            ကလားအမျိုးအစားမှာ, TRAFFIC LIGHTS တွင်သင့်အတွက် လက်တွဲအခြေအနေကို သတ်မှတ်နိုင်သည်။ သင်သည်ဘယ်လက်တွဲတွင် ဘယ်လက်တွဲတစ်လုံးသို့
                            ဘယ်လက်တွဲသတ်မှတ်နိုင်သလဲဆိုတာကိုသိမ်းဆည်းခြင်းဖြင့် (အသားရှင် လက်ရှိ), နောက်လက်တွဲကြားခြင်း (အလုပ်), နောက်လက်တွဲပြီးမှ
                            (အဘယ်နှင့်မကတော့) သင်သည်အတည်ပြုသည်ဖြင့်သည်။
                        </div>
                        <div>သင့်အားအဘယ်တရားကျစရာများ</div>
                        <div>
                            CHIP DISPLAY သည် သင့်ရဲ့လက်ကျန်မှာ ပေါင်းသုံးမှုရွေးချယ်ရန် သုံးစွဲရန် သင့်ရဲ့လက်ကျန်အရေအတွက်ကို ေလွ်ာ့ပြောင်းသည်။
                            သင့်ရဲ့လက်ကျန်မှာ မောင်လိုသည့်အမျိုးအစားများသည် သင့်လက်ကျန်လက်ရှိတင်နိုင်သလဲမှာဖြစ်သည်။
                        </div>
                        <div>
                            သင့်သည်ပုံမှန်အတွင် တစ်ကြိမ်သတ်မှတ်ချက်ဖြင် သင့်ရဲ့လက်ကျန်မှာ စာပေမှာ သင့်ရဲ့လက်ကျန်အမြဲအဝင်တင်နိုင်သည်မှာ
                            သင့်လက်ကျန်အမြဲအဝင်မှ မတင်သေးပါ။ သင်သည် အမြဲအဝင်တင်နိုင်သောအခါမှာ အတန်းအမြဲကို သေချာထားခြင်းမရှိပါက ဤအမျိုးအမည်အပေါ်သို့
                            သင့်လက်ကျန်အမြဲအဝင်ကို မောင်လိုပါသည်။
                        </div>
                        <div>
                            အထက်ပါသို့သင့်အတွက်အသက်ရှင်အမြဲအဝင်တင်မှာမတင်သေးပါ။ ဤအသက်ရှင်အမြဲအဝင်တင်မှာ အတန်းအမြဲကိုလည်း ထွက်လာသည်မှာ
                            သင့်လက်ကျန်အမြဲအဝင်များကို မောင်လိုပါသည်။ သင်သည် အမြဲအဝင်တင်နိုင်သောအခါမှာ သင့်လက်ကျန်အမြဲအဝင်ကို
                            အဆင်သင့်သည်တစ်ကြိမ်ထိုးပြီး သိမ်းဆည်းခြင်းဖြင့်သည်။
                        </div>
                        <div>
                            မှတ်ချက်: သင့်လက်ကျန်အမြဲအဝင်တင်မှာ အတန်းအမြဲလွှဲပြောင်းရန်နှင့် သင့်လက်ကျန်အမြဲအဝင်တင်မှာ အတတ်နိုင်သောအခါတွင်
                            သင့်ရဲ့လက်ကျန်အမြဲအဝင်ကို မောင်လိုပါသည်။ ဒီအလုပ်များသည် ဂျပန်မှာ ဂျပန်မပြောတာဖြစ်သည်။ သင့်လက်ကျန်အမြဲအဝင်ကို
                            လက်ရှိတင်နိုင်သလဲမှာ ပုံမှန်မှာသင့်အများကြီးသို့ ရှိပါသည်။ ထို့နောက်လည်း အလုပ်များအတွင် အမြဲအဝင်တင်ရန်ကိုမပြောင်းချင်ပါက
                            ဤအကြောင်းအရာအပေါ်သို့ သင့်လက်ကျန်အမြဲအဝင်ကို ကူညီပေးရန်မှတ်ချက်ပေးပါလိမ့်မည်။
                        </div>
                        <div>
                            အကျယ်တစ်ခု သို့မဟုတ်ဆောင်းနိုင်ရန် သင်တစ်ခုတည်းကို သင့်နှင့်တစ်ကိုယ်စီတာပေါ်မှ မြန်မာယူနစ် (2x) ခလုတ်ကို
                            ရွေးချယ်ပြီးသားအားဖြင့် တစ်ခါတည်းနှင့်စတင်လိုသည်။ ဤနှစ်ကိုယ်စီတာပေါ်သို့မဟုတ်မြင်သားလိုက်နားပြီး မှားရက်အတွက် သင့်တစ်ခုကို
                            မရှိအောင်စာရင်းသွင်းမှာပိုမိုပါကပိုမိုလူမဆလိုအပ်တဲ့ငွေကန်ကျင်တွင်ရှိရမည်။
                        </div>
                        <div>
                            အားလုံးအတွက် မဟုတ်ရသေးသော တည်းလမ်းကို တစ်ပုဒ်ကို ထပ်ထည့်ခဲ့သည်။ ဤခလုတ်သည် အခြားအစီအစဉ်တွင် သင့်ကိုယ်စီတာပေါ်အတွင်
                            သင်ထိုးထားသည့်အခါတွင် ရရှိပါကနေသွင်းရမည်။
                        </div>
                        <div>အမြင့်အမြင့်ကိုဖယ်ရှားရန် UNDO ခလုတ်ကို အသွားတည်းဖြင့် သင်ထိုးထားခြင်းဖြင့် ထိုးထားပါသည်။</div>
                        <div>
                            သင်သည်တစ်ခုမှတစ်ခုအတွင်းလက်ရှိပြန်ကြားထားသော တည်းလမ်းများကို ပြန်ရေးရန် UNDO ခလုတ်ကို မှားရက်အတွက် မွားရန်တစ်ပြိုင်ခဲ့သည်။
                            သင်သို့မဟုတ်ပြင်ဆင်လျင် အမြင့်မှားလိုသောအခါတွင် UNDO ခလုတ်ကို လိုအပ်ခြင်းဖြင့် တစ်ခုအတွင်း ထည့်မြင်ခြင်းများကို
                            ဖယ်ရှားပါသည်။ UNDO ခလုတ်ကို ကျော်စိနေရာကို လျှင်းကူသွားမည်။
                        </div>
                        <div>စုစုပေါင်းခလုတ်သည် ဤလအားလုံးတွင် လူကျွေးသာမကျန်ပြီး အခြားများမှာ ထည့်သွင်းလာသော အခြားတည်းလမ်းများကို ပြပါမည်။</div>
                        <div>TOTAL BET ကိုကြည့်ပါသည်နှင့်စာရင်းနှင့်စတင်ပြချက်များအတွက် စုစုပေါင်း အလျားကိန်းကောင်း ပေါင်းစပ်သည်။</div>
                        <div>TOTAL BET MYR 500</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">တိုက်ရိုက်လှုပ်ရှားမှု:</div>
                    <div className="content">
                        <div>ဂိမ်းဖွင့်နှင့်ဆက်သွယ်ရန်ကြိုရိုက်ထုတ်ကြောင်းများအတွက် Live Support ကိုဆက်သွယ်ပါ။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">အသံဖိုင်ရှင်မှစီမံခြင်း:</div>
                    <div className="content">
                        <div>
                            သင့်ရဲ့ဂိမ်းရောက်နေမှုမှာ သင်ကိုမဆိုင်မီ တန်းမျှင့်မှသင့်ထည့်သွင်းထားသောမော်ဒယ်များ သင့်အောက်မှာ တည်ဆောက်ထားသည်၊
                            သင်ထွက်လာမှုအတွက် သင်တည်ဆောက်ပြီးမှ History ဝင်များတွင် ဘယ်မော်ဒယ်မျှတစ်ခုကိုကြည့်နိုင်သည်။
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">အော်ပရေတာ:</div>
                    <div className="content">
                        <div>
                            သင့်သည်ဘေးတစ်ခုတည်းတွင် အော်ပရေတာကို သင်သတ်မှတ်ထားပါသည်။ အော်ပရေတာကို သွားပါတယ်ဆိုရင်သင်သတ်မှတ်ရန် Autoplay ကို သင့်မှ
                            ရွေးချယ်ပါ။
                        </div>
                        <div>
                            Autoplay စတင်ရန်သင့်ရဲ့ဘေးတွင် ဘေးတန်ဖို့သင့်ရဲ့ဘေးသားများကို ထပ်ထည့်သွင်းပါ။ ရွေးချယ်ရန် Autoplay ခလုတ်ကို
                            နှိပ်၍တိုင်းလုပ်ပါ။
                        </div>
                        <div>
                            Autoplay ပန်းခြားမှုမှာ သင့်ရဲ့ ဘေးသားများကို သင့်မှ ရွေးချယ်သွင်းရန် သင်တစ်ခုချင်းသားတွင် ရွေးချယ်ရန်စုံစမ်းသွင်းပါ။
                            Autoplay ပန်းခြားမှုမှာ သင့်ရဲ့ရွေးချယ်သွင်းသော ပမာဏအရေအတွက် Autoplay ပန်းခြားမှုမှာ အလုပ်အကိုလည်း ရွေးချယ်သွင်းရန်
                            ပြင်ဆင်ပါသည်။ ရွေးချယ်သွင်းရန် Autoplay စတင်ခြင်းထားသည်ဖြင့် Autoplay ပန်းခြားမှုမှာ သင့်ရဲ့ မက်ဆေ့ချ် ပမာဏကို
                            ထည့်သွင်းရန်ပြင်ဆင်မှုလျှင် ဤ Autoplay ပန်းခြားမှုပိုင်ရှင်စာရင်းအတွက် ပြင်ဆင်ထားသည်။
                        </div>
                        <div>Autoplay ဘေးတွင် BET PER ROUND MYR 10</div>
                        <div>
                            သင့်ရဲ့ အော်ပရေတာအားဖြင့် Autoplay ပန်းခြားမှုအတွက် သင့်ရဲ့ အများကြီးတစ်ခုဖြင့် အရည်အသွေးကို သင့်ရဲ့
                            စာရင်းအသုံးပြုသူအောက်တွင် တွန်းတိုင်းပါဝင်သည်။ Autoplay ဘေးတွင် မက်ဆေ့ချ် Autoplay ပန်းခြားမှုအကြောင်း အရေအတွက်ကို
                            အထောက်အပံ့သို့ ထားရှိပါက ဤ Autoplay ပန်းခြားမှုကို ဆန့်ကွင်းပေးနိုင်သည်။ Autoplay စတင်မှုမှာသင့်ရဲ့ ရွေးချယ်သွင်းသော
                            Autoplay ပန်းခြားမှုအတွက် အသက်ရှင်မက်ဆေ့ချ်အောင် ကျန်ရှိထားပါသည်။
                        </div>
                        <div>
                            သင်၏ အလုပ်အကိုကျန်ရှိပြီးတော့သင့်ရဲ့ ရွေးချယ်သွင်းခြင်းမှာ သင့်အနက် ရွေးချယ်မှုပေါ်ကို သို့မဟုတ် သင့်မှာရွေးချယ်သွင်းသော
                            အကြောင်းအရာကို သင့်မှာ ရွေးချယ်မှုပေါ်ကိုလည်း ထောက်ပံ့ထားသည်။
                        </div>
                        <div>
                            သင်၏ အလုပ်အကိုကျန်ရှိပြီးတော့ သင့်မှာရွေးချယ်သွင်းခြင်းမှာ စကားဝိုင်းကို ထပ်ထည့်သွင်းနိုင်သည့်အခါ တစ်ခုချင်းတစ်ခုထိ
                            Autoplay ပန်းခြားမှုကို သေသင်းပြီး သင့်ရဲ့ ေငြကောက်မှုအတွက် ရွေးချယ်မှုအတွက် တစ်ခုချင်းတစ်ခုထိ သွားပါက ထိုအသစ်ကို
                            သွားပါသည်။
                        </div>
                        <div>သွားပါ</div>
                        <div>
                            အောက်က Autoplay ရှိနေသည်ဟု ရပ်တန့်မှုကို ရပ်ခံရန်၊ REPEAT ကို နှိပ်နိုင်သည့်အခါတွင သင်ယူထားသောအတိုင်းပြန်ထည့်ပေးနိုင်သည်။
                        </div>
                        <div>REPEAT</div>
                        <div>
                            Autoplay ကိုစတင်ပြီးတော့ဖြည့်နိုင်သောအခါ၊ သင်ဘာလုံးများသည် ဘက်အတွင်း နှိပ်ပြီးသော ဘဲ့အတိုင်းဖြည့်လို့ Autoplay ကို
                            ဆက်လုပ်ရန်ကိုယ်စားပွဲများအား ရွေးချယ်နိုင်သည်။ သင်ကျွန်သောနည်းပါရပ်မှာပြန်ပြောင်းနိုင်သော အကြောင်းကို ရွေးချယ်လိုပါက၊
                            Autoplay ကို ဆက်ရန်သင်အပြင်ထားသော ရှိသောဘဲ့အတိုင်းဖြည့်မည့်အခါတွင Autoplay ရပ်ရွေးချယ်လိုက်သောအခါတွင အသေးစိတ်ကို
                            ရွေးချယ်နိုင်ခြင်းမရှိပါနှင့်။
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ချတ်:</div>
                    <div className="content">
                        <div>ဂိမ်းဖြစ်သူနှင့်အခြားဖရန်ရှင်းသူတို့နှင့်ဆက်သွယ်ရန်ခလုတ်ပြသပေးပါ။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ဂိမ်း နံပါတ်:</div>
                    <div className="content">
                        <div>သင့်အားရာထူးတွင် ပါဝင်သည့် ဂိမ်းအကြောင်းတွင်တည်ရှိမှုကို တစ်ဦးဆုံး ဂိမ်း နံပါတ် ဖြင့်ရှာဖွေပေးပါ။</div>
                        <div>
                            ဤနံပါတ်သည် GMT အချိန်နှင့် နာရီ:မီတာ:စက္ကန် အချိန်မှစတင်ပြုပြင်ရှင်းပေးထားခြင်းဖြင့် ဂိမ်းအကြောင်းသည်သက်ဆိုင်ရာ ဂိမ်း
                            နံပါတ် ဖြစ်ပါသည်။ သင့်အားယုံကြည်စွာ ဤ ဂိမ်း နံပါတ်ကို အမှတ်တရားရှိရန် သင့်တို့ဆက်သွယ်ရန် ကျေးဇူးပြုပြင်ပေးရပါမည်။
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">အသံ:</div>
                    <div className="content">
                        <div>အသံအမြင့်အသည်းများအား ဂိမ်းတစ်ခုတည်းနဲ့ ထိန်းချုပ်သည်။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ဂိမ်း သမိုင်း:</div>
                    <div className="content">
                        <div>ဂိမ်း သမိုင်းသည် သင်၏ ဂလပီခန့်ခွဲခြင်းနှင့် ရလဒ်များကို ပြပေးသည်။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ဆက်တင်များ:</div>
                    <div className="content">
                        <div>ဆက်တင်များသည် သင်၏ ပရိုဖိုင်သို့ သင့်အကြောင်းအရာများကို စိတ်ဝင်စားသော အသုံးပြုသူ အသုံးပြုချက်များကို ပြင်ဆင်ပေးသည်။</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">အမှား စီးရီးယောက်:</div>
                    <div className="content">
                        <div>
                            ဂိမ်းတစ်ခုတည်းမှ အမှားတစ်ခုရှိသည်မှားနေပါသည်။ ဂိမ်းကြောင့် သဘောတူသည့် သတင်းသုံးသော အမှားတစ်ခုကို ဂိမ်းဆရာမတ်တို့သည်
                            ဝန်ဆောင်မှားသို့မဟုတ် ဝန်ဆောင်မှုမသိသောအခြေခံသည်။ သင့်နှင့် အခြား ကစားဝယ်သူများကို Chat နှင့် နောက်ဆုံးပြသသည့်အခါ
                            သတင်းများနှင့် အမှားနှင့် ဝန်ဆောင်ပြသသည့် အသိအမှားပြားချက်များနှင့် သတင်းအချက်များဖြင့် သတိပေးသည်။
                            သန်းတူးရန်သက်ရာအချိန်မှာ အမှားကြီးသည်သို့မဟုတ် ဂိမ်းတစ်ခုကို မပိုရှင်းပါက အမှားကြီးကိုလည်း သိမ်းဆည်းပါမည်။ အမှားကြီးကို
                            ကြည့်ရှုနိုင်သည့်အခါမှ ဂိမ်းတစ်ခုကို သတင်းအခြေခံသည့်အခါမှာ မျက်လုံးကို သက်ဆိုင်သည်။ သန်းတူးရန်သက်ရာအချိန်မှာ အမှားတစ်ခုကို
                            မပိုရှင်းပါက ဂိမ်းတစ်ခုကို ဖျက်ပစ်ပါမည်။ ဂိမ်းတစ်ခုကို လည်း လွတ်တင်နိုင်သည်။ ထိုသို့ ဂိမ်းရောက်မှုသည်
                            ဂိမ်းတစ်ခုထက်မကြားစွာ ပြောင်းလဲမှုများကို ရွေးချယ်ပြီး ဂိမ်းတစ်ခုအသစ်တစ်ခုသို့ ပြန်ပြောင်းလဲသည်။ အသစ်တစ်ခုအား ကမ္ဘာ့ကို
                            လွှတ်တော်မည်ဖြင့် ဂိမ်းတစ်ခုကို ဥပမာများနှင့်မသိပါက အပြန်အလှန်ကြည့်နိုင်သည်။
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">ရောလ်လေ့လာမှု အတည်ပြုအဆင့်:</div>
                    <div className="content">
                        <div>အောက်ခံနံပါတ်သည် အောက်ခံလုပ်နည်းအား အောင်မြင်စွာဖြစ်စေရန် အလုပ်သမားမှာ အောက်ခံလုပ်နည်းကို လုပ်သည်။</div>
                        <div>
                            အောက်ခံလုပ်နည်းအတွင်း ရောလ်လေ့လာမှု ဘောလုံးသည် ပန်းဖြန်းနိုင်သော ဘော့ဂ်မှ အားလုံးသို့မဟုတ် အကြောင်းနှင့် ကြိုးစားလျှင်
                            ထွက်ပြောဆိုရန်လိုအပ်ပါသည်။ ရောလ်လေ့လာမှု ဘော့ဂ်ပရောလ်သည် ကြိုးစားလျှင် ဘော့ဂ်သည် ကွဲပြားမှုကိုရှောင်ရှားခဲ့ရန် အောက်ခံရန်
                            ရောလ်သည်ဖြစ်စေသည်။
                        </div>
                        <div>
                            မမှန်ကန် ရောလ်သည် အောက်ခံလုပ်နည်းအား အောင်မြင်စွာဖြစ်စေမှုနှင့် ထိုကြိုးစားလျှင် ကွာရှည်သည်အောက်ခံရန် ဘော့ဂ်သည်
                            ကွဲပြားမှုကို ပြင်ဆင်ခဲ့ရန် အသုံးပြုသော်လည်းဖြစ်သည်။
                        </div>
                    </div>
                    <div>
                        မမှန်ကန် ရောလ်သည် အောက်ခံလုပ်နည်းအား အောင်မြင်စွာဖြစ်စေမှုနှင့် ထိုကြိုးစားလျှင် ကွာရှည်သည်အောက်ခံရန် ဘော့ဂ်သည် ကွဲပြားမှုကို
                        ပြင်ဆင်ခဲ့ရန် အသုံးပြုသော်လည်းဖြစ်သည်။
                    </div>
                    <ul>
                        <li>ဘော်လတ္တတ္စားမှုအနည်းငယ် 3 လုံးအောင်မြင်ခဲ့သည်</li>
                        <li>ဘော်လတ္တတ္စားမှုအနည်းငယ်ကို ကြီးမားခဲ့သည်</li>
                        <li>ဘော်လတ္တတ္စားမှုအနည်းငယ်သည် ဘော်လတ္တတ္စားနည်းနှင့် စားမှုရှိရာတွင်ပိတ်သိမ်းရသည်</li>
                        <li>ဘော်လတ္တတ္စားမှုအနည်းငယ်သည် ဘော်လတ္တတ္စားမှုပိုက်သိမ်းရသည်</li>
                        <li>ဘော်လတ္တတ္စားမှုအနည်းငယ်မှ ဘော်လတ္တတ္စားမှုအရာသို့ နောက်အဝတ်ရာတွင် လိုက်နာပါသည်။</li>
                    </ul>
                    <div>မမှန်ကန်တဲ့ဘော်လတ္တတ္စားမှုအနည်းငယ်အတွက်, ဌာနလိုအပ်သည်အတွက် ထင်ရှားပြီးပါပြီ။</div>
                    <div>
                        မမှန်ကန်တဲ့အခြားအမှတ်အသားများအတွက်, ပရိုဂရမ်အပေါ်ကို တောင်းဆိုသူသို့ တင်သွင်းနိုင်သည်ကို လိုအပ်သည်အတွက် Live Support
                        သို့အဆင်သင့်သို့ တွေ့ရပါမည်။
                    </div>
                </div>
                <div className="section">
                    <div className="title">ပိုင်းဆိုင်ရာ ဂိမ်းများ:</div>
                    <div className="content">
                        <div>
                            ဂလပ်စာ လောဘက် — သင့်လိုက်နေသော ဂိမ်းကို ထွက်ရန် မိမိလက်ရှိ ဂိမ်းကို ရွေးချယ်ရာကို ပြောင်းလဲခြင်းဖြင့်၊ သင်တို့ရဲ့
                            လက်ရှိဂိမ်းကို အသင်သာအားဖြင့် ရွေးချယ်ရာကို ရွေးချယ်ပေးပါလိမ့်မည်။
                        </div>
                    </div>
                </div>
            </div>
        ),
        kr: (
            <div className="rt-how-to-play">
                <div className="section">
                    <div className="title">게임 규칙:</div>
                    <div className="content">
                        <div>
                            룰렛의 목표는 공이 놓일 숫자를 예측하여 해당 숫자를 커버하는 하나 이상의 베팅을 하여 그 숫자에 베팅한 경우에 승리하는
                            것입니다. 룰렛 휠에는 1에서 36까지의 숫자와 단일 0 (제로)이 포함되어 있습니다.
                        </div>
                        <div>
                            베팅 시간이 만료되면 공이 룰렛 휠 내에서 회전됩니다. 공은 결국 휠 내의 번호가 지정된 한 곳에서 정지합니다. 해당 숫자에
                            베팅을 한 경우에 승리합니다.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">베팅 유형:</div>
                    <div className="content">
                        <div>
                            룰렛 테이블에는 다양한 종류의 베팅을 할 수 있습니다. 베팅은 단일 숫자나 특정 범위의 숫자를 커버할 수 있으며 각 베팅
                            유형마다 고유의 배당률이 있습니다.
                        </div>
                        <div>
                            베팅 영역의 번호가 지정된 공간 또는 그 사이의 라인에 베팅하는 것을 내부 베팅이라고하며, 메인 숫자 그리드 아래와 옆에 있는
                            특수 상자에 베팅하는 것은 외부 베팅이라고합니다.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">내부 베팅:</div>
                    <div className="content">
                        <ul>
                            <li>직접 베팅 - 칩을 직접 하나의 숫자 (제로 포함)에 놓습니다.</li>
                            <li>스플릿 베팅 - 칩을 두 숫자 사이의 선에 놓습니다. 수직 또는 수평 중 하나 선택 가능합니다.</li>
                            <li>스트리트 베팅 - 칩을 숫자 행의 끝에 놓습니다. 스트리트 베팅은 세 숫자를 커버합니다.</li>
                            <li>코너 베팅 - 칩을 네 숫자가 만나는 모서리 (중앙 교차점)에 놓습니다. 모든 네 숫자가 커버됩니다.</li>
                            <li>라인 베팅 - 칩을 두 행 사이의 교차점에 놓습니다. 라인 베팅은 두 행의 모든 숫자, 총 여섯 숫자를 커버합니다.</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">외부 베팅:</div>
                    <div className="content">
                        <ul>
                            <li>
                                컬럼 베팅 - 해당 열 끝에 표시된 "2 to 1" 상자 중 하나에 칩을 놓아 해당 열의 모든 12 숫자를 커버합니다. 제로는 어떤
                                컬럼 베팅에도 포함되지 않습니다.
                            </li>
                            <li>다즌 베팅 - "1st 12", "2nd 12" 또는 "3rd 12" 중 하나에 칩을 놓아 해당 상자 옆의 12 숫자를 커버합니다.</li>
                            <li>
                                레드/블랙 - 레드 또는 블랙 상자에 칩을 놓아 18개의 레드 또는 18개의 블랙 숫자를 커버합니다. 제로는 이러한 베팅에
                                포함되지 않습니다.
                            </li>
                            <li>
                                짝/홀 - 이 상자 중 하나에 칩을 놓아 18개의 짝수 또는 18개의 홀수 숫자를 커버합니다. 제로는 이러한 베팅에 포함되지
                                않습니다.
                            </li>
                            <li>
                                1-18/19-36 - 이 상자 중 하나에 칩을 놓아 첫 번째 또는 두 번째 세트의 18개 숫자를 커버합니다. 제로는 이러한 베팅에
                                포함되지 않습니다.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">이웃 베팅:</div>
                    <div className="content">
                        <div>
                            "이웃 베팅" 버튼을 클릭/터치하여 특수한 타원 모양 또는 경주 트랙 모양의 베팅 영역을 볼 수 있습니다. 여기에서 이웃 베팅 및
                            기타 특수 베팅을 더 쉽게 할 수 있습니다. 이 기능을 닫거나 다시 열려면 버튼을 다시 클릭/터치하세요.
                        </div>
                        <div>각 베팅은 다른 숫자 세트를 커버하며 서로 다른 배당률을 제공합니다. 베팅 지점은 강조 표시됩니다.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">티에 듀 시땀드르:</div>
                    <div className="content">
                        <div>
                            이 베팅은 27, 33 및 제로와 반대편의 룰렛 휠에 있는 해당 번호를 포함하는 총 12개 숫자를 커버합니다. 6개의 칩이 다음과 같이
                            배치됩니다:
                        </div>
                        <ul>
                            <li>5/8 스플릿에 1개의 칩</li>
                            <li>10/11 스플릿에 1개의 칩</li>
                            <li>13/16 스플릿에 1개의 칩</li>
                            <li>23/24 스플릿에 1개의 칩</li>
                            <li>27/30 스플릿에 1개의 칩</li>
                            <li>33/36 스플릿에 1개의 칩</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">제로의 이웃:</div>
                    <div className="content">
                        <div>
                            이 베팅은 22, 25 및 제로를 포함하는 해당 번호와 제로를 포함하는 룰렛 휠의 해당 측면에 놓인 숫자 총 17개를 커버합니다.
                            다음과 같이 9개의 칩이 배치됩니다:
                        </div>
                        <ul>
                            <li>0/2/3 스트리트에 2개의 칩</li>
                            <li>4/7 스플릿에 1개의 칩</li>
                            <li>12/15 스플릿에 1개의 칩</li>
                            <li>18/21 스플릿에 1개의 칩</li>
                            <li>19/22 스플릿에 1개의 칩</li>
                            <li>25/26/28/29 코너에 2개의 칩</li>
                            <li>32/35 스플릿에 1개의 칩</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">오펠랭 어 쉬왈:</div>
                    <div className="content">
                        <div>
                            이 베팅은 제로의 이웃 및 티에 듀 시땀드르 베팅에서 커버되지 않은 룰렛 휠의 두 세그먼트에 총 8개 숫자를 커버합니다. 다음과
                            같이 5개의 칩이 배치됩니다:
                        </div>
                        <ul>
                            <li>1 (직접 베팅)에 1개의 칩</li>
                            <li>6/9 스플릿에 1개의 칩</li>
                            <li>14/17 스플릿에 1개의 칩</li>
                            <li>17/20 스플릿에 1개의 칩</li>
                            <li>31/34 스플릿에 1개의 칩</li>
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="title">쥬 제로:</div>
                    <div className="content">
                        <div>
                            이 베팅은 룰렛 휠의 제로와 제로와 가까운 6개 숫자를 커버합니다: 12, 35, 3, 26, 0, 32, 15. 다음과 같이 4개의 칩이
                            배치됩니다:
                        </div>
                        <ul>
                            <li>0/3 스플릿에 1개의 칩</li>
                            <li>12/15 스플릿에 1개의 칩</li>
                            <li>26 (직접 베팅)에 1개의 칩</li>
                            <li>32/35 스플릿에 1개의 칩</li>
                        </ul>
                        <div>
                            이웃 베팅은 특정 번호와 해당 번호와 룰렛 휠에서 가까이 있는 다른 번호를 커버합니다. 이웃 베팅을 하려면 레이싱 트랙에서
                            특정 번호를 클릭/터치하십시오. 선택한 번호와 그 오른쪽과 왼쪽에 인접한 숫자에 칩이 놓이게 됩니다. 선택한 번호의 오른쪽 및
                            왼쪽의 이웃을 늘리거나 줄이려면 원형 "-" 또는 "+" 버튼을 클릭/터치하십시오.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">즐겨찾기 및 특별 베팅:</div>
                    <div className="content">
                        <div className="content-title">즐겨찾기 베팅</div>
                        <div>
                            선택 사항인 즐겨찾기 베팅 기능을 사용하면 룰렛 테이블에서 미래 라운드에서 쉽게 배팅할 수 있도록 선호하는 베팅 또는 다양한
                            베팅 유형의 조합을 저장할 수 있습니다. 최대 30개의 즐겨찾는 베팅을 서로 다른 이름으로 저장하고 편집할 수 있습니다.
                        </div>
                        <div className="content-title">즐겨찾기 베팅 저장하기</div>
                        <div>
                            즐겨찾기 베팅 메뉴를 열려면 FAVOURITE BETS 버튼을 클릭하거나 탭하세요. 이 기능을 닫으려면 버튼을 다시 클릭하거나 탭하세요.
                        </div>
                        <div>
                            룰렛 테이블에 선호하는 베팅 또는 베팅 조합을 배팅한 후 Favourite Bets 메뉴에서 SAVE LAST BET 링크를 클릭하거나 탭하세요.
                            이 베팅에 대한 기본 이름이 제안되지만, 더 쉽게 인식할 수 있는 새 이름을 입력할 수 있습니다. 그런 다음 SAVE 버튼을
                            클릭하거나 키보드에서 "Enter"를 눌러 이 베팅을 즐겨찾기 베팅 목록에 추가할 수 있습니다.
                        </div>
                        <div className="content-title">즐겨찾기 베팅하기</div>
                        <div>
                            룰렛 라운드의 베팅 단계 중에 즐겨찾기 베팅을 배팅하려면 즐겨찾기 베팅 메뉴를 열어 이전에 저장한 모든 베팅 목록을 볼 수
                            있습니다. 이들은 시간순으로 나열되며, 가장 먼저 저장한 즐겨찾기 베팅이 가장 먼저 나타납니다. 목록에 나열된 베팅 이름 위에
                            커서를 올려놓으면 룰렛 테이블에 칩이 어떻게 배치될지 확인할 수 있습니다. 원하는 베팅의 이름을 클릭하거나 탭하여 배팅할 수
                            있습니다. 또한 배팅한 즐겨찾기 베팅의 금액을 (두 배, 세 배, 네 배 등) 클릭하거나 탭하여 여러 번 늘릴 수 있습니다.
                        </div>
                        <div className="content-title">즐겨찾기 베팅 이름 바꾸거나 삭제하기</div>
                        <div>
                            즐겨찾기 베팅 메뉴가 열려있을 때 목록에 나열된 베팅을 삭제하거나 이름을 변경하려면 EDIT 버튼을 클릭하거나 탭할 수
                            있습니다.
                        </div>
                        <div>
                            목록에 나열된 베팅 중 하나의 이름을 변경하려면 현재 이름을 둘러싼 회색 텍스트 상자 내부를 먼저 클릭하거나 탭합니다. 그런
                            다음 새 이름을 입력하고 SAVE 버튼을 클릭하거나 키보드에서 "Enter"를 눌러 저장할 수 있습니다.
                        </div>
                        <div>
                            즐겨찾기 베팅 목록 편집을 마치면 즐겨찾기 베팅 메뉴 오른쪽 상단의 SAVE 버튼을 클릭하거나 탭하거나 FAVOURITE BETS 버튼을
                            클릭하거나 탭합니다.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">특별 베팅:</div>
                    <div className="content">
                        <div>Favourite Bets의 두 번째 탭에서 Finale en plein 및 Finale a cheval 베팅을 더 쉽게 할 수 있습니다.</div>
                        <div className="content-title">Finale en plein</div>
                        <ul>
                            <li>Finale en plein 0 — 0+10+20+30 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale en plein 1 — 1+11+21+31 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale en plein 2 — 2+12+22+32 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale en plein 3 — 3+13+23+33 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale en plein 4 — 4+14+24+34 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale en plein 5 — 5+15+25+35 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale en plein 6 — 6+16+26+36 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale en plein 7 — 7+17+27 각각 1칩으로 커버하는 3칩 베팅</li>
                            <li>Finale en plein 8 — 8+18+28 각각 1칩으로 커버하는 3칩 베팅</li>
                            <li>Finale en plein 9 — 9+19+29 각각 1칩으로 커버하는 3칩 베팅</li>
                        </ul>
                        <div className="content-title">Finale a cheval</div>
                        <ul>
                            <li>Finale a cheval 0/3 — 0/3+10/13+20/23+30/33 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale a cheval 1/4 — 1/4+11/14+21/24+31/34 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale a cheval 2/5 — 2/5+12/15+22/25+32/35 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale a cheval 3/6 — 3/6+13/16+23/26+33/36 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale a cheval 4/7 — 4/7+14/17+24/27+34 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale a cheval 5/8 — 5/8+15/18+25/28+35 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale a cheval 6/9 — 6/9+16/19+26/29+36 각각 1칩으로 커버하는 4칩 베팅</li>
                            <li>Finale a cheval 7/10 — 7/10+17/20+27/30 각각 1칩으로 커버하는 3칩 베팅</li>
                            <li>Finale a cheval 8/11 — 8/11+18/21+28/31 각각 1칩으로 커버하는 3칩 베팅</li>
                            <li>Finale a cheval 9/12 — 9/12+19/22+29/32 각각 1칩으로 커버하는 3칩 베팅</li>
                        </ul>
                        <div className="content-title">완전 베팅</div>
                        <div>완전 베팅은 특정 숫자에 모든 내부 베팅을 배치합니다.</div>
                        <div>
                            예를 들어, 36에 대한 완전 베팅은 다음과 같이 18개의 칩을 배치합니다: 직접 36에 1칩, 각 Split 베팅인 33/36 및 35/36에 2칩,
                            Street 베팅인 34/35/36에 3칩, Corner 베팅인 32/33/35/36에 4칩, 그리고 Line 베팅인 31/32/33/34/35/36에 6칩.
                        </div>
                        <div className="content-title">직접 베팅</div>
                        <div>직접 베팅은 선택한 유형 (빨강, 검정, 홀수, 짝수 등)의 모든 직접 베팅을 1칩으로 커버합니다.</div>
                        <div>예를 들어, 직접 베팅 RED는 모든 빨간 숫자를 커버하기 위해 18개의 칩을 배치합니다.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">당첨 번호:</div>
                    <div className="content">
                        <div>당첨 번호 표시는 가장 최근의 당첨 번호를 보여줍니다.</div>
                        <div>가장 최근에 완료된 라운드의 결과가 왼쪽에 나열됩니다.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">최근 결과:</div>
                    <div className="content">
                        <div>
                            최근 500회의 게임 라운드에서 당첨 번호의 표를 보려면 통계 버튼을 클릭/탭하세요. 과거 라운드의 수를 조절하려면 슬라이더를
                            사용하세요.
                        </div>
                        <div>
                            통계 다이어그램의 어떤 부분에 커서를 가져다 대면 칩이 배치될 베팅 테이블의 위치가 강조됩니다. 단순히 클릭/탭하여 베팅을
                            배치하세요.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">배당금:</div>
                    <div className="content">
                        <div>당신의 배당금은 배팅한 유형에 따라 다릅니다.</div>
                        <div className="content-title">내부 베팅</div>
                        <div>베팅 유형 배당금</div>
                        <table>
                            <tr>
                                <th>직접 베팅</th>
                                <th>35:1</th>
                            </tr>
                            <tr>
                                <th>Split 베팅</th>
                                <th>17:1</th>
                            </tr>
                            <tr>
                                <th>Street 베팅</th>
                                <th>11:1</th>
                            </tr>
                            <tr>
                                <th>Corner 베팅</th>
                                <th>8:1</th>
                            </tr>
                            <tr>
                                <th>Line 베팅</th>
                                <th>5:1</th>
                            </tr>
                        </table>
                        <div className="content-title">외부 베팅</div>
                        <div>베팅 유형 배당금</div>
                        <table>
                            <tr>
                                <th>열 베팅</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>다스 베팅</th>
                                <th>2:1</th>
                            </tr>
                            <tr>
                                <th>빨강/검정 베팅</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>홀/짝 베팅</th>
                                <th>1:1</th>
                            </tr>
                            <tr>
                                <th>1-18/19/36 베팅</th>
                                <th>1:1</th>
                            </tr>
                        </table>
                        <div>고장 발생 시 모든 지불 및 플레이가 무효화됩니다.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">반환율:</div>
                    <div className="content">
                        <div>이론상 최적의 반환율은 97.30%입니다.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">베팅하기:</div>
                    <div className="content">
                        <div>
                            BET LIMITS 패널에는 테이블에서 허용되는 최소 및 최대 베팅 한도가 표시되며, 이는 시간에 따라 변경될 수 있습니다. 현재
                            한도를 확인하려면 베팅 한도를 엽니다.
                        </div>
                        <div>Emperor Roulette MYR 2.50 - 10,000</div>
                        <div>게임에 참여하려면 베팅에 충분한 자금이 있어야 합니다. 현재 BALANCE는 화면에 표시됩니다.</div>
                        <div>BALANCE MYR 100,000</div>
                        <div>Immersive 뷰에서는 타이머가 베팅 시간의 기간을 알려줍니다. 만료되면 베팅이 종료되고 더 이상 베팅을 받지 않습니다.</div>
                        <div>
                            Classic 뷰에서는 TRAFFIC LIGHTS가 현재 게임 라운드의 상태를 알려줍니다. 베팅이 가능한 시점 (녹색 불빛), 베팅 시간이 거의
                            끝날 때 (노란색 불빛), 베팅 시간이 종료될 때 (빨간색 불빛).
                        </div>
                        <div>베팅하기</div>
                        <div>
                            CHIP DISPLAY를 사용하여 베팅할 각 칩의 가치를 선택할 수 있습니다. 현재 잔액으로 지원될 수 있는 칩 단위만 활성화됩니다.
                        </div>
                        <div>
                            칩을 선택한 후 게임 테이블의 적절한 베팅 지점을 클릭/탭하여 베팅을 하세요. 베팅 지점을 클릭/탭할 때마다 베팅 금액이 선택한
                            칩의 가치 또는 선택한 베팅 유형의 최대 한도까지 증가합니다. 최대 한도까지 베팅하면 해당 베팅에 대해 추가 자금이 수락되지
                            않으며 베팅한 최대 한도를 벗어난다는 메시지가 표시됩니다.
                        </div>
                        <div>
                            참고: 베팅 시간이 남아 있고 테이블에 베팅을 이미 했을 때 브라우저를 최소화하거나 브라우저에서 다른 탭을 열지 마세요.
                            이러한 행동은 게임을 나간 것으로 해설될 수 있으며 해당 게임 라운드에서 베팅이 거부될 수 있습니다.
                        </div>
                        <div>
                            DOUBLE (2x) 버튼은 어떤 베팅을 한 후에 사용할 수 있습니다. 클릭/탭할 때마다 모든 베팅을 최대 한도까지 두 배로 늘립니다.
                            모든 베팅을 두 배로 늘리려면 충분한 계정 잔액이 있어야 합니다.
                        </div>
                        <div>
                            REPEAT 버튼을 사용하면 이전 게임 라운드에서 모든 베팅을 반복할 수 있습니다. 이 버튼은 첫 번째 칩이 배치되기 전에만 사용할
                            수 있습니다.
                        </div>
                        <div>UNDO 버튼은 마지막으로 배치한 베팅을 제거합니다.</div>
                        <div>
                            UNDO 버튼을 반복적으로 클릭/탭하여 베팅을 마지막으로 배치된 역순으로 하나씩 제거할 수 있습니다. UNDO 버튼을 길게 눌러 모든
                            베팅을 지울 수도 있습니다.
                        </div>
                        <div>TOTAL BET 인디케이터에는 현재 라운드에 배치된 모든 베팅의 총액이 표시됩니다.</div>
                        <div>TOTAL BET MYR 500</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">실시간 지원:</div>
                    <div className="content">
                        <div>게임 관련 문의 사항은 실시간 지원에 문의하세요.</div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">연결 끊김 정책:</div>
                    <div className="content">
                        <div>
                            게임 라운드에서 연결이 끊기면 배치된 베팅은 유효하며, 당신이 불참한 상태에서 결제됩니다. 다시 연결하면 기록 창에서 베팅
                            결과를 볼 수 있습니다.
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">자동 재생:</div>
                    <div className="content">
                        <div>베팅을 한 후에 자동 재생을 사용하여 선택한 베팅을 원하는 게임 라운드 수로 반복할 수 있습니다.</div>
                        <div>자동 재생을 시작하려면 베팅을 하고 자동 재생 버튼을 클릭/탭하세요.</div>
                        <div>자동 재생 패널에서 베팅이 반복될 라운드 수를 선택하여 자동 재생을 시작할 수 있습니다.</div>
                        <div>
                            선택한 자동 재생 라운드 수는 자동 재생 버튼에 표시됩니다. 자동 재생이 시작되면 남은 자동 재생 라운드의 수도
                            업데이트됩니다.
                        </div>
                        <div>자동 재생 라운드 당 베팅 금액은 MYR 10입니다.</div>
                        <div>
                            자동 재생 베팅 금액은 자동 재생 라운드 수를 전체 베팅의 값과 곱하여 계산됩니다. 각 자동 재생 라운드 카드 아래에
                            표시됩니다. 자동 재생이 시작되면 남은 자동 재생 한도도 각 자동 재생 라운드 카드 아래에 표시됩니다.
                        </div>
                        <div>선택한 자동 게임 라운드는 선택한 자동 재생 라운드가 완료되거나 클릭/탭으로 중지할 때까지 계속됩니다.</div>
                        <div>중지</div>
                        <div>자동 재생을 중지하면 이전에 선택한 베팅과 라운드를 클릭/탭하여 반복할 수 있습니다.</div>
                        <div>반복</div>
                        <div>
                            자동 재생이 실행 중에 베팅 그리드에 추가 베팅을 하거나 베팅을 두 배로 늘린 경우, 메시지가 나타나고 변경된 베팅으로
                            계속할지 여부를 선택할 수 있습니다. 계속 확인하지 않으면 자동 재생이 중지됩니다.
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">채팅:</div>
                    <div className="content">
                        <div>게임 프리젠터 및 다른 플레이어와 채팅하세요.</div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">게임 번호:</div>
                    <div className="content">
                        <div>각 게임 라운드는 고유한 게임 번호로 식별됩니다.</div>
                        <div>
                            이 번호는 게임 라운드가 GMT 기준으로 시간: 분: 초에 시작된 시점을 나타냅니다. 특정 라운드에 대해 고객 서비스에 문의하려면
                            이 게임 번호를 참조하십시오(또는 게임 번호의 스크린샷을 찍으세요).
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">소리:</div>
                    <div className="content">
                        <div>소리는 게임 내 모든 소리를 조절합니다.</div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">게임 기록:</div>
                    <div className="content">
                        <div>게임 기록은 Inno 게임 라운드와 결과를 표시합니다.</div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">설정:</div>
                    <div className="content">
                        <div>설정에서는 프로필에 저장되는 사용자 환경 설정을 사용자 정의할 수 있습니다.</div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">오류 처리:</div>
                    <div className="content">
                        <div>
                            게임, 시스템 또는 게임 절차에 오류가 있으면 게임 라운드는 일시적으로 일시 중지되고 게임 호스트가 서비스 관리자에게
                            알립니다. 오류가 조사되는 동안 채팅을 통해 또는 화면에 팝업 메시지로 오류가 조사 중임을 알리게 됩니다. 관리자가 즉시
                            오류를 해결할 수 있으면 게임 라운드는 정상적으로 계속됩니다. 즉시 해결이 불가능한 경우 게임 라운드는 취소되고 초기 베팅은
                            게임 라운드에 참여한 모든 플레이어에게 환불됩니다.
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">룰렛 유효한 스핀 규칙:</div>
                    <div className="content">
                        <div>당첨 번호는 스핀이 유효로 간주된 경우에만 유효합니다.</div>
                        <div>
                            유효한 스핀을 구성하려면 딜러가 룰렛 공을 바퀴 회전 방향과 반대로 돌리고 바퀴의 트랙 주위를 적어도 3번 완전히 돌아 다녀야
                            합니다.
                        </div>
                        <div>다음 경우에는 유효하지 않은 스핀으로 선언됩니다:</div>
                        <ul>
                            <li>공이 3번 미만으로 회전한 경우</li>
                            <li>공이 바퀴 회전 방향과 동일한 방향으로 회전한 경우</li>
                            <li>스핀 동안 바퀴가 회전을 멈춘 경우</li>
                            <li>공이 스핀 동안 바퀴에서 떨어진 경우</li>
                            <li>스핀 중에 외부 물체가 바퀴에 들어간 경우</li>
                        </ul>
                        <div>유효하지 않은 스핀의 경우, 딜러가 리스핀을 수행합니다.</div>
                        <div>불규칙한 경우에는 해당 라운드의 게임 번호를 라이브 서포트에 제공하세요.</div>
                    </div>
                </div>

                <div className="section">
                    <div className="title">더 많은 게임:</div>
                    <div className="content">
                        <div>Inno 로비 — 현재 게임을 종료하지 않고 간편하게 게임 선택. 새로운 게임이 선택될 때까지.</div>
                    </div>
                </div>
            </div>
        ),
    };

    return translation[localStorage.getItem("i18nextLng")] ?? translation["gb"];
}

export default RtHowToPlay;
