import React from "react";

//if no need color must pass 'none'
function Dot({
  StrokeColor = "none",
  FirstColor = "#159252",
  LastColor = "#159252",
  text = "",
  text2 = "",
  bankerPlayer = false,
  Jackpot = false,
  LineBottom = true,
  LineRight = true,
}) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        aspectRatio: "1/1",
      }}
    >
      {/* <svg height="' + peakY + '" width="100" id="canvas">
        <polygon
          points="0,0 50,0 30, 30"
          style={{ fill: "red", stroke: "red", strokeWidth: "1" }}
        />
      </svg> */}

      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        style={{ background: "transparent" }}
      >
        {Jackpot && (
          <circle
            cx="50%"
            cy="50%"
            r="40%"
            stroke={StrokeColor}
            strokeWidth="1"
            fill={LastColor}
          />
        )}

        {Jackpot || bankerPlayer ? (
          <text
            x="50%"
            y="50%"
            fontSize="65%"
            fill="black"
            textAnchor="middle"
            dy=".38em"
          >
            {text2}
          </text>
        ) : (
          <text
            x="30%"
            y="30%"
            fontSize="75%"
            fill={FirstColor}
            textAnchor="middle"
            dy=".38em"
          >
            {text}
          </text>
        )}

        {!Jackpot && !bankerPlayer && (
          <text
            x="70%"
            y="70%"
            fontSize="75%"
            fill={LastColor}
            textAnchor="middle"
            dy=".38em"
          >
            {text2}
          </text>
        )}

        {!Jackpot && !bankerPlayer && (
          <line
            x1="0%"
            y1="100%"
            x2="100%"
            y2="0%"
            stroke={FirstColor}
            strokeWidth="1.5"
            strokeOpacity={0.3}
          />
        )}

        {LineBottom && (
          <line
            x1="0%"
            y1="100%"
            x2="100%"
            y2="100%"
            stroke="#c5c5c5"
            strokeWidth="3.5"
            strokeOpacity={0.2}
          />
        )}
        {LineRight && (
          <line
            x1="100%"
            y1="0%"
            x2="100%"
            y2="100%"
            stroke="#c5c5c5"
            strokeWidth="3.5"
            strokeOpacity={0.2}
          />
        )}
      </svg>
    </div>
  );
}

export default Dot;
