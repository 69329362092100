import React, { useState } from "react";

import "./banker-card.scss";
import Card from "../../../../components-game/card/Card";

function BankerCard({ tableDetail, dealerSignal, cardResult }) {
  return (
    <div
      className={`banker-cards ${
        cardResult[0]?.isJackpot === 0 ? false : true
      }`}
    >
      {cardResult &&
        cardResult[0]?.card?.map((items, index) => (
          <div className="card" key={index}>
            <Card
              isReveal={
                dealerSignal?.action === "show-card" ||
                dealerSignal?.action === "light-code"
              }
              card={tableDetail?.cardUrl + items.value}
            />
          </div>
        ))}
    </div>
  );
}

export default BankerCard;
