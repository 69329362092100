import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

function BetStatistics() {
    const { t } = useTranslation();

    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("betStat1")}</Paragraph>
                <Paragraph>{t("betStat2")}</Paragraph>
                <Paragraph></Paragraph>
            </Space>
        </Typography>
    );
}

export default BetStatistics;
