import React, { useState } from "react";
import Icon from "../../../components-game/navigator/icon/Icon";
import CFBtnView from "../../../components-game/navigator/CFBtnView";
import { Spin } from "antd";
import Timer from "../../../components-game/timer/Timer";
import BetPanel from "../component/bet-panel/BetPanel";
import ActionMessage from "../../../components-game/action-message/ActionMessage";
import TableList from "../../../components-game/table-list/TableList";
import TryFullscreenModal from "../../../components/common/try-fullscreen-modal/TryFullscreenModal";
import { useDreamCatcher } from "../hook/useDreamCatcher";
import LoadingPage from "../../../pages/loading-page/LoadingPage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import back from "../../../assets/game/common/back.png";
import { formatNumber } from "../../../utils/Formatter";
import "./VDreamCatcher.scss";
import VBetPanel from "../component/vertical-bet-panel/VBetPanel";
import VChips from "../../../components-game/chips-set/new-vertical/VChips";
function VDreamCatcher() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        tableInfo,
        isFirstLoad,
        isLiveLoading,
        userData,
        balance,
        connection,
        chipsArr,
        tableDetail,
        betItems,
        roadMaps,
        streamingObj,
        dealerSignal,
        totalBet,
        setTotalBet,
        betStatistic,
        winList,
        cardResult,
        labelBet,
        setLabelBet,
        actionMsg,
        reviewCard,
        setReviewCard,
        jackpotDescription,
        setJackpotDescription,
        chatMsg,
    } = useDreamCatcher();

    const [openTableList, setOpenTableList] = useState(false);

    if (isFirstLoad) {
        return <LoadingPage />;
    }
    return (
        <div
            style={{
                background: `linear-gradient(to bottom, ${tableDetail?.color?.split(",")[0]}, ${tableDetail?.color?.split(",")[1]})`,
            }}
            className="vertical-dream-catcher"
        >
            <div className="header-container">
                <div className="header-title">
                    <div className="header-btn" onClick={() => navigate(-1)}>
                        <Icon image={back} />
                    </div>
                    {tableDetail?.tableName}
                </div>
                <div className="header-menu">
                    {streamingObj && (
                        <CFBtnView
                            BtnTable
                            BtnGraphic
                            connection={connection}
                            GraphicObj={streamingObj}
                            setOpenTableList={setOpenTableList}
                            tableDetail={tableDetail}
                            chatMsg={chatMsg}
                        />
                    )}
                </div>
            </div>

            <Spin spinning={isLiveLoading}>
                <div className="live-streaming-container">
                    <div className="id-wrapper">
                        <div className="uid">UID: {dealerSignal?.gameSummarySrno ? dealerSignal.gameSummarySrno : "-"}</div>
                        <div className="uid">ID: {userData?.playerID ? userData.playerID : "-"}</div>
                    </div>
                    <canvas id="video" style={{ width: "100%", height: "100%" }} />
                    <div className="win-list-wrapper" style={{ display: winList ? "block" : "none" }}></div>

                    {dealerSignal?.second && (
                        <div className="timer-container">
                            <Timer dealerSignal={dealerSignal} />
                        </div>
                    )}
                </div>
            </Spin>

            <div className="bet-panel-container">
                {betItems && (
                    <VBetPanel
                        tableInfo={tableInfo}
                        connection={connection}
                        chipArr={chipsArr}
                        rate={betItems}
                        tableDetail={tableDetail}
                        dealerSignal={dealerSignal}
                        setTotalBet={setTotalBet}
                        betStatistic={betStatistic}
                        setLabelBet={setLabelBet}
                        cardResult={cardResult ? cardResult : []}
                        betContainerClass=""
                        jackpotDescription={jackpotDescription}
                        setJackpotDescription={setJackpotDescription}
                    />
                )}
            </div>

            <div className={`action-msg-container ${actionMsg}`}>
                <ActionMessage text={actionMsg} color="gold" totalWin={totalBet} />
            </div>

            <div className="chips-set-container">
                {chipsArr && (
                    <VChips
                        setLabelBet={setLabelBet}
                        connection={connection}
                        tableInfo={tableInfo}
                        ChipsSet={chipsArr}
                        tableDetail={tableDetail}
                        dealerSignal={dealerSignal}
                        totalBet={totalBet}
                        setTotalBet={setTotalBet}
                        roadMaps={roadMaps}
                    />
                )}
            </div>
            <div className="footer-container">
                <div className="container-01">
                    <span className="lbl lbl-total-bet">
                        {t(labelBet)}: {userData?.currency + " " + formatNumber(totalBet)}
                    </span>
                    <span className="lbl lbl-balance">Balance: {userData?.currency + " " + formatNumber(balance)}</span>
                </div>

                <div className="container-02">{`${userData?.currency + " " + tableDetail?.minBet + " - " + tableDetail?.maxBet}`}</div>
            </div>

            <TableList connection={connection} tableInfo={tableInfo} openTableList={openTableList} setOpenTableList={setOpenTableList} />

            {/iPhone|iPad/.test(window.navigator.userAgent) && <TryFullscreenModal />}
        </div>
    );
}

export default VDreamCatcher;
