import React, { useEffect, useState, useContext } from "react";

import { AuthContext } from "../../context/AuthContext";
import { formatNumber } from "../../utils/Formatter";

import "./win-list.scss";
import { UserOutlined } from "@ant-design/icons";

function WinList({ winList }) {
    const { userData } = useContext(AuthContext);

    const [totalWin, setTotalWin] = useState();
    const [totalWinPlayerCount, setTotalWinPlayerCount] = useState();

    useEffect(() => {
        setTotalWin(winList?.reduce((total, currentValue) => (total = total + currentValue?.winAmount), 0));
        setTotalWinPlayerCount(winList.length);
    }, []);

    return (
        <div className="win-list">
            <div className="win-list-header">
                <div className="player-count">
                    <UserOutlined />
                    {totalWinPlayerCount}
                </div>
                <div className="total-amount">{`${userData?.currency ?? "-"} ${formatNumber(totalWin, 0)}`}</div>
            </div>

            <div className="content-container">
                <div className="content">
                    {winList?.map((items, index) => (
                        <div className="win-list-item" key={index}>
                            {items.playerName}
                            <div>{`${userData?.currency} ${formatNumber(items.winAmount, 0)}`}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WinList;
