export function handleHoverItem(e) {
    const target = e.target.parentElement.dataset.id;

    if (target?.includes("_")) {
        target.split("_").forEach((element) => {
            document.querySelector(`[data-id="${element}"]`)?.classList?.add("bet-hover");
        });
    } else {
        document.querySelector(`[data-id="${e.target.parentElement.dataset.id}"]`)?.classList?.add("bet-hover");
    }
}

export function handleHoverLeave(e) {
    const target = e.target.parentElement.dataset.id;

    if (target?.includes("_")) {
        target.split("_").forEach((element) => {
            document.querySelector(`[data-id="${element}"]`)?.classList?.remove("bet-hover");
        });
    } else {
        document.querySelector(`[data-id="${e.target.parentElement.dataset.id}"]`)?.classList?.remove("bet-hover");
    }
}

export function handleOvalHoverItem(item) {
    if (item.color === "transparent") {
        const temp = item.lightCode.split(",");

        temp.forEach((element) => {
            document.querySelector(`[data-id="${element}|o"]`)?.classList?.add("bet-hover");
        });
    } else {
        const temp = item.code;

        document.querySelector(`[data-id="${temp}|o"]`)?.classList?.add("bet-hover");
    }
}

export function handleOvalHoverLeave(item) {
    if (item.color === "transparent") {
        const temp = item.lightCode.split(",");

        temp.forEach((element) => {
            document.querySelector(`[data-id="${element}|o"]`)?.classList?.remove("bet-hover");
        });
    } else {
        const temp = item.code;

        document.querySelector(`[data-id="${temp}|o"]`)?.classList?.remove("bet-hover");
    }
}

export function handleGetMainBetItemBetCode(index) {
    let result = [];
    const arr = Array.from({ length: 36 }).map((_, i) => i + 1);

    switch (true) {
        case index <= 36:
            return `R${index}`;
        case index <= 37:
            arr.forEach((item) => {
                if (item % 3 === 1) {
                    result.push(`R${item}`);
                }
            });
            return result.join("_");
        case index <= 38:
            arr.forEach((item) => {
                if (item % 3 === 2) {
                    result.push(`R${item}`);
                }
            });
            return result.join("_");
        case index <= 39:
            arr.forEach((item) => {
                if (item % 3 === 0) {
                    result.push(`R${item}`);
                }
            });
            return result.join("_");
        default:
            break;
    }
}

export function getColor(i) {
    let number = Number(i);

    if (!number) {
        return "green";
    } else if (number < 37) {
        if (
            number === 1 ||
            number === 3 ||
            number === 5 ||
            number === 7 ||
            number === 9 ||
            number === 12 ||
            number === 14 ||
            number === 16 ||
            number === 18 ||
            number === 19 ||
            number === 21 ||
            number === 23 ||
            number === 25 ||
            number === 27 ||
            number === 30 ||
            number === 32 ||
            number === 34 ||
            number === 36
        ) {
            return "red";
        } else {
            return "black";
        }
    }
}

export const data = Array.from({
    length: 40,
}).map((_, i) => ({
    key: i,
    code: handleGetMainBetItemBetCode(i),
    color: getColor(i),
    children: [
        {
            key: 1,
            display: i < 37,
            code:
                i <= 1
                    ? `R${i - 1}_R${i}_R${i + 1}`
                    : i <= 2
                    ? `R${i - 2}_R${i}_R${i + 1}`
                    : i <= 3
                    ? `R${i - 3}_R${i - 2}_R${i - 1}_R${i}`
                    : i % 3 === 0
                    ? `R${i - 5}_R${i - 4}_R${i - 3}_R${i - 2}_R${i - 1}_R${i}`
                    : `R${i - 3}_R${i - 2}_R${i}_R${i + 1}`,
        },
        {
            key: 2,
            display: i < 37,
            code: i % 3 === 0 ? `R${i - 2}_R${i - 1}_R${i}` : `R${i}_R${i + 1}`,
        },
        {
            key: 3,
            display: i < 37,
            code: i <= 1 ? `R${i - 1}_R${i}` : i <= 2 ? `R${i - 2}_R${i}` : i <= 3 ? `R${i - 3}_R${i}` : `R${i - 3}_R${i}`,
        },
        {
            key: 4,
            display: i % 3 === 1 && i < 37,
            code: i <= 1 ? `R${i - 1}_R${i}_R${i + 1}_R${i + 2}` : `R${i - 3}_R${i - 2}_R${i - 1}_R${i}_R${i + 1}_R${i + 2}`,
        },
        {
            key: 5,
            display: i % 3 === 1 && i < 37,
            code: `R${i}_R${i + 1}_R${i + 2}`,
        },
    ],
}));

export function handleGetMainBetItemContent(index) {
    switch (true) {
        case index > 36:
            return "2 to 1";
        case index <= 36:
            return index;
        default:
            break;
    }
}

export function handleGetHSubBetItemContent(index) {
    switch (index) {
        case 0:
            return `${index + 1}st 12`;
        case 1:
            return `${index + 1}nd 12`;
        case 2:
            return `${index + 1}rd 12`;
        case 3:
            return "1-18";
        case 4:
            return "EVEN";
        case 5:
            return <div className="diamond red" />;
        case 6:
            return <div className="diamond black" />;
        case 7:
            return "ODD";
        case 8:
            return "19-36";
        default:
            break;
    }
}

export function handleGetVSubBetItemContent(index) {
    switch (index) {
        case 0:
            return "1-18";
        case 1:
            return `${index}st 12`;
        case 2:
            return "EVEN";
        case 3:
            return <div className="diamond red" />;
        case 4:
            return `${index - 2}nd 12`;
        case 5:
            return <div className="diamond black" />;
        case 6:
            return "ODD";
        case 7:
            return `${index - 4}rd 12`;
        case 8:
            return "19-36";
        default:
            break;
    }
}

export const subData = Array.from({
    length: 9,
}).map((_, i) => ({
    key: i,
    code: handleGetSubBetItemBetCode(i),
}));

export function handleGetSubBetItemBetCode(index) {
    switch (index) {
        case 0:
            return Array.from({ length: 12 })
                .map((_, i) => `R${i + 1}`)
                .join("_");
        case 1:
            return Array.from({ length: 12 })
                .map((_, i) => `R${i + 13}`)
                .join("_");
        case 2:
            return Array.from({ length: 12 })
                .map((_, i) => `R${i + 25}`)
                .join("_");
        case 3:
            return Array.from({ length: 18 })
                .map((_, i) => `R${i + 1}`)
                .join("_");
        case 4:
            let even = [];
            Array.from({ length: 36 })
                .map((_, i) => i + 1)
                .forEach((item) => {
                    if (item % 2 === 0) {
                        even.push(`R${item}`);
                    }
                });
            return even.join("_");
        case 5:
            return "R1_R3_R5_R7_R9_R12_R14_R16_R18_R19_R21_R23_R25_R27_R30_R32_R34_R36";
        case 6:
            return "R2_R4_R6_R8_R10_R11_R13_R15_R17_R20_R22_R24_R26_R28_R29_R31_R33_R35";
        case 7:
            let odd = [];
            Array.from({ length: 36 })
                .map((_, i) => i + 1)
                .forEach((item) => {
                    if (item % 2 !== 0) {
                        odd.push(`R${item}`);
                    }
                });
            return odd.join("_");
        case 8:
            return Array.from({ length: 18 })
                .map((_, i) => `R${i + 19}`)
                .join("_");
        default:
            break;
    }
}

export const vSubData = Array.from({
    length: 9,
}).map((_, i) => ({
    key: i,
    code: handleGetVSubBetItemBetCode(i),
}));

export function handleGetVSubBetItemBetCode(index) {
    switch (index) {
        case 0:
            return Array.from({ length: 18 })
                .map((_, i) => `R${i + 1}`)
                .join("_");
        case 1:
            return Array.from({ length: 12 })
                .map((_, i) => `R${i + 1}`)
                .join("_");
        case 2:
            let even = [];
            Array.from({ length: 36 })
                .map((_, i) => i + 1)
                .forEach((item) => {
                    if (item % 2 === 0) {
                        even.push(`R${item}`);
                    }
                });
            return even.join("_");
        case 3:
            return "R1_R3_R5_R7_R9_R12_R14_R16_R18_R19_R21_R23_R25_R27_R30_R32_R34_R36";
        case 4:
            return Array.from({ length: 12 })
                .map((_, i) => `R${i + 13}`)
                .join("_");
        case 5:
            return "R2_R4_R6_R8_R10_R11_R13_R15_R17_R20_R22_R24_R26_R28_R29_R31_R33_R35";
        case 6:
            let odd = [];
            Array.from({ length: 36 })
                .map((_, i) => i + 1)
                .forEach((item) => {
                    if (item % 2 !== 0) {
                        odd.push(`R${item}`);
                    }
                });
            return odd.join("_");
        case 7:
            return Array.from({ length: 12 })
                .map((_, i) => `R${i + 25}`)
                .join("_");
        case 8:
            return Array.from({ length: 18 })
                .map((_, i) => `R${i + 19}`)
                .join("_");
        default:
            break;
    }
}

export const hSubBetItem = [
    {
        key: 10,
        code: "R10",
        lightCode: "R5,R8,R10,R23,R24",
        color: "black",
    },
    {
        key: 5,
        code: "R5",
        lightCode: "R5,R10,R16,R23,R24",
        color: "red",
    },
    {
        key: 24,
        code: "R24",
        lightCode: "R5,R10,R16,R24,R33",
        color: "black",
    },
    {
        key: 16,
        code: "R16",
        lightCode: "R1,R5,R16,R24,R33",
        color: "red",
    },
    {
        key: 33,
        code: "R33",
        lightCode: "R1,R16,R20,R24,R33",
        color: "black",
    },
    {
        key: 1,
        code: "R1",
        lightCode: "R1,R14,R16,R20,R33",
        color: "red",
    },
    {
        key: 20,
        code: "R20",
        lightCode: "R1,R14,R20,R31,R33",
        color: "black",
    },
    {
        key: 14,
        code: "R14",
        lightCode: "R1,R9,R14,R20,R31",
        color: "red",
    },
    {
        key: 31,
        code: "R31",
        lightCode: "R9,R14,R20,R22,R31",
        color: "black",
    },
    {
        key: 9,
        code: "R9",
        lightCode: "R9,R14,R18,R22,R31",
        color: "red",
    },
    {
        key: 22,
        code: "R22",
        lightCode: "R9,R18,R22,R29,R31",
        color: "black",
    },
    {
        key: 18,
        code: "R18",
        lightCode: "R7,R9,R18,R22,R29",
        color: "red",
    },
    {
        key: 29,
        code: "R29",
        lightCode: "R7,R18,R22,R28,R29",
        color: "black",
    },
    {
        key: 7,
        code: "R7",
        lightCode: "R7,R12,R18,R28,R29",
        color: "red",
    },
    {
        key: 28,
        code: "R28",
        lightCode: "R7,R12,R28,R29,R35",
        color: "black",
    },
    {
        key: 12,
        code: "R12",
        lightCode: "R3,R7,R12,R28,R35",
        color: "red",
    },
    {
        key: 35,
        code: "R35",
        lightCode: "R3,R12,R26,R28,R35",
        color: "black",
    },
    {
        key: 3,
        code: "R3",
        lightCode: "R0,R3,R12,R26,R35",
        color: "red",
    },
    {
        key: 23,
        code: "R23",
        lightCode: "R5,R8,R10,R23,R30",
        color: "red",
    },
    {
        key: "Tiers du Cylindre",
        code: "R5_R8,R10_R11,R13_R16,R23_R24,R27_R30,R33_R36",
        lightCode: "R5,R8,R10,R11,R13,R23,R24,R27,R30,R33,R36",
        color: "transparent",
    },
    {
        key: "Orphelins",
        code: "R1,R6_R9,R14_R17,R17_R20,R31_R34",
        lightCode: "R1,R6,R9,R14,R17,R20,R31,R34",
        color: "transparent",
    },
    {
        key: "Voisins du Zero",
        code: "R0_R2_R3*2,R4_R7,R12_R15,R18_R21,R19_R22,R25_R26_R28_R29*2,R32_R35",
        lightCode: "R0,R2,R3,R4,R7,R12,R15,R18,R21,R22,R25,R26,R28,R29,R32,R35",
        color: "transparent",
    },
    {
        key: "Jeu Zero",
        code: "R0_R3,R12_R15,R26,R32_R35",
        lightCode: "R0,R3,R12,R15,R26,R32,R35",
        color: "transparent",
    },
    {
        key: 26,
        code: "R26",
        lightCode: "R0,R3,R26,R32,R35",
        color: "black",
    },
    {
        key: 8,
        code: "R8",
        lightCode: "R8,R10,R11,R23,R30",
        color: "black",
    },
    {
        key: 0,
        code: "R0",
        lightCode: "R0,R3,R15,R26,R32",
        color: "green",
    },
    {
        key: 30,
        code: "R30",
        lightCode: "R8,R11,R23,R30,R36",
        color: "red",
    },
    {
        key: 11,
        code: "R11",
        lightCode: "R8,R11,R13,R30,R36",
        color: "black",
    },
    {
        key: 36,
        code: "R36",
        lightCode: "R11,R13,R27,R30,R36",
        color: "red",
    },
    {
        key: 13,
        code: "R13",
        lightCode: "R6,R11,R13,R27,R36",
        color: "black",
    },
    {
        key: 27,
        code: "R27",
        lightCode: "R6,R13,R27,R34,R36",
        color: "red",
    },
    {
        key: 6,
        code: "R6",
        lightCode: "R6,R13,R17,R27,R34",
        color: "black",
    },
    {
        key: 34,
        code: "R34",
        lightCode: "R6,R17,R25,R27,R34",
        color: "red",
    },
    {
        key: 17,
        code: "R17",
        lightCode: "R2,R6,R17,R25,R34",
        color: "black",
    },
    {
        key: 25,
        code: "R25",
        lightCode: "R2,R17,R21,R25,R34",
        color: "red",
    },
    {
        key: 2,
        code: "R2",
        lightCode: "R2,R4,R17,R21,R25",
        color: "black",
    },
    {
        key: 21,
        code: "R21",
        lightCode: "R2,R4,R19,R21,R25",
        color: "red",
    },
    {
        key: 4,
        code: "R4",
        lightCode: "R2,R4,R15,R19,R21",
        color: "black",
    },
    {
        key: 19,
        code: "R19",
        lightCode: "R4,R15,R19,R21,R32",
        color: "red",
    },
    {
        key: 15,
        code: "R15",
        lightCode: "R0,R4,R15,R19,R32",
        color: "black",
    },
    {
        key: 32,
        code: "R32",
        lightCode: "R0,R15,R19,R26,R32",
        color: "red",
    },
];

export const vSubBetItem = [
    {
        key: 30,
        code: "R30",
        lightCode: "R8,R11,R23,R30,R36",
        color: "red",
    },
    {
        key: 8,
        code: "R8",
        lightCode: "R8,R10,R11,R23,R30",
        color: "black",
    },
    {
        key: 23,
        code: "R23",
        lightCode: "R5,R8,R10,R23,R30",
        color: "red",
    },
    {
        key: 10,
        code: "R10",
        lightCode: "R5,R8,R10,R23,R24",
        color: "black",
    },
    {
        key: 11,
        code: "R11",
        lightCode: "R8,R11,R13,R30,R36",
        color: "black",
    },
    {
        key: "Tiers du Cylindre",
        code: "R5_R8,R10_R11,R13_R16,R23_R24,R27_R30,R33_R36",
        lightCode: "R5,R8,R10,R11,R13,R23,R24,R27,R30,R33,R36",
        color: "transparent",
    },
    {
        key: 5,
        code: "R5",
        lightCode: "R5,R10,R16,R23,R24",
        color: "red",
    },
    {
        key: 36,
        code: "R36",
        lightCode: "R11,R13,R27,R30,R36",
        color: "red",
    },
    {
        key: 24,
        code: "R24",
        lightCode: "R5,R10,R16,R24,R33",
        color: "black",
    },
    {
        key: 13,
        code: "R13",
        lightCode: "R6,R11,R13,R27,R36",
        color: "black",
    },
    {
        key: 16,
        code: "R16",
        lightCode: "R1,R5,R16,R24,R33",
        color: "red",
    },
    {
        key: 27,
        code: "R27",
        lightCode: "R6,R13,R27,R34,R36",
        color: "red",
    },
    {
        key: 33,
        code: "R33",
        lightCode: "R1,R16,R20,R24,R33",
        color: "black",
    },
    {
        key: 6,
        code: "R6",
        lightCode: "R6,R13,R17,R27,R34",
        color: "black",
    },
    {
        key: "Orphelins",
        code: "R1,R6_R9,R14_R17,R17_R20,R31_R34",
        lightCode: "R1,R6,R9,R14,R17,R20,R31,R34",
        color: "transparent",
    },
    {
        key: 1,
        code: "R1",
        lightCode: "R1,R14,R16,R20,R33",
        color: "red",
    },
    {
        key: 20,
        code: "R20",
        lightCode: "R1,R14,R20,R31,R33",
        color: "black",
    },
    {
        key: 34,
        code: "R34",
        lightCode: "R6,R17,R25,R27,R34",
        color: "red",
    },
    {
        key: 14,
        code: "R14",
        lightCode: "R1,R9,R14,R20,R31",
        color: "red",
    },
    {
        key: 17,
        code: "R17",
        lightCode: "R2,R6,R17,R25,R34",
        color: "black",
    },
    {
        key: 31,
        code: "R31",
        lightCode: "R9,R14,R20,R22,R31",
        color: "black",
    },
    {
        key: 9,
        code: "R9",
        lightCode: "R9,R14,R18,R22,R31",
        color: "red",
    },
    {
        key: 25,
        code: "R25",
        lightCode: "R2,R17,R21,R25,R34",
        color: "red",
    },
    {
        key: "Voisins du Zero",
        code: "R0_R2_R3*2,R4_R7,R12_R15,R18_R21,R19_R22,R25_R26_R28_R29*2,R32_R35",
        lightCode: "R0,R2,R3,R4,R7,R12,R15,R18,R21,R22,R25,R26,R28,R29,R32,R35",
        color: "transparent",
    },
    {
        key: 22,
        code: "R22",
        lightCode: "R9,R18,R22,R29,R31",
        color: "black",
    },
    {
        key: 2,
        code: "R2",
        lightCode: "R2,R4,R17,R21,R25",
        color: "black",
    },
    {
        key: 18,
        code: "R18",
        lightCode: "R7,R9,R18,R22,R29",
        color: "red",
    },
    {
        key: 21,
        code: "R21",
        lightCode: "R2,R4,R19,R21,R25",
        color: "red",
    },
    {
        key: 29,
        code: "R29",
        lightCode: "R7,R18,R22,R28,R29",
        color: "black",
    },
    {
        key: 4,
        code: "R4",
        lightCode: "R2,R4,R15,R19,R21",
        color: "black",
    },
    {
        key: 7,
        code: "R7",
        lightCode: "R7,R12,R18,R28,R29",
        color: "red",
    },
    {
        key: 19,
        code: "R19",
        lightCode: "R4,R15,R19,R21,R32",
        color: "red",
    },
    {
        key: 28,
        code: "R28",
        lightCode: "R7,R12,R28,R29,R35",
        color: "black",
    },
    {
        key: 15,
        code: "R15",
        lightCode: "R0,R4,R15,R19,R32",
        color: "black",
    },
    {
        key: "Jeu Zero",
        code: "R0_R3,R12_R15,R26,R32_R35",
        lightCode: "R0,R3,R12,R15,R26,R32,R35",
        color: "transparent",
    },
    {
        key: 12,
        code: "R12",
        lightCode: "R3,R7,R12,R28,R35",
        color: "red",
    },
    {
        key: 32,
        code: "R32",
        lightCode: "R0,R15,R19,R26,R32",
        color: "red",
    },
    {
        key: 35,
        code: "R35",
        lightCode: "R3,R12,R26,R28,R35",
        color: "black",
    },
    {
        key: 0,
        code: "R0",
        lightCode: "R0,R3,R15,R26,R32",
        color: "green",
    },
    {
        key: 26,
        code: "R26",
        lightCode: "R0,R3,R26,R32,R35",
        color: "black",
    },
    {
        key: 3,
        code: "R3",
        lightCode: "R0,R3,R12,R26,R35",
        color: "red",
    },
];
