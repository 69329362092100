import React from "react";
import { Image, Result, Statistic } from "antd";
import "./Style.scss";
import logo from "../../assets/logo/logo-new.png";
import { useTranslation } from "react-i18next";

const { Countdown } = Statistic;

function LoginAgain() {
    const { t } = useTranslation();

    return (
        <div className="login-again">
            <Result icon={<Image src={logo} width={300} preview={false} />} title={t("sessionExpired")} subTitle={t("sessionExpiredDesc")} />
        </div>
    );
}

export default LoginAgain;
