import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/AuthContext";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

export function useSignalR(hubUrl) {
    const navigate = useNavigate();
    const { setUserData, setBalance, setMaintenanceInfo } = useContext(AuthContext);

    const [connection, setConnection] = useState();
    const [actionTime, setActionTime] = useState();
    const [activeUser, setActiveUser] = useState();

    const [recTable, setRecTable] = useState();
    const [chatMsg, setChatMsg] = useState([]);
    const [connectionState, setConnectionState] = useState("");

    useEffect(() => {
        StartConnect();
    }, []);

    useEffect(() => {
        if (connection) {
            connection.off("live-chat");
            connection.on("live-chat", (result) => {
                // console.log(result);
                // console.log(chatMsg);
                setChatMsg([...chatMsg, result]);
            });
        }
    }, [chatMsg]);

    async function StartConnect() {
        const conn = new HubConnectionBuilder()
            .withAutomaticReconnect()
            .withUrl(hubUrl, HttpTransportType.WebSockets)
            .configureLogging(LogLevel.Information)
            .build();
        // conn.serverTimeoutInMilliseconds = 1800000;

        await conn
            .start()
            .then(() => setConnection(conn))
            .catch((error) => console.log(error));

        if (conn) {
            conn.onreconnecting(() => setConnectionState("reconnecting"));
            conn.onreconnected(() => setConnectionState("reconnected"));
            conn.onclose(() => setConnectionState("offline"));

            conn.on("session-expired", () => {
                localStorage.removeItem("playerID");
                localStorage.removeItem("playerToken");

                window.indexedDB.deleteDatabase("Galaxy_Bet_History");
                window.indexedDB.deleteDatabase("Galaxy_Selected_Chip");
                window.location.replace("/");
            });

            conn.on("api-error", (result) => {
                console.log("api-error", result);
                message.error({ content: "API Error: " + error, key: "api-error" });
            });

            conn.on("maintenance-in-progress", (result) => {
                if (localStorage.getItem("PlayerID").substring(0, 4) !== "tst1") {
                    setMaintenanceInfo(result);
                    navigate("/maintenance");
                }
            });

            conn.on("player-info", (result) => {
                if (result.status) {
                    setUserData(result.data);
                    setBalance(result.data.balance);
                    // setBalance(Math.trunc(result.data.balance * 100) / 100);
                }
            });

            conn.on("active-user", (result) => {
                setActiveUser(Number(result.split(": ")[1]));
            });

            conn.on("recommended-table", (result) => {
                setRecTable(result);
                setChatMsg([...chatMsg, result]);
            });

            conn.on("live-chat", (result) => {
                setChatMsg([...chatMsg, result]);
            });
        }

        return conn;
    }

    async function StopConnect() {
        if (connection) {
            connection.stop();
            setConnection();
        }
    }

    return { connection, actionTime, activeUser, setActionTime, StartConnect, StopConnect, recTable, chatMsg, connectionState };
}
