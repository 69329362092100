export function handleStartStreaming(ref, url, graphic = 1080, setState) {
  handleStopStreaming(ref, setState);

  let play = new NodePlayer();
  play.setView("video");
  play.setBufferTime(2000);

  play.start(url + (graphic !== 1080 ? `_${graphic}` : "") + ".flv");
  ref.current = play;
  setState(false);
}

export function handleStopStreaming(ref, setState) {
  setState(true);

  if (ref.current) {
    ref?.current?.stop();
    ref?.current?.clearView();
  }
}
