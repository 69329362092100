import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../../context/AuthContext";
import { formatNumber } from "../../../../utils/Formatter";

import { useBetPanel } from "../../../../hook/useBetPanel";
import { useChips } from "../../../../hook/useChips";

import "./BetPanel.scss";
import { Progress, message } from "antd";

import banker from "../../../../assets/game/baccarat/banker.png";
import player from "../../../../assets/game/baccarat/player.png";
import tie from "../../../../assets/game/baccarat/tie.png";
import Card from "../../../../components-game/card/Card";

function BetPanel({
    tableInfo,
    connection,
    chipArr,
    rate,
    tableDetail,
    dealerSignal,
    setTotalBet,
    betStatistic,
    roadMaps,
    setLabelBet,
    cardResult,
    betContainerClass,
    gameType,
}) {
    const { t } = useTranslation();
    const { userData } = useContext(AuthContext);

    const { handleDisplayRate, handlePlaceBet, handleUpdateBetStatistic } = useBetPanel();
    const { handlePlaceChipFixed } = useChips();

    async function handleBet(key, e) {
        if (tableDetail && dealerSignal && dealerSignal?.action === "new-game" && betContainerClass) {
            if (roadMaps?.data2?.total > 50 && (key === "BP" || key === "PP")) {
                message.info({ content: t("pair50"), key: "pair50" });
            } else {
                try {
                    const result = await handlePlaceBet(key, tableDetail, dealerSignal);
                    setTotalBet(result?.totalBet);
                    handlePlaceChipFixed(chipArr, key, { image: result?.image, value: result?.curBetCode }, result?.uniqueID, result?.roundUniqueId);
                    handleUpdateBetStatistic(connection, tableInfo, "AddBetStatistic", key, result);
                    setLabelBet("totalBet");
                } catch (error) {
                    message.info({ content: error, key: error });
                }
            }
        }
    }

    return (
        <div className="baccarat-bet-panel">
            <div className="main-bet-item" style={{ height: `calc((100% / 5) * ${gameType === "COMMISSION" ? 3.5 : 3})` }}>
                <div className="player-tie-banker">
                    <div className="btn-main-bet PW" data-id="PW" onClick={(e) => handleBet("PW", e)}>
                        <img src={player} alt="" />
                        <div className="progress">
                            <Progress
                                type="circle"
                                percent={formatNumber((betStatistic?.player / betStatistic?.total) * 100, 0)}
                                size={35}
                                strokeColor="#4e95ed"
                                strokeWidth={6}
                                status="normal"
                            />
                            <div className="PW-text">{userData?.currency + " " + betStatistic?.player}</div>
                        </div>
                        <div className="value-rate">{t("player") + " 1:" + handleDisplayRate(rate, "PW")}</div>
                        <div className="point-container PW">{cardResult?.length > 0 && cardResult[0]?.totalPoint % 10}</div>
                        <div style={{ display: cardResult?.length === 0 && "none" }} className="result-card-container PW">
                            <div className="result-card-wrapper">
                                {cardResult?.map(
                                    (seat) =>
                                        seat.seat === "P1" &&
                                        seat?.card?.map((item, index) => (
                                            <div key={index} className={`result-card ${item.cName}`}>
                                                <Card isReveal={item.isReveal} card={tableDetail?.cardUrl + item.value} />
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="btn-main-bet BW"
                        data-id={gameType === "COMMISSION" ? "BW" : "BW1"}
                        onClick={(e) => handleBet(gameType === "COMMISSION" ? "BW" : "BW1", e)}
                    >
                        <img src={banker} alt="" />
                        <div className="progress banker-progress">
                            <Progress
                                type="circle"
                                percent={formatNumber((betStatistic?.banker / betStatistic?.total) * 100, 0)}
                                size={35}
                                strokeColor="#f54638"
                                strokeWidth={6}
                                status="normal"
                            />
                            <div className="BW-text">{userData?.currency + " " + betStatistic?.banker}</div>
                        </div>
                        <div className="value-rate">{t("banker") + " 1:" + handleDisplayRate(rate, gameType === "COMMISSION" ? "BW" : "BW1")}</div>
                        <div className="point-container BW">{cardResult?.length > 0 && cardResult[1]?.totalPoint % 10}</div>
                        <div style={{ display: cardResult?.length === 0 && "none" }} className="result-card-container BW">
                            <div className="result-card-wrapper">
                                {cardResult?.map(
                                    (seat) =>
                                        seat.seat === "B" &&
                                        seat?.card?.map((item, index) => (
                                            <div key={index} className={`result-card ${item.cName}`}>
                                                <Card isReveal={item.isReveal} card={tableDetail?.cardUrl + item.value} />
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="btn-main-bet T" data-id="T" onClick={(e) => handleBet("T", e)}>
                        <img src={tie} alt="" />
                        <div className="progress tie-progress">
                            <Progress
                                type="circle"
                                percent={formatNumber((betStatistic?.tie / betStatistic?.total) * 100, 0)}
                                size={30}
                                strokeColor="#5ec93d"
                                strokeWidth={6}
                                status="normal"
                            />
                            <div className="T-text">{userData?.currency + " " + betStatistic?.tie}</div>
                        </div>
                        <div className="value-rate">{t("tie") + " 1:" + handleDisplayRate(rate, "T")}</div>
                    </div>
                </div>
            </div>
            <div className="sub-bet-item" style={{ height: `calc((100% / 5) * ${gameType === "COMMISSION" ? 1.5 : 2})` }}>
                <div className="btn-bet-item PP" data-id="PP" onClick={(e) => handleBet("PP", e)}>
                    <span>{t("player") + " " + t("pair")}</span>
                    <span>1:{handleDisplayRate(rate, "PP")}</span>
                </div>
                <div className="btn-bet-item BP" data-id="BP" onClick={(e) => handleBet("BP", e)}>
                    <span>{t("banker") + " " + t("pair")}</span>
                    <span>1:{handleDisplayRate(rate, "BP")}</span>
                </div>

                <div style={{ display: gameType !== "B6" && "none" }} className="btn-bet-item B6" data-id="B6" onClick={(e) => handleBet("B6", e)}>
                    <span>
                        <div style={{ whiteSpace: "wrap" }}>{t("b6") + " (" + t("b6Win") + ")"}&nbsp;</div>
                    </span>
                    <span>1:{handleDisplayRate(rate, "B6")}</span>
                </div>
                <div style={{ display: gameType !== "B6" && "none" }} className="btn-bet-item B6T" data-id="B6T" onClick={(e) => handleBet("B6T", e)}>
                    <span>
                        <div style={{ whiteSpace: "wrap" }}>{t("b6t") + " (" + t("b6TWin") + ")"}&nbsp;</div>
                    </span>
                    <span>1:{handleDisplayRate(rate, "B6T")}</span>
                </div>

                <div
                    style={{ display: gameType !== "TIGER" && "none" }}
                    className="btn-bet-item SMT"
                    data-id="SMT"
                    onClick={(e) => handleBet("SMT", e)}
                >
                    <span>
                        <div style={{ whiteSpace: "wrap" }}>{t("smallTiger")}&nbsp;</div>
                        <div style={{ whiteSpace: "wrap" }}>{t("smallTigerWin")}&nbsp;</div>
                    </span>
                    <span>1:{handleDisplayRate(rate, "SMT")}</span>
                </div>
                <div style={{ display: gameType !== "TIGER" && "none" }} className="btn-bet-item BT" data-id="BT" onClick={(e) => handleBet("BT", e)}>
                    <span>
                        <div style={{ whiteSpace: "wrap" }}>{t("bigTiger")}&nbsp;</div>
                        <div style={{ whiteSpace: "wrap" }}>{t("bigTigerWin")}&nbsp;</div>
                    </span>
                    <span>1:{handleDisplayRate(rate, "BT")}</span>
                </div>
                <div
                    style={{ display: gameType !== "TIGER" && "none" }}
                    className="btn-bet-item SPT"
                    data-id="SPT"
                    onClick={(e) => handleBet("SPT", e)}
                >
                    <span>
                        <div style={{ whiteSpace: "wrap" }}>{t("superTiger")}&nbsp;</div>
                        <div style={{ whiteSpace: "wrap" }}>{t("superTigerWin")}&nbsp;</div>
                    </span>
                    <span>1:{handleDisplayRate(rate, "SPT")}</span>
                </div>
                <div style={{ display: gameType !== "TIGER" && "none" }} className="btn-bet-item TT" data-id="TT" onClick={(e) => handleBet("TT", e)}>
                    <span>
                        <div style={{ whiteSpace: "wrap" }}>{t("tigerTie")}&nbsp;</div>
                        <div style={{ whiteSpace: "wrap" }}>{t("tigerTieWin")}&nbsp;</div>
                    </span>
                    <span>1:{handleDisplayRate(rate, "TT")}</span>
                </div>
            </div>
        </div>
    );
}

export default BetPanel;
