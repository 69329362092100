// import "antd/dist/antd.min.css";
import "antd/dist/reset.css";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Login from "./pages/login/Login";
import Lobby from "./pages/lobby/Lobby";
import MerchantLogin from "./pages/merchant-login/MerchantLogin";
import PageNotFound from "./pages/error/PageNotFound";
import Error from "./pages/error/Error";
import LobbyLayout from "./components/lobby-layout/LobbyLayout";
// import GameLayout from "./components/game-layout/GameLayout";
import GameLayout from "./components-game/main-layout/GameLayout";
import Maintenance from "./pages/maintenance/Maintenance";
import LoginAgain from "./pages/error/LoginAgain";
import { ConfigProvider } from "antd";

function App() {
    const Protected = ({ children }) => {
        const { userData } = useContext(AuthContext);

        if (userData) {
            return children;
        } else {
            return <Navigate to="/error" replace />;
        }
    };

    const router = createBrowserRouter([
        {
            path: "/",
            element: <LoginAgain />,
            errorElement: <Error />,
        },
        {
            path: "/admin-login",
            element: <Login />,
            errorElement: <Error />,
        },
        {
            path: "/dev-login",
            element: <Login />,
            errorElement: <Error />,
        },
        {
            path: "/merchant-login/:playerID?/:playerToken?/:gameType?/:tableInfo?/:lang?",
            element: <MerchantLogin />,
            errorElement: <Error />,
        },
        {
            path: "error",
            element: <Error />,
            errorElement: <Error />,
        },
        {
            element: <LobbyLayout />,
            errorElement: <Error />,
            children: [
                {
                    path: "/lobby/:menu?/:category?",
                    element: <Protected children={<Lobby />} />,
                },
            ],
        },
        {
            element: <GameLayout />,
            errorElement: <Error />,
            path: "/game-table/:tableInfo?",
        },
        {
            element: <Maintenance />,
            errorElement: <Error />,
            path: "/maintenance",
        },

        {
            path: "/error",
            element: <Error />,
            errorElement: <Error />,
        },
    ]);

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Times New Roman",
                },
            }}
        >
            <RouterProvider router={router} />
        </ConfigProvider>
    );
}

export default App;
