import React from "react";

//if no need color must pass 'none'
function Dot({
    StrokeColor = "none",
    FillColor = "#159252",
    text = "",
    TextColor = "#ddd",
    LineBottom = true,
    LineRight = true,
    PlayerPair = false,
    BankerPair = false,
    Tie = false,
    TieColor = "#159252",
}) {
    return (
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", aspectRatio: "1/1" }}>
            <svg width="100%" height="100%" preserveAspectRatio="none" style={{ background: "transparent" }}>
                {LineBottom && <line x1="0%" y1="100%" x2="100%" y2="100%" stroke="#c5c5c5" strokeWidth="2" strokeOpacity={0.2} />}
                {LineRight && <line x1="100%" y1="0%" x2="100%" y2="100%" stroke="#c5c5c5" strokeWidth="2" strokeOpacity={0.2} />}

                <circle cx="50%" cy="50%" r="35%" stroke={StrokeColor} strokeWidth="1" fill={FillColor} />

                {PlayerPair && <circle cx="23%" cy="23%" r="15%" fill="#3788ff" stroke="white" strokeWidth="1" />}
                {BankerPair && <circle cx="77.5%" cy="77%" r="15%" fill="#ee4043" stroke="white" strokeWidth="1" />}
                {Tie && <line x1="0%" y1="100%" x2="100%" y2="0%" stroke={TieColor} strokeWidth="1" />}
                {text && (
                    <text x="50%" y="50%" fontSize="80%" fill={TextColor} fontWeight="700" textAnchor="middle" dy=".38em">
                        {text}
                    </text>
                )}
            </svg>
        </div>
    );
}

export default Dot;
