import { Modal } from "antd";
import React, { useContext } from "react";
import "./gametypeselect.scss";
import { AuthContext } from "../../../../context/AuthContext";

function GameTypeSelect({ gameType, setGameType }) {
    const { userData } = useContext(AuthContext);

    function getImage(type) {
        switch (type) {
            case "TIGER":
                return `https://galaxy-game.sgp1.digitaloceanspaces.com/game-type/${userData?.lang}/tiger.png`;
            case "B6":
                return `https://galaxy-game.sgp1.digitaloceanspaces.com/game-type/${userData?.lang}/b6.png`;
            default:
                return `https://galaxy-game.sgp1.digitaloceanspaces.com/game-type/${userData?.lang}/commission.png`;
        }
    }
    return (
        <Modal width={window.innerWidth} className="type-select-modal" destroyOnClose={true} closable={false} open={!gameType} footer={null} centered>
            <div className="type-select-container">
                {["COMMISSION", "B6", "TIGER"].map((item, index) => (
                    <div key={index} className="type" onClick={() => setGameType(item)}>
                        <img src={getImage(item)} alt="" />
                    </div>
                ))}
            </div>
        </Modal>
    );
}

export default GameTypeSelect;
