import axios from "axios";
import Swal from "sweetalert2";

export const baseDomain = "https://ui-api." + process.env.REACT_APP_API_DOMAIN;

export const authApiBaseUrl = axios.create({
  baseURL: `${baseDomain}`,
});

export default function apiBaseUrl() {
  const api = axios.create({
    baseURL: `${baseDomain}`,
  });

  const onResponseSuccess = (response) => {
    return response;
  };

  const onResponseError = (error) => {
    if (!error.response) {
      Swal.fire({
        text: "Unable connect to server",
        icon: "error",
      });
    } else if (error?.code === "ERR_NETWORK") {
      Swal.fire({
        text: "Maintenance In Progress",
        icon: "error",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    } else {
      if (error.response.status === 401) {
        if (window.location.pathname !== "/" && window.location.pathname !== "/admin-login" && window.location.pathname !== "/dev-login") {
          window.location.assign("/");
        }
      } else if (error.response.status === 307) {
        if (window.location.pathname !== "/maintenance" && window.location.pathname !== "/dev-login") {
          window.location.assign("/maintenance");
        }
      }
    }
    return Promise.reject(error);
  };

  api.interceptors.response.use(onResponseSuccess, onResponseError);
  return api;
}
