import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

function MoreGames() {
    const { t } = useTranslation();

    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("moreGames1")}</Paragraph>
                <Paragraph>{t("moreGames2")}</Paragraph>
                <Paragraph>{t("moreGames3")}</Paragraph>
                {/* <Paragraph>Image</Paragraph> */}
            </Space>
        </Typography>
    );
}

export default MoreGames;
