import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    gb: {
        translation: {
            videoLag: "Video Lagging",
            gameStutter: "Game Stuttering",
            slowGame: "Slow Game Entry",
            clutteredInt: "Cluttered Interface",
            others: "Others",
            chips8: "Please select 8 chips",
            selectChips: "Please Select Chips",
            success: "Success",
            pleaseSelectFirst: "Please select chip to replace first",
            nothing: "Nothing to update",
            update: "UPDATE",
            logout: "Logout",
            fullScreen: "Full Screen",
            exitFullScreen: "Exit Full Screen",
            bankerWin: "Banker Win",
            banker: "Banker",
            banker_s: "B",
            playerWin: "Player Win",
            player: "Player",
            player_s: "P",
            tp: "3P",
            win: "Win",
            tie_s: "T",
            tie: "Tie",
            super6: "Super 6",
            jackpot: "Jackpot",
            autoSplit: "Auto Split",
            ante: "Ante",
            bet: "Bet",
            point: "Point",
            bonus: "Bonus",
            pair: "Pair",
            either: "Either",
            cancelRound: "We're sorry.",
            startBet: "Start Bet",
            stopBet: "No More Bet",
            nextRound: "Please Wait For Next Round",
            lastGame: "Last Game",
            insufficientBalance: "Insufficient Balance",
            betLimitExceed: "Bet Limit Exceed",
            selectAmount: "Please Select Bet Amount First",
            peekCard: "Please Select Card To Scratch",
            nothingToUndo: "No bet to undo",
            noBetHistory: "No bet history",
            playerPair: "Player Pair",
            bankerPair: "Banker Pair",
            menu: "Menu",
            lobby: "Lobby",
            setting: "Settings",
            gameHistory: "Game History",
            how: "How To Play",
            video: "Video",
            playVideo: "Play with video",
            quality: "Video Quality",
            sound: "Sound",
            playSound: "Play with sound",
            general: "General",
            fullscreen: "Fullscreen Setting",
            auto: "Auto",
            low: "Low",
            high: "High",
            language: "Language",
            game: "Game",
            date: "Date",
            winLoss: "Win Loss",
            gameObjectives: "Game Objectives",
            gameRules: "Game Rules",
            sideBets: "Side Bets",
            payouts: "Payouts",
            redEnvelope: "Red Envelope",
            playerReturn: "Return To Player",
            placeBets: "Place Bets",
            betStat: "Bet Statistics",
            chat: "Chat",
            scoreCard: "Score Card",
            gameNumber: "Game Number",
            errorHandling: "Error Handling",
            policy: "Disconnection Policy",
            shuffling: "Shuffling",
            moreGames: "More Games",
            betCode: "Bet Code",
            b6: "Super 6",
            b6Win: "Banker 6 Win",
            b6t: "Super 6 Tie",
            b6TWin: "Banker 6 Tie",
            smallTiger: "Small Tiger",
            bigTiger: "Big Tiger",
            superTiger: "Super Tiger",
            tigerTie: "Tiger Tie",
            smallTigerWin: "B6 Win (2 Cards)",
            bigTigerWin: "B6 Win (3 Cards)",
            superTigerWin: "B6 Win",
            tigerTieWin: "Tie with 6",
            allGame: "All Game",
            youWin: "YOU WIN",
            threeP: "3 Pictures",
            betPoint: "More Bet",
            traditionalBet: "Traditional Bet",
            clickToStay: "Click on blank space to stay!",
            clickToStayDesc: "You will be redirected to Lobby in <b></b> seconds.",
            maintenance: "Maintenance in progress.",
            tableClosed: "Sorry, table maintenance in progress.",
            merchantReturnNull: "System operation timed out. Your operation may failed or not sent successfully.",
            playerInsufficientBalance: "Player Insufficient Balance",
            merchantInsufficientBalance: "Merchant Insufficient Balance",
            betLimitExceeded: "Bet Limit Exceeded",
            tableNotFound: "Table Not Found",
            betPlaced: "Current Player Already Place Bet",
            playerNotFound: "Player Not Found",
            gameClose: "Game Close",
            amountLess: "Amount Cannot Be Less Than Or Equal To 0",
            apiError: "Some error occur, please contact your agent",
            last: "Last",
            result: "Result",
            COMMISSION: "Baccarat",
            B6: "Super Six Baccarat",
            TIGER: "Tiger Six Baccarat",
            THREEP: "Three Picture",
            FOURSEASON: "Four Seasons",
            ROULETTE: "Roulette",
            SICBO: "Sicbo",
            PEEKBC: "Peek Baccarat",
            DREAMCATCHER: "Dreamcatcher",
            sessionExpired: "Session Expired",
            sessionExpiredDesc: "User authentication failed or your session may be expired, please try again.",
            maintInProgressDesc: "We apologize for the inconvenience, but we're performing some maintenance. We'll be back up soon!",
            countdownDesc: "We'll be back in",
            playerID: "Player ID",
            betStat1: "The total wager placed on a particular bet spot is shown as well as the number of players who placed bets on the spot.",
            betStat2: "The percentages of all bets placed on the Banker, the Player or on a Tie are also shown.",
            betStat3: "You can choose to hide these statistics under Game Settings.",
            disconnect1:
                "If you are disconnected from a game round, any placed bets remain valid and are settled in your absence. Upon reconnecting, you can view the bet outcomes in the History window.",
            errorHandling1:
                "If there is an error in the game, system or game procedure, the game round will be temporarily paused while the game host notifies the service manager. You and other players will be notified by an on-screen pop-up message, that the issue is being investigated. If the manager can immediately resolve the error, the game round will continue as normal. If immediate resolution is not possible, the game round will be cancelled, and the initial bets will be refunded to all the players who participated in the game round.",
            gameHistory1: "The HISTORY button will launch a window showing all game rounds you have played and the results of those rounds.",
            gameHistory2: "Image",
            gameHistory3: "You can review your past gaming activity by viewing your:",
            gameHistory4:
                "ACCOUNT HISTORY — shows your complete account history as a list of dates, games, bet amounts and payouts. The game round completed most recently appears at the top of the list.",
            gameHistory5: "GAME HISTORY — shows your history related to a particular game once you click/tap the game in the GAME column.",
            gameNumber1: "Each game round is identified by a unique GAME NUMBER.",
            gameNumber2:
                'This number reflects when the game round began in terms of GMT by {"time"}. Please use this game number for reference (or take a screenshot of the game number) if you wish to contact Customer Service regarding a particular round.',
            gameObjectives1: "The objective in Speed Baccarat is to predict whose hand will win by having the value closest to 9.",
            gameRules1: "The game is hosted by a dealer and i played with eight standard 52-card decks. Card values are as follows:",
            gameRules2: "Aces are the lowest cards and are worth 1 point each.",
            gameRules3: "Cards from 2 to 9 are worth their numerical face values.",
            gameRules4: "10s and face cards (Jacks, Queens & Kings) are each worth 0.",
            gameRules5:
                "Only the numeric value of each card is relevant in the main Baccarat game; each card's suit (Hearts, Spades, Clubs or Diamonds) is irrelevant.",
            gameRules6:
                "Prior to each deal, you must place your bet on whether the Player or Banker will win the round by having the hand with a value closest to 9.",
            gameRules7: "If you believe that both the Player and Banker will hold hands of equal value, you also have the option to bet on Tie.",
            gameRules8:
                "In addition, you can also bet on Player/Banker Pair (P/B Pair), which will pay if the first two cards dealt to the Player/Banker constitute a pair.",
            gameRules9: "The dealer begins by dealing tow cards each to the Player and to the Banker.",
            gameRules10: "Two hands are dealt in Baccarat: one hand to the Player and one hand to the Banker.",
            gameRules11:
                "If the Player and Banker hold hands of equal value, the round ends in a tie. The Tie bet wins, and bets on the Player and Banker push (are returned).",
            gameRules12:
                "Each hand's value is calculated by dropping the tens digit in a hand otherwise worth 10 or more. For example, a hand consisting of a 7 and a 9 is only worth 6 in Baccarat (because 16-10=6). Similarly, a face card plus a 9 will be worth 9.",
            gameRules13:
                'If the Player or the Banker receives an initial two-card hand worth 8 or a 9 (a "natural" 8 or 9), no additional cards will be dealt to either hand.',
            gameRules14:
                'If the Player and Banker receive initial two-card hands worth 0-7, the "Third Card Rule" is consulted to determine if a third card is to be dealt to either hand or both hands. The Player always goes first.',
            gameRules15: "Player's Hand",
            gameRules16: "Player's initial two-card hand",
            gameRules17: "Player draws a third card.",
            gameRules18: "Player stands.",
            gameRules19: "No third card to wither hand.",
            gameRules20: "Banker's Hand",
            gameRules21: "D - Draw; S- Stand",
            gameRules22:
                "If the Player's hand stands on a 6 or 7, then a Banker's hand totalling 3, 4 or 5 must draw, while a Banker's hand totalling 6 must stand.",
            gameRules23: "Whoever gets the hand closest to a total of 9 wins.",
            gameRules27: "Rules for the banker drawing a third card",
            gameRules28: "When the total points of the banker's first two cards are:",
            gameRules29: "0, 1, or 2 points: Unless the player has a natural total of 8 or 9 points, the banker draws a card.",
            gameRules30: "(Banker with 3 points): If the player's third card is 0, 1, 2, 3, 4, 5, 6, 7, or 9 points, the banker draws a card.",
            gameRules31: "When the player's third card is 8 points, the banker does not draw a card.",
            gameRules32: "(Banker with 4 points): When the player's third card is 2, 3, 4, 5, 6, or 7 points, the banker draws a card.",
            gameRules33: "When the player's third card is 0, 1, 8, or 9 points, the banker does not draw a card.",
            gameRules34: "(Banker with 5 points): When the player's third card is 4, 5, 6, or 7 points, the banker draws a card.",
            gameRules35: "When the player's third card is 0, 1, 2, 3, 8, or 9 points, the banker does not draw a card.",
            gameRules36: "(Banker with 6 points): When the player's third card is 6 or 7 points, the banker draws a card.",
            gameRules37: "When the player's third card is 0, 1, 2, 3, 4, 5, 8, or 9 points, the banker does not draw a card.",
            gameRules38: "7 points: Neither side draws a card.",
            gameRules39: "8 or 9 points: A natural total, neither side draws a third card.",
            moreGames1: "The LOBBY button can be selected at any time from any game.",
            moreGames2:
                "It allows you to easily change the game table or select any other live game. You will not be removed from this game until you have actually selected the new game/table you wish to join. You can use the LOBBY to browse other games while still playing in your current game.",
            moreGames3:
                "Baccarat Multiplay is a Multiplay panel in which players can quickly observe and sort the road patterns, place bets, and play at multiple Baccarat tables at the same time.",
            payouts1: "PAYS",
            payouts2: "commission is charged",
            placeBets1: "Disabling side bets depends on the number of cards dealt during the game on your particular table.",
            placeBets2:
                "The BET LIMITS panel shows the minimum and maximum allowed bet limits at the table, which may change from time to time. Open the Bet Limits to check your current limits.",
            placeBets3:
                "To participate in the game, you must have sufficient funds to cover your bets. You can see your current BALANCE on your screen.",
            placeBets4:
                "In the Immersive view, the TIMER informs you of the duration of betting time. After it expires, betting is closed and no more bets are accepted.",
            placeBets5:
                "The CHIP DISPLAY allows you to select the value of each chip you wish to bet. Only chips of denominations that can be covered by your current balance will be enabled.",
            placeBets6:
                "Once you have selected a chip, place your bet by simply clicking/tapping the appropriate bet spot on the game table. Each time you click/tap the bet spot, the amount of your bet increases by the value of the selected chip or up to the maximum limit for the type of bet you have selected. Once you have bet the maximum limit, no additional funds will be accepted for that bet, and a message will appear above your bet to notify you that you have bet the maximum.",
            placeBets7:
                "NOTE: Please do not minimise your browser or open any other tab in your browser while betting time remains and you have placed bets on the table. Such actions may be interpreted as leaving the game, and your bets will therefore be declined for that particular game round.",
            placeBets8:
                "The REPEAT button allows you to repeat all bets from the previous game round. This button is available only before the first chip is placed.",
            placeBets9:
                "The DOUBLE (2x) button becomes available after you have placed any bet. Each click/tap doubles all your bets up to the maximum limit. Note that you must have a sufficient account balance to double ALL your placed bets.",
            placeBets10: "The UNDO button removes the last bet you placed.",
            placeBets11:
                "You can click/tap the UNDO button repeatedly to remove bets, one by one, in the reverse order in which they were placed. You can clear all your bets by holding the UNDO button.",
            placeBets12: "The TOTAL BET indicator displays the total amount of all bets placed in the current round.",
            return1: "The optimal Return To Player (RTP) percentage for Baccarat is 98.94%*.",
            return2: "The following table displays the return-to-player percentage on the different optional side bets.",
            return3: "Return to Player",
            return4: "*RTP based on optimal strategy for Banker bet.",
            score1: "Baccarat streaks and trends for either the Player or the Banker using a particular shoe are recorded in various scoreboards. These pictorial representations of past round results and other statistics regarding the current shoe may be of help to you in predicting the results of future rounds.",
            score2: "The BEAD ROAD and BIG ROAD display the results of each past round, while the Big Eye Road, Small Road and Cockroach Road display patterns derived from the BIG ROAD.",
            score3: "Roads and shoe statistics are always cleared when a new shoe is introduced.",
            score4: "BEAD ROAD",
            score5: "Each cell in the BEAD ROAD represents the result of a past round. The result of the earliest round is recorded in the upper left corner. Read the column downwards all the way to the bottom; then start at the top of the adjacent column to the right and so forth. A solid red cell indicates a Banker win. A solid blue cell represents a Player win. A solid green cell represents a Tie. A red dot in the bottom right corner of a cell means the Banker had a pair. A blue dot in the top left corner of a cell means the Player had a pair.",
            score6: "You can change the Bead Road display from English to Simplified Chinese or Score Mode by clicking/tapping it anywhere.",
            score7: "BIG ROAD",
            score8: "In the BIG ROAD, the result of the earliest round is recorded in the upper left corner.",
            score9: "A new column is created each time the Banker’s winning streak changes in favour of the Player, or vice versa.",
            score10: "A cell outlined in red indicates a Banker win. A cell outlined in blue indicates a Player win.",
            score11:
                "A Tie is recorded as a green line through the cell for the preceding round. If the first round is a Tie, the green line will appear first, and the red or blue outline will appear around the line once the Player or Banker wins a round.",
            score12: "If there are two or more consecutive tie rounds, the number on line will show the number of ties.",
            score13: "DERIVED ROADS",
            score14:
                'For the true Baccarat enthusiast, the Big Eye Road, Small Road and Cockroach Road are included to display patterns derived from past results recorded in the BIG ROAD. The Big Eye Road uses outlined circles, the Small Road uses solid circles, and the Cockroach Road uses slashes. However, in these derived roads, the colours red and blue do not correspond to Banker and Player wins, and there is no way to discern ties or pairs. In derived roads, red entries are a sign of repetition, whereas blue entries signal a more erratic, "choppy" shoe.',
            score15:
                "The derived roads do not start at the very beginning of the shoe. They start with the hand following the first hand in the second, third and fourth columns of the BIG ROAD. Once a derived road starts, an additional red or blue symbol is added after every round.",
            score16: "SHOE STATISTICS",
            score17: "The following statistics based on the current shoe are displayed for you:",
            score18: "Total — the number of completed rounds thus far.",
            score19: "Player — the number of Player wins thus far.",
            score20: "Banker — the number of Banker wins thus far.",
            score21: "Tie — the number of tie rounds thus far.",
            score22: "Banker (Pair) — the number of Banker pairs thus far.",
            score23: "Player (Pair) — the number of Player pairs thus far.",
            score24: "ROAD PROBING TABLE",
            score25:
                "The Road PROBING Table displays the icon that will be added to the three derived roads if the next round is won by the Banker or the Player. Click the Banker (B) or Player (P) button to see the icon that will be added in the roads if the next round is won by the Banker or the Player.",
            setting1: "The SETTINGS button launches a menu of user-changeable settings.",
            setting2:
                "Your chosen settings will be applied at once and will be stored in your profile. Stored settings will be launched automatically when you log in from any device.",
            setting3: "You can alter your general and game settings.",
            shuffling1: "Gameplay continues until the cut card is dealt. Subsequently, the cards are shuffled by a dealer or a shuffler.",
            shuffling2:
                "If a shuffle table is available, then two shoes with two sets of playing cards are used at the table. In this case, the dealer swaps the shoes, and the shuffle is performed by a shuffler at the shuffling table while the dealer continues to host the game round.",
            sideBets1: "Side Bets",
            sideBets2: "Description",
            sideBets3: "Pays if the first two cards dealt to the Player constitute a pair.",
            sideBets4: "Pays if the first two cards dealt to the Banker constitute a pair.",
            lastWin: "Last Win",
            totalBet: "Total Bet",
            balance: "Balance",
            bankerVSPlayer: "Banker VS Player",
            bankerCard: "Banker's Card",
            won: "won",
            pair50: "Unable to bet pair after 50 rounds",
            royalStraightFlush: "Royal Straight Flush",
            straightFlush: "Straight Flush",
            fourOfAKind: "Four of A Kind",
            flush: "Flush",
            straight: "Straight",
            threeOfAKind: "Three of A Kind",
            twoPair: "Two Pair",
            small: "SMALL",
            to: "TO",
            odd: "ODD",
            anyTriple: "ANY TRIPLE",
            big: "BIG",
            even: "EVEN",
            twoDice: "TWO DICE",
            onOneDice: "ON ONE DICE",
            onTwoDice: "ON TWO DICE",
            onThreeDice: "ON THREE DICE",
            eachDouble: "EACH DOUBLE",
            eachTriple: "EACH TRIPLE",
            freeSpin: "Free Spin",
            newGame: "New Game",
        },
    },
    cn: {
        translation: {
            videoLag: "视频滞后",
            gameStutter: "游戏卡顿",
            slowGame: "游戏进入速度慢",
            clutteredInt: "混乱的界面",
            others: "其他",
            chips8: "请选择8筹码",
            selectChips: "请选择筹码",
            success: "成功",
            pleaseSelectFirst: "请先选定要更换的筹码",
            nothing: "无更新",
            update: "更新",
            logout: "登出",
            fullScreen: "全频",
            exitFullScreen: "退出全频",
            bankerWin: "庄 赢",
            banker: "庄",
            banker_s: "庄",
            playerWin: "闲 赢",
            player: "闲",
            player_s: "闲",
            tp: "三公",
            win: "赢",
            tie_s: "T",
            tie: "和",
            super6: "Super 6",
            jackpot: "积宝",
            autoSplit: "自动分注",
            ante: "赌注",
            bet: "押注",
            point: "点数",
            bonus: "龙宝",
            pair: "对子",
            either: "任何",
            tableClosed: "抱歉, 该游戏桌正在进行维护",
            cancelRound: "抱歉",
            startBet: "开始下注",
            stopBet: "停止下注",
            nextRound: "请稍等新的一局",
            lastGame: "最后一局",
            insufficientBalance: "钱包余额不足",
            betLimitExceed: "未達最低投注限额 / 超过投注限额",
            selectAmount: "请先选择下注额",
            peekCard: "请选择刮牌",
            nothingToUndo: "撤销无效",
            noBetHistory: "无投注历史",
            playerPair: "闲 对子",
            bankerPair: "庄 对子",
            menu: "菜单",
            lobby: "大堂",
            setting: "设置",
            gameHistory: "投注历史",
            how: "游戏玩法",
            video: "视频",
            playVideo: "游戏视频",
            quality: "视频清晰",
            sound: "声音",
            playSound: "游戏背景音乐",
            general: "一般",
            fullscreen: "全屏设置",
            auto: "自动",
            low: "低",
            high: "高",
            language: "语言",
            game: "游戏",
            date: "日期",
            winLoss: "输赢",
            gameObjectives: "游戏目标",
            gameRules: "游戏规则",
            sideBets: "边注",
            payouts: "支出",
            redEnvelope: "红信封",
            playerReturn: "返回玩家",
            placeBets: "下注",
            betStat: "投注统计",
            chat: "聊天",
            scoreCard: "记分卡",
            gameNumber: "游戏号码",
            errorHandling: "错误处理",
            policy: "断线政策",
            shuffling: "洗牌",
            moreGames: "更多游戏",
            betCode: "下注代号",
            b6: "超级6",
            b6Win: "庄6点赢",
            b6t: "超级6 和",
            b6TWin: "庄6点和",
            smallTiger: "小老虎",
            bigTiger: "大老虎",
            superTiger: "超级虎",
            tigerTie: "老虎 和",
            smallTigerWin: "庄6点赢 (2张牌)",
            bigTigerWin: "庄6点赢 (3张牌)",
            superTigerWin: "庄6点赢",
            tigerTieWin: "6点 和",
            allGame: "全部游戏",
            youWin: "你赢了",
            threeP: "三公",
            betPoint: "更多玩法",
            traditionalBet: "传统玩法",
            clickToStay: "点击空白处即可留下！",
            clickToStayDesc: "您将在<b></b>秒内被重定向到大厅。",
            maintenance: "维护正在进行中.",
            merchantReturnNull: "系统操作超时。您的操作可能失败或未成功发送.",
            playerInsufficientBalance: "玩家余额不足",
            merchantInsufficientBalance: "商户余额不足",
            betLimitExceeded: "未達最低投注限额 / 超过投注限额",
            tableNotFound: "获取桌子信息失败",
            betPlaced: "玩家已下注",
            playerNotFound: "获取玩家信息失败",
            gameClose: "游戏已结束",
            amountLess: "总下注不能低于或等于0",
            apiError: "系统错误,请联系您的代理",
            last: "最后",
            result: "结果",
            COMMISSION: "百家乐",
            B6: "超级6百家乐",
            TIGER: "老虎6百家乐",
            THREEP: "三公",
            FOURSEASON: "四季花",
            ROULETTE: "轮盘",
            SICBO: "骰宝",
            PEEKBC: "眯牌百家乐",
            DREAMCATCHER: "追梦人",
            sessionExpired: "会话已过期",
            sessionExpiredDesc: "用户身份验证失败或您的会话可能已过期，请重试。",
            maintInProgressDesc: "对于给您带来的不便，我们深表歉意，但我们正在进行一些维护。我们很快就会回来的！",
            countdownDesc: "维护将结束于",
            playerID: "玩家ID",
            betStat1: "显示特定投注点的总投注额以及在该投注点投注的玩家数量。",
            betStat2: "还显示对庄家、闲家或平局的所有投注的百分比。",
            betStat3: "您可以选择在“游戏设置”下隐藏这些统计信息。",
            disconnect1: "如果您在游戏回合中断开连接，任何投注仍然有效，并在您缺席时进行结算。 重新连接后，您可以在历史窗口中查看投注结果。",
            errorHandling1:
                "如果游戏、系统或游戏程序出现错误，游戏回合将暂时暂停，同时游戏主机通知服务管理者。 您和其他玩家将收到屏幕上的弹出消息通知，该问题正在调查中。 如果经理可以立即解决错误，则比赛将正常继续。 如果无法立即解决，该轮比赛将被取消，初始投注将退还给所有参加该轮比赛的玩家。",
            gameHistory1: "“历史记录”按钮将启动一个窗口，显示您玩过的所有游戏回合以及这些回合的结果。",
            gameHistory2: "图像",
            gameHistory3: "您可以通过查看以下内容来回顾您过去的游戏活动：",
            gameHistory4: "账户历史记录 — 以日期、游戏、投注金额和支出列表的形式显示您的完整账户历史记录。 最近完成的游戏回合出现在列表顶部。",
            gameHistory5: "游戏历史记录 — 单击/点按“游戏”列中的游戏后，会显示与特定游戏相关的历史记录。",
            gameNumber1: "每轮游戏都由唯一的游戏编号标识。",
            gameNumber2:
                '该数字反映了游戏回合在 {"time"} 之前以格林威治标准时间 (GMT) 开始的时间。 如果您想就特定回合联系客户服务人员，请使用此游戏号码作为参考（或截取游戏号码的屏幕截图）。',
            gameObjectives1: "速度百家乐的目标是通过最接近 9 的点数来预测哪手牌会获胜。",
            gameRules1: "该游戏由荷官主持，我玩了八副标准的 52 张牌。 卡面值如下：",
            gameRules2: "A 是最低的牌，每张 1 分。",
            gameRules3: "2 到 9 的牌的价值与其面值相同。",
            gameRules4: "10 和人头牌（J、Q 和 K）的价值均为 0。",
            gameRules5: "在百家乐主游戏中，只有每张牌的数值是相关的； 每张牌的花色（红心、黑桃、梅花或方块）无关紧要。",
            gameRules6: "在每次发牌之前，您必须下注闲家或庄家是否会通过持有最接近 9 的牌来赢得该轮。",
            gameRules7: "如果您相信闲家和庄家都会持有同等价值的牌，您也可以选择押平局。",
            gameRules8: "此外，您还可以投注闲家/庄家对（P/B 对），如果发给闲家/庄家的前两张牌构成对子，则赔付。",
            gameRules9: "庄家首先向闲家和庄家各发两张牌。",
            gameRules10: "百家乐发两手牌：一手给闲家，一手给庄家。",
            gameRules11: "如果闲家和庄家持有相同价值的牌，则该轮以平局结束。 平局投注获胜，闲家和庄家全押的投注（返回）。",
            gameRules12:
                "每手牌的价值是通过去掉一手牌中的十位数字来计算的，否则价值 10 或更多。 例如，一手由 7 和 9 组成的手牌在百家乐中只值 6（因为 16-10=6）。 同样，一张人头牌加上一个 9 将价值 9。",
            gameRules13: "如果闲家或庄家收到一手初始两张牌，价值 8 或 9（“自然”8 或 9），则不会再向任何一手牌发牌。",
            gameRules14: "如果闲家和庄家最初收到的两张牌价值为 0-7，则参考“第三张牌规则”来确定是向其中一方还是双手发第三张牌。 玩家总是先行。",
            gameRules15: "玩家的手牌",
            gameRules16: "玩家最初的两张牌手牌",
            gameRules17: "玩家抓第三张牌。",
            gameRules18: "球员站立。",
            gameRules19: "没有第三张牌可以枯萎。",
            gameRules20: "庄家的手牌",
            gameRules21: "D-平局； S-型支架",
            gameRules22: "如果闲家的牌为 6 或 7，则庄家的牌总数为 3、4 或 5 必须听牌，而庄家的牌总数为 6 必须停牌。",
            gameRules23: "谁的牌数最接近 9 点，谁就获胜。",
            gameRules27: "庄家补第三张牌的规则",
            gameRules28: "当庄家前两张牌的总点数为：",
            gameRules29: "0，1或2点：除非闲家得到总计8或9点的例牌，庄家补牌。",
            gameRules30: "(庄3点）: 如果闲家补的第三张牌为0，1，2，3，4，5，6，7或9点，则庄家补牌。",
            gameRules31: "当闲家补牌为8点时，庄家不补牌。",
            gameRules32: "(庄4点)：当闲家补的第三张牌为2，3，4，5，6或7点时，庄家补牌。",
            gameRules33: "当闲家补牌为0，1，8或9点时，庄家不补牌。",
            gameRules34: "(庄5点)：当闲家补的第三张牌为4，5，6或7点时，庄家补牌。",
            gameRules35: "闲家补牌为0，1，2，3，8或9点时，庄家不补牌。",
            gameRules36: "(庄6点)：当闲家补的第三张牌为6或7点时，庄家补牌。",
            gameRules37: "当闲家补的牌为0，1，2，3，4，5，8或9点时，庄家不补牌。",
            gameRules38: "7点: 双方均不补牌。",
            gameRules39: "8或9点：例牌数字任何一方都不补第三张牌。",
            moreGames1: "可以在任何游戏中随时选择“大厅”按钮。",
            moreGames2:
                "它可以让您轻松更改游戏桌或选择任何其他现场游戏。 在您实际选择要加入的新游戏/牌桌之前，您不会被从该游戏中删除。 您可以在玩当前游戏的同时使用大厅浏览其他游戏。",
            moreGames3: "Baccarat Multiplay 是一个 Multiplay 面板，玩家可以在其中快速观察和整理路型、下注并同时在多个百家乐牌桌上玩。",
            payouts1: "付费",
            payouts2: "收取佣金",
            placeBets1: "是否禁用边注取决于您的特定牌桌上游戏期间发牌的数量。",
            placeBets2: "赌注限制面板显示牌桌上允许的最小和最大赌注限制，该限制可能会不时发生变化。 打开投注限额以检查您当前的限额。",
            placeBets3: "要参加游戏，您必须有足够的资金来支付您的赌注。 您可以在屏幕上看到当前的余额。",
            placeBets4: "在沉浸式视图中，计时器会通知您投注时间的持续时间。 过期后，投注将被关闭，不再接受任何投注。",
            placeBets5: "筹码显示允许您选择您想要下注的每个筹码的值。 仅启用您当前余额可以覆盖的面额的筹码。",
            placeBets6:
                "选择筹码后，只需单击/点击游戏桌上相应的下注点即可下注。 每次您单击/点击下注点时，您的下注金额都会增加所选筹码的值或最多达到您所选下注类型的最大限额。 一旦您下注达到最高限额，该投注将不再接受任何额外资金，并且您的投注上方会出现一条消息，通知您您已下注最高限额。",
            placeBets7:
                "注意：在剩余下注时间且您已在赌桌上下注时，请不要最小化您的浏览器或打开浏览器中的任何其他选项卡。 此类行为可能会被解释为离开游戏，因此您对该特定游戏轮次的投注将被拒绝。",
            placeBets8: "重复按钮允许您重复上一轮游戏的所有投注。 此按钮仅在放置第一个芯片之前可用。",
            placeBets9:
                "您下注后，双 (2x) 按钮即可使用。 每次点击/点击都会使您的所有赌注翻倍，直至达到最大限额。 请注意，您必须有足够的账户余额才能将所有投注加倍。",
            placeBets10: "“撤消”按钮可删除您上次下的注。",
            placeBets11: "您可以重复单击/点击“撤消”按钮，以与下注顺序相反的顺序一一删除赌注。您可以通过按住“撤消”按钮来清除所有赌注。",
            placeBets12: "总投注指示器显示当前回合中所有投注的总金额。",
            return1: "百家乐的最佳玩家回报率 (RTP) 为 98.94%*。",
            return2: "下表显示了不同可选边注的返还玩家百分比。",
            return3: "返回玩家",
            return4: "*RTP 基于庄家投注的最佳策略。",
            score1: "使用特定鞋子的玩家或庄家的百家乐连胜和趋势记录在各种记分牌中。这些过去回合结果的图示以及有关当前鞋子的其他统计数据可能有助于您预测未来回合的结果。",
            score2: "BEAD ROAD 和 BIG ROAD 显示过去每一轮的结果，而 Big Eye Road、Small Road 和 Cockroach Road 显示源自 BIG ROAD 的图案。",
            score3: "当推出新鞋时，道路和鞋子统计数据总是会被清除。",
            score4: "珠路",
            score5: "BEAD ROAD 中的每个单元格代表上一轮的结果。 最早一轮的结果记录在左上角。 向下阅读该栏直至底部； 然后从右侧相邻列的顶部开始，依此类推。 实心红色方格表示庄家获胜。 实心蓝色单元格代表玩家获胜。 实心绿色单元格代表平局。 单元格右下角的红点表示庄家有一对。 单元格左上角的蓝点表示玩家有一对。",
            score6: "您可以通过在任意位置单击/点击将珠路显示从英语更改为简体中文或乐谱模式。",
            score7: "大路",
            score8: "在BIG ROAD中，最早一轮的结果记录在左上角。",
            score9: "每次庄家的连胜有利于闲家时，都会创建一个新列，反之亦然。",
            score10: "红色框框表示庄家获胜。 蓝色框表示玩家获胜。",
            score11: "平局记录为前一轮穿过单元格的绿线。 如果第一轮是平局，则首先出现绿线，一旦闲家或庄家赢得一轮，线周围将出现红色或蓝色轮廓。",
            score12: "如果有两轮或以上连续平局，则线上的数字将显示平局数。",
            score13: "衍生道路",
            score14:
                "对于真正的百家乐爱好者来说，大眼路、小路和蟑螂路都包含在内，以显示根据大路记录的过去结果得出的模式。 大眼路使用轮廓圆圈，小路使用实心圆圈，蟑螂路使用斜线。 然而，在这些派生道路中，红色和蓝色并不对应于庄家和闲家的获胜，并且无法辨别平局或对子。 在衍生道路中，红色入口是重复的标志，而蓝色入口则表示鞋子更不稳定、“不稳定”。",
            score15:
                "派生道路并非从鞋子的最开始处开始。 他们从大路第二、第三和第四列中第一手牌之后的手牌开始。 一旦派生道路开始，每轮后都会添加一个额外的红色或蓝色符号。",
            score16: "鞋子统计",
            score17: "为您显示以下基于当前鞋子的统计数据：",
            score18: "总计 — 迄今为止已完成的回合数。",
            score19: "玩家 — 迄今为止获胜的玩家数量。",
            score20: "庄家 — 迄今为止庄家获胜的数量。",
            score21: "平局 — 迄今为止平局的轮数。",
            score22: "庄家（对）—迄今为止庄家对的数量。",
            score23: "玩家（对）— 迄今为止玩家对的数量。",
            score24: "道路探测台",
            score25:
                "道路探测表显示如果下一轮庄家或闲家获胜则将添加到三个派生道路的图标。 单击庄家 (B) 或闲家 (P) 按钮可查看如果庄家或闲家赢得下一轮比赛将在道路中添加的图标。",
            setting1: "“设置”按钮可启动用户可更改设置的菜单。",
            setting2: "您选择的设置将立即应用并存储在您的个人资料中。 当您从任何设备登录时，存储的设置将自动启动。",
            setting3: "您可以更改常规和游戏设置。",
            shuffling1: "游戏继续进行，直到发完切牌为止。随后，发牌者或洗牌者对牌进行洗牌。",
            shuffling2:
                "如果有洗牌桌，则在牌桌上使用两只鞋和两套扑克牌。 在这种情况下，荷官交换发牌盒，并由洗牌台上的洗牌者进行洗牌，同时荷官继续主持游戏回合。",
            sideBets1: "边注",
            sideBets2: "描述",
            sideBets3: "如果发给玩家的前两张牌构成对子，则支付。",
            sideBets4: "如果发给庄家的前两张牌构成对子，则支付。",
            lastWin: "最后胜利",
            totalBet: "总投注额",
            balance: "余额",
            bankerVSPlayer: "庄家 VS 闲家",
            bankerCard: "庄家卡牌",
            won: "赢",
            pair50: "经50场游戏之后无法下注对子",
            newGame: "新的一局",
        },
    },
    my: {
        translation: {
            videoLag: "Ketinggalan Video",
            gameStutter: "Permainan Gagap",
            slowGame: "Kemasukan Permainan Perlahan",
            clutteredInt: "Skrin Berterabur",
            others: "Lain-lain",
            chips8: "Sila pilih 8 cip",
            selectChips: "Sila pilih cip",
            success: "Berjaya",
            pleaseSelectFirst: "Sila pilih cip untuk dikemaskini",
            nothing: "Tiada kemaskini",
            update: "Kemaskini",
            logout: "Log Keluar",
            fullScreen: "Skrin Penuh",
            exitFullScreen: "Batal Skrin Penuh",
            bankerWin: "Banker Menang",
            banker: "Banker",
            banker_s: "B",
            playerWin: "Player Menang",
            player: "Player",
            player_s: "P",
            tp: "3P",
            win: "Menang",
            tie_s: "T",
            tie: "Seri",
            super6: "Super 6",
            jackpot: "Jackpot",
            autoSplit: "Pemisahan Auto",
            ante: "Ante",
            bet: "Bet",
            point: "Mata",
            bonus: "Bonus",
            pair: "Pasangan",
            either: "Salah Satu",
            tableClosed: "Maaf, penyelenggaraan meja sedang dijalankan",
            cancelRound: "Kami minta maaf.",
            startBet: "Mulakan Pertaruhan",
            stopBet: "Pertaruhan Berhenti",
            nextRound: "Sila Tunggu Pusingan Seterusnya",
            lastGame: "Pusingan Terakhir",
            insufficientBalance: "Baki Tidak Mencukupi",
            betLimitExceed: "Pertaruhan Melebihi Had",
            selectAmount: "Sila Pilih Jumlah Pertaruhan Dahulu",
            peekCard: "Sila Pilih Kad Untuk Dicalar",
            nothingToUndo: "Tiada pertaruhan untuk dibatal",
            noBetHistory: "Tiada sejarah pertaruhan",
            playerPair: "Pasangan Player",
            bankerPair: "Pasangan Banker",
            menu: "Menu",
            lobby: "Lobi",
            setting: "Penetapan",
            gameHistory: "Sejarah Permainan",
            how: "Cara Bermain",
            video: "Video",
            playVideo: "Video Permainan",
            quality: "Kualiti Video",
            sound: "Bunyi",
            playSound: "Bunyi BGM",
            general: "Umum",
            fullscreen: "Skrin Penuh",
            auto: "Auto",
            low: "Rendah",
            high: "Tinggi",
            language: "Bahasa",
            game: "Permainan",
            date: "Tarikh",
            winLoss: "menang kalah",
            gameObjectives: "Objektif Permainan",
            gameRules: "Peraturan permainan",
            sideBets: "Pertaruhan Sampingan",
            payouts: "Pembayaran",
            redEnvelope: "Paket Merah",
            playerReturn: "Kembalian Pemain",
            placeBets: "Pertaruhan",
            betStat: "Statistik Pertaruhan",
            chat: "Perbualan",
            scoreCard: "Kad Skor",
            gameNumber: "Nombor Permainan",
            errorHandling: "Pengendalian Ralat",
            policy: "Dasar",
            shuffling: "Kocok",
            moreGames: "Permainan Lain",
            betCode: "Kod Pertaruhan",
            b6: "B6",
            b6Win: "Banker Menang 6 Mata",
            b6t: "Super 6 Seri",
            b6TWin: "Banker 6 Mata Seri",
            smallTiger: "Harimau Kecil",
            bigTiger: "Harimau Besar",
            superTiger: "Super Harimau",
            tigerTie: "Harimau Seri",
            smallTigerWin: "Banker Menang 6 Mata (2 Kad)",
            bigTigerWin: "Banker Menang 6 Mata (3 Kad)",
            superTigerWin: "Banker Menang 6 Mata",
            tigerTieWin: "6 Mata Seri",
            allGame: "Semua Permainan",
            youWin: "MENANG",
            threeP: "3 Pictures",
            betPoint: "Lebih Permainan",
            traditionalBet: "Permainan tradisional",
            clickToStay: "Klik pada ruang kosong untuk meninggalkannya!",
            clickToStayDesc: "Anda akan diubah hala ke lobi dalam masa <b></b> saat.",
            maintenance: "Penyelenggaraan sedang dijalankan.",
            merchantReturnNull: "Operasi sistem tamat masa. Operasi anda mungkin gagal atau tidak berjaya dihantar.",
            playerInsufficientBalance: "Baki pemain tidak mencukupi",
            merchantInsufficientBalance: "Baki peniaga tidak mencukupi",
            betLimitExceeded: "Had pertaruhan melebihi",
            tableNotFound: "Gagal mendapatkan maklumat jadual",
            betPlaced: "Pemain telah membuat pertaruhan",
            playerNotFound: "Gagal mendapatkan maklumat pemain",
            gameClose: "Permainan tamat",
            amountLess: "Jumlah Pertaruhan Tidak Boleh Kurang Daripada 0",
            apiError: "Ralat sistem, sila hubungi ejen anda",
            last: "Keputusan",
            result: "Terakhir",
            COMMISSION: "Bakarat",
            B6: "Super Six Bakarat",
            TIGER: "Harimau Six Bakarat",
            THREEP: "3 Pictures",
            FOURSEASON: "Bunga Empat Musim",
            ROULETTE: "Roulette",
            SICBO: "Sicbo",
            PEEKBC: "Bakarat Intip",
            DREAMCATCHER: "Penangkap Impian",
            sessionExpired: "Sesi Tamat",
            sessionExpiredDesc: "Pengesahan pengguna gagal atau sesi anda mungkin tamat tempoh, sila cuba lagi.",
            maintInProgressDesc:
                "Kami memohon maaf atas kesulitan ini, tetapi kami sedang melakukan beberapa penyelenggaraan. Kami akan kembali tidak lama lagi!",
            countdownDesc: "Kami akan masuk semula",
            playerID: "ID Pemain",
            betStat1:
                "Jumlah pertaruhan yang diletakkan pada tempat pertaruhan tertentu ditunjukkan serta bilangan pemain yang meletakkan pertaruhan di tempat tersebut.",
            betStat2: "Peratusan semua pertaruhan yang diletakkan pada Jurubank, Pemain atau pada Seri juga ditunjukkan.",
            betStat3: "Anda boleh memilih untuk menyembunyikan statistik ini di bawah Tetapan Permainan.",
            disconnect1:
                "Jika anda terputus hubungan daripada pusingan permainan, sebarang pertaruhan yang diletakkan kekal sah dan diselesaikan tanpa kehadiran anda. Setelah menyambung semula, anda boleh melihat hasil pertaruhan dalam tetingkap Sejarah.",
            errorHandling1:
                "Jika terdapat ralat dalam permainan, sistem atau prosedur permainan, pusingan permainan akan dijeda sementara sementara hos permainan memberitahu pengurus perkhidmatan. Anda dan pemain lain akan dimaklumkan melalui mesej pop timbul pada skrin, bahawa isu itu sedang disiasat. Jika pengurus dapat segera menyelesaikan ralat, pusingan permainan akan diteruskan seperti biasa. Jika penyelesaian segera tidak mungkin, pusingan permainan akan dibatalkan, dan pertaruhan awal akan dikembalikan kepada semua pemain yang mengambil bahagian dalam pusingan permainan.",
            gameHistory1:
                "Butang HISTORY akan melancarkan tetingkap yang menunjukkan semua pusingan permainan yang telah anda mainkan dan keputusan pusingan tersebut.",
            gameHistory2: "Gambar",
            gameHistory3: "Anda boleh menyemak aktiviti permainan masa lalu anda dengan melihat:",
            gameHistory4:
                "SEJARAH AKAUN — menunjukkan sejarah akaun lengkap anda sebagai senarai tarikh, permainan, jumlah pertaruhan dan pembayaran. Pusingan permainan selesai paling baru muncul di bahagian atas senarai.",
            gameHistory5:
                "SEJARAH PERMAINAN — menunjukkan sejarah anda yang berkaitan dengan permainan tertentu sebaik sahaja anda mengklik/mengetik permainan dalam lajur PERMAINAN.",
            gameNumber1: "Setiap pusingan permainan dikenal pasti oleh NOMBOR PERMAINAN yang unik.",
            gameNumber2:
                'Nombor ini menunjukkan apabila pusingan permainan bermula dari segi GMT menjelang {"masa"}. Sila gunakan nombor permainan ini untuk rujukan (atau ambil tangkapan skrin nombor permainan) jika anda ingin menghubungi Khidmat Pelanggan mengenai pusingan tertentu.',
            gameObjectives1:
                "Objektif dalam Speed Baccarat adalah untuk meramalkan tangan siapa yang akan menang dengan mempunyai nilai yang paling hampir dengan 9.",
            gameRules1: "Permainan ini dihoskan oleh peniaga dan saya bermain dengan lapan dek 52 kad standard. Nilai kad adalah seperti berikut:",
            gameRules2: "Aces ialah kad paling rendah dan bernilai 1 mata setiap satu.",
            gameRules3: "Kad dari 2 hingga 9 bernilai nilai muka berangkanya.",
            gameRules4: "10s dan kad muka (Jacks, Queens dan Kings) masing-masing bernilai 0.",
            gameRules5:
                "Hanya nilai berangka setiap kad yang relevan dalam permainan Baccarat utama; setiap saman kad (Hearts, Spades, Clubs atau Diamonds) adalah tidak relevan.",
            gameRules6:
                "Sebelum setiap tawaran, anda mesti meletakkan pertaruhan anda sama ada Pemain atau Jurubank akan memenangi pusingan dengan mempunyai tangan dengan nilai yang paling hampir dengan 9.",
            gameRules7:
                "Jika anda percaya bahawa kedua-dua Pemain dan Jurubank akan memegang tangan dengan nilai yang sama, anda juga mempunyai pilihan untuk bertaruh pada Tie.",
            gameRules8:
                "Selain itu, anda juga boleh bertaruh pada Pasangan Pemain/Banker (Pasangan P/B), yang akan membayar jika dua kad pertama diuruskan kepada Pemain/Banker membentuk pasangan.",
            gameRules9: "Peniaga bermula dengan memberitahu dua kad setiap satu kepada Pemain dan Jurubank.",
            gameRules10: "Dua tangan diuruskan dalam Baccarat: satu tangan kepada Pemain dan satu tangan kepada Banker.",
            gameRules11:
                "Jika Pemain dan Jurubank memegang tangan dengan nilai yang sama, pusingan berakhir dengan seri. Pertaruhan Seri menang, dan pertaruhan pada tolakan Pemain dan Jurubank (dipulangkan).",
            gameRules12:
                "Nilai setiap tangan dikira dengan menjatuhkan digit sepuluh dalam tangan jika tidak bernilai 10 atau lebih. Sebagai contoh, tangan yang terdiri daripada 7 dan 9 hanya bernilai 6 dalam Baccarat (kerana 16-10=6). Begitu juga, kad muka ditambah 9 akan bernilai 9.",
            gameRules13:
                'Jika Pemain atau Jurubank menerima tangan dua kad awal bernilai 8 atau 9 ("semulajadi" 8 atau 9), tiada kad tambahan akan diberikan kepada kedua-dua tangan.',
            gameRules14:
                'Jika Pemain dan Jurubank menerima tangan dua kad awal bernilai 0-7, "Peraturan Kad Ketiga" dirujuk untuk menentukan sama ada kad ketiga akan diberikan kepada salah satu tangan atau kedua-dua tangan. Pemain sentiasa pergi dahulu.',
            gameRules15: "Tangan Pemain",
            gameRules16: "Tangan dua kad awal pemain",
            gameRules17: "Pemain mengeluarkan kad ketiga.",
            gameRules18: "Pemain berdiri.",
            gameRules19: "Tiada kad ketiga untuk layu tangan.",
            gameRules20: "Tangan Jurubank",
            gameRules21: "D - Lukis; S- Berdiri",
            gameRules22:
                "Jika tangan Pemain berdiri di atas 6 atau 7, maka tangan Jurubank berjumlah 3, 4 atau 5 mesti seri, manakala tangan Jurubank berjumlah 6 mesti berdiri.",
            gameRules23: "Sesiapa yang mendapat tangan paling hampir dengan jumlah 9 menang.",
            gameRules27: "Peraturan untuk Banker menarik kad ketiga",
            gameRules28: "Apabila jumlah mata dua kad pertama Banker adalah:",
            gameRules29: "0, 1, atau 2 mata: Kecuali Player mendapat 8 atau 9 mata, Banker akan menarik kad.",
            gameRules30: "(Banker 3 mata): Jika kad ketiga Player adalah 0, 1, 2, 3, 4, 5, 6, 7, atau 9 mata, Banker akan menarik kad.",
            gameRules31: "Apabila kad ketiga Player adalah 8 mata, Banker tidak akan menarik kad.",
            gameRules32: "(Banker 4 mata): Apabila kad ketiga Player adalah 2, 3, 4, 5, 6, atau 7 mata, Banker akan menarik kad.",
            gameRules33: "Apabila kad ketiga Player adalah 0, 1, 8, atau 9 mata, Banker tidak akan menarik kad.",
            gameRules34: "(Banker 5 mata): Apabila kad ketiga Player adalah 4, 5, 6, atau 7 mata, Banker akan menarik kad.",
            gameRules35: "Apabila kad ketiga Player adalah 0, 1, 2, 3, 8, atau 9 mata, Banker tidak akan menarik kad.",
            gameRules36: "(Banker 6 mata): Apabila kad ketiga Player adalah 6 atau 7 mata, Banker akan menarik kad.",
            gameRules37: "Apabila kad ketiga Player adalah 0, 1, 2, 3, 4, 5, 8, atau 9 mata, Banker tidak akan menarik kad.",
            gameRules38: "7 mata: Kedua-dua pihak tidak akan menarik kad.",
            gameRules39: "8 atau 9 mata: Tiada pihak yang akan menarik kad ketiga.",
            moreGames1: "Butang LOBI boleh dipilih pada bila-bila masa daripada mana-mana permainan.",
            moreGames2:
                "Ia membolehkan anda menukar jadual permainan dengan mudah atau memilih mana-mana permainan langsung yang lain. Anda tidak akan dialih keluar daripada permainan ini sehingga anda benar-benar memilih permainan/jadual baharu yang anda ingin sertai. Anda boleh menggunakan LOBI untuk menyemak imbas permainan lain semasa masih bermain dalam permainan semasa anda.",
            moreGames3:
                "Baccarat Multiplay ialah panel Multiplay di mana pemain boleh memerhati dan mengisih corak jalan dengan pantas, meletakkan pertaruhan dan bermain di berbilang jadual Baccarat pada masa yang sama.",
            payouts1: "MEMBAYAR",
            payouts2: "komisen dikenakan",
            placeBets1: "Melumpuhkan pertaruhan sampingan bergantung pada bilangan kad yang diuruskan semasa permainan di atas meja tertentu anda.",
            placeBets2:
                "Panel BET LIMITS menunjukkan had pertaruhan minimum dan maksimum yang dibenarkan pada jadual, yang mungkin berubah dari semasa ke semasa. Buka Had Pertaruhan untuk menyemak had semasa anda.",
            placeBets3:
                "Untuk menyertai permainan, anda mesti mempunyai dana yang mencukupi untuk menampung pertaruhan anda. Anda boleh melihat BAKI semasa anda pada skrin anda.",
            placeBets4:
                "Dalam paparan Immersive, PEMASA memberitahu anda tentang tempoh masa pertaruhan. Selepas tamat tempoh, pertaruhan ditutup dan tiada lagi pertaruhan diterima.",
            placeBets5:
                "PAPARAN CHIP membolehkan anda memilih nilai setiap cip yang anda ingin pertaruhan. Hanya cip denominasi yang boleh dilindungi oleh baki semasa anda akan didayakan.",
            placeBets6:
                "Sebaik sahaja anda telah memilih cip, letakkan pertaruhan anda dengan hanya mengklik/mengetik tempat pertaruhan yang sesuai pada meja permainan. Setiap kali anda mengklik/mengetik tempat pertaruhan, jumlah pertaruhan anda meningkat mengikut nilai cip yang dipilih atau sehingga had maksimum untuk jenis pertaruhan yang anda pilih. Sebaik sahaja anda telah membuat pertaruhan had maksimum, tiada dana tambahan akan diterima untuk pertaruhan itu, dan mesej akan muncul di atas pertaruhan anda untuk memberitahu anda bahawa anda telah membuat pertaruhan maksimum.",
            placeBets7:
                "NOTA: Sila jangan meminimumkan penyemak imbas anda atau membuka mana-mana tab lain dalam pelayar anda sementara masa pertaruhan masih ada dan anda telah meletakkan pertaruhan di atas meja. Tindakan sedemikian boleh ditafsirkan sebagai meninggalkan permainan, dan pertaruhan anda akan ditolak untuk pusingan permainan tertentu itu.",
            placeBets8:
                "Butang REPEAT membolehkan anda mengulang semua pertaruhan dari pusingan permainan sebelumnya. Butang ini tersedia hanya sebelum cip pertama diletakkan.",
            placeBets9:
                "Butang DOUBLE (2x) tersedia selepas anda membuat sebarang pertaruhan. Setiap klik/ketik menggandakan semua pertaruhan anda sehingga had maksimum. Ambil perhatian bahawa anda mesti mempunyai baki akaun yang mencukupi untuk menggandakan SEMUA taruhan anda yang diletakkan.",
            placeBets10: "Butang UNDO mengalih keluar pertaruhan terakhir yang anda letakkan.",
            placeBets11:
                "Anda boleh mengklik/ketik butang BATALKAN berulang kali untuk mengalih keluar pertaruhan, satu demi satu, dalam susunan terbalik di mana ia diletakkan. Anda boleh mengosongkan semua pertaruhan anda dengan menahan butang UNDO.",
            placeBets12: "Penunjuk TOTAL BET memaparkan jumlah keseluruhan semua pertaruhan yang diletakkan dalam pusingan semasa.",
            return1: "Peratusan Return To Player (RTP) optimum untuk Baccarat ialah 98.94%*.",
            return2: "Jadual berikut memaparkan peratusan pulangan kepada pemain pada pertaruhan sampingan pilihan yang berbeza.",
            return3: "Kembali ke Pemain",
            return4: "*RTP berdasarkan strategi optimum untuk pertaruhan Jurubank.",
            score1: "Contengan dan aliran Baccarat untuk sama ada Pemain atau Jurubank yang menggunakan kasut tertentu direkodkan dalam pelbagai papan markah. Perwakilan bergambar keputusan pusingan lalu dan statistik lain mengenai kasut semasa ini mungkin dapat membantu anda dalam meramalkan keputusan pusingan akan datang.",
            score2: "JALAN MANIK dan JALAN BESAR memaparkan keputusan setiap pusingan lalu, manakala Jalan Mata Besar, Jalan Kecil dan Jalan Lipas memaparkan corak yang diperoleh daripada JALAN BESAR.",
            score3: "Perangkaan jalan raya dan kasut sentiasa dibersihkan apabila kasut baharu diperkenalkan.",
            score4: "JALAN MANIK",
            score5: "Setiap sel dalam BEAD ROAD mewakili keputusan pusingan yang lalu. Keputusan pusingan terawal direkodkan di sudut kiri atas. Baca lajur ke bawah sehingga ke bawah; kemudian mulakan di bahagian atas lajur bersebelahan di sebelah kanan dan seterusnya. Sel merah pepejal menunjukkan kemenangan Jurubank. Sel biru pepejal mewakili kemenangan Pemain. Sel hijau pepejal mewakili Tie. Titik merah di sudut kanan bawah sel bermakna Jurubank mempunyai sepasang. Titik biru di penjuru kiri sebelah atas sel bermakna Pemain mempunyai pasangan.",
            score6: "Anda boleh menukar paparan Bead Road daripada Bahasa Inggeris kepada Bahasa Cina Ringkas atau Mod Skor dengan mengklik/mengetiknya di mana-mana sahaja.",
            score7: "JALAN BESAR",
            score8: "Di JALAN BESAR, keputusan pusingan terawal direkodkan di sudut kiri atas.",
            score9: "Lajur baharu dibuat setiap kali rentak kemenangan Banker berubah memihak kepada Pemain, atau sebaliknya.",
            score10:
                "Sel yang digariskan dengan warna merah menunjukkan kemenangan Jurubank. Sel yang digariskan dengan warna biru menunjukkan kemenangan Pemain.",
            score11:
                "Seri direkodkan sebagai garisan hijau melalui sel untuk pusingan sebelumnya. Jika pusingan pertama ialah Seri, garisan hijau akan muncul dahulu, dan garis besar merah atau biru akan muncul di sekeliling garisan sebaik sahaja Pemain atau Jurubank memenangi satu pusingan.",
            score12: "Jika terdapat dua atau lebih pusingan seri berturut-turut, nombor dalam talian akan menunjukkan bilangan seri.",
            score13: "JALAN TERUS",
            score14:
                'Bagi peminat Baccarat sejati, Big Eye Road, Small Road dan Cockroach Road disertakan untuk memaparkan corak yang diperoleh daripada keputusan lepas yang direkodkan dalam BIG ROAD. Jalan Mata Besar menggunakan bulatan bergaris, Jalan Kecil menggunakan bulatan pepejal, dan Jalan Lipas menggunakan garis miring. Walau bagaimanapun, dalam jalan terbitan ini, warna merah dan biru tidak sepadan dengan kemenangan Jurubank dan Pemain, dan tiada cara untuk membezakan hubungan atau pasangan. Dalam jalan terbitan, entri merah adalah tanda pengulangan, manakala entri biru menandakan kasut yang lebih tidak menentu, "berombak".',
            score15:
                "Jalan asal tidak bermula pada awal kasut. Mereka bermula dengan tangan mengikut tangan pertama di lajur kedua, ketiga dan keempat JALAN BESAR. Setelah jalan terbitan bermula, simbol merah atau biru tambahan ditambahkan selepas setiap pusingan.",
            score16: "STATISTIK KASUT",
            score17: "Statistik berikut berdasarkan kasut semasa dipaparkan untuk anda:",
            score18: "Jumlah — bilangan pusingan selesai setakat ini.",
            score19: "Pemain — bilangan Pemain yang menang setakat ini.",
            score20: "Jurubank — bilangan Jurubank yang menang setakat ini.",
            score21: "Seri — bilangan pusingan seri setakat ini.",
            score22: "Jurubank (Pair) — bilangan pasangan Jurubank setakat ini.",
            score23: "Pemain (Pair) — bilangan pasangan Pemain setakat ini.",
            score24: "JADUAL PROBING JALAN",
            score25:
                "Jadual PROBING Jalan memaparkan ikon yang akan ditambah pada tiga jalan terbitan jika pusingan seterusnya dimenangi oleh Jurubank atau Pemain. Klik butang Jurubank (B) atau Pemain (P) untuk melihat ikon yang akan ditambah di jalan raya jika pusingan seterusnya dimenangi oleh Jurubank atau Pemain.",
            setting1: "Butang SETTINGS melancarkan menu tetapan boleh tukar pengguna.",
            setting2:
                "Tetapan pilihan anda akan digunakan sekali gus dan akan disimpan dalam profil anda. Tetapan yang disimpan akan dilancarkan secara automatik apabila anda log masuk dari mana-mana peranti.",
            setting3: "Anda boleh mengubah tetapan umum dan permainan anda.",
            shuffling1: "Permainan diteruskan sehingga kad yang dipotong diuruskan. Selepas itu, kad dikocok oleh peniaga atau kocok.",
            shuffling2:
                "Jika meja shuffle tersedia, maka dua kasut dengan dua set kad permainan digunakan di meja. Dalam kes ini, peniaga menukar kasut, dan kocok dilakukan oleh kocok di meja kocok sementara peniaga terus menjadi tuan rumah pusingan permainan.",
            sideBets1: "Taruhan Sampingan",
            sideBets2: "Penerangan",
            sideBets3: "Membayar jika dua kad pertama diedarkan kepada Pemain membentuk sepasang.",
            sideBets4: "Membayar jika dua kad pertama yang diserahkan kepada Jurubank merupakan sepasang.",
            lastWin: "Kemenangan Terakhir",
            totalBet: "Jumlah Pertaruhan",
            balance: "Baki",
            bankerVSPlayer: "Banker VS Pemain",
            bankerCard: "Kad Banker",
            won: "menang",
            pair50: "Pasangan tidak boleh ditaruh selepas melebihi 50 pusingan",
            newGame: "permainan baru",
        },
    },
    pt: {
        translation: {
            videoLag: "Atraso de vídeo",
            gameStutter: "Gagueira no jogo",
            slowGame: "Entrada lenta no jogo",
            clutteredInt: "Interface desordenada",
            others: "Outras",
            chips8: "Selecione 8 fichas",
            selectChips: "Selecione fichas",
            success: "Sucesso",
            pleaseSelectFirst: "Selecione o chip para atualizar",
            nothing: "Nada para atualizar",
            update: "ATUALIZAR",
            logout: "sair",
            fullScreen: "tela cheia",
            exitFullScreen: "sair da tela cheia",
            bankerWin: "banqueira ganhar",
            banker: "banqueira",
            banker_s: "banqueira",
            playerWin: "jogadora ganhar",
            player: "jogadora",
            player_s: "jogadora",
            tp: "três foto",
            win: "ganhar",
            tie_s: "T",
            tie: "gravata",
            super6: "Super 6",
            jackpot: "jackpot",
            autoSplit: "divisão automática",
            ante: "ante",
            bet: "aposta",
            point: "apontar",
            bonus: "jackpot",
            pair: "par",
            either: "qualquer",
            tableClosed: "Desculpe, manutenção da tabela em andamento",
            cancelRound: "desculpe",
            startBet: "aposta inicial",
            stopBet: "Não há mais apostas",
            nextRound: "Por favor, aguarde a próxima rodada",
            lastGame: "Ultimo jogo",
            insufficientBalance: "Saldo insuficiente",
            betLimitExceed: "Limite de aposta excedido",
            selectAmount: "Selecione o valor da aposta primeiro",
            peekCard: "Selecione o cartão para raspar",
            nothingToUndo: "Nenhuma aposta para desfazer",
            noBetHistory: "Sem histórico de apostas",
            playerPair: "Par de jogadores",
            bankerPair: "Par de banqueiro",
            menu: "Cardápio",
            lobby: "Salão",
            setting: "Configurar",
            gameHistory: "Histórico De Apostas",
            how: "Jogabilidade",
            video: "Vídeo",
            playVideo: "Brinque com vídeo",
            quality: "Qualidade de vídeo",
            sound: "Som",
            playSound: "Brinque Com Som",
            general: "Geral",
            fullscreen: "Tela Cheia",
            auto: "Auto",
            low: "Baixa",
            high: "Alta",
            language: "Linguagem",
            game: "Jogo",
            date: "A Data",
            winLoss: "Ganhar Perda",
            gameObjectives: "Objetivos Do Jogo",
            gameRules: "Regras Do Jogo",
            sideBets: "Apostas Paralelas",
            payouts: "Despesa",
            redEnvelope: "Envelope Vermelho",
            playerReturn: "retorno do jogador",
            placeBets: "fazer apostas",
            betStat: "estatística de aposta",
            chat: "conversar",
            scoreCard: "tabela de desempenho",
            gameNumber: "Número do jogo",
            errorHandling: "Manipulação de erros",
            policy: "política",
            shuffling: "embaralhando",
            moreGames: "mais jogos",
            betCode: "Código de aposta",
            b6: "B6",
            b6Win: "Banqueiro vence com 6 pontos",
            b6t: "Super 6 atado",
            b6TWin: "Banqueiro 6 atado",
            smallTiger: "Tigre pequeno",
            bigTiger: "Grande tigre",
            superTiger: "super tigre",
            tigerTie: "tigre atado",
            smallTigerWin: "Banqueiro vence com 6 pontos (2 cartas)",
            bigTigerWin: "Banqueiro vence com 6 pontos (3 cartas)",
            superTigerWin: "Banqueiro vence com 6 pontos",
            tigerTieWin: "6 atado",
            allGame: "Todos os jogos",
            youWin: "VOCÊ GANHA",
            threeP: "três foto",
            betPoint: "mais aposta",
            traditionalBet: "Aposta Tradicional",
            clickToStay: "Clique no espaço em branco para ficar!",
            clickToStayDesc: "Você será redirecionado para o Lobby em <b></b> segundos.",
            maintenance: "Manutenção em andamento.",
            merchantReturnNull: "A operação do sistema expirou. Sua operação pode ter falhado ou não ter sido enviada com sucesso.",
            playerInsufficientBalance: "Saldo insuficiente do jogador",
            merchantInsufficientBalance: "Saldo insuficiente do comerciante",
            betLimitExceeded: "Limite de aposta excedido",
            tableNotFound: "Tabela não encontrada",
            betPlaced: "O jogador atual já fez uma aposta",
            playerNotFound: "Jogador não encontrado",
            gameClose: "Jogo encerrado",
            amountLess: "A aposta total não pode ser inferior ou igual a 0",
            apiError: "Ocorreu algum erro, entre em contato com seu agente",
            last: "final",
            result: "resultado",
            COMMISSION: "Bacará",
            B6: "Super Six Bacará",
            TIGER: "Tigre 6 Bacará",
            THREEP: "três foto",
            FOURSEASON: "flores das quatro estações",
            ROULETTE: "Roleta",
            SICBO: "Sicbó",
            PEEKBC: "Espreitar Bacará",
            DREAMCATCHER: "Apanhador de Sonhos",
            sessionExpired: "Sessão expirada",
            sessionExpiredDesc: "A autenticação do usuário falhou ou sua sessão pode ter expirado. Tente novamente.",
            maintInProgressDesc: "Pedimos desculpas pelo transtorno, mas estamos realizando algumas manutenções. Voltaremos em breve!",
            countdownDesc: "Estaremos de volta",
            playerID: "ID do jogador",
            betStat1:
                "A aposta total feita num determinado local de aposta é mostrada, bem como o número de jogadores que fizeram apostas naquele local.",
            betStat2: "As percentagens de todas as apostas feitas na Banca, no Jogador ou no Empate também são mostradas.",
            betStat3: "Você pode optar por ocultar essas estatísticas nas configurações do jogo.",
            disconnect1:
                "Se você for desconectado de uma rodada de jogo, todas as apostas feitas permanecerão válidas e serão liquidadas na sua ausência. Ao reconectar, você poderá ver os resultados das apostas na janela Histórico.",
            errorHandling1:
                "Se houver um erro no jogo, no sistema ou no procedimento do jogo, a rodada do jogo será temporariamente pausada enquanto o host do jogo notifica o gerente de serviço. Você e outros jogadores serão notificados por uma mensagem pop-up na tela de que o problema está sendo investigado. Se o treinador conseguir resolver imediatamente o erro, a ronda de jogo continuará normalmente. Caso a resolução imediata não seja possível, a ronda de jogo será cancelada e as apostas iniciais serão reembolsadas a todos os jogadores que participaram na ronda de jogo.",
            gameHistory1: "O botão HISTÓRICO abrirá uma janela mostrando todas as rodadas de jogo que você jogou e os resultados dessas rodadas.",
            gameHistory2: "Imagem",
            gameHistory3: "Você pode revisar sua atividade de jogo anterior visualizando:",
            gameHistory4:
                "HISTÓRICO DA CONTA — mostra o histórico completo da sua conta como uma lista de datas, jogos, valores de apostas e pagamentos. A rodada de jogo concluída mais recentemente aparece no topo da lista.",
            gameHistory5: "HISTÓRICO DO JOGO — mostra seu histórico relacionado a um jogo específico quando você clica/toca no jogo na coluna JOGO.",
            gameNumber1: "Cada rodada de jogo é identificada por um NÚMERO DE JOGO exclusivo.",
            gameNumber2:
                'Este número reflete quando a rodada do jogo começou em termos de GMT às {"hora"}. Use este número de jogo como referência (ou faça uma captura de tela do número do jogo) se desejar entrar em contato com o Atendimento ao Cliente sobre uma rodada específica.',
            gameObjectives1: "O objetivo no Speed Baccarat é prever qual mão vencerá tendo o valor mais próximo de 9.",
            gameRules1: "O jogo é hospedado por um dealer e joguei com oito baralhos padrão de 52 cartas. Os valores dos cartões são os seguintes:",
            gameRules2: "Ases são as cartas mais baixas e valem 1 ponto cada.",
            gameRules3: "As cartas de 2 a 9 valem seus valores numéricos.",
            gameRules4: "Os 10 e as cartas com figuras (Valetes, Damas e Reis) valem 0 cada.",
            gameRules5:
                "Apenas o valor numérico de cada carta é relevante no jogo principal de Bacará; o naipe de cada carta (Copas, Espadas, Paus ou Ouros) é irrelevante.",
            gameRules6:
                "Antes de cada negociação, você deve apostar se o Jogador ou a Banca vencerá a rodada por ter a mão com um valor mais próximo de 9.",
            gameRules7:
                "Se você acredita que tanto o Jogador quanto a Banca terão mãos de igual valor, você também tem a opção de apostar no Empate.",
            gameRules8:
                "Além disso, você também pode apostar no Par Jogador/Banca (Par P/B), que será pago se as duas primeiras cartas distribuídas ao Jogador/Banca constituírem um par.",
            gameRules9: "O dealer começa contando duas cartas para o Jogador e para a Banca.",
            gameRules10: "Dua tangan diuruskan dalam Baccarat: satu tangan kepada Pemain dan satu tangan kepada Banker.",
            gameRules11:
                "Nếu Người chơi và Nhà cái có bài có giá trị bằng nhau, vòng đấu sẽ kết thúc với tỷ số hòa. Cược Hòa sẽ thắng và cược vào cửa Người chơi và Nhà cái (được trả lại).",
            gameRules12:
                "O valor de cada mão é calculado eliminando o dígito das dezenas em uma mão que vale 10 ou mais. Por exemplo, uma mão composta por 7 e 9 só vale 6 no Baccarat (porque 16-10=6). Da mesma forma, uma carta com figura mais um 9 valerá 9.",
            gameRules13:
                'Se o Jogador ou a Banca receber uma mão inicial de duas cartas no valor de 8 ou 9 (um 8 ou 9 "natural"), nenhuma carta adicional será distribuída para nenhuma das mãos.',
            gameRules14:
                'Jika Pemain dan Jurubank menerima tangan dua kad awal bernilai 0-7, "Peraturan Kad Ketiga" dirujuk untuk menentukan sama ada kad ketiga akan diberikan kepada salah satu tangan atau kedua-dua tangan. Pemain sentiasa pergi dahulu.',
            gameRules15: "Mão do jogador",
            gameRules16: "Mão inicial de duas cartas do jogador",
            gameRules17: "O jogador compra uma terceira carta.",
            gameRules18: "O jogador fica de pé.",
            gameRules19: "Nenhuma terceira carta para murchar a mão.",
            gameRules20: "Mão do banqueiro",
            gameRules21: "D - Empate; S- Suporte",
            gameRules22:
                "Se a mão do jogador for 6 ou 7, então a mão da Banca totalizando 3, 4 ou 5 deve empatar, enquanto a mão da Banca totalizando 6 deve permanecer.",
            gameRules23: "Quem acertar a mão mais próxima de um total de 9 ganha.",
            gameRules27: "Regras para o banqueiro tirar a terceira carta",
            gameRules28: "Quando a soma dos pontos das duas primeiras cartas do banqueiro for:",
            gameRules29: "0, 1 ou 2 pontos: exceto se o jogador tiver um total de 8 ou 9 pontos (natural), o banqueiro tira uma carta.",
            gameRules30:
                "(Banqueiro com 3 pontos): Se a terceira carta do jogador for 0, 1, 2, 3, 4, 5, 6, 7 ou 9 pontos, o banqueiro tira uma carta.",
            gameRules31: "Se a terceira carta do jogador for 8 pontos, o banqueiro não tira carta.",
            gameRules32: "(Banqueiro com 4 pontos): Se a terceira carta do jogador for 2, 3, 4, 5, 6 ou 7 pontos, o banqueiro tira uma carta.",
            gameRules33: "Se a terceira carta do jogador for 0, 1, 8 ou 9 pontos, o banqueiro não tira carta.",
            gameRules34: "(Banqueiro com 5 pontos): Se a terceira carta do jogador for 4, 5, 6 ou 7 pontos, o banqueiro tira uma carta.",
            gameRules35: "Se a terceira carta do jogador for 0, 1, 2, 3, 8 ou 9 pontos, o banqueiro não tira carta.",
            gameRules36: "(Banqueiro com 6 pontos): Se a terceira carta do jogador for 6 ou 7 pontos, o banqueiro tira uma carta.",
            gameRules37: "Se a terceira carta do jogador for 0, 1, 2, 3, 4, 5, 8 ou 9 pontos, o banqueiro não tira carta.",
            gameRules38: "7 pontos: Nenhuma das partes tira carta.",
            gameRules39: "8 ou 9 pontos: Natural, nenhuma das partes tira a terceira carta.",
            moreGames1: "O botão LOBBY pode ser selecionado a qualquer momento em qualquer jogo.",
            moreGames2:
                "Permite alterar facilmente a mesa de jogo ou selecionar qualquer outro jogo ao vivo. Você não será removido deste jogo até que tenha selecionado o novo jogo/mesa em que deseja entrar. Você pode usar o LOBBY para navegar por outros jogos enquanto ainda joga seu jogo atual.",
            moreGames3:
                "Baccarat Multiplay é um painel Multiplay no qual os jogadores podem observar e classificar rapidamente os padrões das estradas, fazer apostas e jogar em várias mesas de Bacará ao mesmo tempo.",
            payouts1: "PAGA",
            payouts2: "comissão é cobrada",
            placeBets1: "A desativação das apostas paralelas depende do número de cartas distribuídas durante o jogo na sua mesa específica.",
            placeBets2:
                "O painel BET LIMITS mostra os limites de aposta mínimo e máximo permitidos na mesa, que podem mudar de tempos em tempos. Abra os Limites de Aposta para verificar seus limites atuais.",
            placeBets3: "Para participar do jogo, você deve ter fundos suficientes para cobrir suas apostas. Você pode ver seu SALDO atual na tela.",
            placeBets4:
                "Na visualização Imersiva, o TIMER informa a duração do tempo de aposta. Após expirar, as apostas são encerradas e não são aceitas mais apostas.",
            placeBets5:
                "O CHIP DISPLAY permite selecionar o valor de cada ficha que deseja apostar. Somente fichas de valores que possam ser cobertos pelo seu saldo atual serão habilitadas.",
            placeBets6:
                "Depois de selecionar uma ficha, faça sua aposta simplesmente clicando/tocando no local de aposta apropriado na mesa de jogo. Cada vez que você clica/toca no local da aposta, o valor da sua aposta aumenta de acordo com o valor da ficha selecionada ou até o limite máximo para o tipo de aposta que você selecionou. Depois de apostar o limite máximo, nenhum fundo adicional será aceito para essa aposta e uma mensagem aparecerá acima da sua aposta para notificá-lo de que você apostou o máximo.",
            placeBets7:
                "NOTA: Por favor, não minimize o seu navegador ou abra qualquer outra aba no seu navegador enquanto o tempo de aposta ainda resta e você fez apostas na mesa. Tais ações podem ser interpretadas como abandono do jogo e, portanto, as suas apostas serão recusadas para essa ronda de jogo específica.",
            placeBets8:
                "O botão REPEAT permite repetir todas as apostas da rodada anterior. Este botão está disponível apenas antes da primeira ficha ser colocada.",
            placeBets9:
                "Nút NHÂN ĐÔI (2x) sẽ khả dụng sau khi bạn đặt bất kỳ cược nào. Mỗi lần nhấp/chạm sẽ nhân đôi tất cả số tiền đặt cược của bạn lên đến giới hạn tối đa. Lưu ý rằng bạn phải có đủ số dư tài khoản để nhân đôi TẤT CẢ số tiền đặt cược của mình.",
            placeBets10: "O botão UNDO remove a última aposta que você fez.",
            placeBets11:
                "Bạn có thể nhấp/chạm vào nút HOÀN TÁC nhiều lần để xóa từng cược một theo thứ tự ngược lại với vị trí đặt cược. Bạn có thể xóa tất cả cược của mình bằng cách giữ nút HOÀN TÁC.",
            placeBets12: "O indicador TOTAL BET exibe o valor total de todas as apostas feitas na rodada atual.",
            return1: "A porcentagem ideal de Retorno ao Jogador (RTP) para Bacará é de 98,94%*.",
            return2: "A tabela a seguir exibe a porcentagem de retorno ao jogador nas diferentes apostas paralelas opcionais.",
            return3: "Voltar ao jogador",
            return4: "*RTP baseado na estratégia ideal para aposta do Banker.",
            score1: "As sequências e tendências do Bacará para o Jogador ou para a Banca que usam um sapato específico são registradas em vários placares. Estas representações pictóricas dos resultados das rodadas anteriores e outras estatísticas relacionadas ao calçado atual podem ajudá-lo a prever os resultados das rodadas futuras.",
            score2: "O BEAD ROAD e o BIG ROAD exibem os resultados de cada rodada anterior, enquanto o Big Eye Road, o Small Road e o Cockroach Road exibem padrões derivados do BIG ROAD.",
            score3: "As estatísticas de estradas e calçados são sempre apagadas quando um novo calçado é lançado.",
            score4: "ESTRADA DE CONTAS",
            score5: "Cada célula no BEAD ROAD representa o resultado de uma rodada anterior. O resultado da primeira rodada é registrado no canto superior esquerdo. Leia a coluna de baixo para cima até o final; em seguida, comece no topo da coluna adjacente à direita e assim por diante. Uma célula vermelha sólida indica uma vitória do Banqueiro. Uma célula azul sólida representa a vitória do Jogador. Uma célula verde sólida representa um empate. Um ponto vermelho no canto inferior direito de uma célula significa que a banca tinha um par. Um ponto azul no canto superior esquerdo de uma célula significa que o Jogador tinha um par.",
            score6: "Você pode alterar a exibição do Bead Road de inglês para chinês simplificado ou modo de pontuação clicando/tocando em qualquer lugar.",
            score7: "ESTRADA GRANDE",
            score8: "Na BIG ROAD, o resultado da primeira rodada é registrado no canto superior esquerdo.",
            score9: "Uma nova coluna é criada cada vez que a sequência de vitórias da Banca muda a favor do Jogador, ou vice-versa.",
            score10:
                "Sel yang digariskan dengan warna merah menunjukkan kemenangan Jurubank. Sel yang digariskan dengan warna biru menunjukkan kemenangan Pemain.",
            score11:
                "Um empate é registrado como uma linha verde através da célula da rodada anterior. Se a primeira rodada for um empate, a linha verde aparecerá primeiro e o contorno vermelho ou azul aparecerá ao redor da linha quando o jogador ou banca vencer uma rodada.",
            score12: "Se houver dois ou mais empates consecutivos, o número na linha mostrará o número de empates.",
            score13: "ESTRADAS DERIVADAS",
            score14:
                'Para o verdadeiro entusiasta do Bacará, Big Eye Road, Small Road e Cockroach Road estão incluídos para exibir padrões derivados de resultados anteriores registrados no BIG ROAD. A Big Eye Road usa círculos delineados, a Small Road usa círculos sólidos e a Cockroach Road usa barras. No entanto, nestas estradas derivadas, as cores vermelha e azul não correspondem às vitórias da Banca e do Jogador, e não há forma de discernir empates ou pares. Em estradas derivadas, as entradas vermelhas são um sinal de repetição, enquanto as entradas azuis sinalizam um sapato mais errático e "agitado".',
            score15:
                "As estradas derivadas não começam logo no início do sapato. Eles começam com a mão seguinte à primeira mão na segunda, terceira e quarta colunas da BIG ROAD. Assim que uma estrada derivada começa, um símbolo vermelho ou azul adicional é adicionado após cada rodada.",
            score16: "ESTATÍSTICAS DE SAPATOS",
            score17: "As seguintes estatísticas baseadas no calçado atual são exibidas para você:",
            score18: "Total — o número de rodadas concluídas até o momento.",
            score19: "Jogador — o número de vitórias do Jogador até o momento.",
            score20: "Banqueiro — o número de vitórias do Banqueiro até o momento.",
            score21: "Hòa — số vòng hòa cho đến nay.",
            score22: "Banker (Pair) — o número de pares de Banker até o momento.",
            score23: "Player (Pair) — o número de pares de jogadores até o momento.",
            score24: "TABELA DE SONDA DE ESTRADAS",
            score25:
                "A Tabela de PROBING de Estrada exibe o ícone que será adicionado às três estradas derivadas se a próxima rodada for vencida pela Banca ou pelo Jogador. Clique no botão Banca (B) ou Jogador (P) para ver o ícone que será adicionado nas estradas se a próxima rodada for vencida pela Banca ou pelo Jogador.",
            setting1: "O botão CONFIGURAÇÕES abre um menu de configurações que podem ser alteradas pelo usuário.",
            setting2:
                "As configurações escolhidas serão aplicadas imediatamente e armazenadas em seu perfil. As configurações armazenadas serão iniciadas automaticamente quando você fizer login em qualquer dispositivo.",
            setting3: "Você pode alterar suas configurações gerais e de jogo.",
            shuffling1:
                "O jogo continua até que a carta cortada seja distribuída. Posteriormente, as cartas são embaralhadas por um dealer ou embaralhador.",
            shuffling2:
                "Se uma mesa de embaralhamento estiver disponível, dois sapatos com dois conjuntos de cartas de baralho serão usados na mesa. Neste caso, o dealer troca os sapatos e o embaralhamento é realizado por um embaralhador na mesa de embaralhamento enquanto o dealer continua a hospedar a rodada do jogo.",
            sideBets1: "Apostas paralelas",
            sideBets2: "Descrição",
            sideBets3: "Paga se as duas primeiras cartas distribuídas ao Jogador formarem um par.",
            sideBets4: "Paga se as duas primeiras cartas distribuídas à Banca constituírem um par.",
            lastWin: "Última vitória",
            totalBet: "Aposta Total",
            balance: "Equilíbrio",
            bankerVSPlayer: "Banqueiro VS Jogador",
            bankerCard: "Cartão do Banqueiro",
            won: "ganho",
            pair50: "Não é possível apostar o par após 50 rodadas",
            newGame: "novo jogo",
        },
    },
    vn: {
        translation: {
            videoLag: "Độ trễ video",
            gameStutter: "Trò chơi nói lắp",
            slowGame: "Vào trò chơi chậm",
            clutteredInt: "Giao diện lộn xộn",
            others: "Người khác",
            chips8: "Hãy chọn 8 chip",
            selectChips: "Hãy chọn chip",
            success: "Thành công",
            pleaseSelectFirst: "Vui lòng chọn chip để cập nhật",
            nothing: "Không có gì để cập nhật",
            update: "CẬP NHẬT",
            logout: "Đăng xuất",
            fullScreen: "Toàn màn hình",
            exitFullScreen: "Thoát toàn màn hình",
            bankerWin: "chủ ngân thắng",
            banker: "chủ ngân hàng",
            banker_s: "B",
            playerWin: "Người chơi thắng",
            player: "Người chơi",
            player_s: "P",
            tp: "3P",
            win: "Thắng",
            tie_s: "T",
            tie: "Cà vạt",
            super6: "Super 6",
            jackpot: "giải độc đắc",
            autoSplit: "Tự động chia",
            ante: "tiền đề",
            bet: "Cá cược",
            point: "Điểm",
            bonus: "Thưởng",
            pair: "Đôi",
            either: "Hoặc",
            tableClosed: "Xin lỗi, bảng đang được bảo trì",
            cancelRound: "Chúng tôi xin lỗi.",
            startBet: "Bắt đầu đặt cược",
            stopBet: "Không còn đặt cược nữa",
            nextRound: "Hãy Đợi Vòng Tiếp Theo",
            lastGame: "Trận đấu cuối cùng",
            insufficientBalance: "Thiếu cân bằng",
            betLimitExceed: "Vượt quá giới hạn đặt cược",
            selectAmount: "Vui lòng chọn số tiền đặt cược trước",
            peekCard: "Vui Lòng Chọn Thẻ Cào",
            nothingToUndo: "Không đặt cược để hoàn tác",
            noBetHistory: "Không có lịch sử đặt cược",
            playerPair: "cặp người chơi",
            bankerPair: "cặp ngân hàng",
            menu: "thực đơn",
            lobby: "hành lang",
            setting: "sự dàn cảnh",
            gameHistory: "Lịch sử trò chơi",
            how: "cách chơi",
            video: "băng hình",
            playVideo: "chơi với video",
            quality: "chất lượng video",
            sound: "âm thanh",
            playSound: "chơi với âm thanh",
            general: "tổng quan",
            fullscreen: "toàn màn hình",
            auto: "xe hơi",
            low: "không cao",
            high: "cao",
            language: "ngôn ngữ",
            game: "trò chơi",
            date: "ngày",
            winLoss: "thắng thua",
            gameObjectives: "mục tiêu trò chơi",
            gameRules: "luật chơi",
            sideBets: "cược phụ",
            payouts: "khoản thanh toán",
            redEnvelope: "phong bì màu đỏ",
            playerReturn: "cầu thủ trở lại",
            placeBets: "đặt cược",
            betStat: "thống kê cá cược",
            chat: "trò chuyện",
            scoreCard: "thẻ điểm",
            gameNumber: "số trò chơi",
            errorHandling: "xử lý lỗi",
            policy: "chính sách",
            shuffling: "xáo trộn",
            moreGames: "thêm những trò chơi khác",
            betCode: "mã cá cược",
            b6: "B6",
            b6Win: "Banker thắng với 6 điểm",
            b6t: "siêu 6 và",
            b6TWin: "Nhân viên ngân hàng lúc 6 giờ và",
            smallTiger: "Tiểu Hổ",
            bigTiger: "Con hổ lớn",
            superTiger: "siêu hổ",
            tigerTie: "hổ và",
            smallTigerWin: "Banker thắng với 6 điểm (2 thẻ)",
            bigTigerWin: "Banker thắng với 6 điểm (3 thẻ)",
            superTigerWin: "Banker thắng với 6 điểm",
            tigerTieWin: "tổng 6 điểm",
            allGame: "Tất cả trò chơi",
            youWin: "BẠN THẮNG",
            threeP: "3 hình ảnh",
            betPoint: "đặt cược nhiều hơn",
            traditionalBet: "Cược truyền thống",
            clickToStay: "Bấm vào chỗ trống để ở lại!",
            clickToStayDesc: "Bạn sẽ được chuyển hướng đến Sảnh sau <b></b> giây.",
            maintenance: "Đang bảo trì.",
            merchantReturnNull: "Đã hết thời gian hoạt động của hệ thống. Thao tác của bạn có thể không thành công hoặc không được gửi thành công.",
            playerInsufficientBalance: "Người chơi không đủ số dư",
            merchantInsufficientBalance: "Người bán không đủ số dư",
            betLimitExceeded: "Đã vượt quá giới hạn đặt cược",
            tableNotFound: "Không tìm thấy bảng",
            betPlaced: "Người chơi hiện tại đã đặt cược",
            playerNotFound: "Không tìm thấy người chơi",
            gameClose: "Đóng trò chơi",
            amountLess: "Tổng cược không thể nhỏ hơn hoặc bằng 0",
            apiError: "Xảy ra một số lỗi, vui lòng liên hệ với đại lý của bạn",
            last: "cuối cùng",
            result: "kết quả",
            COMMISSION: "Baccarat",
            B6: "siêu 6 Baccarat",
            TIGER: "hổ 6 Baccarat",
            THREEP: "3 hình ảnh",
            FOURSEASON: "bốn mùa",
            ROULETTE: "trò chơi quay vòng",
            SICBO: "bệnh hoạn",
            PEEKBC: "nhìn trộm baccarat",
            DREAMCATCHER: "Dreamcatcher",
            sessionExpired: "Phiên hết hạn",
            sessionExpiredDesc: "Xác thực người dùng không thành công hoặc phiên của bạn có thể đã hết hạn, vui lòng thử lại.",
            maintInProgressDesc:
                "Chúng tôi xin lỗi vì sự bất tiện này nhưng chúng tôi đang thực hiện một số bảo trì. Chúng tôi sẽ sớm hoạt động trở lại!",
            countdownDesc: "Chúng tôi sẽ quay lại",
            playerID: "ID người chơi",
            betStat1: "Tổng số tiền đặt cược cho một điểm đặt cược cụ thể sẽ được hiển thị cũng như số lượng người chơi đã đặt cược tại chỗ đó.",
            betStat2: "Tỷ lệ phần trăm của tất cả các cược đặt vào Nhà cái, Người chơi hoặc vào cửa Hòa cũng được hiển thị.",
            betStat3: "Bạn có thể chọn ẩn các số liệu thống kê này trong Cài đặt trò chơi.",
            disconnect1:
                "Nếu bạn bị ngắt kết nối khỏi vòng chơi, mọi cược đã đặt vẫn có hiệu lực và được giải quyết khi bạn vắng mặt. Sau khi kết nối lại, bạn có thể xem kết quả đặt cược trong cửa sổ Lịch sử.",
            errorHandling1:
                "Nếu có lỗi trong trò chơi, hệ thống hoặc quy trình trò chơi, vòng trò chơi sẽ tạm dừng trong khi máy chủ trò chơi thông báo cho người quản lý dịch vụ. Bạn và những người chơi khác sẽ được thông báo bằng một thông báo bật lên trên màn hình rằng vấn đề đang được điều tra. Nếu người quản lý có thể khắc phục lỗi ngay lập tức, vòng chơi sẽ tiếp tục như bình thường. Nếu không thể giải quyết ngay lập tức, vòng chơi sẽ bị hủy và số tiền đặt cược ban đầu sẽ được hoàn trả cho tất cả người chơi đã tham gia vòng chơi.",
            gameHistory1: "Nút LỊCH SỬ sẽ mở ra một cửa sổ hiển thị tất cả các vòng trò chơi bạn đã chơi và kết quả của các vòng đó.",
            gameHistory2: "Hình ảnh",
            gameHistory3: "Bạn có thể xem lại hoạt động chơi trò chơi trước đây của mình bằng cách xem:",
            gameHistory4:
                "LỊCH SỬ TÀI KHOẢN — hiển thị toàn bộ lịch sử tài khoản của bạn dưới dạng danh sách ngày, trận đấu, số tiền đặt cược và khoản thanh toán. Vòng trò chơi hoàn thành gần đây nhất sẽ xuất hiện ở đầu danh sách.",
            gameHistory5:
                "LỊCH SỬ TRÒ CHƠI — hiển thị lịch sử của bạn liên quan đến một trò chơi cụ thể khi bạn nhấp/nhấn vào trò chơi đó trong cột TRÒ CHƠI.",
            gameNumber1: "Mỗi vòng trò chơi được xác định bằng một SỐ TRÒ CHƠI duy nhất.",
            gameNumber2:
                'Con số này phản ánh thời điểm vòng đấu bắt đầu tính theo GMT trước {"time"}. Vui lòng sử dụng số trò chơi này để tham khảo (hoặc chụp ảnh màn hình số trò chơi) nếu bạn muốn liên hệ với Dịch vụ khách hàng về một vòng cụ thể.',
            gameObjectives1: "Mục tiêu của Baccarat tốc độ là dự đoán ván bài của ai sẽ thắng bằng cách có giá trị gần nhất với 9.",
            gameRules1: "Trò chơi được tổ chức bởi một người chia bài và tôi chơi với tám bộ bài 52 lá tiêu chuẩn. Giá trị thẻ như sau:",
            gameRules2: "Ách là quân bài thấp nhất và có giá trị 1 điểm mỗi quân.",
            gameRules3: "Các lá bài từ 2 đến 9 có giá trị theo mệnh giá bằng số.",
            gameRules4: "Các lá bài 10 và mặt (Jack, Queen và Kings) đều có giá trị 0.",
            gameRules5:
                "Chỉ giá trị số của mỗi lá bài là có liên quan trong trò chơi Baccarat chính; chất của mỗi lá bài (Tim, Bích, Câu lạc bộ hoặc Kim cương) không liên quan.",
            gameRules6:
                "Trước mỗi giao dịch, bạn phải đặt cược xem Người chơi hoặc Nhà cái sẽ thắng vòng chơi bằng cách có ván bài có giá trị gần nhất với 9.",
            gameRules7: "Nếu bạn tin rằng cả Người chơi và Nhà cái sẽ có giá trị ngang nhau, bạn cũng có tùy chọn đặt cược vào Hòa.",
            gameRules8:
                "Ngoài ra, bạn cũng có thể đặt cược vào Cặp Người chơi/Nhà cái (Cặp P/B), sẽ trả tiền nếu hai lá bài đầu tiên được chia cho Người chơi/Nhà cái tạo thành một cặp.",
            gameRules9: "Người chia bài bắt đầu bằng cách chia cho mỗi người hai lá bài cho Người chơi và Nhà cái.",
            gameRules10: "Hai tay bài được chia trong Baccarat: một tay cho Người chơi và một tay cho Nhà cái.",
            gameRules11:
                "Nếu Người chơi và Nhà cái có bài có giá trị bằng nhau, vòng đấu sẽ kết thúc với tỷ số hòa. Cược Hòa sẽ thắng và cược vào cửa Người chơi và Nhà cái (được trả lại).",
            gameRules12:
                "Giá trị của mỗi bàn tay được tính bằng cách bỏ chữ số hàng chục trong một bàn tay có giá trị từ 10 trở lên. Ví dụ: một ván bài gồm 7 và 9 chỉ có giá trị 6 trong Baccarat (vì 16-10=6). Tương tự, một lá bài cộng với số 9 sẽ có giá trị là 9.",
            gameRules13:
                'Nếu Người chơi hoặc Nhà cái nhận được ván bài có hai lá bài ban đầu có giá trị là 8 hoặc 9 ("tự nhiên" 8 hoặc 9), sẽ không có lá bài bổ sung nào được chia cho cả hai ván bài.',
            gameRules14:
                'Nếu Người chơi và Nhà cái nhận được hai lá bài ban đầu có giá trị từ 0-7, thì "Quy tắc lá bài thứ ba" sẽ được tham khảo để xác định xem lá bài thứ ba sẽ được chia cho một trong hai tay hay cả hai tay. Người chơi luôn đi trước.',
            gameRules15: "Bàn tay của người chơi",
            gameRules16: "Tay bài hai lá đầu tiên của người chơi",
            gameRules17: "Người chơi rút lá bài thứ ba.",
            gameRules18: "Người chơi đứng.",
            gameRules19: "Không có lá bài thứ ba để héo bài.",
            gameRules20: "Bàn tay của nhân viên ngân hàng",
            gameRules21: "D – Vẽ; S-Đứng",
            gameRules22:
                "Nếu ván bài của Người chơi đứng ở số 6 hoặc 7 thì ván bài của Nhà cái có tổng điểm là 3, 4 hoặc 5 phải rút, trong khi ván bài của Nhà cái có tổng điểm là 6 phải đứng.",
            gameRules23: "Ai có bộ bài gần nhất với tổng số 9 sẽ thắng.",
            gameRules27: "Quy tắc rút lá bài thứ ba của nhà cái",
            gameRules28: "Khi tổng điểm của hai lá bài đầu tiên của nhà cái là:",
            gameRules29: "0, 1 hoặc 2 điểm: Trừ khi người chơi đạt tổng cộng 8 hoặc 9 điểm, nhà cái sẽ rút thêm bài.",
            gameRules30: "(Nhà cái có 3 điểm): Nếu lá bài thứ ba của người chơi là 0, 1, 2, 3, 4, 5, 6, 7 hoặc 9 điểm, nhà cái sẽ rút thêm bài.",
            gameRules31: "Khi lá bài thứ ba của người chơi là 8 điểm, nhà cái không rút thêm bài.",
            gameRules32: "(Nhà cái có 4 điểm): Khi lá bài thứ ba của người chơi là 2, 3, 4, 5, 6 hoặc 7 điểm, nhà cái sẽ rút thêm bài.",
            gameRules33: "Khi lá bài thứ ba của người chơi là 0, 1, 8 hoặc 9 điểm, nhà cái không rút thêm bài.",
            gameRules34: "(Nhà cái có 5 điểm): Khi lá bài thứ ba của người chơi là 4, 5, 6 hoặc 7 điểm, nhà cái sẽ rút thêm bài.",
            gameRules35: "Khi lá bài thứ ba của người chơi là 0, 1, 2, 3, 8 hoặc 9 điểm, nhà cái không rút thêm bài.",
            gameRules36: "(Nhà cái có 6 điểm): Khi lá bài thứ ba của người chơi là 6 hoặc 7 điểm, nhà cái sẽ rút thêm bài.",
            gameRules37: "Khi lá bài thứ ba của người chơi là 0, 1, 2, 3, 4, 5, 8 hoặc 9 điểm, nhà cái không rút thêm bài.",
            gameRules38: "7 điểm: Cả hai bên đều không rút thêm bài.",
            gameRules39: "8 hoặc 9 điểm: Bất kỳ bên nào có 8 hoặc 9 điểm đều không rút thêm lá bài thứ ba.",
            moreGames1: "Nút LOBBY có thể được chọn bất cứ lúc nào từ bất kỳ trò chơi nào.",
            moreGames2:
                "Nó cho phép bạn dễ dàng thay đổi bàn trò chơi hoặc chọn bất kỳ trò chơi trực tiếp nào khác. Bạn sẽ không bị xóa khỏi trò chơi này cho đến khi bạn thực sự chọn được trò chơi/bàn mới mà bạn muốn tham gia. Bạn có thể sử dụng LOBBY để duyệt các trò chơi khác trong khi vẫn chơi trò chơi hiện tại của mình.",
            moreGames3:
                "Baccarat Multiplay là một bảng Multiplay trong đó người chơi có thể nhanh chóng quan sát và sắp xếp các đường đi, đặt cược và chơi tại nhiều bàn Baccarat cùng một lúc.",
            payouts1: "THANH TOÁN",
            payouts2: "hoa hồng được tính",
            placeBets1: "Việc vô hiệu hóa cược phụ tùy thuộc vào số lượng quân bài được chia trong trò chơi trên bàn cụ thể của bạn.",
            placeBets2:
                "Bảng GIỚI HẠN CƯỢC hiển thị giới hạn đặt cược tối thiểu và tối đa được phép tại bàn, giới hạn này có thể thay đổi theo thời gian. Mở Giới hạn đặt cược để kiểm tra giới hạn hiện tại của bạn.",
            placeBets3:
                "Để tham gia trò chơi, bạn phải có đủ tiền để chi trả cho các khoản đặt cược của mình. Bạn có thể thấy số dư hiện tại của mình trên màn hình.",
            placeBets4:
                "Trong chế độ xem Nhập vai, TIMER thông báo cho bạn về khoảng thời gian đặt cược. Sau khi hết hạn, cá cược sẽ đóng và không chấp nhận đặt cược nữa.",
            placeBets5:
                "HIỂN THỊ CHIP cho phép bạn chọn giá trị của mỗi chip bạn muốn đặt cược. Chỉ những chip có mệnh giá có thể được chi trả bằng số dư hiện tại của bạn mới được kích hoạt.",
            placeBets6:
                "Khi bạn đã chọn chip, hãy đặt cược bằng cách nhấp/chạm vào vị trí đặt cược thích hợp trên bàn trò chơi. Mỗi lần bạn nhấp/chạm vào điểm đặt cược, số tiền đặt cược của bạn sẽ tăng theo giá trị của chip đã chọn hoặc lên đến giới hạn tối đa cho loại cược bạn đã chọn. Khi bạn đã đặt cược đến giới hạn tối đa, sẽ không có khoản tiền bổ sung nào được chấp nhận cho lần đặt cược đó và một thông báo sẽ xuất hiện phía trên số tiền đặt cược của bạn để thông báo cho bạn rằng bạn đã đặt cược số tiền tối đa.",
            placeBets7:
                "LƯU Ý: Vui lòng không thu nhỏ trình duyệt của bạn hoặc mở bất kỳ tab nào khác trong trình duyệt của bạn trong khi vẫn còn thời gian đặt cược và bạn đã đặt cược trên bàn. Những hành động như vậy có thể được hiểu là rời khỏi trò chơi và do đó cược của bạn sẽ bị từ chối cho vòng trò chơi cụ thể đó.",
            placeBets8: "Nút LẶP LẠI cho phép bạn lặp lại tất cả các cược từ vòng chơi trước. Nút này chỉ khả dụng trước khi đặt chip đầu tiên.",
            placeBets9:
                "Nút NHÂN ĐÔI (2x) sẽ khả dụng sau khi bạn đặt bất kỳ cược nào. Mỗi lần nhấp/chạm sẽ nhân đôi tất cả số tiền đặt cược của bạn lên đến giới hạn tối đa. Lưu ý rằng bạn phải có đủ số dư tài khoản để nhân đôi TẤT CẢ số tiền đặt cược của mình.",
            placeBets10: "Nút HOÀN TÁC sẽ loại bỏ lần đặt cược cuối cùng bạn đã đặt.",
            placeBets11:
                "Bạn có thể nhấp/chạm vào nút HOÀN TÁC nhiều lần để xóa từng cược một theo thứ tự ngược lại với vị trí đặt cược. Bạn có thể xóa tất cả cược của mình bằng cách giữ nút HOÀN TÁC.",
            placeBets12: "Chỉ báo TỔNG CƯỢC hiển thị tổng số tiền đặt cược trong vòng hiện tại.",
            return1: "Tỷ lệ hoàn trả cho người chơi (RTP) tối ưu cho Baccarat là 98,94%*.",
            return2: "Bảng sau đây hiển thị tỷ lệ phần trăm trả lại cho người chơi đối với các cược phụ tùy chọn khác nhau.",
            return3: "Trở lại Người chơi",
            return4: "*RTP dựa trên chiến lược tối ưu để đặt cược Banker.",
            score1: "Các chuỗi và xu hướng Baccarat dành cho Người chơi hoặc Nhà cái sử dụng một chiếc giày cụ thể được ghi lại trong nhiều bảng điểm khác nhau. Những hình ảnh trình bày về kết quả của các vòng đấu trước đây và số liệu thống kê khác liên quan đến chiếc giày hiện tại có thể giúp bạn dự đoán kết quả của các vòng đấu trong tương lai.",
            score2: "O BEAD ROAD e o BIG ROAD exibem os resultados de cada rodada anterior, enquanto o Big Eye Road, o Small Road e o Cockroach Road exibem padrões derivados do BIG ROAD.",
            score3: "Số liệu thống kê về đường và giày luôn được xóa khi giới thiệu giày mới.",
            score4: "ĐƯỜNG HẠT",
            score5: "Mỗi ô trong BEAD ROAD đại diện cho kết quả của vòng trước. Kết quả của vòng đấu sớm nhất được ghi ở góc trên bên trái. Đọc cột từ trên xuống dưới; sau đó bắt đầu ở đầu cột liền kề bên phải, v.v. Một ô màu đỏ liền cho biết Banker thắng. Một ô màu xanh đậm tượng trưng cho chiến thắng của Người chơi. Một ô màu xanh lá cây đặc tượng trưng cho Hòa. Dấu chấm màu đỏ ở góc dưới bên phải của ô có nghĩa là Nhà cái có một đôi. Dấu chấm màu xanh lam ở góc trên cùng bên trái của ô có nghĩa là Người chơi có một đôi.",
            score6: "Bạn có thể thay đổi màn hình Đường hạt từ tiếng Anh sang tiếng Trung giản thể hoặc Chế độ tính điểm bằng cách nhấp/nhấn vào nó ở bất cứ đâu.",
            score7: "ĐƯỜNG LỚN",
            score8: "Ở BIG ROAD, kết quả của vòng đấu sớm nhất được ghi ở góc trên bên trái.",
            score9: "Một cột mới được tạo mỗi khi chuỗi chiến thắng của Nhà cái thay đổi theo hướng có lợi cho Người chơi hoặc ngược lại.",
            score10: "Ô có đường viền màu đỏ biểu thị Nhà cái thắng. Ô có đường viền màu xanh lam biểu thị Người chơi thắng.",
            score11:
                "Hòa được ghi dưới dạng một đường màu xanh lục xuyên qua ô của vòng trước. Nếu vòng đầu tiên là Hòa, đường màu xanh lá cây sẽ xuất hiện đầu tiên và đường viền màu đỏ hoặc xanh lam sẽ xuất hiện xung quanh đường này khi Người chơi hoặc Nhà cái thắng một vòng.",
            score12: "Nếu có từ hai hiệp hòa liên tiếp trở lên, con số trên dòng sẽ hiển thị số trận hòa.",
            score13: "ĐƯỜNG PHÁT HIỆN",
            score14:
                'Đối với những người đam mê Baccarat thực sự, Đường Mắt Lớn, Đường Nhỏ và Đường Gián được đưa vào để hiển thị các mô hình bắt nguồn từ các kết quả trong quá khứ được ghi trong ĐƯỜNG LỚN. Đường Mắt Lớn sử dụng các vòng tròn có đường viền, Đường Nhỏ sử dụng các vòng tròn liền nét và Đường Gián sử dụng các đường gạch chéo. Tuy nhiên, trong những con đường dẫn xuất này, màu đỏ và xanh lam không tương ứng với chiến thắng của Nhà cái và Người chơi, và không có cách nào để phân biệt hòa hoặc cặp. Trong các con đường dẫn xuất, các mục màu đỏ là dấu hiệu của sự lặp lại, trong khi các mục màu xanh lam báo hiệu một chiếc giày "lắc lư" thất thường hơn.',
            score15:
                "Những con đường dẫn xuất không bắt đầu ngay từ đầu khung. Họ bắt đầu với ván bài tiếp theo ván bài đầu tiên ở cột thứ hai, thứ ba và thứ tư của ĐƯỜNG LỚN. Khi đường dẫn xuất bắt đầu, một biểu tượng màu đỏ hoặc xanh lam bổ sung sẽ được thêm vào sau mỗi vòng.",
            score16: "THỐNG KÊ GIÀY",
            score17: "Các số liệu thống kê sau đây dựa trên loại giày hiện tại được hiển thị cho bạn:",
            score18: "Tổng cộng - số vòng đã hoàn thành cho đến nay.",
            score19: "Người chơi — số lượng Người chơi thắng cho đến nay.",
            score20: "Banker — số lượng Banker thắng cho đến nay.",
            score21: "Hòa — số vòng hòa cho đến nay.",
            score22: "Banker (Cặp) - số lượng cặp Banker cho đến nay.",
            score23: "Người chơi (Cặp) — số lượng cặp Người chơi cho đến nay.",
            score24: "BẢNG THĂM ĐƯỜNG",
            score25:
                "Bảng THỬ NGHIỆM Đường hiển thị biểu tượng sẽ được thêm vào ba đường xuất phát nếu Nhà cái hoặc Người chơi thắng ở vòng tiếp theo. Nhấp vào nút Nhà cái (B) hoặc Người chơi (P) để xem biểu tượng sẽ được thêm vào các con đường nếu Nhà cái hoặc Người chơi thắng ở vòng tiếp theo.",
            setting1: "Nút CÀI ĐẶT sẽ khởi chạy menu gồm các cài đặt mà người dùng có thể thay đổi.",
            setting2:
                "Cài đặt bạn đã chọn sẽ được áp dụng ngay lập tức và sẽ được lưu trữ trong hồ sơ của bạn. Cài đặt được lưu trữ sẽ tự động được khởi chạy khi bạn đăng nhập từ bất kỳ thiết bị nào.",
            setting3: "Bạn có thể thay đổi cài đặt chung và trò chơi của mình.",
            shuffling1:
                "Trò chơi tiếp tục cho đến khi lá bài bị cắt được chia. Sau đó, các quân bài sẽ được xáo trộn bởi người chia bài hoặc người xáo bài.",
            shuffling2:
                "Nếu có bàn chơi bài shuffle thì sử dụng hai chiếc giày với hai bộ bài trên bàn. Trong trường hợp này, người chia bài đổi giày và việc xáo bài được thực hiện bởi một người xáo trộn tại bàn xáo bài trong khi người chia bài tiếp tục tổ chức vòng chơi.",
            sideBets1: "Cược phụ",
            sideBets2: "Sự miêu tả",
            sideBets3: "Trả tiền nếu hai lá bài đầu tiên được chia cho Người chơi tạo thành một cặp.",
            sideBets4: "Trả tiền nếu hai lá bài đầu tiên được chia cho Nhà cái tạo thành một cặp.",
            lastWin: "Chiến thắng cuối cùng",
            totalBet: "Tổng cược",
            balance: "THĂNG BẰNG",
            bankerVSPlayer: "Nhân viên ngân hàng VS người chơi",
            bankerCard: "Thẻ ngân hàng",
            won: "thắng",
            pair50: "Không thể đặt cược cặp sau 50 vòng",
            newGame: "trò chơi mới",
        },
    },
    th: {
        translation: {
            videoLag: "วิดีโอล้าหลัง",
            gameStutter: "เกมพูดติดอ่าง",
            slowGame: "เข้าเกมช้า",
            clutteredInt: "อินเตอร์เฟซที่ยุ่งเหยิง",
            others: "คนอื่น",
            chips8: "กรุณาเลือก 8 ชิป",
            selectChips: "กรุณาเลือก ชิป",
            success: "ความสำเร็จ",
            pleaseSelectFirst: "โปรดเลือกชิปที่จะอัปเดต",
            nothing: "ไม่มีอะไรจะอัปเดต",
            update: "อัปเดต",
            logout: "ออกจากระบบ",
            fullScreen: "เต็มจอ",
            exitFullScreen: "ออกจากโหมดเต็มหน้าจอ",
            bankerWin: "นายธนาคาร ชนะ",
            banker: "นายธนาคาร",
            banker_s: "B",
            playerWin: "ผู้เล่น ชนะ",
            player: "ผู้เล่น",
            player_s: "P",
            tp: "3P",
            win: "ชนะ",
            tie_s: "T",
            tie: "สิ่งที่ใช้ผูก",
            super6: "Super 6",
            jackpot: "แจ็คพอต",
            autoSplit: "แยกอัตโนมัติ",
            ante: "อันเต้",
            bet: "เดิมพัน",
            point: "จุด",
            bonus: "โบนัส",
            pair: "คู่",
            either: "ทั้ง",
            tableClosed: "ขออภัย กำลังดำเนินการปรับปรุงโต๊ะ",
            cancelRound: "พวกเราขอโทษ.",
            startBet: "เริ่มเดิมพัน",
            stopBet: "ไม่มีเดิมพันอีกต่อไป",
            nextRound: "กรุณารอรอบต่อไป",
            lastGame: "เกมสุดท้าย",
            insufficientBalance: "ยอดเงินคงเหลือไม่เพียงพอ",
            betLimitExceed: "เกินวงเงินเดิมพัน",
            selectAmount: "กรุณาเลือกจำนวนเงินเดิมพันก่อน",
            peekCard: "กรุณาเลือกการ์ดที่จะขูด",
            nothingToUndo: "ไม่มีการเดิมพันที่จะเลิกทำ",
            noBetHistory: "ไม่มีประวัติการเดิมพัน",
            playerPair: "ผู้เล่นคู่",
            bankerPair: "คู่นายธนาคาร",
            menu: "เมนู",
            lobby: "ล็อบบี้",
            setting: "ติดตั้ง",
            gameHistory: "ประวัติการเดิมพัน",
            how: "การเล่นเกม",
            video: "วิดีโอ",
            playVideo: "เล่นกับวิดีโอ",
            quality: "คุณภาพวีดีโอ",
            sound: "เสียง",
            playSound: "เล่นด้วยเสียง",
            general: "ทั่วไป",
            fullscreen: "เต็มจอ",
            auto: "อัตโนมัติ",
            low: "ต่ำ",
            high: "สูง",
            language: "ภาษา",
            game: "เกม",
            date: "วันที่",
            winLoss: "ชนะการสูญเสีย",
            gameObjectives: "วัตถุประสงค์ของเกม",
            gameRules: "กฎของเกม",
            sideBets: "เดิมพันข้าง",
            payouts: "การจ่ายเงิน",
            redEnvelope: "ซองจดหมายสีแดง",
            playerReturn: "ผู้เล่นกลับมา",
            placeBets: "วางเดิมพัน",
            betStat: "เดิมพันสถิติ",
            chat: "แชท",
            scoreCard: "บัตรคะแนน",
            gameNumber: "เกมหมายเลข",
            errorHandling: "การจัดการข้อผิดพลาด",
            policy: "นโยบาย",
            shuffling: "กำลังสับ",
            moreGames: "เกมส์เพิ่มเติม",
            betCode: "รหัสเดิมพัน",
            b6: "B6",
            b6Win: "เจ้ามือชนะด้วยคะแนน 6 แต้ม",
            b6t: "ซุปเปอร์ 6 และ",
            b6TWin: "นายธนาคาร 6 โมงและ",
            smallTiger: "เสือน้อย",
            bigTiger: "เสือใหญ่",
            superTiger: "ซุปเปอร์เสือ",
            tigerTie: "เสือและ",
            smallTigerWin: "เจ้ามือชนะด้วยคะแนน 6 แต้ม (การ์ด 2 ใบ)",
            bigTigerWin: "เจ้ามือชนะด้วยคะแนน 6 แต้ม (การ์ด 3 ใบ)",
            superTigerWin: "เจ้ามือชนะด้วยคะแนน 6 แต้ม",
            tigerTieWin: "รวม 6 แต้ม",
            allGame: "เกมส์ทั้งหมด",
            youWin: "คุณชนะ",
            threeP: "3 รูปภาพ",
            betPoint: "เดิมพันเพิ่มเติม",
            traditionalBet: "เดิมพันแบบดั้งเดิม",
            clickToStay: "คลิกที่ช่องว่างเพื่ออยู่ต่อ!",
            clickToStayDesc: "คุณจะถูกนำไปที่ล็อบบี้ภายใน <b></b> วินาที",
            maintenance: "อยู่ระหว่างการบำรุงรักษา",
            merchantReturnNull: "การทำงานของระบบหมดเวลา การดำเนินการของคุณอาจล้มเหลวหรือส่งไม่สำเร็จ",
            playerInsufficientBalance: "ผู้เล่นมียอดคงเหลือไม่เพียงพอ",
            merchantInsufficientBalance: "ผู้ขายมียอดคงเหลือไม่เพียงพอ",
            betLimitExceeded: "เกินขีดจำกัดการเดิมพัน",
            tableNotFound: "ไม่พบตาราง",
            betPlaced: "ผู้เล่นปัจจุบันวางเดิมพันแล้ว",
            playerNotFound: "ไม่พบผู้เล่น",
            gameClose: "เกมปิด",
            amountLess: "ยอดเดิมพันทั้งหมดต้องไม่น้อยกว่าหรือเท่ากับ 0",
            apiError: "เกิดข้อผิดพลาดบางประการ โปรดติดต่อตัวแทนของคุณ",
            last: "ล่าสุด",
            result: "ผลลัพธ์",
            COMMISSION: "บาคาร่า",
            B6: "ซุปเปอร์ 6 บาคาร่า",
            TIGER: "เสือ 6 บาคาร่า",
            THREEP: "3 รูปภาพ",
            FOURSEASON: "สี่ฤดู",
            ROULETTE: "รูเล็ต",
            SICBO: "ซิกโบ",
            PEEKBC: "แอบดูบาคาร่า",
            DREAMCATCHER: "ดรีมแคชเชอร์",
            sessionExpired: "เซสชั่นหมดอายุแล้ว",
            sessionExpiredDesc: "การตรวจสอบสิทธิ์ผู้ใช้ล้มเหลวหรือเซสชันของคุณอาจหมดอายุ โปรดลองอีกครั้ง",
            maintInProgressDesc: "เราต้องขออภัยในความไม่สะดวก แต่เรากำลังดำเนินการบำรุงรักษาบางอย่าง เราจะกลับมาอีกครั้งเร็วๆ นี้!",
            countdownDesc: "เราจะกลับเข้าไปแล้ว",
            playerID: "รหัสผู้เล่น",
            betStat1: "การเดิมพันทั้งหมดที่วางเดิมพัน ณ ตำแหน่งนั้นจะแสดงขึ้น เช่นเดียวกับจำนวนผู้เล่นที่วางเดิมพัน ณ จุดนั้น",
            betStat2: "เปอร์เซ็นต์ของการเดิมพันทั้งหมดที่วางกับเจ้ามือ ผู้เล่น หรือเสมอกันก็จะแสดงเช่นกัน",
            betStat3: "คุณสามารถเลือกที่จะซ่อนสถิติเหล่านี้ได้ในการตั้งค่าเกม",
            disconnect1:
                "หากคุณถูกตัดการเชื่อมต่อจากรอบเกม การเดิมพันใดๆ ที่วางไว้จะยังคงมีผลและจะตัดสินในกรณีที่คุณไม่อยู่ เมื่อเชื่อมต่อใหม่ คุณสามารถดูผลการเดิมพันได้ในหน้าต่างประวัติ",
            errorHandling1:
                "หากมีข้อผิดพลาดในเกม ระบบ หรือขั้นตอนของเกม รอบเกมจะถูกหยุดชั่วคราวในขณะที่โฮสต์เกมแจ้งผู้จัดการฝ่ายบริการ คุณและผู้เล่นคนอื่นๆ จะได้รับแจ้งด้วยข้อความป๊อปอัปบนหน้าจอว่าปัญหากำลังได้รับการตรวจสอบ หากผู้จัดการสามารถแก้ไขข้อผิดพลาดได้ทันที รอบเกมจะดำเนินต่อไปตามปกติ หากไม่สามารถแก้ไขปัญหาได้ในทันที รอบเกมจะถูกยกเลิก และการเดิมพันเริ่มแรกจะคืนให้กับผู้เล่นทุกคนที่เข้าร่วมในรอบเกม",
            gameHistory1: "ปุ่มประวัติจะเปิดหน้าต่างที่แสดงรอบเกมทั้งหมดที่คุณเล่นและผลลัพธ์ของรอบเหล่านั้น",
            gameHistory2: "ภาพ",
            gameHistory3: "คุณสามารถตรวจสอบกิจกรรมการเล่นเกมที่ผ่านมาของคุณได้โดยการดู:",
            gameHistory4:
                "ประวัติบัญชี — แสดงประวัติบัญชีทั้งหมดของคุณเป็นรายการวันที่ เกม จำนวนเงินเดิมพัน และการจ่ายเงิน รอบเกมที่เสร็จสิ้นล่าสุดจะปรากฏที่ด้านบนของรายการ",
            gameHistory5: "ประวัติเกม — แสดงประวัติของคุณที่เกี่ยวข้องกับเกมใดเกมหนึ่งเมื่อคุณคลิก/แตะเกมในคอลัมน์ GAME",
            gameNumber1: "แต่ละรอบของเกมจะถูกระบุด้วยหมายเลขเกมที่ไม่ซ้ำกัน",
            gameNumber2:
                'ตัวเลขนี้แสดงถึงเมื่อรอบเกมเริ่มต้นตาม GMT ภายใน {"time"} โปรดใช้หมายเลขเกมนี้เป็นข้อมูลอ้างอิง (หรือจับภาพหน้าจอหมายเลขเกม) หากคุณต้องการติดต่อฝ่ายบริการลูกค้าเกี่ยวกับรอบใดรอบหนึ่ง',
            gameObjectives1: "วัตถุประสงค์ใน Speed Baccarat คือการทายว่ามือใดจะชนะโดยมีค่าใกล้เคียง 9 มากที่สุด",
            gameRules1: "เกมนี้โฮสต์โดยเจ้ามือและฉันเล่นโดยใช้สำรับไพ่มาตรฐาน 52 ใบแปดสำรับ มูลค่าบัตรมีดังนี้:",
            gameRules2: "เอซเป็นไพ่ที่ต่ำที่สุดและมีมูลค่า 1 แต้มต่อไพ่",
            gameRules3: "ไพ่ตั้งแต่ 2 ถึง 9 มีค่าตามหน้าไพ่เป็นตัวเลข",
            gameRules4: "ไพ่ 10 ใบและไพ่หน้า (แจ็ค ควีน และคิง) มีค่าเท่ากับ 0",
            gameRules5: "เฉพาะค่าตัวเลขของไพ่แต่ละใบเท่านั้นที่เกี่ยวข้องในเกมบาคาร่าหลัก ชุดไพ่แต่ละใบ (ฮาร์ต โพดำ ไม้กอล์ฟ หรือเพชร) ไม่เกี่ยวข้อง",
            gameRules6: "ก่อนแต่ละข้อตกลง คุณต้องวางเดิมพันว่าผู้เล่นหรือเจ้ามือจะชนะรอบนั้นหรือไม่โดยให้มีมือที่มีค่าใกล้เคียงที่สุดกับ 9",
            gameRules7: "หากคุณเชื่อว่าทั้งผู้เล่นและเจ้ามือจะถือมือที่มีมูลค่าเท่ากัน คุณยังมีตัวเลือกในการเดิมพันที่เสมอกัน",
            gameRules8: "นอกจากนี้ คุณยังสามารถเดิมพันคู่ผู้เล่น/เจ้ามือ (คู่ P/B) ซึ่งจะจ่ายหากไพ่สองใบแรกที่แจกให้ผู้เล่น/เจ้ามือเป็นคู่",
            gameRules9: "เจ้ามือเริ่มต้นด้วยการบอกไพ่สองใบแก่ผู้เล่นและเจ้ามือ",
            gameRules10: "ไพ่สองมือถูกแจกในบาคาร่า: มือหนึ่งสำหรับผู้เล่น และอีกมือหนึ่งสำหรับเจ้ามือ",
            gameRules11:
                "หากผู้เล่นและเจ้ามือถือไพ่ในมือที่มีมูลค่าเท่ากัน รอบจะจบลงด้วยการเสมอกัน การเดิมพันเสมอจะชนะ และการเดิมพันฝั่งผู้เล่นและเจ้ามือ (จะถูกส่งคืน)",
            gameRules12:
                "มูลค่าของแต่ละมือจะคำนวณโดยการวางหลักสิบในมือหรือมีค่าเท่ากับ 10 หรือมากกว่า ตัวอย่างเช่น มือที่ประกอบด้วย 7 และ 9 มีค่าเพียง 6 ในบาคาร่า (เพราะว่า 16-10=6) ในทำนองเดียวกัน ไพ่หน้าบวก 9 จะมีค่าเท่ากับ 9",
            gameRules13:
                'หากผู้เล่นหรือเจ้ามือได้รับไพ่สองใบเริ่มแรกมูลค่า 8 หรือ 9 (ไพ่ "เนเชอรัล" 8 หรือ 9) จะไม่มีการแจกไพ่เพิ่มเติมให้กับมือทั้งสองข้าง',
            gameRules14:
                'หากผู้เล่นและเจ้ามือได้รับไพ่สองใบเริ่มแรกที่มีค่า 0-7 จะมีการพิจารณา "กฎไพ่ใบที่สาม" เพื่อพิจารณาว่าไพ่ใบที่สามจะต้องถูกแจกให้มือใดมือหนึ่งหรือทั้งสองมือ ผู้เล่นไปก่อนเสมอ',
            gameRules15: "มือของผู้เล่น",
            gameRules16: "ไพ่สองใบแรกของผู้เล่น",
            gameRules17: "ผู้เล่นจั่วไพ่ใบที่สาม",
            gameRules18: "ผู้เล่นยืน",
            gameRules19: "ไม่มีไพ่ใบที่สามให้มือเหี่ยวเฉา",
            gameRules20: "มือของนายธนาคาร",
            gameRules21: "D - วาด; S- ยืน",
            gameRules22:
                "หากมือของผู้เล่นอยู่ที่ 6 หรือ 7 มือของนายธนาคารที่มีแต้มรวม 3, 4 หรือ 5 จะต้องจั่ว ในขณะที่มือของนายธนาคารที่มีแต้มรวม 6 จะต้องอยู่",
            gameRules23: "ใครก็ตามที่ได้มือใกล้เคียงที่สุดกับทั้งหมด 9 แต้มจะเป็นผู้ชนะ",
            gameRules27: "กฎการจั่วไพ่ใบที่สามของเจ้ามือ",
            gameRules28: "เมื่อแต้มรวมของไพ่สองใบแรกของเจ้ามือเป็น:",
            gameRules29: "0, 1 หรือ 2 แต้ม: ยกเว้นว่าผู้เล่นมีแต้มรวม 8 หรือ 9 เจ้ามือจะจั่วไพ่ใบที่สาม",
            gameRules30: "(เจ้า 3 แต้ม): ถ้าผู้เล่นจั่วไพ่ใบที่สามได้ 0, 1, 2, 3, 4, 5, 6, 7 หรือ 9 แต้ม เจ้ามือจะจั่วไพ่",
            gameRules31: "ถ้าผู้เล่นจั่วไพ่ได้ 8 แต้ม เจ้ามือจะไม่จั่วไพ่",
            gameRules32: "(เจ้า 4 แต้ม): ถ้าผู้เล่นจั่วไพ่ใบที่สามได้ 2, 3, 4, 5, 6 หรือ 7 แต้ม เจ้ามือจะจั่วไพ่",
            gameRules33: "ถ้าผู้เล่นจั่วไพ่ได้ 0, 1, 8 หรือ 9 แต้ม เจ้ามือจะไม่จั่วไพ่",
            gameRules34: "(เจ้า 5 แต้ม): ถ้าผู้เล่นจั่วไพ่ใบที่สามได้ 4, 5, 6 หรือ 7 แต้ม เจ้ามือจะจั่วไพ่",
            gameRules35: "ถ้าผู้เล่นจั่วไพ่ได้ 0, 1, 2, 3, 8 หรือ 9 แต้ม เจ้ามือจะไม่จั่วไพ่",
            gameRules36: "(เจ้า 6 แต้ม): ถ้าผู้เล่นจั่วไพ่ใบที่สามได้ 6 หรือ 7 แต้ม เจ้ามือจะจั่วไพ่",
            gameRules37: "ถ้าผู้เล่นจั่วไพ่ได้ 0, 1, 2, 3, 4, 5, 8 หรือ 9 แต้ม เจ้ามือจะไม่จั่วไพ่",
            gameRules38: "7 แต้ม: ทั้งสองฝ่ายจะไม่จั่วไพ่",
            gameRules39: "8 หรือ 9 แต้ม: ไม่ว่าฝ่ายใดได้แต้มนี้ จะไม่มีการจั่วไพ่ใบที่สาม",
            moreGames1: "ปุ่ม LOBBY สามารถเลือกได้ตลอดเวลาจากเกมใดก็ได้",
            moreGames2:
                "ช่วยให้คุณเปลี่ยนตารางเกมหรือเลือกเกมสดอื่น ๆ ได้อย่างง่ายดาย คุณจะไม่ถูกนำออกจากเกมนี้จนกว่าคุณจะเลือกเกม/โต๊ะใหม่ที่คุณต้องการเข้าร่วม คุณสามารถใช้ล็อบบี้เพื่อเรียกดูเกมอื่นในขณะที่ยังคงเล่นเกมปัจจุบันของคุณได้",
            moreGames3:
                "Baccarat Multiplay เป็นแผงมัลติเพลย์ที่ผู้เล่นสามารถสังเกตและจัดเรียงรูปแบบถนน วางเดิมพัน และเล่นที่โต๊ะ Baccarat หลายโต๊ะพร้อมกันได้อย่างรวดเร็ว",
            payouts1: "จ่าย",
            payouts2: "มีการเรียกเก็บค่าคอมมิชชั่น",
            placeBets1: "การปิดใช้งานการเดิมพันข้างขึ้นอยู่กับจำนวนไพ่ที่แจกระหว่างเกมบนโต๊ะของคุณ",
            placeBets2:
                "แผงขีดจำกัดการเดิมพันจะแสดงขีดจำกัดการเดิมพันขั้นต่ำและสูงสุดที่อนุญาตที่โต๊ะ ซึ่งอาจเปลี่ยนแปลงได้เป็นครั้งคราว เปิดขีดจำกัดการเดิมพันเพื่อตรวจสอบขีดจำกัดปัจจุบันของคุณ",
            placeBets3: "เพื่อเข้าร่วมในเกม คุณต้องมีเงินทุนเพียงพอที่จะครอบคลุมการเดิมพันของคุณ คุณสามารถดูยอดคงเหลือปัจจุบันของคุณได้บนหน้าจอ",
            placeBets4:
                "ในมุมมองที่สมจริง ตัวจับเวลาจะแจ้งให้คุณทราบถึงระยะเวลาการเดิมพัน หลังจากหมดเวลา การเดิมพันจะปิดลงและไม่ยอมรับการเดิมพันอีกต่อไป",
            placeBets5:
                "CHIP DISPLAY ช่วยให้คุณสามารถเลือกมูลค่าของแต่ละชิปที่คุณต้องการเดิมพัน เฉพาะชิปของสกุลเงินที่สามารถครอบคลุมโดยยอดเงินปัจจุบันของคุณเท่านั้นที่จะเปิดใช้งาน",
            placeBets6:
                "เมื่อคุณเลือกชิปแล้ว ให้วางเดิมพันโดยคลิก/แตะจุดเดิมพันที่เหมาะสมบนโต๊ะเกม แต่ละครั้งที่คุณคลิก/แตะจุดเดิมพัน จำนวนเงินเดิมพันของคุณจะเพิ่มขึ้นตามมูลค่าของชิปที่เลือก หรือจนถึงขีดจำกัดสูงสุดสำหรับประเภทการเดิมพันที่คุณเลือก เมื่อคุณเดิมพันถึงขีดจำกัดสูงสุดแล้ว จะไม่มีการรับเงินเพิ่มเติมสำหรับการเดิมพันนั้น และข้อความจะปรากฏขึ้นเหนือการเดิมพันของคุณเพื่อแจ้งให้คุณทราบว่าคุณได้เดิมพันสูงสุดแล้ว",
            placeBets7:
                "หมายเหตุ: โปรดอย่าย่อขนาดเบราว์เซอร์ของคุณหรือเปิดแท็บอื่นใดในเบราว์เซอร์ของคุณในขณะที่เวลาเดิมพันยังคงอยู่ และคุณได้วางเดิมพันไว้บนโต๊ะแล้ว การกระทำดังกล่าวอาจตีความได้ว่าเป็นการออกจากเกม และการเดิมพันของคุณจะถูกปฏิเสธสำหรับรอบเกมนั้น ๆ",
            placeBets8: "ปุ่ม REPEAT ช่วยให้คุณสามารถทำซ้ำการเดิมพันทั้งหมดจากรอบเกมที่แล้ว ปุ่มนี้จะใช้ได้เฉพาะก่อนที่จะวางชิปตัวแรกเท่านั้น",
            placeBets9:
                "ปุ่มสองเท่า (2x) จะใช้งานได้หลังจากที่คุณวางเดิมพันแล้ว การคลิก/แตะแต่ละครั้งจะเพิ่มเงินเดิมพันของคุณเป็นสองเท่าจนถึงขีดจำกัดสูงสุด โปรดทราบว่าคุณต้องมียอดเงินในบัญชีเพียงพอที่จะเพิ่มเงินเดิมพันทั้งหมดเป็นสองเท่า",
            placeBets10: "ปุ่ม UNDO จะลบการเดิมพันครั้งล่าสุดที่คุณวาง",
            placeBets11:
                "คุณสามารถคลิก/แตะปุ่มเลิกทำซ้ำๆ เพื่อลบการเดิมพันทีละรายการในลำดับย้อนกลับที่ได้วางเดิมพัน คุณสามารถล้างเดิมพันทั้งหมดของคุณได้โดยกดปุ่มเลิกทำค้างไว้",
            placeBets12: "ตัวบ่งชี้เดิมพันรวมจะแสดงยอดรวมของการเดิมพันทั้งหมดที่วางในรอบปัจจุบัน",
            return1: "เปอร์เซ็นต์ผลตอบแทนต่อผู้เล่น (RTP) ที่เหมาะสมที่สุดสำหรับบาคาร่าคือ 98.94%*",
            return2: "ตารางต่อไปนี้แสดงเปอร์เซ็นต์ผลตอบแทนต่อผู้เล่นในการเดิมพันข้างทางเลือกต่างๆ",
            return3: "กลับไปที่ผู้เล่น",
            return4: "*RTP ขึ้นอยู่กับกลยุทธ์ที่เหมาะสมที่สุดสำหรับการเดิมพันเจ้ามือ",
            score1: "เส้นบาคาร่าและแนวโน้มของผู้เล่นหรือเจ้ามือที่ใช้รองเท้าแบบใดแบบหนึ่งจะถูกบันทึกไว้ในกระดานคะแนนต่างๆ การแสดงภาพผลการแข่งขันรอบที่ผ่านมาและสถิติอื่นๆ เกี่ยวกับรองเท้าปัจจุบันอาจช่วยคุณในการทำนายผลการแข่งขันรอบอนาคตได้",
            score2: "BEAD ROAD และ BIG ROAD แสดงผลของแต่ละรอบที่ผ่านมา ในขณะที่ Big Eye Road, Small Road และ Cockroach Road แสดงรูปแบบที่ได้มาจาก BIG ROAD",
            score3: "สถิติถนนและรองเท้าจะถูกล้างเสมอเมื่อมีการเปิดตัวรองเท้าใหม่",
            score4: "ถนนบีด",
            score5: "แต่ละช่องใน BEAD ROAD แสดงถึงผลลัพธ์ของรอบที่ผ่านมา ผลการแข่งขันรอบแรกสุดจะถูกบันทึกไว้ที่มุมซ้ายบน อ่านคอลัมน์ลงไปจนสุดด้านล่าง แล้วเริ่มจากด้านบนของคอลัมน์ที่อยู่ติดกันไปทางขวาเป็นต้น เซลล์สีแดงทึบบ่งชี้ว่าเจ้ามือชนะ เซลล์สีน้ำเงินทึบแสดงถึงชัยชนะของผู้เล่น เซลล์สีเขียวทึบแสดงถึงเน็คไท จุดสีแดงที่มุมขวาล่างของเซลล์หมายความว่าเจ้ามือมีคู่หนึ่ง จุดสีน้ำเงินที่มุมซ้ายบนของเซลล์หมายความว่าผู้เล่นมีคู่แล้ว",
            score6: "คุณสามารถเปลี่ยนการแสดงผล Bead Road จากภาษาอังกฤษเป็นภาษาจีนตัวย่อหรือโหมดคะแนนได้โดยการคลิก/แตะที่ใดก็ได้",
            score7: "ถนนสายใหญ่",
            score8: "ในถนนใหญ่ ผลการแข่งขันรอบแรกสุดจะถูกบันทึกไว้ที่มุมซ้ายบน",
            score9: "คอลัมน์ใหม่จะถูกสร้างขึ้นในแต่ละครั้งที่สตรีคที่ชนะของเจ้ามือเปลี่ยนไปเป็นฝ่ายผู้เล่นหรือในทางกลับกัน",
            score10: "ช่องที่ร่างด้วยสีแดงแสดงว่าเจ้ามือชนะ ช่องที่ร่างด้วยสีน้ำเงินแสดงว่าผู้เล่นชนะ",
            score11:
                "การเสมอจะถูกบันทึกเป็นเส้นสีเขียวผ่านช่องสำหรับรอบก่อนหน้า หากรอบแรกเสมอกัน เส้นสีเขียวจะปรากฏขึ้นก่อน และเส้นขอบสีแดงหรือสีน้ำเงินจะปรากฏขึ้นรอบๆ เส้นเมื่อผู้เล่นหรือเจ้ามือชนะรอบ",
            score12: "หากมีการเสมอกันสองรอบขึ้นไป หมายเลขบนบรรทัดจะแสดงจำนวนการเสมอกัน",
            score13: "ถนนที่ได้รับ",
            score14:
                'สำหรับผู้ที่ชื่นชอบบาคาร่าอย่างแท้จริง ถนนตาใหญ่ ถนนเล็ก และถนนแมลงสาบ จะถูกรวมไว้เพื่อแสดงรูปแบบที่ได้มาจากผลลัพธ์ในอดีตที่บันทึกไว้ในถนนใหญ่ ถนนตาใหญ่ใช้วงกลมที่มีโครงร่าง ถนนเล็กใช้วงกลมทึบ และถนนแมลงสาบใช้เครื่องหมายทับ อย่างไรก็ตาม ในถนนที่ได้รับเหล่านี้ สีแดงและสีน้ำเงินไม่ตรงกับการชนะของเจ้ามือและผู้เล่น และไม่มีวิธีใดที่จะแยกแยะความสัมพันธ์หรือคู่ได้ ในถนนที่ได้รับมา ทางเข้าสีแดงเป็นสัญลักษณ์ของการซ้ำซ้อน ในขณะที่ทางเข้าสีน้ำเงินบ่งบอกถึงรองเท้าที่ "ขาดๆ หายๆ" ที่เอาแน่เอานอนไม่ได้',
            score15:
                "ถนนที่ได้รับไม่ได้เริ่มต้นที่จุดเริ่มต้นของรองเท้า พวกเขาเริ่มต้นด้วยเข็มตามเข็มแรกในคอลัมน์ที่สอง สาม และสี่ของถนนใหญ่ เมื่อถนนเริ่มต้นขึ้น จะมีการเพิ่มสัญลักษณ์สีแดงหรือสีน้ำเงินเพิ่มเติมหลังทุกรอบ",
            score16: "สถิติรองเท้า",
            score17: "สถิติต่อไปนี้ตามรองเท้าปัจจุบันจะแสดงให้คุณเห็น:",
            score18: "ทั้งหมด — จำนวนรอบที่เสร็จสมบูรณ์จนถึงขณะนี้",
            score19: "ผู้เล่น — จำนวนผู้เล่นที่ชนะจนถึงตอนนี้",
            score20: "Banker — จำนวน Banker ที่ชนะจนถึงตอนนี้",
            score21: "เสมอ — จำนวนรอบที่เสมอกันจนถึงตอนนี้",
            score22: "Banker (คู่) — จำนวนคู่ Banker จนถึงตอนนี้",
            score23: "ผู้เล่น (คู่) — จำนวนคู่ผู้เล่นจนถึงตอนนี้",
            score24: "ตารางการตรวจวัดถนน",
            score25:
                "ตารางการตรวจสอบถนนจะแสดงไอคอนที่จะถูกเพิ่มลงในถนนทั้งสามสาย หากเจ้ามือหรือผู้เล่นชนะในรอบถัดไป คลิกปุ่มเจ้ามือ (B) หรือผู้เล่น (P) เพื่อดูไอคอนที่จะถูกเพิ่มบนถนน หากเจ้ามือหรือผู้เล่นชนะในรอบถัดไป",
            setting1: "ปุ่มการตั้งค่าจะเปิดเมนูการตั้งค่าที่ผู้ใช้เปลี่ยนแปลงได้",
            setting2:
                "การตั้งค่าที่คุณเลือกจะถูกนำไปใช้พร้อมกันและจะถูกจัดเก็บไว้ในโปรไฟล์ของคุณ การตั้งค่าที่จัดเก็บไว้จะเปิดขึ้นโดยอัตโนมัติเมื่อคุณเข้าสู่ระบบจากอุปกรณ์ใดๆ",
            setting3: "คุณสามารถเปลี่ยนการตั้งค่าทั่วไปและการตั้งค่าเกมของคุณได้",
            shuffling1: "การเล่นเกมจะดำเนินต่อไปจนกว่าจะแจกไพ่คัต ต่อจากนั้นไพ่จะถูกสับโดยเจ้ามือหรือผู้สับไพ่",
            shuffling2:
                "หากมีโต๊ะสับเปลี่ยนให้ใช้รองเท้าสองใบพร้อมไพ่สองชุดที่โต๊ะ ในกรณีนี้ ดีลเลอร์จะสลับรองเท้า และการสับไพ่จะดำเนินการโดยนักสับไพ่ที่โต๊ะสับไพ่ ในขณะที่เจ้ามือยังคงเป็นเจ้าภาพรอบเกมต่อไป",
            sideBets1: "เดิมพันข้าง",
            sideBets2: "คำอธิบาย",
            sideBets3: "จ่ายถ้าไพ่สองใบแรกที่แจกให้ผู้เล่นเป็นคู่",
            sideBets4: "จ่ายถ้าไพ่สองใบแรกที่แจกให้เจ้ามือเป็นคู่",
            lastWin: "ชัยชนะครั้งสุดท้าย",
            totalBet: "เดิมพันทั้งหมด",
            balance: "สมดุล",
            bankerVSPlayer: "นายธนาคาร VS ผู้เล่น",
            bankerCard: "บัตรธนาคาร",
            won: "วอน",
            pair50: "ไม่สามารถเดิมพันคู่ได้หลังจากผ่านไป 50 รอบ",
            newGame: "เกมส์ใหม่",
        },
    },
    kr: {
        translation: {
            videoLag: "비디오 지연",
            gameStutter: "게임 끊김 현상",
            slowGame: "느린 게임 진입",
            clutteredInt: "복잡한 인터페이스",
            others: "기타",
            chips8: "8개의 칩을 선택해주세요.",
            selectChips: "개의 칩을 선택해주세요.",
            success: "성공",
            pleaseSelectFirst: "업데이트할 칩을 선택하세요",
            nothing: "업데이트할 내용이 없습니다.",
            update: "업데이트",
            logout: "로그 아웃",
            fullScreen: "전체 화면",
            exitFullScreen: "전체 화면 종료",
            bankerWin: "은행가 이기다",
            banker: "은행가",
            banker_s: "B",
            playerWin: "플레이어 이기다",
            player: "플레이어",
            player_s: "P",
            tp: "3P",
            win: "승리",
            tie_s: "T",
            tie: "묶다",
            super6: "Super 6",
            jackpot: "큰 횡재",
            autoSplit: "자동 분할",
            ante: "앤티",
            bet: "내기",
            point: "포인트",
            bonus: "보너스",
            pair: "쌍",
            either: "어느 하나",
            tableClosed: "죄송합니다. 테이블 유지 관리가 진행 중입니다.",
            cancelRound: "우리가 미안해.",
            startBet: "베팅 시작",
            stopBet: "더 이상 베팅하지 마세요",
            nextRound: "다음 라운드를 기다려주세요",
            lastGame: "마지막 게임",
            insufficientBalance: "잔액 불충분",
            betLimitExceed: "베팅 한도 초과",
            selectAmount: "배팅금액을 먼저 선택해주세요",
            peekCard: "스크래치할 카드를 선택하십시오",
            nothingToUndo: "취소할 베팅 없음",
            noBetHistory: "베팅 내역 없음",
            playerPair: "플레이어 페어",
            bankerPair: "뱅커 페어",
            menu: "메뉴",
            lobby: "로비",
            setting: "설정",
            gameHistory: "베팅 내역",
            how: "게임플레이",
            video: "동영상",
            playVideo: "비디오를 가지고 놀다",
            quality: "비디오 품질",
            sound: "소리",
            playSound: "소리를 가지고 놀다",
            general: "일반적인",
            fullscreen: "전체 화면 설정",
            auto: "자동적 인",
            low: "낮은",
            high: "높은",
            language: "언어",
            game: "게임",
            date: "날짜",
            winLoss: "이기거나 지거나",
            gameObjectives: "게임 목표",
            gameRules: "게임 규칙",
            sideBets: "사이드 노트",
            payouts: "지출",
            redEnvelope: "빨간 봉투",
            playerReturn: "플레이어로 돌아가기",
            placeBets: "베팅하기",
            betStat: "베팅 통계",
            chat: "채팅",
            scoreCard: "스코어카드",
            gameNumber: "게임 번호",
            errorHandling: "오류 처리",
            policy: "연결 끊김 정책",
            shuffling: "혼합",
            moreGames: "더 많은 게임",
            betCode: "베팅하기",
            b6: "슈퍼 6",
            b6Win: "뱅커가 6점으로 승리",
            b6t: "슈퍼 6 합",
            b6TWin: "뱅커 6시와",
            smallTiger: "작은 호랑이",
            bigTiger: "큰 호랑이",
            superTiger: "슈퍼 타이거",
            tigerTie: "호랑이 합",
            smallTigerWin: "뱅커가 6점으로 승리 (카드 2장)",
            bigTigerWin: "뱅커가 6점으로 승리 (카드 3장)",
            superTigerWin: "뱅커가 6점으로 승리",
            tigerTieWin: "6점 합",
            allGame: "모든 게임들",
            youWin: "네가 이겼다",
            threeP: "사진 3장",
            betPoint: "더 많은 내기",
            traditionalBet: "전통적인 베팅",
            clickToStay: "머물려면 빈 공간을 클릭하세요!",
            clickToStayDesc: "<b></b>초 후에 로비로 리디렉션됩니다.",
            maintenance: "유지보수가 진행 중입니다.",
            merchantReturnNull: "시스템 작업 시간이 초과되었습니다. 작업이 실패했거나 성공적으로 전송되지 않았을 수 있습니다.",
            playerInsufficientBalance: "플레이어 잔액 부족",
            merchantInsufficientBalance: "판매자 잔액 부족",
            betLimitExceeded: "베팅 한도 초과",
            tableNotFound: "테이블을 찾을 수 없음",
            betPlaced: "현재 플레이어가 이미 베팅을 했습니다",
            playerNotFound: "플레이어를 찾을 수 없음",
            gameClose: "게임 종료",
            amountLess: "총 베팅 금액은 0보다 작거나 같을 수 없습니다.",
            apiError: "일부 오류가 발생했습니다. 대리점에 문의하세요.",
            last: "마지막",
            result: "결과",
            COMMISSION: "바카라",
            B6: "슈퍼 6 바카라",
            TIGER: "호랑이 6 바카라",
            THREEP: "사진 3장",
            FOURSEASON: "사계절",
            ROULETTE: "룰렛",
            SICBO: "식보",
            PEEKBC: "바카라 엿보기",
            DREAMCATCHER: "드림캐쳐",
            sessionExpired: "세션이 만료되었습니다",
            sessionExpiredDesc: "사용자 인증에 실패했거나 세션이 만료되었을 수 있습니다. 다시 시도해 주세요.",
            maintInProgressDesc: "불편을 끼쳐드려 죄송합니다. 일부 점검 작업이 진행 중입니다. 곧 다시 돌아올게요!",
            countdownDesc: "우리는 다시 들어갈 것이다",
            playerID: "플레이어 ID",
            betStat1: "특정 베팅 지점에 베팅한 총 금액과 그 자리에 베팅한 플레이어의 수가 표시됩니다.",
            betStat2: "뱅커, 플레이어 또는 타이에 대한 모든 베팅의 백분율도 표시됩니다.",
            betStat3: "게임 설정에서 이러한 통계를 숨기도록 선택할 수 있습니다.",
            disconnect1:
                "게임 라운드에서 연결이 끊어진 경우 모든 베팅은 유효하며 귀하가 부재 중일 때 정산됩니다. 재접속 시 내역 창에서 베팅 결과를 확인할 수 있습니다.",
            errorHandling1:
                "게임이나 시스템, 게임 진행 과정에 오류가 있는 경우, 게임 라운드는 일시적으로 중단되며, 게임 진행자는 서비스 관리자에게 이를 통보합니다. 귀하와 다른 플레이어는 화면 팝업 메시지를 통해 문제를 조사 중이라는 알림을 받게 됩니다. 관리자가 즉시 오류를 해결할 수 있는 경우 게임 라운드는 정상적으로 진행됩니다. 즉각적인 해결이 불가능한 경우 해당 게임 라운드는 취소되며 해당 게임 라운드에 참여한 모든 플레이어에게 최초 베팅 금액이 환불됩니다.",
            gameHistory1: "HISTORY 버튼을 누르면 플레이한 모든 게임 라운드와 해당 라운드의 결과를 보여주는 창이 열립니다.",
            gameHistory2: "영상",
            gameHistory3: "다음을 확인하여 과거 게임 활동을 검토할 수 있습니다.",
            gameHistory4:
                "계정 내역 — 전체 계정 내역을 날짜, 게임, 베팅 금액 및 지불금 목록으로 표시합니다. 가장 최근에 완료된 게임 라운드가 목록 상단에 표시됩니다.",
            gameHistory5: "게임 기록 — 게임 열에서 게임을 클릭/탭하면 특정 게임과 관련된 기록을 표시합니다.",
            gameNumber1: "각 게임 라운드는 고유한 GAME NUMBER로 식별됩니다.",
            gameNumber2:
                '이 숫자는 게임 라운드가 GMT 기준으로 {"시간"}에 시작된 시점을 반영합니다. 특정 라운드와 관련하여 고객 서비스에 문의하려면 이 게임 번호를 참조용으로 사용하거나 게임 번호의 스크린샷을 찍으십시오.',
            gameObjectives1: "스피드 바카라의 목표는 9에 가장 가까운 값을 가지고 누가 이길지 예측하는 것입니다.",
            gameRules1: "게임은 딜러가 주최하며 저는 8개의 표준 52카드 덱으로 플레이했습니다. 카드 값은 다음과 같습니다.",
            gameRules2: "에이스는 가장 낮은 카드이며 각각 1점의 가치가 있습니다.",
            gameRules3: "2부터 9까지의 카드는 숫자의 액면가만큼 가치가 있습니다.",
            gameRules4: "10과 페이스 카드(잭, 퀸, 킹)는 각각 0의 가치가 있습니다.",
            gameRules5:
                "메인 바카라 게임에서는 각 카드의 숫자 값만 관련됩니다. 각 카드의 모양(하트, 스페이드, 클럽 또는 다이아몬드)은 관련이 없습니다.",
            gameRules6: "각 거래에 앞서 플레이어 또는 뱅커가 9에 가장 가까운 값을 가진 핸드를 가지고 라운드에서 승리할지 여부에 베팅해야 합니다.",
            gameRules7: "플레이어와 뱅커가 모두 동일한 가치의 핸드를 보유할 것이라고 생각한다면 타이에 베팅할 수도 있습니다.",
            gameRules8:
                "또한 플레이어/뱅커 페어(P/B 페어)에 베팅할 수도 있습니다. 이는 플레이어/뱅커에게 딜링된 처음 두 장의 카드가 페어를 구성하는 경우 지불됩니다.",
            gameRules9: "딜러는 플레이어와 뱅커에게 각각 두 장의 카드를 알려주는 것으로 시작합니다.",
            gameRules10: "바카라에서는 두 손이 처리됩니다. 한 손은 플레이어에게, 다른 한 손은 뱅커에게 주어집니다.",
            gameRules11:
                "플레이어와 뱅커가 동일한 가치의 핸드를 보유하면 라운드는 무승부로 종료됩니다. 타이 베팅이 승리하고 플레이어 및 뱅커 푸시에 대한 베팅이 반환됩니다.",
            gameRules12:
                "각 핸드의 값은 10 이상의 가치가 있는 핸드에 10자리 숫자를 놓아 계산됩니다. 예를 들어, 바카라에서는 7과 9로 구성된 핸드의 가치는 6뿐입니다(16-10=6이기 때문). 마찬가지로 페이스 카드에 9를 더하면 9의 가치가 됩니다.",
            gameRules13:
                '플레이어나 뱅커가 처음에 8이나 9("자연" 8이나 9) 가치의 두 장의 카드 패를 받는 경우, 양 패에 추가 카드가 전달되지 않습니다.',
            gameRules14:
                '플레이어와 뱅커가 처음에 0-7의 가치가 있는 두 장의 카드 패를 받은 경우 "세 번째 카드 규칙"을 참고하여 세 번째 카드를 양손에 할지 양손에 할지 결정합니다. 플레이어가 항상 먼저 갑니다.',
            gameRules15: "플레이어의 손",
            gameRules16: "플레이어의 초기 두 장의 카드 패",
            gameRules17: "플레이어는 세 번째 카드를 뽑습니다.",
            gameRules18: "플레이어 스탠드.",
            gameRules19: "손을 시들게 할 세 번째 카드는 없습니다.",
            gameRules20: "은행가의 손",
            gameRules21: "D - 그리기; S-스탠드",
            gameRules22: "플레이어의 패가 6 또는 7이면 뱅커의 패의 합이 3, 4 또는 5이면 추첨을 해야 하고, 뱅커의 패의 합이 6이면 서 있어야 합니다.",
            gameRules23: "총합이 9에 가장 가까운 사람이 승리합니다.",
            gameRules27: "장가가 세 번째 카드를 뽑는 규칙",
            gameRules28: "장가의 첫 두 장의 카드 합계가 다음과 같을 때:",
            gameRules29: "0, 1 또는 2점: 한가가 총 8 또는 9점의 네추럴을 얻지 않는 한, 장가는 카드를 뽑습니다.",
            gameRules30: "(장가 3점): 한가가 세 번째 카드로 0, 1, 2, 3, 4, 5, 6, 7 또는 9점을 뽑으면, 장가는 카드를 뽑습니다.",
            gameRules31: "한가가 8점을 뽑으면 장가는 카드를 뽑지 않습니다.",
            gameRules32: "(장가 4점): 한가가 세 번째 카드로 2, 3, 4, 5, 6 또는 7점을 뽑으면, 장가는 카드를 뽑습니다.",
            gameRules33: "한가가 0, 1, 8 또는 9점을 뽑으면 장가는 카드를 뽑지 않습니다.",
            gameRules34: "(장가 5점): 한가가 세 번째 카드로 4, 5, 6 또는 7점을 뽑으면, 장가는 카드를 뽑습니다.",
            gameRules35: "한가가 0, 1, 2, 3, 8 또는 9점을 뽑으면 장가는 카드를 뽑지 않습니다.",
            gameRules36: "(장가 6점): 한가가 세 번째 카드로 6 또는 7점을 뽑으면, 장가는 카드를 뽑습니다.",
            gameRules37: "한가가 0, 1, 2, 3, 4, 5, 8 또는 9점을 뽑으면 장가는 카드를 뽑지 않습니다.",
            gameRules38: "7점: 양쪽 모두 카드를 뽑지 않습니다.",
            gameRules39: "8 또는 9점: 네추럴 숫자는 양쪽 모두 세 번째 카드를 뽑지 않습니다.",
            moreGames1: "LOBBY 버튼은 어느 게임에서나 언제든지 선택할 수 있습니다.",
            moreGames2:
                "게임 테이블을 쉽게 변경하거나 다른 라이브 게임을 선택할 수 있습니다. 실제로 참가하려는 새 게임/테이블을 선택할 때까지 이 게임에서 제거되지 않습니다. 현재 게임을 플레이하는 동안 LOBBY를 사용하여 다른 게임을 탐색할 수 있습니다.",
            moreGames3:
                "Baccarat Multiplay는 플레이어가 도로 패턴을 빠르게 관찰 및 정렬하고, 베팅을 하고, 동시에 여러 바카라 테이블에서 플레이할 수 있는 멀티플레이 패널입니다.",
            payouts1: "지불",
            payouts2: "수수료가 부과됩니다",
            placeBets1: "사이드 베팅 비활성화는 특정 테이블에서 게임 중에 처리된 카드 수에 따라 달라집니다.",
            placeBets2:
                "BET LIMITS 패널에는 테이블에서 허용되는 최소 및 최대 베팅 한도가 표시되며, 이는 수시로 변경될 수 있습니다. 현재 한도를 확인하려면 베팅 한도를 엽니다.",
            placeBets3: "게임에 참여하려면 베팅 금액을 감당할 수 있는 충분한 자금이 있어야 합니다. 화면에서 현재 잔액을 확인할 수 있습니다.",
            placeBets4: "몰입형 보기에서는 TIMER가 베팅 시간을 알려줍니다. 만료된 후에는 베팅이 종료되고 더 이상 베팅이 허용되지 않습니다.",
            placeBets5: "칩 디스플레이를 사용하면 베팅하려는 각 칩의 값을 선택할 수 있습니다. 현재 잔액으로 충당할 수 있는 단위 칩만 활성화됩니다.",
            placeBets6:
                "칩을 선택한 후에는 게임 테이블에서 적절한 베팅 지점을 클릭/탭하여 베팅하세요. 베팅 지점을 클릭/탭할 때마다 선택한 칩의 가치만큼 또는 선택한 베팅 유형의 최대 한도까지 베팅 금액이 늘어납니다. 최대 한도를 베팅하면 해당 베팅에 대해 추가 자금이 허용되지 않으며 최대 한도를 베팅했음을 알리는 메시지가 베팅 위에 나타납니다.",
            placeBets7:
                "참고: 베팅 시간이 남아 있고 테이블에 베팅한 동안에는 브라우저를 최소화하거나 브라우저에서 다른 탭을 열지 마십시오. 이러한 행위는 게임을 떠나는 것으로 해석될 수 있으며, 따라서 특정 게임 라운드에 대한 베팅은 거부됩니다.",
            placeBets8:
                "REPEAT 버튼을 사용하면 이전 게임 라운드의 모든 베팅을 반복할 수 있습니다. 이 버튼은 첫 번째 칩을 배치하기 전에만 사용할 수 있습니다.",
            placeBets9:
                "베팅을 한 후에는 DOUBLE(2x) 버튼을 사용할 수 있습니다. 각 클릭/탭은 최대 한도까지 모든 베팅을 두 배로 늘립니다. 모든 베팅을 두 배로 늘리려면 충분한 계정 잔고가 있어야 합니다.",
            placeBets10: "UNDO 버튼은 마지막 베팅을 제거합니다.",
            placeBets11:
                "UNDO 버튼을 반복적으로 클릭/탭하여 베팅을 역순으로 하나씩 제거할 수 있습니다. UNDO 버튼을 길게 누르면 모든 베팅을 취소할 수 있습니다.",
            placeBets12: "TOTAL BET 표시기는 현재 라운드에 배치된 모든 베팅의 총액을 표시합니다.",
            return1: "바카라의 최적 플레이어 복귀(RTP) 비율은 98.94%*입니다.",
            return2: "다음 표는 다양한 선택적 사이드 베팅에 대한 플레이어 복귀율을 표시합니다.",
            return3: "플레이어로 돌아가기",
            return4: "*뱅커 베팅을 위한 최적의 전략을 기반으로 한 RTP입니다.",
            score1: "특정 신발을 사용하는 플레이어 또는 뱅커의 바카라 연속 기록 및 추세는 다양한 점수판에 기록됩니다. 과거 라운드 결과에 대한 이러한 그림 표현과 현재 신발에 관한 기타 통계는 향후 라운드 결과를 예측하는 데 도움이 될 수 있습니다.",
            score2: "BEAD ROAD와 BIG ROAD는 각 라운드의 결과를 표시하고, Big Eye Road, Small Road 및 Cockroach Road는 BIG ROAD에서 파생된 패턴을 표시합니다.",
            score3: "도로 및 신발 통계는 새 신발이 출시되면 항상 지워집니다.",
            score4: "비드로드",
            score5: "BEAD ROAD의 각 셀은 지난 라운드의 결과를 나타냅니다. 가장 빠른 라운드의 결과는 왼쪽 상단에 기록됩니다. 열을 아래쪽으로 끝까지 읽으십시오. 그런 다음 오른쪽에 있는 인접한 열의 맨 위에서 시작합니다. 빨간색으로 채워진 셀은 뱅커가 승리했음을 나타냅니다. 파란색 실선 셀은 플레이어의 승리를 나타냅니다. 녹색 실선 셀은 동점을 나타냅니다. 셀의 오른쪽 하단 모서리에 있는 빨간색 점은 뱅커가 쌍을 가지고 있다는 의미입니다. 셀의 왼쪽 상단 모서리에 있는 파란색 점은 플레이어가 쌍을 가지고 있다는 의미입니다.",
            score6: "Bead Road 디스플레이는 어디에서나 클릭/탭하여 영어에서 중국어 간체 또는 점수 모드로 변경할 수 있습니다.",
            score7: "큰 길",
            score8: "BIG ROAD에서는 가장 빠른 라운드의 결과가 왼쪽 상단에 기록됩니다.",
            score9: "뱅커의 연속 연승이 플레이어에게 유리하게 변경되거나 그 반대로 바뀔 때마다 새로운 열이 생성됩니다.",
            score10: "빨간색 윤곽선으로 표시된 셀은 뱅커의 승리를 나타냅니다. 파란색 외곽선으로 표시된 셀은 플레이어의 승리를 나타냅니다.",
            score11:
                "동점은 이전 라운드의 셀을 통과하는 녹색 선으로 기록됩니다. 첫 번째 라운드가 동점인 경우 녹색 선이 먼저 나타나고 플레이어 또는 뱅커가 라운드에서 승리하면 선 주위에 빨간색 또는 파란색 윤곽선이 나타납니다.",
            score12: "2회 이상의 연속 동점 라운드가 있는 경우 줄에 있는 숫자에 동점 수가 표시됩니다.",
            score13: "파생 도로",
            score14:
                '진정한 바카라 매니아를 위해 Big Eye Road, Small Road 및 Cockroach Road가 포함되어 BIG ROAD에 기록된 과거 결과에서 파생된 패턴을 표시합니다. Big Eye Road는 윤곽선이 있는 원을 사용하고, Small Road는 채워진 원을 사용하며, Cockroach Road는 슬래시를 사용합니다. 그러나 이러한 파생 도로에서 빨간색과 파란색은 뱅커 및 플레이어의 승리와 일치하지 않으며 동점이나 쌍을 식별할 방법이 없습니다. 파생된 도로에서 빨간색 항목은 반복을 나타내는 반면 파란색 항목은 더 불규칙하고 "고르지 않은" 신발을 나타냅니다.',
            score15:
                "파생 도로는 슈의 맨 처음부터 시작되지 않습니다. BIG ROAD의 두 번째, 세 번째, 네 번째 열에서 첫 번째 손을 따르는 손으로 시작합니다. 파생 도로가 시작되면 매 라운드마다 빨간색 또는 파란색 기호가 추가됩니다.",
            score16: "신발 통계",
            score17: "현재 신발을 기준으로 다음 통계가 표시됩니다.",
            score18: "전체 — 지금까지 완료된 라운드 수입니다.",
            score19: "플레이어 — 지금까지 플레이어가 승리한 횟수입니다.",
            score20: "뱅커 — 지금까지 뱅커가 승리한 횟수입니다.",
            score21: "동점 — 지금까지의 동점 라운드 수입니다.",
            score22: "뱅커(페어) — 지금까지의 뱅커 페어 수입니다.",
            score23: "플레이어(페어) — 지금까지의 플레이어 페어 수입니다.",
            score24: "도로 조사 테이블",
            score25:
                "로드 프로빙 테이블에는 다음 라운드에서 뱅커나 플레이어가 승리할 경우 세 개의 파생 도로에 추가될 아이콘이 표시됩니다. 뱅커(B) 또는 플레이어(P) 버튼을 클릭하면 다음 라운드에서 뱅커 또는 플레이어가 승리할 경우 도로에 추가될 아이콘을 볼 수 있습니다.",
            setting1: "SETTINGS 버튼은 사용자가 변경할 수 있는 설정 메뉴를 시작합니다.",
            setting2: "선택한 설정은 즉시 적용되며 프로필에 저장됩니다. 어떤 장치에서든 로그인하면 저장된 설정이 자동으로 시작됩니다.",
            setting3: "일반 및 게임 설정을 변경할 수 있습니다.",
            shuffling1: "컷 카드가 처리될 때까지 게임 플레이는 계속됩니다. 그 후, 딜러나 셔플러가 카드를 섞습니다.",
            shuffling2:
                "셔플 테이블을 사용할 수 있는 경우 테이블에는 두 개의 카드 세트가 있는 두 개의 신발이 사용됩니다. 이 경우, 딜러는 슈를 교환하고 딜러가 게임 라운드를 계속 진행하는 동안 셔플링 테이블의 셔플러에 의해 셔플이 수행됩니다.",
            sideBets1: "사이드 베팅",
            sideBets2: "설명",
            sideBets3: "플레이어에게 전달된 처음 두 장의 카드가 쌍을 구성하는 경우 지불됩니다.",
            sideBets4: "뱅커에게 전달된 처음 두 장의 카드가 쌍을 구성하는 경우 지불됩니다.",
            lastWin: "마지막 승리",
            totalBet: "총 베팅",
            balance: "균형",
            bankerVSPlayer: "뱅커 VS 플레이어",
            bankerCard: "은행가 카드",
            won: "이겼다",
            pair50: "50라운드 이후에는 페어에 베팅할 수 없습니다.",
            newGame: "새로운 게임",
        },
    },
    mm: {
        translation: {
            videoLag: "Video Lagging",
            gameStutter: "ဂိမ်းထစ်ခြင်း။",
            slowGame: "အနှေးဂိမ်းဝင်ခြင်း။",
            clutteredInt: "ရှုပ်ပွနေသော မျက်နှာပြင်",
            others: "တခြားသူတွေ",
            chips8: "ချစ်ပ် 8 ခု ရွေးပါ။",
            selectChips: "ချစ်ပ် ခု ရွေးပါ။",
            success: "အောင်မြင်မှု",
            pleaseSelectFirst: "အပ်ဒိတ်လုပ်ရန် ချစ်ပ်ကို ရွေးပါ။",
            nothing: "အပ်ဒိတ်လုပ်စရာမရှိပါ။",
            update: "အပ်ဒိတ်",
            logout: "ထွက်လိုက်ပါ။",
            fullScreen: "မျက်နှာပြင်အပြည့်",
            exitFullScreen: "မျက်နှာပြင်အပြည့်ထွက်ပါ။",
            bankerWin: "ဒိုင် အနိုင်ရ",
            banker: "ဒိုင်",
            banker_s: "B",
            playerWin: "ဘာညာ အနိုင်ရ",
            player: "ဘာညာ",
            player_s: "P",
            tp: "3P",
            win: "ဝင်း",
            tie_s: "T",
            tie: "ချည်",
            super6: "Super 6",
            jackpot: "Jackpot",
            autoSplit: "အလိုအလျောက်ခွဲခြမ်း",
            ante: "အန်တီ",
            bet: "လောင်းကစား",
            point: "ပွိုင့်",
            bonus: "ဘောနပ်စ်",
            pair: "တွဲ",
            either: "ဖြစ်ဖြစ်ချင်း",
            tableClosed: "ဝမ်းနည်းပါသည်၊ စားပွဲပြုပြင်ထိန်းသိမ်းမှု လုပ်ဆောင်နေပါသည်။",
            cancelRound: "စိတ်မကောင်းပါဘူး။",
            startBet: "Bet ကိုစတင်ပါ။",
            stopBet: "လောင်းကြေးထပ်မထားပါ။",
            nextRound: "ကျေးဇူးပြု၍ နောက်တစ်ဆင့်ကို စောင့်ပါ။",
            lastGame: "နောက်ဆုံးကစားပွဲ",
            insufficientBalance: "လက်ကျန်ငွေ မလုံလောက်ခြင်း။",
            betLimitExceed: "လောင်းကစား ကန့်သတ်ချက်ထက် ကျော်လွန်သည်။",
            selectAmount: "ကျေးဇူးပြု၍ လောင်းကြေးပမာဏကို ဦးစွာရွေးချယ်ပါ။",
            peekCard: "ခြစ်ရန် ကတ်ကို ရွေးပါ။",
            nothingToUndo: "ပြန်ဖျက်ရန် အလောင်းအစားမရှိပါ။",
            noBetHistory: "လောင်းကြေးမှတ်တမ်းမရှိပါ။",
            playerPair: "ကစားသမားအတွဲ",
            bankerPair: "ဒိုင်တွဲ",
            menu: "မီနူး",
            lobby: "လော်ဘီ",
            setting: "တည်ဆောက်သည်",
            gameHistory: "လောင်းကစားမှတ်တမ်း",
            how: "ဂိမ်းကစားခြင်း။",
            video: "ဗီဒီယို",
            playVideo: "ဗီဒီယိုဖြင့် ကစားပါ။",
            quality: "ဗီဒီယိုအရည်အသွေး",
            sound: "အသံ",
            playSound: "အသံဖြင့်ကစားပါ။",
            general: "ယေဘုယျ",
            fullscreen: "မျက်နှာပြင်အပြည့် ဆက်တင်",
            auto: "အလိုအလျောက်",
            low: "နိမ့်သည်။",
            high: "မြင့်မားသော",
            language: "ဘာသာစကား",
            game: "ဂိမ်း",
            date: "ရက်စွဲ",
            winLoss: "အနိုင်ရသည်ဖြစ်စေ ရှုံးသည်ဖြစ်စေ",
            gameObjectives: "ဂိမ်းရည်ရွယ်ချက်",
            gameRules: "ဂိမ်းစည်းမျဉ်းများ",
            sideBets: "ဘေးထွက်မှတ်ချက်",
            payouts: "အသုံးစရိတ်",
            redEnvelope: "အနီရောင်စာအိတ်",
            playerReturn: "ကစားသူအတွက်ပြန်လည်ရရှိမှု",
            placeBets: "လောင်းကြေးထပ်ပါ။",
            betStat: "လောင်းကြေးစာရင်းဇယား",
            chat: "စကားစမြည်",
            scoreCard: "ရမှတ်ကတ်",
            gameNumber: "ဂိမ်းနံပါတ်များ",
            errorHandling: "ကိုင်တွယ်မှုအမှား",
            policy: "အဆက်ဖြတ်ခြင်းမူဝါဒ",
            shuffling: "ရှပ်တိုက်",
            moreGames: "နောက်ထပ်ဂိမ်းများ",
            betCode: "လောင်းကြေးထပ်ပါ။",
            b6: "စူပါ ၆",
            b6Win: "ဒိုင်က 6 မှတ်နဲ့ အနိုင်ရတယ်။",
            b6t: "super 6 နှင့်",
            b6TWin: "ဒိုင်က ၆ နာရီမှာ ချက်တယ်။",
            smallTiger: "ချစ်ကျား",
            bigTiger: "ကျားကြီး",
            superTiger: "စူပါကျား",
            tigerTie: "ကျားနှင့်",
            smallTigerWin: "ဒိုင်က 6 မှတ်နဲ့ အနိုင်ရတယ်။ (2 ကတ်)",
            bigTigerWin: "ဒိုင်က 6 မှတ်နဲ့ အနိုင်ရတယ်။ (3 ကတ်)",
            superTigerWin: "ဒိုင်က 6 မှတ်နဲ့ အနိုင်ရတယ်။",
            tigerTieWin: "အမှတ်ပေါင်း ၆",
            allGame: "ဂိမ်းအားလုံး",
            youWin: "သင်အနိုင်ရ",
            threeP: "3 ပုံများ",
            betPoint: "ပိုလောင်း",
            traditionalBet: "ရိုးရာလောင်းကစား",
            clickToStay: "နေရန် နေရာလွတ်ကို နှိပ်ပါ။",
            clickToStayDesc: "<b></b> စက္ကန့်အတွင်း သင့်အား Lobby သို့ ပြန်ညွှန်းပါမည်။",
            maintenance: "ပြုပြင်ထိန်းသိမ်းမှုများ လုပ်ဆောင်နေသည်။",
            merchantReturnNull: "စနစ်လည်ပတ်မှု အချိန်ကုန်သွားသည်။ သင့်လုပ်ဆောင်ချက် မအောင်မြင်နိုင် သို့မဟုတ် မအောင်မြင်နိုင်ပါ။",
            playerInsufficientBalance: "ကစားသမား လက်ကျန် မလုံလောက်ခြင်း။",
            merchantInsufficientBalance: "ကုန်သည် လက်ကျန်ငွေ မလုံလောက်",
            betLimitExceeded: "လောင်းကြေးကန့်သတ်ချက် ကျော်လွန်သွားပါပြီ။",
            tableNotFound: "ဇယားမတွေ့ပါ။",
            betPlaced: "လက်ရှိကစားသမားသည် လောင်းကြေးငွေကို ချထားပြီးဖြစ်သည်။",
            playerNotFound: "Player မတွေ့ပါ။",
            gameClose: "ဂိမ်းပိတ်",
            amountLess: "စုစုပေါင်းလောင်းကြေးသည် 0 ထက်နည်းသည် သို့မဟုတ် ညီမျှ၍မရပါ။",
            apiError: "အမှားအယွင်းအချို့ ဖြစ်ပေါ်ပါက သင့်အေးဂျင့်ထံ ဆက်သွယ်ပါ။",
            last: "နောက်ဆုံး",
            result: "ရလဒ်",
            COMMISSION: "ဘေကာရက်",
            B6: "စူပါ ၆ ဘေကာရက်",
            TIGER: "ကျား ၆ ဘေကာရက်",
            THREEP: "3 ပုံများ",
            FOURSEASON: "လေးရာသီ",
            ROULETTE: "ကစားတဲ့",
            SICBO: "sicbo",
            PEEKBC: "ဘေကာရာကြည့်",
            DREAMCATCHER: "အိပ်မက်ဖမ်းပိုက်ကွန်",
            sessionExpired: "စက်ရှင် သက်တမ်းကုန်သွားပါပြီ။",
            sessionExpiredDesc:
                "အသုံးပြုသူ စစ်မှန်ကြောင်းအထောက်အထားပြခြင်း မအောင်မြင်ပါ သို့မဟုတ် သင့်စက်ရှင်သက်တမ်းကုန်သွားနိုင်ပါသည်။ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
            maintInProgressDesc: "အဆင်မပြေမှုအတွက် တောင်းပန်သော်လည်း ပြုပြင်ထိန်းသိမ်းမှုအချို့ လုပ်ဆောင်နေပါသည်။ မကြာမီ အရန်သိမ်းပါမည်။",
            countdownDesc: "ငါတို့ပြန်ဝင်မယ်။",
            playerID: "ကစားသမား ID",
            betStat1: "လောင်းကစားတွင် လောင်းကြေးစုစုပေါင်းကိုဖော်ပြသထားသည့်အပြင်ကစားသမားအရေအတွက်ကိုလည်း ဖော်ပြထားသည်။",
            betStat2: "ဤစာရင်းကိုဖျောက်ရန် ဂိမ်းဆက်တင်များအောက်တွင်ရွေးချယ်နိုင်ပါသည်။",
            betStat3: "ဤစာရင်းဇယားများကို ဂိမ်းဆက်တင်များအောက်တွင် ဖျောက်ရန် သင်ရွေးချယ်နိုင်ပါသည်။",
            disconnect1:
                "သင်သည် ဂိမ်းကစားပွဲမှဆက်သွယ်မှုပြတ်တောက်ပါက လောင်းကစားထဲမှာသင်လောင်းကြေးထည့်ထားသောဂိမ်းများသည်ဆက်လက်လုပ်ဆောင်နေမည်၊ သင်မရှိသော်လဲ လောင်းကြေးများရလဒ်အတိုင်းတွက်ချက်ပေးပါမည်၊ သင်ပြန်လည်ဆက်သွယ်မှုရရှိပါက မှတ်တမ်း(History)ထဲသို့ဝင်ရောက်ပြီး လောင်းကစားရလဒ်များကိုကြည့်ရှုနိုင်သည်။",
            errorHandling1:
                "ဂိမ်း(သို့)systemတခုခုမှားယွင်းပါက ကစားဝိုင်းခနရပ်နားပြီး ကိုယ်စားလှယ်က ဝန်ဆောင်မှုမန်နေဂျာကိုအကြောင်းကြားပါမည်၊ သင်နှင့်အခြားကစားသမားထံ'error မှားယွင်းမှုစုံစမ်းနေဆဲ' ဆိုသည့်Notification ပေါ်လာလိမ့်မည်။ မန်နေဂျာဖက်မှချက်ချင်းဖြေရှင်းလို့ရပါက ကစားဝိုင်းဆက်လက်ကစားသွားမည်၊ အကယ်၍ချက်ချင်းဖြေရှင်းလို့မရပါက လက်ရှိဂိမ်းကစားဝိုင်းကိုcancleပါလိမ့်မည်၊လက်ရှိကစားဝိုင်းတွင်ပါဝင်သောကစားသမားများသည် အစကလောင်းထားသောလောင်းကြေးများအကုန်ပြန်လည်ရရှိပါမည်။",
            gameHistory1: "'မှတ်တမ်း'ခလုတ်သည် သင်ကစားခဲ့သောဂိမ်းများနှင့်ရလဒ်များကိုwindowအသစ်တစ်ခုမှာဖော်ပြသည်။",
            gameHistory2: "ပအောက်ဖော်ပြပါအကြောင်းအရာမှတဆင့်သင်၏ဂိမ်းကစားမှုမှတ်တမ်းများကိုကြည့်ရှုနိုင်သည်-",
            gameHistory3: "စာရင်းမှတ်တမ်း-ရက်စွဲ၊ကစားဂိမ်း၊လောင်းကြေး၊ငွေထွက်စာရင်းများမှတဆင့် စာရင်းမှတ်တမ်းအခြေအနေကိုဖော်ပြပါမည်။",
            gameHistory4: "နောက်ဆုံးကစားသောဂိမ်းကိုစာရင်း၏ထိပ်ဆုံးမှာဖော်ပြထားပါသည်။",
            gameHistory5: "ဂိမ်းကစားမှုမှတ်တမ်း-'ဂိမ်း'ကိုတချက်နှိပ်ပြီး ကစားပြီးသားဂိမ်းများ၏မှတ်တမ်းများကိုဖော်ပြပါမည်။",
            gameNumber1: "ကစားပွဲတိုင်းကိုထူးခြားသောဂိမ်းနံပါတ်ဖြင့် သတ်မှတ်ထားသည်။",
            gameNumber2:
                "ဤနံပါတ်သည်အချိန်အားဖြင့် GMT၏သတ်မှတ်ချက်အရဂိမ်းကစားပွဲစတင်ချိန်ကိုဖော်ပြသည်။ ဂိမ်းတစ်ခုခုနှင့်ပတ်သက်၍ဖောက်သည်ဝန်ဆောင်မှုကိုဆက်သွယ်လိုပါက ဤဂိမ်းနံပါတ်ကိုအသုံးပြုနိုင်သည်(သို့)ဤဂိမ်းနံပါတ်ကိုscreenshotရိုက်ထားပါ။",
            gameObjectives1:
                "Speed Baccarat ၏ရည်ရွယ်ချက်သည် ကိန်းကဏန်းနံပါတ်၉နှင့် အနီးစပ်ဆုံးအမှတ်ကိုကြည့်ပြီး ဘယ်သူအနိုင်ရမလဲဆိုတာကိုခန့်မှန်းတဲ့ ဂိမ်းကစားမျိုးဖြစ်သည်။",
            gameRules1: "ဒီဂိမ်းသည် ကိုယ်စားလှယ်တစ်ယောက်၊ပုံမှန်ဖဲ52ကတ်*ဖဲ၈စုံနှင့်ကစားရပါတယ်။ ကဒ်တန်ဖိုးမှာ အောက်ပါအတိုင်းဖြစ်ပါတယ်။",
            gameRules2: "Aသည် အမှတ်အနည်းဆုံးဖြစ်ပြီး ၁ဖဲ၁မှတ်ပါ။",
            gameRules3: "2-9အထိတန်ဖိုးကတော့ နံပါတ်အတိုင်းပါ။",
            gameRules4: "10/J/Q/K သည် 0 မှတ်။",
            gameRules5: "Speed Baccaratအဓိကဂိမ်းမှာ ဖဲ၏အမှတ်သာဆက်စပ်မှုရှိသည်။ ပန်းပွင့်ကိုမကြည့်ပါ။",
            gameRules6: "ဖဲမဝေမီ  သင်သည်ကစားသူဖက်(သို့)ဒိုင်ဖက်ကို လောင်းကြေးထည့်ရမည်။ ကိန်းကဏန်းနံပါတ်၉နှင့် အနီးစပ်ဆုံးအမှတ်ရရှိသောဖက်ကအနိုင်ရမည်။",
            gameRules7: "၂ဖက်လုံးသားရေကျမည်ဟု သင်ထင်ပါက သားရေပွဲလောင်းလို့လဲရသည်။",
            gameRules8: "ဒါအပြင် ကစားသူ/ဒိုင်စုံတူဖဲ(P/B တွဲ)ကိုလဲ လောင်းလို့ရသည်။ပထမဦးဆုံးဝေသောဖဲ၂ဖဲနံပါတ်တူညီပါက ရော်ကြေးရမည်။",
            gameRules9: "အရောင်းကိုယ်စားလှယ်သည် ကစားသမားနှင့် ဘဏ်ပိုင်ရှင်အား ကတ်နှစ်ကတ်စီ ပြောပြခြင်းဖြင့် စတင်သည်။",
            gameRules10: "Baccarat တွင် လက်နှစ်ချောင်းကို ပေးသည်- လက်တစ်ဖက်က ကစားသမားထံ တစ်ဖက် ဘဏ်သမားကို လက်တစ်ဖက် ပေးသည်။",
            gameRules11:
                "ကစားသမားနှင့် ဘဏ်လုပ်ငန်းရှင်က တူညီသောတန်ဖိုးကို ကိုင်ထားလျှင် အဝိုင်းသည် သရေဖြင့် ပြီးဆုံးသည်။ Tie အလောင်းအစားသည် အနိုင်ရရှိပြီး၊ Player နှင့် Banker တို့၏ တွန်းအား (ပြန်ပေးသည်) တွင် အလောင်းအစားများ။",
            gameRules12:
                "မည့်သည့်ဖက်မဆို 10(သို့)10မှတ်ထက်ပိုပါက 10မှတ်ကိုထည့်မတွက်ပါ၊ဥပမာ၊ 7နှင့်9ပါလျှင်Baccaratတွင် 6မှတ်သာတန်ဖိုးသတ်မှတ်သည် (16-10=6)၊အလားတူ (J/Q/K)နှင့်9ပါလျှင် 9မှတ်သာသတ်မှတ်မည်။",
            gameRules13: "ကစားသူ(သို့)ဒိုင် တဖက်ဖက်က ပထမဆုံးဖဲ၂ဖဲ၏တန်ဖိုးမှာ 8(သို့)9မှတ်ဖြစ်ပါက တတိယဖဲကိုမဝေတော့ပါ။",
            gameRules14:
                'ကစားသူနှင့်ဒိုင်၂ဖက်လုံး၏ ပထမဆုံးဖဲ၂ဖဲတန်ဖိုးမှာ0-7မှတ်ဖြစ်ပါက "တတိယဖဲဝေမှုစည်းကမ်း"အတိုင်း တစ်ဖက်(သို့)၂ဖက်လုံးကိုဖဲဝေမည်။ ကစားသူဖက်ကိုအမြဲအရင်ဖဲဝေမည်။',
            gameRules15: "ကစားသူဖက်",
            gameRules16: "ကစားသူဖက်ကဦးဆုံးဖဲ၂ဖဲတန်ဖိုးမှာ",
            gameRules17: "မှတ်ဖြစ်ပါက တတိယဖဲဆွဲရမည်။",
            gameRules18: "မှတ်ဖြစ်ပါက ဖဲမဆွဲပဲ စောင့်ရမည်။",
            gameRules19: "မှတ်ဖြစ်ပါက ဖဲဆွဲစရာမလိုပါ။",
            gameRules20: "ဒိုင်ဖက်",
            gameRules21: "D-ဖဲဆွဲမည်  S-ဖဲစောင့်မည်",
            gameRules22: "ကစားသူဖက်က 6-7မှတ်ဖြစ်ပြီး ဒိုင်ဖက်က3/4/5မှတ်ဖြစ်ပါက ဖဲစောင့်ရမည်။ ဒိုင်ဖက်ကစုစုပေါင်းအမှတ်6မှတ်ဖြစ်ပါက ဖဲမဝေတော့ပါ။",
            gameRules23: "9နှင့်အနီးစပ်ဆုံးအမှတ်ရသောဖက်က အနိုင်ရမည်။",
            gameRules27: "ဘဏ်ဆိုင်ကတ်သုံးကတ်ထည့်ခြင်း၏ စည်းကမ်းများ",
            gameRules28: "ဘဏ်ဆိုင်၏ ပထမနှစ်ကတ်စုစုပေါင်းကျစ်ကြပ်ချက်:",
            gameRules29: "0၊ 1 သို့မဟုတ် 2 ကျစ်: ပျော်ရွှင်သည် 8 သို့မဟုတ် 9 ကျစ်ကိုရမှသာ ဘဏ်ဆိုင်ကတ်ထည့်သည်။",
            gameRules30: "(ဘဏ်3ကျစ်): ပျော်ရွှင်က ကတ်သုံးကို 0၊ 1၊ 2၊ 3၊ 4၊ 5၊ 6၊ 7 သို့မဟုတ် 9 အဆင့်များရသော် ဘဏ်ဆိုင်ကတ်ထည့်သည်။",
            gameRules31: "ပျော်ရွှင်ကတ် 8 အဆင့်ရပါက ဘဏ်ဆိုင်ကတ်မထည့်ပါ။",
            gameRules32: "(ဘဏ်4ကျစ်): ပျော်ရွှင်က ကတ်သုံးကို 2၊ 3၊ 4၊ 5၊ 6 သို့မဟုတ် 7 အဆင့်များရသော် ဘဏ်ဆိုင်ကတ်ထည့်သည်။",
            gameRules33: "ပျော်ရွှင်ကတ် 0၊ 1၊ 8 သို့မဟုတ် 9 အဆင့်ရပါက ဘဏ်ဆိုင်ကတ်မထည့်ပါ။",
            gameRules34: "(ဘဏ်5ကျစ်): ပျော်ရွှင်က ကတ်သုံးကို 4၊ 5၊ 6 သို့မဟုတ် 7 အဆင့်များရသော် ဘဏ်ဆိုင်ကတ်ထည့်သည်။",
            gameRules35: "ပျော်ရွှင်ကတ် 0၊ 1၊ 2၊ 3၊ 8 သို့မဟုတ် 9 အဆင့်ရပါက ဘဏ်ဆိုင်ကတ်မထည့်ပါ။",
            gameRules36: "(ဘဏ်6ကျစ်): ပျော်ရွှင်က ကတ်သုံးကို 6 သို့မဟုတ် 7 အဆင့်ရသော် ဘဏ်ဆိုင်ကတ်ထည့်သည်။",
            gameRules37: "ပျော်ရွှင်က ကတ် 0၊ 1၊ 2၊ 3၊ 4၊ 5၊ 8 သို့မဟုတ် 9 အဆင့်ရပါက ဘဏ်ဆိုင်ကတ်မထည့်ပါ။",
            gameRules38: "7ကျစ်: နှစ်ဖက်စလုံးကတ်မထည့်ပါ။",
            gameRules39: "8 သို့မဟုတ် 9ကျစ်: လုံခြုံစည်းကမ်းအရ မည်သည့်ဖက်မှစလုံးကတ်သုံးကိုမထည့်ပါ။",
            moreGames1:
                "ဘယ်ဂိမ်းမှာမဆို Home ခလုတ်ကိုအချိန်မရွေးရွေးလို့ရသည်။အခြားဂိမ်းကိုအချိန်မရွေးပြောင်းဆော့လို့ရသည်။ သင်ရွေးချယ်ထားသောဂိမ်းအသစ်ထဲမဝင်မချင်း လက်ရှိကစားနေသောဂိမ်းမှသင့်ကိုပယ်ထုတ်မှာမဟုတ်ပါ။",
            moreGames2: "လက်ရှိဂိမ်းကစားနေစဉ် Homeကနေတဆင့်အခြားဂိမ်းကိုဝင်ကြည့်လို့ရပါသည်။",
            moreGames3:
                "Baccarat Multiplayသည် ကစားသမားများ လျှင်မြန်စွာကြည့်ရှုနိုင်ခြင်း၊လမ်းကြောင်းများစီရင်ခြင်း၊လောင်းကြေးထည့်ခြင်းနှင့် အခြားBaccaratကစားဝိုင်းတွင်တပြိုင်နက်ကစားလို့ရသော ဂိမ်းတစ်ခုဖြစ်သည်။",
            payouts1: " ရော်ကြေး",
            payouts2: "ကော်မရှင်ပါပြီး",
            placeBets1: "ဘက်လောင်းခြင်းပိတ်ရန်အတွက် သင်ပါဝင်သောကစားဝိုင်း၏ဖဲဝေမှုအရေအတွက်ပေါ်မူတည်သည်။",
            placeBets2:
                "လောင်းကြေးသတ်မှတ်သောစာမျက်နှာတွင်အနည်းဆုံးနှင့်အများဆုံးလောင်းကြေးသတ်မှတ်ချက်ကိုဖော်ပြထားသည်။ သတ်မှတ်သည့်လောင်းကြေးများ မကြာခဏပြောင်းလဲနိုင်သည်။ လောင်းကြေးသတ်မှတ်ခြင်းကိုနှိပ်ပြီး သင်၏လောင်းကြေးသတ်မှတ်ချက်ကိုကြည့်ရှုနိုင်သည်။",
            placeBets3: "ဂိမ်းကစားလိုပါက သင့်မှာလုံလောက်သောလောင်းကြေးပမာဏရှိရမည်။ screen ပေါ်တွင်သင်၏လက်ကျန်ငွေကိုကြည့်ရှုနိုင်သည်။",
            placeBets4:
                "လောင်းကြေးထည့်သည့်အချိန်တွင် အချိန်တွက်စက်ကသင်လောင်းကြေးထည့်လို့ရသည့်အချိန်ကိုသတိပေးပါမည်။ အချိန်ကုန်ဆုံးပါက လောင်းကြေးလက်မခံတော့ပါ။",
            placeBets5: "ချစ်ပ်ပြား(chip)သည် သင်လောင်းထည့်မည့်လောင်းကြေးပမာဏဖြစ်သည်။ သင့်မှာရှိသောလောင်းကြေးပမာဏအတွက်သာသုံးလို့ရမည်။",
            placeBets6:
                "ချစ်ပ်ပြား(chip)ရွေးချယ်ပြီးပါက 'လောင်းမည်'ခလုတ်နှိပ်ရုံဖြင့် လောင်းကြေးထည့်လို့ရသည်။ သင်လောင်းထည့်သည့်ပမာဏအတိုင်းချစ်ပ်ပြား(chip)ပမာဏတိုးလာမည်။ သတ်မှတ်ထားသောအမြင့်ဆုံးလောင်းကြေးကိုသင်ရွေးပြီးပါက အခြားထပ်စောင်းလောင်းကြေးကိုလက်မခံတော့ပါ၊ သင်၏လောင်းကြေးအပေါ်တွင် 'အမြင့်ဆုံးလောင်းကြေးလောင်းထည့်မည်'ဟု Notificationပေါ်လာလိမ့်မည်။",
            placeBets7:
                "မှတ်ချက်။ ။သင်လောင်းကြေးထည့်ပြီးအချိန်တွင် windowe browser ကိုအသေးမလုပ်ပါနဲ့(သို့)အခြားလုပ်ဆောင်မှုကိုမလုပ်ပါနဲ့။ ဒီလိုလုပ်ပါက သင်ဂိမ်းကစားမှထွက်ခွာမည်ဟု သတ်မှတ်ခံရလိမ့်မည်။သင်၏လောင်းကြေးများပယ်ဖျက်လိမ့်မည်။",
            placeBets8:
                "'ပြန်လည်လုပ်ဆောင်'သောခလုတ်သည် ရှေ့တပွဲ၏လောင်းကြေးများကိုပြန်လည်လောင်းထည့်ပေးသည်။ပထမဆုံးချစ်ပ်မချထားမီဤခလုတ်ကိုအသုံးပြုနိုင်သည်။",
            placeBets9: "'နှစ်ဆ'ခလုတ်ကိုနှိပ်တိုင်း သင်၏လောင်းကြေးများနှစ်ဆဖြစ်သွားလိမ့်မည်၊သတ်မှတ်ထားသည့်အမြင့်ဆုံးလောင်းကြေးအထိနှိပ်လို့ရသည်။",
            placeBets10: "သင့်မှာလုံလောက်သောလောင်းကြေးပမာဏရှိမှသာ နှစ်ဆခလုတ်ကို အသုံးပြုနိုင်သည်။",
            placeBets11: "'ပယ်ဖျက်'ခလုတ်သည် သင်ရှေ့တစ်ပွဲလောင်းထားသောလောင်းကြေးကို ပယ်ဖျက်နိုင်သည်။",
            placeBets12: "'စုစုပေါင်းလောင်းကြေး'သည် လက်ရှိကစားဝိုင်း၏ လောင်းကြေးစုစုပေါင်းဖြစ်ပါသည်။",
            return1: "Baccaratတွင်ကစားသူအတွက်အကောင်းဆုံးပြန်လည်ရရန်（RTP）ရာနှုန်းမှာ98.94%ရှိသည်။",
            return2: "မတူညီသောလောင်းကစားဖက်မှမတူညီသောပြန်လည်ရရှိနှုန်းကိုအောက်မှာဖော်ပြထားသည်။",
            return3: "ပြန်လည်ရရှိနှန်း",
            return4: "RTPသည် ဒိုင်ဖက်ကလောင်းကြေးအပေါ်မူတည်သည်။",
            score1: "ဖိနပ်တစ်ရံကိုအသုံးပြု၍ ကစားသူ(သို့)ဒိုင်ခံအတွက်ဘေကာရက်အစီအစဥ်များနှင့်အညီ အမှတ်စာရင်းများမှတ်တမ်းတင်ထားသည်။အဲ့သည်စာရင်းများသည် သင်လက်ရှိဖိနပ်စာရင်းအရ   သင်၏လာမည့်ကစားဂိမ်းများအတွက်အထောက်အကူပြုနိုင်သည်။",
            score2: "BEAD ROADနှင့်BIG ROADသည် ပြီးခဲ့သည့်ကစားဂိမ်းတိုင်း၏ရလဒ်ကိုဖော်ပြသည်။Big Eye Road၊Small Road နှင့် Cockroach Roadသည်BIG ROADရဲ့ပုံများကိုဖော်ပြသည်။",
            score3: "ဖိနပ်အသစ်ထွက်တိုင်း လမ်းကြောင်းနှင့်ဖိနပ်အဟောင်းများ၏စုစုပေါင်းစာရင်းများကိုအကုန်ပယ်ဖျက်လိမ့်မည်။",
            score4: "BEAD ROAD",
            score5: "Beard Roadထဲရှိ ဆဲလ်များသည် ပြီးခဲ့သောကစားဝိုင်း၏ရလဒ်ဖြစ်ပြီး ဘယ်ဘက်ထောင့်မှာဖော်ပြထားပါသည်။အောက်ဘက်အထိကော်လံကိုဖတ်ပါ၊ပြီးလျှင်ညာဘက်ကပ်လျက်ကော်လံထိပ်မှစတင်ပါ။အနီရောင်သည်ဒိုင်ခံကအနိုင်ရသည်။ အပြာရောင်သည်ကစားသူကအနိုင်ရသည်။အစိမ်းရောင်သည်သားရေဖြစ်သည်။ဆဲလ်ညာဘက်ထောင့်ရှိအနီရောင်သည်ဒိုင်ခံကစုံတူဖဲရှိသည်။ဆဲလ်ဘယ်ဘက်ထောင့်အပြာရောင်သည်ကစားသူကစုံတူဖဲရှိသည်။",
            score6: "Bead Roadပြသမှုကို အင်္ဂလိပ်မှရိုးရှင်းသောတရုတ်(သို့)ရမှတ်ပုံစံသို့ မည်သည့်နေရာတွင်မဆိုနှိပ်ပြီး ပြောင်းလဲနိုင်သည်။",

            score7: "BIG ROAD",
            score8: "BIG ROADထဲတွင် ပထမဆုံးကစားပွဲ၏ရလဒ်ကို ဘယ်ဖက်ထောင့်မှာဖော်ပြထားသည်။",
            score9: "ဒိုင်ဖက်ကဆက်လက်အနိုင်ရရှိမှုသည် ကစားသူအတွက်အခွင့်အရေးကောင်းရချိန်တွင် စာရင်းအသစ်တစ်ခုတိုးလာသည်။",
            score10: "အနီရောင်ကွပ်သည်ဒိုင်နိုင်သည်၊အပြာရောင်ကွပ်သည်ကစားသူနိုင်သည်။",
            score11:
                "အစိမ်းရောင်ကွပ်သည်သားရေပွဲဖြစ်သည်။ ပထမပွဲသားရေဖြစ်ပါက အစိမ်းကွပ်အရင်ပေါ်မည်၊ပြီးလျှင်ဒိုင်(သို့)ကစားသူနိုင်ပါက အစိမ်းကွပ်အပေါ် အနီရောင်ကွပ်(သို့)အပြာရောင်ကွပ်ပေါ်လာလိမ့်မည်။",
            score12: "အကယ်၍၂ပွဲ(သို့)၂ပွဲအထက်သားရေဖြစ်ပါက သားရေလိုင်းနံပါတ်ပေါ်လိမ့်မည်။",
            score13: "ဆင်းသက်လာသောလမ်းများ",
            score14:
                "လမ်းကြောင်းဖောက်ထွင်းမှုသည် တကယ့်Baccaratဝါသနာအိုးများအတွက် Big Eye Road၊Small Road၊Cockroach Roadအပါအဝင်လမ်းများ BIG ROAD တွင်မှတ်တမ်းတင်ထားပြီးယခင်ရလဒ်များမှရရှိသည့်ပုံများကိုဖော်ပြခြင်းဖြစ်သည်။",
            score15:
                "ထိုးဖောက်ထိုးဖောက်လမ်းသွယ်များသည် ဖိနပ်၏အစကနေစတင်ခြင်းမဟုတ်ပါ၊ BIG ROAD၏ဒုတိယ၊တတိယ၊စတုတ္ထကော်လံထဲက ပထမဆုံးကစားဖက်၏နောက်ကကစားဖက်မှစတင်သည်။ ထိုးဖောက်လမ်းသွယ်စတင်ပါက ကစားပွဲတစ်ပွဲပြီးတိုင်း အနီရောင်(သို့)အပြာရောင်အကွပ်ထပ်တိုးမည်။",
            score16: "ဖိနပ်စာရင်းအင်းများ",
            score17: "ဖိနပ်စာရင်းသည်လက်ရှိဖိနပ်စာရင်းအပေါ်အခြေခံ၍အောက်ပါစာရင်းများသင့်အတွက်ပြသထားသည်။",
            score18: "စုစုပေါင်း-ယနေ့ချိန်ထိပြီးမြောက်သောပွဲရေစုစုပေါင်း",
            score19: "ကစားသူ-ယနေ့ချိန်ထိအနိုင်ရရှိသောကစားသူစုစုပေါင်း",
            score20: "ဒိုင်-ယနေ့ချိန်ထိဒိုင်ဖက်မှအနိုင်ရရှိသောစုစုပေါင်းကြိမ်ရေ",
            score21: "သားရေ-ယနေ့ချိန်ထိသားရေရသောစုစုပေါင်းကြိမ်ရေ",
            score22: "ဒိုင်(စုံတူဖဲ)-ယနေ့ချိန်ထိဒိုင်ခံဖက်မှရရှိသောစုံတူဖဲစုစုပေါင်းကြိမ်ရေ",
            score23: "ကစားသူ(စုံတူဖဲ)-ယနေ့ချိန်ထိကစားသူဖက်မှရရှိသောစုံတူဖဲစုစုပေါင်းကြိမ်ရေ",
            score24: "လမ်းကြောင်းစုံစမ်းဇယား",
            score25:
                "လမ်းကြောင်းစုံစမ်းဇယားသည် နောက်တစ်ပွဲတွင်ကစားသူ(သို့)ဒိုင်ဖက်ကအနိုင်ရရှိပါက သုံးထိုးဖောက်လမ်းသွယ်ပေါ်တွင်ပေါင်းထည့်မည့်အိုင်ကွန်ကိုဖော်ပြသည်။",
            setting1: "settingခလုတ်သည် အသုံးပြုသူပြောင်းလဲနိုင်သောဆက်တင်များ၏မီနူးကိုစတင်သည်။",
            setting2:
                "သင်ရွေးချယ်ထားသောsettingအမျိုးအစားများသည် သင်၏အချက်အလက်များတွင်သိမ်းဆည်းထားပါမည်၊ သင်ဘယ်စက်မှာမဆို loggin ဝင်ပါက သိမ်းဆည်းထားသော settingအတိုင်းအော်တိုစတင်ပါမည်။",
            setting3: "သင်၏အထွေထွေနှင့် ဂိမ်းဆက်တင်များကိုပြောင်းလဲနိုင်သည်။",
            shuffling1: "ချိုးထားသောဖဲမရောက်မချင်း ဂိမ်းဆက်လက်ကစားနေမည်။ပြီးမှ ကိုယ်စားလှယ်(သို့)ဖဲချိုးသမားက ဖဲချိုးမည်။",
            shuffling2:
                "ဖဲချိုးသောကစားဝိုင်းရှိပါက ဖိနပ်၂ရံနှင့်ဖဲ၂စုံအသုံးပြုမည်။ ကိုယ်စားလှယ်က ဖိနပ်၂ရံနှင့်ဖဲ၂စုံကိုလှဲလှယ်ပြီး ဖဲချိုးသမားကဖဲချိုးပါမည်။ ကိုယ်စားလှယ်က ကစားဂိမ်းကိုဆက်လက်လုပ်ဆောင်သွားမည်။",
            sideBets1: "ဘက်လောင်း",
            sideBets2: "ရှင်းပြချက်",
            sideBets3: "ကစားသူဖက်ကပထမဖဲနှစ်ဖဲနံပါတ်တူညီပါက ကစားသူရော်ကြေးရမည်။",
            sideBets4: "ဒိုင်ဖက်ကပထမဖဲနှစ်ဖဲနံပါတ်တူညီပါက ဒိုင်ရော်ကြေးရမည်။",
            lastWin: "နောက်ဆုံး Win",
            totalBet: "စုစုပေါင်းလောင်းကစား",
            balance: "လက်ကျန်",
            bankerVSPlayer: "ဘဏ်သမား VS ကစားသမား",
            bankerCard: "ဘဏ်လုပ်ငန်းရှင်ကတ်",
            won: "အနိုင်ရခဲ့သည်။",
            pair50: "အကြိမ် 50 ပြီးနောက် အတွဲကို လောင်း၍မရပါ။",
            newGame: "ဂိမ်းအသစ်",
        },
    },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng: "gb",
});

export default i18n;
