import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

function PlaceBets() {
    const { t } = useTranslation();

    return (
        <Typography>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Paragraph>{t("placeBets1")}</Paragraph>
                <Paragraph>{t("placeBets2")}</Paragraph>
                <Paragraph>{t("placeBets3")}</Paragraph>
                <Paragraph>{t("placeBets4")}</Paragraph>
                <Paragraph>{t("placeBets5")}</Paragraph>
                <Paragraph>{t("placeBets6")}</Paragraph>
                <Paragraph>{t("placeBets7")}</Paragraph>
                <Paragraph>{t("placeBets8")}</Paragraph>
                <Paragraph>{t("placeBets9")}</Paragraph>
                <Paragraph>{t("placeBets10")}</Paragraph>
                <Paragraph>{t("placeBets11")}</Paragraph>
                <Paragraph>{t("placeBets12")}</Paragraph>
            </Space>
        </Typography>
    );
}

export default PlaceBets;
