import React from "react";
import "./Style.scss";

function LoadingPage() {
    return <div className={`${window.innerWidth <= window.innerHeight ? "v" : "h"}-loading-container`} />;

    // return <div className="loader-container" />;
}

export default LoadingPage;
