import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import BcHowToPlay from "../../../../page-game/baccarat/how-to-play/BcHowToPlay";
import TpHowToPlay from "../../../../page-game/three-picture/how-to-play/TpHowToPlay";
import FsHowToPlay from "../../../../page-game/four-season/how-to-play/FsHowToPlay";
import RtHowToPlay from "../../../../page-game/roulette/how-to-play/RtHowToPlay";
import SbHowToPlay from "../../../../page-game/sicbo/how-to-play/SbHowToPlay";

function HowToPlay() {
  const { tableInfo } = useParams();
  const [component, setComponent] = useState();

  useEffect(() => {
    switch (tableInfo.split("_")[0]) {
      case "baccarat":
        setComponent(<BcHowToPlay />);
        break;
      case "three-picture":
        setComponent(<TpHowToPlay />);
        break;
      case "four-seasons":
        setComponent(<FsHowToPlay />);
        break;
      case "roulette":
        setComponent(<RtHowToPlay />);
        break;
      case "sicbo":
        setComponent(<SbHowToPlay />);
        break;
      default:
        setComponent(<div>Coming Soon</div>);
        break;
    }
  }, []);

  return <div style={{ color: "#ddd" }}>{component}</div>;
}

export default HowToPlay;
