import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import "./i18n";
import "swiper/css";

const root = ReactDOM.createRoot(document.getElementById("root"));

NodePlayer.load(() => {
    root.render(
        <AuthContextProvider>
            <div style={{ fontFamily: "Times New Roman" }}>
                <App />
            </div>
        </AuthContextProvider>
    );
});
