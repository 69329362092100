import React from "react";
import { useTranslation } from "react-i18next";

import { CircleFlag } from "react-circle-flags";
import { handleUpdateLanguage } from "../../../../utils/SignalR";
import { langArr } from "../../../../utils/Common";

import { Col, Row, Card, Space, Radio } from "antd";

function ChangeLanguage({ connection }) {
  const { i18n } = useTranslation();

  function handleChangeLanguage(lang) {
    i18n.changeLanguage(lang);
    handleUpdateLanguage(connection, lang);
  }

  return (
    <Row justify="center" align="middle" gutter={[0, 10]} className="setting-card">
      {langArr?.map((item, index) => (
        <Col xs={24} key={index}>
          <Card hoverable onClick={() => handleChangeLanguage(item?.key)}>
            <Row justify="center" align="middle" gutter={[0, 10]}>
              <Col xs={24}>
                <Radio checked={i18n.language === item?.key}>
                  <Space>
                    <CircleFlag countryCode={item?.key} height="20" />
                    {item?.label}
                  </Space>
                </Radio>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default ChangeLanguage;
