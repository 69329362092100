import { Avatar, Card, Col, Row, Space } from "antd";
import React from "react";
import { HistoryOutlined, SettingFilled, QuestionCircleFilled, RocketFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { checkUrl } from "../../../../utils/Common";

function DefaultMenu({ func }) {
    const { t } = useTranslation();
    const { tableInfo } = useParams();
    const navigate = useNavigate();

    const menu = [
        {
            name: t("lobby"),
            key: "lobby",
            icon: <RocketFilled style={{ color: "gold" }} />,
        },
        {
            name: t("setting"),
            key: "setting",
            icon: <SettingFilled style={{ color: "gold" }} />,
        },
        {
            name: t("gameHistory"),
            key: "game-history",
            icon: <HistoryOutlined style={{ color: "gold" }} />,
        },

        {
            name: t("how"),
            key: "how-to-play",
            icon: <QuestionCircleFilled style={{ color: "gold" }} />,
        },
    ];

    function handleMenuClick(item) {
        func(item?.key, item?.name);
    }

    return (
        <Row justify="center" align="middle" gutter={[10, 10]}>
            {menu.map((item, index) => (
                <Col key={index} xs={12} className="menu-item">
                    <Card hoverable align="center" onClick={() => handleMenuClick(item)}>
                        <Space direction="vertical">
                            <Avatar icon={item.icon} size="large" />
                            {item.name}
                        </Space>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}

export default DefaultMenu;
