import { Button, Form, Input, Modal, Select, Spin, message } from "antd";
import React, { useState } from "react";
import close from "../../assets/game/common/close.png";
import { giveFeedback } from "../../services/login-api/LoginServices";
import Swal from "sweetalert2";
import "./suggestionmodal.scss";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const { Option } = Select;

function SuggestionModal({ suggestion, setSuggestion }) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    async function handleSubmitFeedback(values) {
        setIsLoading(true);

        const finalInput = {
            playerID: localStorage.getItem("playerID"),
            playerToken: localStorage.getItem("playerToken"),
            type: values?.type,
            message: values?.feedback,
        };

        try {
            const result = await giveFeedback(finalInput);
            if (result.status) {
                Swal.fire({ text: t("success") }).then((result) => {
                    if (result.isConfirmed) {
                        setSuggestion(false);
                    }
                });
            }
        } catch (error) {
            console.log(error);
            message.error({ key: "error", content: error?.response?.data?.message });
        }
        setIsLoading(false);
    }

    return (
        <Modal
            className="suggestion-modal"
            closeIcon={<img src={close} alt="" />}
            destroyOnClose={true}
            onCancel={() => setSuggestion(false)}
            open={suggestion}
            footer={null}
            centered
        >
            <Spin spinning={isLoading}>
                <Form className="feedback-form" layout="vertical" onFinish={handleSubmitFeedback}>
                    <Form.Item
                        label="Please select feedback type"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: "Please Select",
                            },
                        ]}
                    >
                        <Select placeholder="Please select">
                            <Option value="Video Lagging">{t("videoLag")}</Option>
                            <Option value="Game Shuttering">{t("gameStutter")}</Option>
                            <Option value="Slow Game Entry">{t("slowGame")}</Option>
                            <Option value="Cluttered Interface">{t("clutteredInt")}</Option>
                            <Option value="Others">{t("others")}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Feedback"
                        name="feedback"
                        rules={[
                            {
                                required: true,
                                message: "Please Enter",
                            },
                            {
                                max: 2000,
                                message: "Max 2000 characters",
                            },
                        ]}
                    >
                        <TextArea placeholder="Your feedback" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}

export default SuggestionModal;
