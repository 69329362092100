import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import screenfull from "screenfull";
import { handleStartStreaming } from "../../utils/LiveStreaming";

import "./CFBtnView.scss";
import Icon from "./icon/Icon";

import Menu from "./menu/Menu";

import home from "../../assets/game/common/home.png";
import chatImg from "../../assets/game/common/chat.png";
import q1080 from "../../assets/game/common/1080.png";
import q720 from "../../assets/game/common/720.png";
// import mute from "../../assets/game/common/mute.png";
import fullscreen from "../../assets/game/common/fullscreen.png";
import exit from "../../assets/game/common/exit-fullscreen.png";
import menu from "../../assets/game/common/menu.png";
import table from "../../assets/game/common/table.png";
import { AuthContext } from "../../context/AuthContext";
import { formatSpaceAndToLower } from "../../utils/Formatter";
import { Badge } from "antd";

function CFBtnView({ BtnTable = false, BtnGraphic = false, connection, GraphicObj, setOpenTableList, tableDetail, chatMsg }) {
    const { tableInfo } = useParams();
    const navigate = useNavigate();

    const [graphic, setGraphic] = useState(BtnGraphic ? GraphicObj?.graphic : 1080);
    const [isFullScreen, setIsFullScreen] = useState(screenfull.isEnabled && screenfull.isFullscreen);

    const [openMenu, setOpenMenu] = useState(false);
    const [chat, setChat] = useState(false);
    const [showDot, setShowDot] = useState(false);
    const { userData } = useContext(AuthContext);

    const emojis = [
        "https://galaxy-game.sgp1.digitaloceanspaces.com/emoji/celebrate.png",
        "https://galaxy-game.sgp1.digitaloceanspaces.com/emoji/cool.png",
        "https://galaxy-game.sgp1.digitaloceanspaces.com/emoji/cry.png",
        "https://galaxy-game.sgp1.digitaloceanspaces.com/emoji/cute.png",
        "https://galaxy-game.sgp1.digitaloceanspaces.com/emoji/sad.png",
        "https://galaxy-game.sgp1.digitaloceanspaces.com/emoji/shock.png",
    ];

    useEffect(() => {
        const element = document.getElementById("chat-box");
        element.scrollTop = element.scrollHeight;

        if (chatMsg.length > 0) {
            setShowDot(true);
        }
    }, [chatMsg]);

    async function handleBack() {
        let url = tableInfo?.split("&")[1].replaceAll("|", "/");
        url ? window.location.replace(url) : navigate("/lobby/games/all");
    }

    function handleGraphic() {
        handleStartStreaming(GraphicObj?.ref, GraphicObj?.url, graphic === 1080 ? 480 : 1080, GraphicObj?.setState);
        setGraphic(graphic === 1080 ? 480 : 1080);
    }

    function handleScreenSize() {
        if (screenfull.isEnabled) {
            if (screenfull.isFullscreen) {
                screenfull.exit();
                setIsFullScreen(false);
            } else {
                screenfull.request();
                setIsFullScreen(true);
            }
        }
    }

    function handleRedirect(item) {
        navigate(`/game-table/${formatSpaceAndToLower(item?.gameType)}_${item?.redirectUrl}`);
    }

    function handleSend(emoji) {
        connection.invoke("SendMessage", {
            playerName: userData?.playerName,
            emoji: emoji,
        });
        setChat(true);
    }

    function openChat() {
        setChat(!chat);
        setShowDot(false);
    }

    return (
        <div className="cf-btn-view">
            <div className="cf-btn btn-home" onClick={() => handleBack()}>
                <Icon image={home} />
            </div>

            <div className="chat-box-input">
                {emojis?.map((item, index) => (
                    <div key={index} className="emoji" onClick={() => handleSend(item)}>
                        <img src={item} alt="" />
                    </div>
                ))}
            </div>
            <div hidden={!chat} className="chat-box">
                <div id="chat-box" className="chat-box-wrapper">
                    {chatMsg?.map((item, index) => (
                        <div key={index} className="chat">
                            {item?.msgType === "livechat" ? (
                                <div className="chat-msg">
                                    {item?.playerName}:
                                    <img src={item?.emoji} alt="" />
                                </div>
                            ) : (
                                <div className="chat-table" onClick={() => handleRedirect(item)}>
                                    <span>Good road:</span>
                                    {`${item?.gameType.toUpperCase()} - ${item?.tableCode}`}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div style={{ display: BtnTable ? "block" : "none" }} className="cf-btn btn-table" onClick={() => setOpenTableList((prev) => !prev)}>
                <Icon image={table} />
            </div>

            <div style={{ display: BtnGraphic ? "block" : "none" }} className="cf-btn btn-vid-graphic" onClick={() => handleGraphic()}>
                <Icon image={graphic === 1080 ? q1080 : q720} />
            </div>

            <div
                className="cf-btn btn-fullscreen"
                style={{ display: /iPhone/.test(window.navigator.userAgent) ? "none" : "block" }}
                onClick={() => handleScreenSize()}
            >
                <Icon image={isFullScreen ? exit : fullscreen} />
            </div>

            <div className="cf-btn btn-chat" onClick={() => openChat()}>
                <Badge dot={showDot}>
                    <Icon image={chatImg} />
                </Badge>
            </div>

            <div className="cf-btn btn-menu" onClick={() => setOpenMenu(!openMenu)}>
                <Icon image={menu} />
            </div>

            <Menu connection={connection} tableInfo={tableInfo} openMenu={openMenu} setOpenMenu={setOpenMenu} />
        </div>
    );
}

export default CFBtnView;
