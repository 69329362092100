import React from "react";

function Dice({ point = 0 }) {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <svg width="100%" height="100%" preserveAspectRatio="none" style={{ background: "transparent" }}>
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" fill="red" />
        {point === 1 && <circle cx="50%" cy="50%" r="8%" fill="white" />}

        {point === 2 && <circle cx="25%" cy="50%" r="8%" fill="white" />}
        {point === 2 && <circle cx="75%" cy="50%" r="8%" fill="white" />}

        {point === 3 && <circle cx="25%" cy="25%" r="8%" fill="white" />}
        {point === 3 && <circle cx="50%" cy="50%" r="8%" fill="white" />}
        {point === 3 && <circle cx="75%" cy="75%" r="8%" fill="white" />}

        {point === 4 && <circle cx="25%" cy="25%" r="8%" fill="white" />}
        {point === 4 && <circle cx="25%" cy="75%" r="8%" fill="white" />}
        {point === 4 && <circle cx="75%" cy="25%" r="8%" fill="white" />}
        {point === 4 && <circle cx="75%" cy="75%" r="8%" fill="white" />}

        {point === 5 && <circle cx="25%" cy="25%" r="8%" fill="white" />}
        {point === 5 && <circle cx="25%" cy="75%" r="8%" fill="white" />}
        {point === 5 && <circle cx="50%" cy="50%" r="8%" fill="white" />}
        {point === 5 && <circle cx="75%" cy="25%" r="8%" fill="white" />}
        {point === 5 && <circle cx="75%" cy="75%" r="8%" fill="white" />}

        {point === 6 && <circle cx="25%" cy="25%" r="8%" fill="white" />}
        {point === 6 && <circle cx="25%" cy="50%" r="8%" fill="white" />}
        {point === 6 && <circle cx="25%" cy="75%" r="8%" fill="white" />}
        {point === 6 && <circle cx="75%" cy="25%" r="8%" fill="white" />}
        {point === 6 && <circle cx="75%" cy="50%" r="8%" fill="white" />}
        {point === 6 && <circle cx="75%" cy="75%" r="8%" fill="white" />}
      </svg>
    </div>
  );
}

export default Dice;
