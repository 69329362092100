import { Descriptions } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

function SideBets() {
    const { t } = useTranslation();

    return (
        <Descriptions column={1} bordered>
            <Descriptions.Item label={t("sideBets1")}>{t("sideBets2")}</Descriptions.Item>
            <Descriptions.Item label="P Pair">{t("sideBets3")}</Descriptions.Item>
            <Descriptions.Item label="B Pair">{t("sideBets4")}</Descriptions.Item>
        </Descriptions>
    );
}

export default SideBets;
