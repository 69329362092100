import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../context/AuthContext";
import { useChips } from "../../../hook/useChips";
import { useBetPanel } from "../../../hook/useBetPanel";

import "./VChips.scss";
import { Switch, message } from "antd";

import Chip from "../chip/Chip";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import confirm from "../../../assets/game/common/confirm.png";
import clear from "../../../assets/game/common/clear.png";
import undo from "../../../assets/game/common/undo.png";
import double from "../../../assets/game/common/x2.png";
import rebet from "../../../assets/game/common/rebet.png";
import ChipSetting from "../chip-setting/ChipSetting";
import setting from "../../../assets/game/common/chip.png";

import { Swiper, SwiperSlide } from "swiper/react";

function VChips({ connection, tableInfo, ChipsSet, tableDetail, dealerSignal, totalBet, setTotalBet, setLabelBet, roadMaps }) {
    const { t } = useTranslation();
    const { setBalance } = useContext(AuthContext);
    const { selectedChip, handleSelectChip, handleDefaultSelectedChip } = useChips();
    const { handleRemoveAllChip, handleConfirmBet, handleUndoBet, handleReBet, handleDoubleBet, handleUpdateBetStatistic } = useBetPanel();
    const [chipSetting, setChipSetting] = useState(false);
    const [swiperRef, setSwiperRef] = useState();

    useEffect(() => {
        handleDefaultSelectedChip(ChipsSet);
    }, [ChipsSet]);

    async function handleUndo() {
        if (dealerSignal?.action === "new-game") {
            try {
                const result = await handleUndoBet(dealerSignal);

                setTotalBet((prev) => prev - result?.amount);
                setBalance((prev) => prev + result?.amount);
                handleUpdateBetStatistic(connection, tableInfo, "DeductBetStatistic", result?.betCode, { curValue: result?.amount });
            } catch (error) {
                message.info({ content: error, key: error });
            }
        }
    }

    async function handleBetAgain() {
        if (dealerSignal?.action === "new-game") {
            try {
                setLabelBet((prev) => (totalBet > 0 ? prev : t("totalBet")));
                const result =
                    totalBet > 0
                        ? await handleDoubleBet(tableDetail, dealerSignal, ChipsSet)
                        : await handleReBet(tableDetail, dealerSignal, ChipsSet, roadMaps);

                setTotalBet(result?.totalBet);
                result?.betItems?.forEach((item) => {
                    handleUpdateBetStatistic(connection, tableInfo, "AddBetStatistic", item.betCode, { curValue: item.amount });
                });
            } catch (error) {
                message.info({ content: error, key: error });
            }
        }
    }

    async function handleClear() {
        if (dealerSignal?.action === "new-game") {
            try {
                await handleRemoveAllChip(dealerSignal?.gameSummarySrno, true, 1, setTotalBet, connection, tableInfo);
            } catch (error) {
                message.info({ content: error, key: error });
            }
        }
    }

    return (
        <div className="nv-chips">
            <div className="wrapper-1">
                <div className="btn-function" onClick={() => handleBetAgain()}>
                    <Chip image={totalBet > 0 ? double : rebet} text="" isSelected={false} />
                </div>
                <div id="confirm-btn" className="btn-function-1" onClick={() => handleConfirmBet(connection, dealerSignal, tableInfo)}>
                    <Chip image={confirm} text="" isSelected={false} />
                </div>
                <div className="btn-function" onClick={() => handleUndo()}>
                    <Chip image={undo} text="" isSelected={false} />
                </div>
                <div className="btn-function-1" onClick={() => handleClear()}>
                    <Chip image={clear} text="" isSelected={false} />
                </div>
                <div className="btn-function" onClick={() => setChipSetting(!chipSetting)}>
                    <Chip image={setting} text="" isSelected={false} />
                </div>
            </div>
            <div className="wrapper-2">
                <LeftOutlined style={{ color: "#ddd" }} onClick={() => swiperRef?.slidePrev()} />
                <Swiper className="chips-container" slidesPerView={8} onSlideChange={() => console.log("slide change")} onSwiper={setSwiperRef}>
                    {ChipsSet?.map((items, index) => (
                        <SwiperSlide key={index}>
                            <div className={`btn-chip ${selectedChip?.value === items.value}`} onClick={() => handleSelectChip(items)}>
                                <Chip image={items.image} text={items.value} isSelected={selectedChip?.value === items.value} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <RightOutlined style={{ color: "#ddd" }} onClick={() => swiperRef?.slideNext()} />
            </div>

            {chipSetting && <ChipSetting chipSetting={chipSetting} setChipSetting={setChipSetting} ChipsSet={ChipsSet} tableDetail={tableDetail} />}
        </div>
    );
}

export default VChips;
