import React from "react";

import "./vbetpanel.scss";
import {
  arrBetPanel,
  arrBetPanel2,
  arrBetPanel3,
} from "../bet-panel/ArrayBetPanel";
import { useFourSeasonBetPanel } from "../../../four-season/hook/useFourSeasonBetPanel";
import { useBetPanel } from "../../../../hook/useBetPanel";
import { message } from "antd";
import { useDreamCatcher } from "../../hook/useDreamCatcher";

function VBetPanel({
  tableInfo,
  connection,
  chipArr,
  rate,
  tableDetail,
  dealerSignal,
  setTotalBet,
  betStatistic,
  setLabelBet,
  cardResult,
}) {
  const { handleDisplayRate, handlePlaceBet } = useBetPanel();
  const {
    handlePlaceBetFourSeason,
    handlePlaceChipFourSeasonFixed,
    handleUpdateBetStatistic,
  } = useDreamCatcher();

  async function handleBet(key, e) {
    if (dealerSignal?.action === "new-game") {
      try {
        const callApi = handlePlaceBet(
          key,
          tableDetail,
          dealerSignal,
          undefined
        );
        const result = await callApi;
        setTotalBet(result?.totalBet);
        handlePlaceChipFourSeasonFixed(
          chipArr,
          key,
          { image: result?.image, value: result?.curBetCode },
          result?.uniqueID,
          result?.roundUniqueId
        );
        handleUpdateBetStatistic(
          connection,
          tableInfo,
          "AddBetStatistic",
          key,
          result
        );
        setLabelBet("totalBet");
      } catch (error) {
        message.info({ content: error, key: error });
      }
    }
  }
  return (
    <div className="vbet-panel-dream-catcher">
      <div className="blue-red-number-container">
        {arrBetPanel?.map((items) => (
          <div
            className={`bet-items ${items.title}`}
            style={{ background: items.color }}
            key={items.title}
            data-id={items.title}
            onClick={() => handleBet(items.title)}
          >
            <img src={items.image} alt={items.title} className="animal" />
            <div className="title">{items.title}</div>
          </div>
        ))}
      </div>

      <div className="blue-red-number-container">
        {arrBetPanel2?.map((items) => (
          <div
            className={`bet-items ${items.title}`}
            style={{ background: items.color }}
            key={items.title}
            data-id={items.title}
            onClick={() => handleBet(items.title)}
          >
            <img src={items.image} alt={items.title} className="animal" />
            <div className="title">{items.title}</div>
          </div>
        ))}
      </div>

      <div className="blue-red-odd-even-container">
        {arrBetPanel3?.map((items) => (
          <div
            className={`bet-items ${items.title}`}
            style={{ background: items.color }}
            key={items.title}
            data-id={items.title}
            onClick={() => handleBet(items.title)}
          >
            <div className="title">{items.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VBetPanel;
