import React, { useState, useEffect, useRef, useContext } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Swal from "sweetalert2";
import { AuthContext } from "../../context/AuthContext";
import { handleConnectSignalR, handleStopAudio } from "../../utils/Common";

import TableCategory from "./table-category/TableCategory";
import LoadingPage from "../loading-page/LoadingPage";

import LobbyHeader1 from "./lobby-header/lobby-header-demo/LobbyHeader1";
import TableList1 from "./table-list/table-list-demo/TableList1";

import "./Style.scss";
import { Divider, Space, message } from "antd";
import Timer from "../../components-game/timer/Timer";
import { renderToString } from "react-dom/server";
import TimerLobby from "../../components-game/timer-lobby/TimerLobby";
import { useTranslation } from "react-i18next";

function Lobby() {
    const navigate = useNavigate();
    const { category } = useParams();

    const { bgMusic, setIsMuted, setMaintenanceInfo, userData, setUserData } = useContext(AuthContext);

    const { t } = useTranslation();

    const [lobbyUser, setLobbyUser] = useState(0);
    const [activeUser, setActiveUser] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCatLoading, setIsCatLoading] = useState(false);
    const [connection, setConnection] = useState();
    const [dealerSignalLobby, setDealerSignalLobby] = useState();
    const [isFavorite, setIsFavorite] = useState(false);

    const tableListRef = useRef();
    const [tableList, setTableList] = useState([]);

    useEffect(() => {
        handleConnectSignalR(setConnection, process.env.REACT_APP_LOBBY_URL);
    }, []);

    useEffect(() => {
        connection && handleConnectionOn();
        handleStopAudio(bgMusic);
        setIsMuted(true);
    }, [connection]);

    useEffect(() => {
        connection && handleGetTableList();
        connection && connection.off("update-favorite-response");
        connection &&
            connection.on("update-favorite-response", (result) => {
                console.log(category, isFavorite);
                if (result.status) {
                    connection.invoke("GetTableListLobby", {
                        playerID: localStorage.getItem("playerID"),
                        playerToken: localStorage.getItem("playerToken"),
                        category: category,
                        lang: userData?.lang,
                        isFavorite: isFavorite,
                    });
                } else {
                    Swal.fire({
                        text: result.message,
                        icon: "error",
                    });
                }
            });
    }, [category, isFavorite]);

    async function handleGetTableList() {
        setIsCatLoading(true);
        connection.invoke("GetTableListLobby", {
            playerID: localStorage.getItem("playerID"),
            playerToken: localStorage.getItem("playerToken"),
            category: category,
            lang: userData?.lang,
            isFavorite: isFavorite,
        });
    }

    async function handleConnectionOn() {
        try {
            handleGetTableList();

            connection.onreconnected((connectionId) => {
                setIsCatLoading(true);
                connection.invoke("GetTableListLobby", {
                    playerID: localStorage.getItem("playerID"),
                    playerToken: localStorage.getItem("playerToken"),
                    category: category,
                    lang: userData?.lang,
                    isFavorite: isFavorite,
                });
            });

            connection.on("table-list-lobby", (result) => {
                if (result.status) {
                    tableListRef.current = result.data;
                    setTableList(result.data);
                    setCategoryList(result.data2);
                } else {
                    Swal.fire({
                        text: result.message,
                        icon: "error",
                    }).then(() => window.location.replace("/error"));
                }
                requestAnimationFrame(() => {
                    setIsLoading(false);
                    setIsCatLoading(false);
                });
            });

            connection.on("table-list-route", (result) => {
                result.forEach((table) => {
                    let tableIndex = tableListRef.current.findIndex((item) => item.tableCode === table?.tableCode && item.type === table?.type);

                    if (tableIndex >= 0) {
                        let updatedTable = Object.assign({}, tableListRef.current[tableIndex]);
                        updatedTable.roadmap = table.roadmap;

                        let newTablelist = tableListRef.current.slice();
                        newTablelist[tableIndex] = updatedTable;
                        tableListRef.current = newTablelist;
                        setTableList(tableListRef.current);
                    }
                });
            });

            connection.on("player-info", (result) => {
                if (result.status) {
                    setUserData(result.data);
                    connection.invoke("GetTableListLobby", {
                        playerID: localStorage.getItem("playerID"),
                        playerToken: localStorage.getItem("playerToken"),
                        category: category,
                        lang: result?.data?.lang,
                    });
                } else {
                    Swal.fire({
                        text: result.message,
                        icon: "error",
                    });
                }
            });

            connection.on("active-user", (result) => {
                const activeUserResult = result.split(",");
                if (activeUserResult?.length !== 0) {
                    const activeUserData = activeUserResult[0].split(":");
                    setLobbyUser(Number(activeUserData[1]));
                    setActiveUser(activeUserResult);
                }
            });

            connection.on("api-error", (result) => {
                message.error({
                    content: result,
                    key: "api-error",
                });
            });

            connection.on("session-expired", async () => {
                await connection.stop();
                setConnection();
                localStorage.removeItem("playerID");
                localStorage.removeItem("playerToken");
                window.location.replace("/");
            });

            connection.on("maintenance-in-progress", (result) => {
                setIsMuted(true);
                handleStopAudio(bgMusic);
                setMaintenanceInfo(result);
                navigate("/maintenance");
            });

            connection.on("recommended-table", (result) => {
                // console.log("recommended-table", result);
            });

            connection.on("dealer-signal", (result) => {
                if (result?.second !== 0) {
                    //get main container info
                    // const main_container = document.querySelector(
                    //   `[data-id="${result?.tableID}"]`
                    // );
                    const action = document.querySelector(`[data-id="action-${result?.tableID}"]`);
                    // if (action !== undefined && main_container !== undefined) {
                    if (action !== null) {
                        if (result?.action === "new-game") {
                            action.innerHTML = t("newGame");
                        }
                        //create timer container with data-id
                        const timer = document.querySelector(`[data-id="timer-${result?.tableID}"]`);
                        timer.innerHTML = renderToString(<TimerLobby dealerSignal={result} />);
                    }
                } else {
                    const action = document.querySelector(`[data-id="action-${result?.tableID}"]`);
                    if (action !== undefined) {
                        if (result?.action === "stop-bet") {
                            action.innerHTML = t("stopBet");
                        } else if (result?.action === "last-game") {
                            action.innerHTML = t("lastGame");
                        }
                    }
                }
            });
        } catch (error) {
            message.error({
                content: result,
                key: "connection-on",
            });
        }
    }

    if (isLoading) {
        return <LoadingPage />;
    }

    return (
        <div className="lobby-bg">
            <LobbyHeader1 activeUser={activeUser} lobbyUser={lobbyUser} connection={connection} setConnection={setConnection} />
            <Space direction="vertical" style={{ width: "100%", padding: "0px 20px" }}>
                <div style={{ overflowX: "auto" }}>
                    <TableCategory setIsFavorite={setIsFavorite} categoryList={categoryList} isLoading={isLoading} />
                </div>
                <Divider style={{ margin: 0, borderBottom: "1px solid #e6b311" }} />
                <TableList1
                    isFavorite={isFavorite}
                    setIsFavorite={setIsFavorite}
                    activeUser={activeUser}
                    tableListRef={tableListRef}
                    connection={connection}
                    isCatLoading={isCatLoading}
                    setIsCatLoading={setIsCatLoading}
                    tableList={tableList}
                />
            </Space>
        </div>
    );
}

export default Lobby;
